import {Button} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectFetching} from "../../../../data/reselects/subscriptionSelector";

const InfoPackages = props => {

    return (
        <div className="container px-0">
            <div className="row package-subscription__detail__package--wrapper">
                <div className="col package-subscription__detail__package--item">
                    <div className="package-subscription__detail__package--title">
                        <h4 className="text-center fw-bold mb-2">Free-Trial</h4>
                    </div>
                </div>
                <div className="col package-subscription__detail__package--item">
                    <div className="package-subscription__detail__package--title">
                        <h4 className="text-center fw-bold mb-2">Standard</h4>
                        <p className="text-center m-0 p-0">Limited</p>
                        <div className="text-center mt-2">
                            <Button type="primary" htmlType="button">
                                View Information
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col package-subscription__detail__package--item">
                    <div className="package-subscription__detail__package--title">
                        <h4 className="text-center fw-bold mb-2">Premium</h4>
                        <p className="text-center m-0 p-0">Unlimited</p>
                        <div className="text-center mt-2">
                            <Button type="primary" htmlType="button">
                                View Information
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPackages
