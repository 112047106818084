import moment from "moment"
import {DATE_TIME_FORMAT} from "../../constants/define";

export default class User {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || undefined;
        this.avatar = data?.avatar || undefined;
        this.username = data?.username || undefined;
        this.email = data?.email || undefined;
        this.password = data?.password || undefined;
        this.first_name = data?.first_name || undefined;
        this.last_name = data?.last_name || undefined;
        this.status = data?.status || 1;
        this.menu = data?.menu || [];
        this.phone_number = data?.phone_number || undefined;
        this.is_edit_data = data?.is_edit_data || false;
        this.api_key = data?.api_key || "";
        this.credit = data?.credit || "";
        this.is_use_credit = data?.is_use_credit;
        this.account_manager = {
            email: data?.account_manager?.email || "",
            id: data?.account_manager?.id || "",
            name: data?.account_manager?.name || "",
        }
    }

    profile(data) {
        this.id = data.id || undefined;
        this.avatar = data.avatar || undefined;
        this.username = data.username || undefined;
        this.email = data.email || undefined;
        this.status = data.status || 1;
        this.menu = data.menu || [];
        this.is_edit_data = data.is_edit_data || false;
        this.account_manager = data.account_manager || "";
        this.api_key = data.api_key || "";
        this.credit = data.credit || "";
        this.is_use_credit = data.is_use_credit || "";
        return this
    }

    setPermission(data = {}) {
        this.permissions = data.permissions || [];
    }

    setProperties(data = {}) {
        this.propertyIds = data.propertyIds || [];
    }

    actionRegister() {
        return {
            email: this.email,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
        }
    }

    export() {
        return {
            id: this.id,
            password: this.password,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            username: this.username,
            phoneNumber: this.phoneNumber,
            avatar: this.avatar,
        }
    }
}

export class CreditResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.user = data?.user || "";
        this.before_credit = data?.before_credit || "";
        this.credit = data?.credit || "";
        this.after_credit = data?.after_credit || "";
        this.actor = data?.actor || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        this.note = data?.note || "";
    }

    exportListHistory() {
        return {
            id: this.id,
            user: this.user,
            before_credit: this.before_credit,
            credit: this.credit,
            after_credit: this.after_credit,
            actor: this.actor,
            created_at: this.created_at,
            note: this.note,
        }
    }
}

export const customerCreditCols = ["created_at", "before_credit", "credit", "after_credit", "actor", "note"];
