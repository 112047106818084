import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Data';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/dataService';
import * as FunctionAction from '../actions/dataAction';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import DataRequest, {filtersPreForeClosure} from "../mapping/Request/DataRequest";
import moment from "moment";
import {offMarketFiltersRequest} from "../mapping/CommonMapping";

function* actionGetDataMasterLeadType() {
    yield takeLatest(Types.DATA_MASTER_LEAD_TYPE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterDataLeadType, action.params);
        yield put(FunctionAction.dataMasterLeadTypeSuccess(result));
    }, errorHandle(Types.DATA_FAIL)))
}

function* actionGetDataMasterPreForeClosure() {
    yield takeLatest(Types.DATA_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterDataPreForeClosure, action.params);
        yield put(FunctionAction.dataMasterPreForeClosureSuccess(result));
    }, errorHandle(Types.DATA_FAIL)))
}

function* actionGetDataPreForeClosureList() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionDataPreForeClosureList, params);
        yield put(FunctionAction.dataPreForeClosureListSuccess(result));
    }, errorHandle(Types.DATA_FAIL)))
}

function* actionGetDataPreForeClosureExport() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionDataPreForeClosureExport, params);
        const fileName = ("Pre_Foreclosure_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.dataPreForeClosureExportFavoriteSuccess("ok"));
    }, errorHandle(Types.DATA_FAIL)))
}

function* actionGetDataPreForeClosureDetail() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionDataPreForeClosureDetail, action.params.id);
        yield put(FunctionAction.dataPreForeClosureDetailSuccess(result));
    }, errorHandle(Types.DATA_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.dataPreForeClosureUnlockSuccess(result));
        yield put(FunctionAction.dataPreForeClosureDetailAction({id: action.params.id}));
    }, errorHandle(Types.DATA_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new DataRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.dataPreForeClosureDetailAction({id: action.params.id}));
    }, errorHandle(Types.DATA_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.dataPreForeClosureDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.dataPreForeClosureListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.DATA_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.dataPreForeClosureFavoriteSuccess(result));
        yield put(FunctionAction.dataPreForeClosureListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.DATA_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.dataPreForeClosureDeleteFavoriteSuccess({}));
        yield put(FunctionAction.dataPreForeClosureListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.DATA_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* dataSaga() {
    yield all([
        fork(actionGetDataMasterLeadType),
        fork(actionGetDataPreForeClosureList),
        fork(actionGetDataPreForeClosureExport),
        fork(actionGetDataMasterPreForeClosure),
        fork(actionGetDataPreForeClosureDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
    ])
}
