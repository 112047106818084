import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/Ticket';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/ticketService';
import * as FunctionAction from '../actions/ticketAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import TicketRequest from "../mapping/Request/TicketRequest";

function* actionGetList() {
    yield takeLatest(Types.TICKET_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTicketList, action?.filters);
        yield put(FunctionAction.ticketListSuccess(result));
    }, errorHandle(Types.TICKET_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.TICKET_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTicketDetail, action.params.id);
        yield put(FunctionAction.ticketDetailSuccess(result));
    }, errorHandle(Types.TICKET_FAIL)))
}

function* actionCreate() {
    yield takeLatest(Types.TICKET_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const paramSubmit = new TicketRequest(action.params).exportCreate(),
            result = yield call(FunctionService.create, paramSubmit);
        yield put(FunctionAction.ticketCreateSuccess(result));
        message.success(result)
        yield put(FunctionAction.ticketListAction({id: action.params.id}));
    }, errorHandle(Types.TICKET_FAIL)))
}

function* actionComment() {
    yield takeLatest(Types.TICKET_COMMENT_ACTION, Func.sagaWrapper(function* (action) {
        const paramSubmit = new TicketRequest(action.params).exportComment(),
            result = yield call(FunctionService.comment, paramSubmit);
        yield put(FunctionAction.ticketCommentSuccess(result));
        yield put(FunctionAction.ticketDetailAction({id: paramSubmit.id}));
    }, errorHandle(Types.TICKET_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* ticketSaga() {
    yield all([
        fork(actionGetList),
        fork(actionCreate),
        fork(actionComment),
        fork(actionGetDetail),
    ])
}
