import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Invoice';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/invoiceService';
import * as FunctionAction from '../actions/invoiceAction';
import {message} from "antd";
import InvoiceRequest, {filtersParams} from "../mapping/Request/InvoiceRequest";
import TAG_DEFINE from "../../constants/common";
import moment from "moment";
import {downloadFile} from "../../utils/functions";

function* actionGetList() {
    yield takeLatest(Types.INVOICE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionList, params);
        yield put(FunctionAction.invoiceListSuccess(result));
    }, errorHandle(Types.INVOICE_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.INVOICE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionExport, action?.filters?.id);
        const fileName = ("Invoice_" + action?.filters?.code + ".pdf");
        downloadFile(result, fileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.invoiceExportSuccess("ok"));
    }, errorHandle(Types.INVOICE_FAIL)))
}

// function* actionGetItem() {
//     yield takeLatest(Types.INVOICE_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.actionGetDetail);
//         yield put(FunctionAction.invoiceDetailSuccess(result));
//     }, errorHandle(Types.INVOICE_FAIL)))
// }

// function* actionGetMasterInvoice() {
//     yield takeLatest(Types.INVOICE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.masterInvoice, action.params);
//         yield put(FunctionAction.invoiceMasterSuccess(result));
//     }, errorHandle(Types.INVOICE_FAIL)))
// }
//
// function* createAction() {
//     yield takeLatest(Types.INVOICE_CREATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new InvoiceRequest(action.params).exportCreate();
//         const result = yield call(FunctionService.create, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
//         action.params.props.history.goBack();
//         yield put({type: Types.INVOICE_LIST_ACTION});
//     }, errorHandle(Types.INVOICE_FAIL)))
// }
//
// function* updateAction() {
//     yield takeLatest(Types.INVOICE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new InvoiceRequest(action.params).exportUpdate();
//         yield call(FunctionService.update, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
//         yield put(FunctionAction.invoiceDetailAction({id: action.params.id}));
//     }, errorHandle(Types.INVOICE_FAIL)))
// }

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* invoiceSaga() {
    yield all([
        fork(actionGetList),
        fork(actionGetExport),
        // fork(createAction),
        // fork(actionGetMasterInvoice),
        // fork(actionGetItem),
        // fork(updateAction),
    ])
}
