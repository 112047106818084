import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/ApptOfSubTrustee';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/apptOfSubTrusteeService';
import * as FunctionAction from '../actions/apptOfSubTrusteeAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import ApptOfSubTrusteeRequest, {filtersParams} from "../mapping/Request/ApptOfSubTrusteeRequest";
import moment from 'moment'
import {offMarketFiltersRequest} from "../mapping/CommonMapping";

function* actionGetMaster() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterApptOfSubTrustee, action.params);
        yield put(FunctionAction.apptOfSubTrusteeMasterSuccess(result));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* actionGetList() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionApptOfSubTrusteeList, params);
        yield put(FunctionAction.apptOfSubTrusteeListSuccess(result));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionApptOfSubTrusteeExport, params);
        const fileName = ("Appointment_of_Substitute_Trustee_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.apptOfSubTrusteeExportFavoriteSuccess("ok"));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionApptOfSubTrusteeDetail, action.params.id);
        yield put(FunctionAction.apptOfSubTrusteeDetailSuccess(result));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.apptOfSubTrusteeUnlockSuccess(result));
        yield put(FunctionAction.apptOfSubTrusteeDetailAction({id: action.params.id}));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ApptOfSubTrusteeRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.apptOfSubTrusteeDetailAction({id: action.params.id}));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.heirshipDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.apptOfSubTrusteeListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.apptOfSubTrusteeFavoriteSuccess(result));
        yield put(FunctionAction.apptOfSubTrusteeListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.apptOfSubTrusteeDeleteFavoriteSuccess({}));
        yield put(FunctionAction.apptOfSubTrusteeListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.APPT_OF_SUB_TRUSTEE_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* apptOfSubTrusteeSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetList),
        fork(actionGetExport),
        fork(actionGetDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
    ])
}
