export const TICKET_LIST_ACTION = "TICKET_LIST_ACTION";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";

export const TICKET_CREATE_ACTION = "TICKET_CREATE_ACTION";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";

export const TICKET_COMMENT_ACTION = "TICKET_COMMENT_ACTION";
export const TICKET_COMMENT_SUCCESS = "TICKET_COMMENT_SUCCESS";

export const TICKET_DETAIL_ACTION = "TICKET_DETAIL_ACTION";
export const TICKET_DETAIL_SUCCESS = "TICKET_DETAIL_SUCCESS";

export const TICKET_INIT = "TICKET_INIT";
export const TICKET_FAIL = "TICKET_FAIL";
