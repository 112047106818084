import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class AuthRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.avatar = data?.avatar || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.token_captcha = data?.token_captcha || "";
        this.password = data?.password || "";
        this.phone_number = data?.phone_number || "";
        this.otp = data?.otp || "";
        this.hash = data?.hash || "";
        this.api_key = data?.api_key || "";
        this.credit = data?.credit || "";
        this.is_use_credit = formatInt(data?.is_use_credit);
    }

    exportUpdate() {
        return removeObjectNullFull({
            id: this.id,
            // avatar: this.avatar,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            phone_number: this.phone_number,
            api_key: this.api_key,
            credit: this.credit,
            is_use_credit: this.is_use_credit,
        })
    }

    exportPasswordRequest() {
        return {
            email: this.email,
            token_captcha: this.token_captcha,
        }
    }

    exportPasswordReset() {
        return {
            password: this?.password,
            token_captcha: this?.token_captcha,
            otp: this?.otp,
            hash: this?.hash,
        }
    }
}
