import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/packagePayment'

export const ActionPackageCreateOrder = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.packagePaymentCreateOrderAction(params)),
        [dispatch]
    )
}

export const ActionPackagePaymentInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.packagePaymentInitStore(params)),
        [dispatch]
    )
}

