import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class PlansPricingRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.package_id = data?.package_id || "";
        this.code = data?.code || "";
        this.state = data?.select_state || [];
        this.city = data?.select_city || [];
        this.metro = data?.select_metro || [];
        this.county = data?.select_county || [];
    }

    exportCreate() {
        return {
            package_id: this.package_id,
            code: this.code,
            state: this.state,
            city: this.city,
            metro: this.metro,
            county: this.county,
        }
    }

    exportUpdate() {
        return {
            package_id: this.package_id,
            code: this.code,
            state: this.state,
            city: this.city,
            metro: this.metro,
            county: this.county,
        }
    }
}
