export const TEMPLATE_GENERAL_ACTION = "TEMPLATE_GENERAL_ACTION";
export const TEMPLATE_GENERAL_SUCCESS = "TEMPLATE_GENERAL_SUCCESS";

export const TEMPLATE_MARKET_LEAD_FILTERS_ACTION = "TEMPLATE_MARKET_LEAD_FILTERS_ACTION";
export const TEMPLATE_MARKET_LEAD_FILTERS_SUCCESS = "TEMPLATE_MARKET_LEAD_FILTERS_SUCCESS";

export const TEMPLATE_MARKET_LEAD_CREATE_FILTERS_ACTION = "TEMPLATE_MARKET_LEAD_CREATE_FILTERS_ACTION";
export const TEMPLATE_MARKET_LEAD_CREATE_FILTERS_SUCCESS = "TEMPLATE_MARKET_LEAD_CREATE_FILTERS_SUCCESS";

export const TEMPLATE_MARKET_LEAD_DELETE_FILTERS_ACTION = "TEMPLATE_MARKET_LEAD_DELETE_FILTERS_ACTION";
export const TEMPLATE_MARKET_LEAD_DELETE_FILTERS_SUCCESS = "TEMPLATE_MARKET_LEAD_DELETE_FILTERS_SUCCESS";

export const TEMPLATE_READ_NOTIFY_ACTION = "TEMPLATE_READ_NOTIFY_ACTION";
export const TEMPLATE_READ_NOTIFY_SUCCESS = "TEMPLATE_READ_NOTIFY_SUCCESS";

export const TEMPLATE_INIT = "TEMPLATE_INIT";
export const TEMPLATE_FAIL = "TEMPLATE_FAIL";
