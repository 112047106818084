import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import InvoiceResponse from "../mapping/Response/InvoiceResponse";

// export function masterContact() {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_MASTER_DATA,
//         // params: removeObjectNull({ city_id }),
//         parser: data => new ContactMasterResponse(data.data.result).exportMaster(),
//     }).get();
// }

export function actionList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.INVOICE_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionExport(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.INVOICE_EXPORT,
        endpointParams: {id},
        isDownload: true,
        parser: data => data,
    }).get();
}

// export function actionGetDetail(id) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.BILL_DETAIL,
//         // endpointParams: {id},
//         parser: data => new InvoiceResponse(data.data.result).exportDetail(),
//     }).get();
// }

// export function update(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_UPDATE,
//         endpointParams: {id: data?.id},
//     }).put(data);
// }
//
// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_CREATE,
//     }).post(data);
// }

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new InvoiceResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
