import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/taxSaleAction'
import {ActionPreForeClosureDelete} from "./data";

export const ActionMasterTaxSale = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleMasterAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleListAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleDetailAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleUpdateAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleDeleteAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleUnlockAction(params)),
        [dispatch]
    )
}

export const ActionTaxSaleInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taxSaleDeleteFavoriteAction(params)),
        [dispatch]
    )
}
