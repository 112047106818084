import {createSelector} from 'reselect'

const stateReducer = state => state.probateHeirshipReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        probateHeirshipReducer => probateHeirshipReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        probateHeirshipReducer => probateHeirshipReducer.statusAction
    )

export const selectMasterProbateHeirship = (initItems) =>
    createSelector(
        stateReducer,
        probateHeirshipReducer => probateHeirshipReducer.master
    )

export const selectProbateHeirshipList = (initItems) =>
    createSelector(
        stateReducer,
        probateHeirshipReducer => probateHeirshipReducer.data
    )


export const selectProbateHeirshipDetail = (initItems) =>
    createSelector(
        stateReducer,
        probateHeirshipReducer => probateHeirshipReducer.detail
    )
