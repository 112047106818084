import {createSelector} from 'reselect'

const stateReducer = state => state.plansPricingReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        plansPricingReducer => plansPricingReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        plansPricingReducer => plansPricingReducer.statusAction
    )

export const selectPlansPricingMaster = (initItems) =>
    createSelector(
        stateReducer,
        plansPricingReducer => plansPricingReducer.master
    )

export const selectPlansPricingDetail = (initItems) =>
    createSelector(
        stateReducer,
        plansPricingReducer => plansPricingReducer.detail
    )
