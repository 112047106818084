import moment from "moment";
import {removeObjectNullFull} from "../../utils/functions";
import {checkNumber} from "../../utils/commonUtils";

export default class CommonMapping {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
    }

}

export const offMarketFiltersRequest = filters => {
    let newDateAdded = (filters?.date_added || []).length > 0 ? [moment(filters?.date_added[0]).format("YYYY-MM-DD"), moment(filters?.date_added[1]).format("YYYY-MM-DD")] : ["", ""]
    let newDateFiled = (filters?.date_filed || []).length > 0 ? [moment(filters?.date_filed[0]).format("YYYY-MM-DD"), moment(filters?.date_filed[1]).format("YYYY-MM-DD")] : ["", ""]

    let newAuctionDate = (filters?.auction_date || []).length > 0 ? [moment(filters?.auction_date[0]).format("YYYY-MM-DD"), moment(filters?.auction_date[1]).format("YYYY-MM-DD")] : ["", ""]
    let newRecordDate = (filters?.record_date || []).length > 0 ? [moment(filters?.record_date[0]).format("YYYY-MM-DD"), moment(filters?.record_date[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        date_added_from: newDateAdded[0],
        date_added_to: newDateAdded[1],
        date_filed_from: newDateFiled[0],
        date_filed_to: newDateFiled[1],
        auction_date_from: newAuctionDate[0],
        auction_date_to: newAuctionDate[1],
        record_date_from: newRecordDate[0],
        record_date_to: newRecordDate[1],
        minimum_bid_min: filters?.minimum_bid?.min || "",
        minimum_bid_max: filters?.minimum_bid?.max || "",
        property_type: filters?.property_type ? filters?.property_type.join(",") : "",
        status_occupied: filters?.status_occupied || "",
        owner_occupied: filters?.owner_occupied || "",
        appraised_value_min: filters?.appraised_value?.min || "",
        appraised_value_max: filters?.appraised_value?.max || "",
        adjudged_value_min: filters?.adjudged_value?.min || "",
        adjudged_value_max: filters?.adjudged_value?.max || "",
        estimated_equity_min: filters?.estimated_equity?.min || "",
        estimated_equity_max: filters?.estimated_equity?.max || "",
        assessed_value_min: filters?.assessed_value?.min || "",
        assessed_value_max: filters?.assessed_value?.max || "",
        estimated_value_min: filters?.estimated_value?.min || "",
        estimated_value_max: filters?.estimated_value?.max || "",
        bedrooms_min: filters?.bedrooms?.min || "",
        bedrooms_max: filters?.bedrooms?.max || "",
        bathrooms_min: filters?.bathrooms?.min || "",
        bathrooms_max: filters?.bathrooms?.max || "",
        year_built_min: filters?.year_built?.min || "",
        year_built_max: filters?.year_built?.max || "",
        square_feet_min: filters?.square_feet?.min || "",
        square_feet_max: filters?.square_feet?.max || "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
        is_export: filters?.is_export || "",
        is_csv: filters?.is_csv || "",
    })
}

export const offMarketFiltersResponse = (filters) => {
    let newDateAdded = (filters?.date_added_from && filters?.date_added_to) ? [moment(filters?.date_added_from), moment(filters?.date_added_to)] : []
    let newDateFiled = (filters?.date_filed_from && filters?.date_filed_to) ? [moment(filters?.date_filed_from), moment(filters?.date_filed_to)] : []

    let newAuctionDate = (filters?.auction_date_from && filters?.auction_date_to) ? [moment(filters?.auction_date_from), moment(filters?.auction_date_to)] : []
    let newRecordDate = (filters?.record_date_from && filters?.record_date_to) ? [moment(filters?.record_date_from), moment(filters?.record_date_to)] : []
    return removeObjectNullFull({
        date_added: newDateAdded,
        date_filed: newDateFiled,
        auction_date: newAuctionDate,
        record_date: newRecordDate,
        property_type: (filters?.property_type || "").split(","),
        square_feet: {min: filters?.square_feet_min || "", max: filters?.square_feet_max || ""},
        year_built: {min: filters?.year_built_min || "", max: filters?.year_built_max || ""},
        bathrooms: {min: filters?.bathrooms_min || "", max: filters?.bathrooms_max || ""},
        bedrooms: {min: filters?.bedrooms_min || "", max: filters?.bedrooms_max || ""},
        estimated_value: {min: filters?.estimated_value_min || "", max: filters?.estimated_value_max || ""},
        assessed_value: {min: filters?.assessed_value_min || "", max: filters?.assessed_value_max || ""},
        estimated_equity: {min: filters?.estimated_equity_min || "", max: filters?.estimated_equity_max || ""},
        adjudged_value: {min: filters?.adjudged_value_min || "", max: filters?.adjudged_value_max || ""},
        appraised_value: {min: filters?.appraised_value_min || "", max: filters?.appraised_value_max || ""},
        minimum_bid: {min: filters?.minimum_bid_min || "", max: filters?.minimum_bid_max || ""},
        status_occupied: filters?.status_occupied || "",
        owner_occupied: filters?.owner_occupied || "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}
