export const NOTICE_OF_DEFAULT_MASTER_ACTION = "NOTICE_OF_DEFAULT_MASTER_ACTION";
export const NOTICE_OF_DEFAULT_MASTER_SUCCESS = "NOTICE_OF_DEFAULT_MASTER_SUCCESS";

export const NOTICE_OF_DEFAULT_LIST_ACTION = "NOTICE_OF_DEFAULT_LIST_ACTION";
export const NOTICE_OF_DEFAULT_LIST_SUCCESS = "NOTICE_OF_DEFAULT_LIST_SUCCESS";

export const NOTICE_OF_DEFAULT_DETAIL_ACTION = "NOTICE_OF_DEFAULT_DETAIL_ACTION";
export const NOTICE_OF_DEFAULT_DETAIL_SUCCESS = "NOTICE_OF_DEFAULT_DETAIL_SUCCESS";

export const NOTICE_OF_DEFAULT_UPDATE_ACTION = "NOTICE_OF_DEFAULT_UPDATE_ACTION";
export const NOTICE_OF_DEFAULT_UPDATE_SUCCESS = "NOTICE_OF_DEFAULT_UPDATE_SUCCESS";

export const NOTICE_OF_DEFAULT_DELETE_ACTION = "NOTICE_OF_DEFAULT_DELETE_ACTION";
export const NOTICE_OF_DEFAULT_DELETE_SUCCESS = "NOTICE_OF_DEFAULT_DELETE_SUCCESS";

export const NOTICE_OF_DEFAULT_UNLOCK_ACTION = "NOTICE_OF_DEFAULT_UNLOCK_ACTION";
export const NOTICE_OF_DEFAULT_UNLOCK_SUCCESS = "NOTICE_OF_DEFAULT_UNLOCK_SUCCESS";

export const NOTICE_OF_DEFAULT_FAVORITE_ACTION = "NOTICE_OF_DEFAULT_FAVORITE_ACTION";
export const NOTICE_OF_DEFAULT_FAVORITE_SUCCESS = "NOTICE_OF_DEFAULT_FAVORITE_SUCCESS";

export const NOTICE_OF_DEFAULT_FAVORITE_DELETE_ACTION = "NOTICE_OF_DEFAULT_FAVORITE_DELETE_ACTION";
export const NOTICE_OF_DEFAULT_FAVORITE_DELETE_SUCCESS = "NOTICE_OF_DEFAULT_FAVORITE_DELETE_SUCCESS";

export const NOTICE_OF_DEFAULT_FAVORITE_EXPORT_ACTION = "NOTICE_OF_DEFAULT_FAVORITE_EXPORT_ACTION";
export const NOTICE_OF_DEFAULT_FAVORITE_EXPORT_SUCCESS = "NOTICE_OF_DEFAULT_FAVORITE_EXPORT_SUCCESS";

export const NOTICE_OF_DEFAULT_INIT = "NOTICE_OF_DEFAULT_INIT";
export const NOTICE_OF_DEFAULT_FAIL = "NOTICE_OF_DEFAULT_FAIL";
