export const EVICTION_MASTER_ACTION = "EVICTION_MASTER_ACTION";
export const EVICTION_MASTER_SUCCESS = "EVICTION_MASTER_SUCCESS";

export const EVICTION_LIST_ACTION = "EVICTION_LIST_ACTION";
export const EVICTION_LIST_SUCCESS = "EVICTION_LIST_SUCCESS";

export const EVICTION_DETAIL_ACTION = "EVICTION_DETAIL_ACTION";
export const EVICTION_DETAIL_SUCCESS = "EVICTION_DETAIL_SUCCESS";

export const EVICTION_UPDATE_ACTION = "EVICTION_UPDATE_ACTION";
export const EVICTION_UPDATE_SUCCESS = "EVICTION_UPDATE_SUCCESS";

export const EVICTION_DELETE_ACTION = "EVICTION_DELETE_ACTION";
export const EVICTION_DELETE_SUCCESS = "EVICTION_DELETE_SUCCESS";

export const EVICTION_UNLOCK_ACTION = "EVICTION_UNLOCK_ACTION";
export const EVICTION_UNLOCK_SUCCESS = "EVICTION_UNLOCK_SUCCESS";

export const EVICTION_FAVORITE_ACTION = "EVICTION_FAVORITE_ACTION";
export const EVICTION_FAVORITE_SUCCESS = "EVICTION_FAVORITE_SUCCESS";

export const EVICTION_FAVORITE_DELETE_ACTION = "EVICTION_FAVORITE_DELETE_ACTION";
export const EVICTION_FAVORITE_DELETE_SUCCESS = "EVICTION_FAVORITE_DELETE_SUCCESS";

export const EVICTION_FAVORITE_EXPORT_ACTION = "EVICTION_FAVORITE_EXPORT_ACTION";
export const EVICTION_FAVORITE_EXPORT_SUCCESS = "EVICTION_FAVORITE_EXPORT_SUCCESS";

export const EVICTION_INIT = "EVICTION_INIT";
export const EVICTION_FAIL = "EVICTION_FAIL";
