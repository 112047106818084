export const LOAN_MODIFICATION_MASTER_ACTION = "LOAN_MODIFICATION_MASTER_ACTION";
export const LOAN_MODIFICATION_MASTER_SUCCESS = "LOAN_MODIFICATION_MASTER_SUCCESS";

export const LOAN_MODIFICATION_LIST_ACTION = "LOAN_MODIFICATION_LIST_ACTION";
export const LOAN_MODIFICATION_LIST_SUCCESS = "LOAN_MODIFICATION_LIST_SUCCESS";

export const LOAN_MODIFICATION_DETAIL_ACTION = "LOAN_MODIFICATION_DETAIL_ACTION";
export const LOAN_MODIFICATION_DETAIL_SUCCESS = "LOAN_MODIFICATION_DETAIL_SUCCESS";

export const LOAN_MODIFICATION_UPDATE_ACTION = "LOAN_MODIFICATION_UPDATE_ACTION";
export const LOAN_MODIFICATION_UPDATE_SUCCESS = "LOAN_MODIFICATION_UPDATE_SUCCESS";

export const LOAN_MODIFICATION_DELETE_ACTION = "LOAN_MODIFICATION_DELETE_ACTION";
export const LOAN_MODIFICATION_DELETE_SUCCESS = "LOAN_MODIFICATION_DELETE_SUCCESS";

export const LOAN_MODIFICATION_UNLOCK_ACTION = "LOAN_MODIFICATION_UNLOCK_ACTION";
export const LOAN_MODIFICATION_UNLOCK_SUCCESS = "LOAN_MODIFICATION_UNLOCK_SUCCESS";

export const LOAN_MODIFICATION_FAVORITE_ACTION = "LOAN_MODIFICATION_FAVORITE_ACTION";
export const LOAN_MODIFICATION_FAVORITE_SUCCESS = "LOAN_MODIFICATION_FAVORITE_SUCCESS";

export const LOAN_MODIFICATION_FAVORITE_DELETE_ACTION = "LOAN_MODIFICATION_FAVORITE_DELETE_ACTION";
export const LOAN_MODIFICATION_FAVORITE_DELETE_SUCCESS = "LOAN_MODIFICATION_FAVORITE_DELETE_SUCCESS";

export const LOAN_MODIFICATION_FAVORITE_EXPORT_ACTION = "LOAN_MODIFICATION_FAVORITE_EXPORT_ACTION";
export const LOAN_MODIFICATION_FAVORITE_EXPORT_SUCCESS = "LOAN_MODIFICATION_FAVORITE_EXPORT_SUCCESS";

export const LOAN_MODIFICATION_INIT = "LOAN_MODIFICATION_INIT";
export const LOAN_MODIFICATION_FAIL = "LOAN_MODIFICATION_FAIL";
