import React, {useEffect, useState} from "react";
import MainLayout from "../../common/layout/MainLayout";
import {Select, Calendar, Button, Radio, Divider, Badge, Card, Modal, Alert, Pagination, Spin, Popover} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    UserOutlined
} from '@ant-design/icons';
import queryString from 'query-string'

// ============== ACTION ==============
import {
    ActionSubscriptionInitStore,
    ActionSubscriptionListTransaction,
    ActionSubscriptionCurrent,
    ActionSubscriptionGeneral
} from "../../../data/hooks/subscription"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectStatusAction,
    selectSubscriptionListTransaction,
    selectSubscriptionCurrent,
    selectSubscriptionGeneral, selectFetchingGeneral
} from "../../../data/reselects/subscriptionSelector"
import {useSelector} from "react-redux";
import moment from "moment";
import {PAGINATION, ROUTE_LIST} from "../../../constants/define";
import LazyImage from "../../common/components/LazyImage";
import SlideItem from "../../common/components/SlideItem";
import {columnsTable, getDataSource, getTitleTable} from "../../../utils/functions";
import ListComponent from "../../common/ListComponent";
import {transactionColumn, ListTransactionsResponse} from "../../../data/mapping/Response/SubscriptionResponse";
import InvoiceComponent from "./components/InvoiceComponent";
import CancelRenewalComponent from "./components/CancelRenewalComponent";
import {truncate} from "lodash";

const listCustomTitleTabs = [
    {
        route: ROUTE_LIST.MyProfile,
        name: "My Profile"
    },
    {
        route: ROUTE_LIST.MySubscription,
        name: "My Subscription"
    }
]

const ModelFields = Object.getOwnPropertyNames(new ListTransactionsResponse())
const Index = (props) => {
    const [initData, setInitData] = useState({
        titleItemTable: getTitleTable(transactionColumn, ['download'], ["id", "status"]),
        modelItem: ModelFields,
    });
    const namePage = "subscription_current",
        namePageTransactions = "subscription_transactions",
        [isFetching, setIsFetching] = useState(false),
        [isOpenModalBannerUpgrade, setIsOpenModalBannerUpgrade] = useState(false),
        [statusAction, setStatusAction] = useState({}),
        [isFetchingGeneral, setIsFetchingGeneral] = useState(false),
        [isInvoice, setIsInvoice] = useState(false),
        [isCancelRenewal, setIsCancelRenewal] = useState(false),
        [resultItem, setResultItem] = useState({}),
        [resultItemList, setResultItemList] = useState([]),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        // ============== ACTION ==============
        actionSubscriptionCurrent = ActionSubscriptionCurrent(),
        actionSubscriptionGeneral = ActionSubscriptionGeneral(),
        actionSubscriptionListTransaction = ActionSubscriptionListTransaction(),
        actionSubscriptionInitStore = ActionSubscriptionInitStore(),
        // ============== SELECT DATA ==============
        itemFetchingSubscription = useSelector(selectFetching()),
        itemFetchingGeneralSubscription = useSelector(selectFetchingGeneral()),
        itemSubscriptionListTransaction = useSelector(selectSubscriptionListTransaction()),
        itemSubscriptionGeneral = useSelector(selectSubscriptionGeneral()),
        itemSubscriptionCurrent = useSelector(selectSubscriptionCurrent());

    const componentTable = {
        code: {
            width: 200,
        },
        subscribe_date: {
            align: 'center',
            width: 200,
        },
        status_explain: {
            key: 'status_explain',
            width: 80,
            // fixed: 'right',
            render: (text, record) => (record?.status === 3) ?
                <CheckOutlined className="color--primary fw-bold fs--27px"/> :
                <CloseOutlined className="color--foreign fw-bold fs--27px"/>,
        },
        type: {
            width: 80,
        },
        subscription_type: {
            width: 150,
            render: (val, record) => (
                <Popover placement="top" title="Subscription" content={val} trigger="click" className="cursorPointer">
                    {
                        truncate(val, {
                            'length': 50,
                            'omission': '...'
                        })
                    }
                </Popover>
            ),
        },
        total_amount: {
            align: 'center',
            width: 80,
        },
        download: {
            key: 'download',
            width: 50,
            align: 'center',
            // fixed: 'right',
            render: (text, record) => <div
                className="bg--disabled width-30px height--30px m-auto cursorPointer text-center">
                <DownloadOutlined className="color--black fw-bold fs--15px"/></div>,
        },
    }

    useEffect(() => {
        actionSubscriptionGeneral();
        return () => {
            actionSubscriptionInitStore();
        }
    }, []);

    useEffect(() => {
        setResultItem(itemSubscriptionGeneral);
    }, [itemSubscriptionGeneral]);

    useEffect(() => {
        setResultItemList(itemSubscriptionListTransaction?.result);
        setPagination(itemSubscriptionListTransaction.pagination)
    }, [itemSubscriptionListTransaction]);

    useEffect(() => {
        setIsFetching(itemFetchingSubscription);
    }, [itemFetchingSubscription]);

    useEffect(() => {
        setIsFetchingGeneral(itemFetchingGeneralSubscription);
    }, [itemFetchingGeneralSubscription]);

    return (
        <MainLayout
            pathname={props.location.pathname}
            history={props.history}
            listCustomTitleTabs={listCustomTitleTabs}
            disabledTab
            isCustomTitleTabs>
            <InvoiceComponent
                isInvoice={isInvoice}
                setIsInvoice={e => setIsInvoice(e)}
                isFetching={isFetching}
                resultItemList={resultItemList}
                initData={initData}
                componentTable={componentTable}
                namePageTransactions={namePageTransactions}
                pagination={pagination}
            />
            <CancelRenewalComponent
                isCancelRenewal={isCancelRenewal}
                setIsCancelRenewal={e => setIsCancelRenewal(e)}
            />
            <Modal
                width={750}
                className="my-subscription__slide--popup"
                destroyOnClose
                visible={isOpenModalBannerUpgrade}
                onOk={() => setIsOpenModalBannerUpgrade(false)}
                onCancel={() => setIsOpenModalBannerUpgrade(false)}
                getContainer={false}
                footer={null}>
                <div className="mt--58">
                    <p className="text-center fs--20px">
                        If you subscribe to the Premium Plan, you will receive in addition: <span
                        className="fw-bold fs--20px">up to 5 landline phone
                        numbers, 5 mobile phone numbers, 5 e-mail addresses, the contact information of a relative, plus
                        bankruptcy and death notices.</span>
                    </p>
                    <p className="text-center fs--20px">
                        If you are subscribed to our Standard Plan and want to upgrade to Premium, <span
                        className="fw-bold fs--20px">we can contact you
                        and upgrade your plan right away!</span>
                    </p>
                    <div className="text-center">
                        <button type="button" className="mt-3 my-subscription__slide--contact-us">UPGRADE!</button>
                    </div>
                </div>
            </Modal>
            <Spin spinning={isFetchingGeneral}>
                <div className="container mt-4 contact-us max-width--1450px ms-0 p-x--30px">
                    <div className="row">
                        <div className="col-7 height--272px">
                            <Card title="Current Plan"
                                  className="my-subscription__card box--shadow height--272px min-height--272px overflow-hidden">
                                <div className="min-height--65px">
                                    {
                                        (Object.keys(resultItem?.subscription || []).length > 0) && Object.keys(resultItem?.subscription || []).map((i, k) => {
                                            return <p className="fs--16px color--black" key={k}><span
                                                className="fw-bold">{i}</span> {resultItem?.subscription[i]}</p>
                                        })
                                    }
                                </div>
                                <Divider/>
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <p className="fs--14px fw-bold">{resultItem?.hint?.title}</p>
                                        <p className="fs--14px">{resultItem?.hint?.message}</p>
                                    </div>
                                    <div className="col-4 text-end">
                                        <button
                                            className={`btn btn-default my-subscription__card--btn-cancel-renewal`}
                                            onClick={() => {
                                                if (Object.keys(resultItem?.subscription || []).length > 0) {
                                                    setIsCancelRenewal(true)
                                                }
                                            }}>
                                            Cancel Renewal
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-5 height--272px">
                            <Card title="Invoices" className="my-subscription__card box--shadow height--272px mb-1">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <p className="fs--14px">Next payment: <span
                                            className="fw-bold">{resultItem?.next_payment}</span></p>
                                    </div>
                                    {/*<div className="col-6 text-end">*/}
                                    {/*    <p className="fs--40px color--primary cursorPointer color--primary fw-bold"></p>*/}
                                    {/*    <button className="btn btn-default my-subscription__card--btn-cancel-renewal">*/}
                                    {/*        Modify payment information*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                                <Divider className="mt-3 mb-5"/>
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <p className="fs--14px">Last payment: <span
                                            className="fw-bold">{resultItem?.last_payment}</span></p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <p className="fs--14px cursorPointer color--primary fw-bold"
                                           onClick={() => {
                                               setIsInvoice(true);
                                               actionSubscriptionListTransaction();
                                           }}>Check invoice history.</p>
                                        {/*<button className="btn btn-default my-subscription__card--btn-cancel-renewal">*/}
                                        {/*    Modify payment information*/}
                                        {/*</button>*/}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </Spin>
            <div className="p-x--30px mt-4">
                <div className="home__slide slide-dot-inside max-width--1390px bg-white box--shadow mb-2">
                    <SlideItem className=""
                               settings={{
                                   dots: true,
                                   infinite: true,
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                               }}>
                        <div onClick={() => setIsOpenModalBannerUpgrade(true)}>
                            <LazyImage
                                imageClass="w-100 max-width--1400px object-fit "
                                alt="asdasd"
                                placeholder={true}
                                src="/layout/banner/subscription-banner-1.png"
                                // src="https://wallpaperaccess.com/full/267434.jpg"
                            />
                        </div>
                    </SlideItem>
                </div>
            </div>
        </MainLayout>
    )
}

export default Index
