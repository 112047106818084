import * as Types from '../types/NoticOfDefault';

export const noticeOfDefaultMasterAction = (filters) => ({type: Types.NOTICE_OF_DEFAULT_MASTER_ACTION, filters});
export const noticeOfDefaultMasterSuccess = (master) => ({
    type: Types.NOTICE_OF_DEFAULT_MASTER_SUCCESS,
    master
});

export const noticeOfDefaultListAction = (filters) => ({type: Types.NOTICE_OF_DEFAULT_LIST_ACTION, filters});
export const noticeOfDefaultListSuccess = (noticeOfDefault) => ({
    type: Types.NOTICE_OF_DEFAULT_LIST_SUCCESS,
    noticeOfDefault
});

export const noticeOfDefaultDetailAction = (params) => ({type: Types.NOTICE_OF_DEFAULT_DETAIL_ACTION, params});
export const noticeOfDefaultDetailSuccess = (noticeOfDefaultDetail) => ({
    type: Types.NOTICE_OF_DEFAULT_DETAIL_SUCCESS,
    noticeOfDefaultDetail
});

export const noticeOfDefaultUpdatelAction = (params) => ({type: Types.NOTICE_OF_DEFAULT_UPDATE_ACTION, params});
export const noticeOfDefaultUpdatelSuccess = (updateData) => ({
    type: Types.NOTICE_OF_DEFAULT_UPDATE_SUCCESS,
    updateData
});

export const noticeOfDefaultDeleteAction = (params) => ({type: Types.NOTICE_OF_DEFAULT_DELETE_ACTION, params});
export const noticeOfDefaultDeleteSuccess = (deleteData) => ({
    type: Types.NOTICE_OF_DEFAULT_DELETE_SUCCESS,
    deleteData
});

export const noticeOfDefaultUnlockAction = (params) => ({type: Types.NOTICE_OF_DEFAULT_UNLOCK_ACTION, params});
export const noticeOfDefaultUnlockSuccess = (resultUnlock) => ({
    type: Types.NOTICE_OF_DEFAULT_UNLOCK_SUCCESS,
    resultUnlock
});

export const noticeOfDefaultFavoriteAction = (params) => ({type: Types.NOTICE_OF_DEFAULT_FAVORITE_ACTION, params});
export const noticeOfDefaultFavoriteSuccess = (favorite) => ({
    type: Types.NOTICE_OF_DEFAULT_FAVORITE_SUCCESS,
    favorite
});

export const noticeOfDefaultDeleteFavoriteAction = (params) => ({type: Types.NOTICE_OF_DEFAULT_FAVORITE_DELETE_ACTION, params});
export const noticeOfDefaultDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.NOTICE_OF_DEFAULT_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const noticeOfDefaultExportFavoriteAction = (filters) => ({type: Types.NOTICE_OF_DEFAULT_FAVORITE_EXPORT_ACTION, filters});
export const noticeOfDefaultExportFavoriteSuccess = (resultExport) => ({
    type: Types.NOTICE_OF_DEFAULT_FAVORITE_EXPORT_SUCCESS,
    resultExport
});
export const noticeOfDefaultInitStore = (params) => ({type: Types.NOTICE_OF_DEFAULT_INIT, params});
export const noticeOfDefaultClearStore = (params) => ({type: Types.NOTICE_OF_DEFAULT_FAIL, params});
