import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscription'
import {subscriptionCancelationFeedbackAction} from "../actions/subscription";

export const ActionSubscriptionCurrent = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCurrentAction(params)),
        [dispatch]
    )
}

export const ActionSubscriptionGeneral = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionGeneralAction(params)),
        [dispatch]
    )
}

export const ActionConfirmPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionConfirmPackageAction(params)),
        [dispatch]
    )
}

export const ActionSubscriptionDetailUpgrade = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionDetailUpgradeAction(params)),
        [dispatch]
    )
}

export const ActionSubscriptionListPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionListPackageAction(params)),
        [dispatch]
    )
}

export const ActionSubscriptionListTransaction = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionListTransactionAction(params)),
        [dispatch]
    )
}

export const ActionSubscriptionInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionInitStore(params)),
        [dispatch]
    )
}

export const ActionCreateCancelationFeedback = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCancelationFeedbackAction(params)),
        [dispatch]
    )
}
