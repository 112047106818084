import {createSelector} from 'reselect'

const stateReducer = state => state.dataReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.statusAction
    )

export const selectMasterDataLeadType = (initItems) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.masterLeadTypes
    )

export const selectMasterDataPreForeClosure = (initItems) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.preForeClosureMaster
    )

export const selectDataPreForeClosureList = (initItems) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.preForeClosureList
    )


export const selectDataPreForeClosureDetail = (initItems) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.preForeClosureDetail
    )
