import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {PATHS, ROUTE_LIST} from '../constants/define';
import Layout from './Main';
import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import VerifyAuthRoute from './pages/authentication';

import '../assets/index.scss';

class MainCheck extends Component {

    componentDidMount() {
        // window.resizeLoadBody();
    }

    render() {
        return (
            <Route>
                <Switch>
                    <LoginRoute path="/login" component={Layout.Login}/>
                    <VerifyAuthRoute path="/auth/:id" component={Layout.Authentication}/>
                    <PrivateRoute path="/" exact component={Layout.Index}/>
                    <PrivateRoute path={ROUTE_LIST.MyProfile} exact component={Layout.MyProfile}/>
                    <PrivateRoute path={ROUTE_LIST.PreForeClosure} exact component={Layout.PreForeClosure}/>
                    <PrivateRoute path={ROUTE_LIST.TaxSale} exact component={Layout.TaxSale}/>
                    <PrivateRoute path={ROUTE_LIST.ProbateHeirship} exact component={Layout.ProbateHeirship}/>
                    <PrivateRoute path={ROUTE_LIST.Heirship} exact component={Layout.Heirship}/>
                    <PrivateRoute path={ROUTE_LIST.Eviction} exact component={Layout.Eviction}/>
                    <PrivateRoute path={ROUTE_LIST.Divorce} exact component={Layout.Divorce}/>
                    <PrivateRoute path={ROUTE_LIST.LoanModification} exact component={Layout.LoanModification}/>
                    <PrivateRoute path={ROUTE_LIST.ApptOfSubTrustee} exact component={Layout.ApptOfSubTrustee}/>
                    <PrivateRoute path={ROUTE_LIST.LisPendens} exact component={Layout.LisPendens}/>
                    <PrivateRoute path={ROUTE_LIST.NoticeOfDefault} exact component={Layout.NoticeOfDefault}/>
                    <PrivateRoute path={ROUTE_LIST.MySubscription} exact component={Layout.MySubscription}/>
                    <PrivateRoute path={ROUTE_LIST.MySubscriptionDetail} exact component={Layout.MySubscriptionDetail}/>
                    <PrivateRoute path={ROUTE_LIST.PackageSubscription} exact component={Layout.PackageSubscription}/>
                    <PrivateRoute path={ROUTE_LIST.TransactionSubscription} exact
                                  component={Layout.TransactionSubscription}/>
                    <PrivateRoute path={ROUTE_LIST.PaymentResult} exact component={Layout.PaymentResult}/>
                    <PrivateRoute path={ROUTE_LIST.ContactUs} exact component={Layout.ContactUs}/>
                    <PrivateRoute path={ROUTE_LIST.Ticket} exact component={Layout.Ticket}/>
                    <PrivateRoute path={ROUTE_LIST.TicketDetail} exact component={Layout.TicketDetail}/>
                    <PrivateRoute path={ROUTE_LIST.Faq} exact component={Layout.Faq}/>
                    <PrivateRoute path={ROUTE_LIST.ExclusiveContent} exact component={Layout.ExclusiveContent}/>
                    <PrivateRoute path={ROUTE_LIST.PlansPricing} exact component={Layout.PlansPricing}/>
                    <PrivateRoute path={ROUTE_LIST.MyPackages} exact component={Layout.MyPackages}/>
                    <PrivateRoute path={ROUTE_LIST.BillDetail} exact component={Layout.Bill}/>
                    <PrivateRoute path={ROUTE_LIST.CodeViolation} exact component={Layout.CodeViolation}/>
                    <PrivateRoute path={ROUTE_LIST.DelinquentTax} exact component={Layout.DelinquentTax}/>
                    {/*<PrivateRoute path="/test" exact component={Layout.Test}/>*/}
                    <PrivateRoute path="/403" exact component={Layout.Error403}/>
                    <Route path='' exact={false} component={Layout.Error}/>
                </Switch>
            </Route>

        );
    }
}

export default (MainCheck);

