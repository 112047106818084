import * as Types from '../types/ProbateHeirship';

export const probateHeirshipMasterAction = (filters) => ({type: Types.PROBATE_HEIRSHIP_MASTER_ACTION, filters});
export const probateHeirshipMasterSuccess = (master) => ({
    type: Types.PROBATE_HEIRSHIP_MASTER_SUCCESS,
    master
});

export const probateHeirshipListAction = (filters) => ({type: Types.PROBATE_HEIRSHIP_LIST_ACTION, filters});
export const probateHeirshipListSuccess = (probateHeirship) => ({
    type: Types.PROBATE_HEIRSHIP_LIST_SUCCESS,
    probateHeirship
});

export const probateHeirshipDetailAction = (params) => ({type: Types.PROBATE_HEIRSHIP_DETAIL_ACTION, params});
export const probateHeirshipDetailSuccess = (probateHeirshipDetail) => ({
    type: Types.PROBATE_HEIRSHIP_DETAIL_SUCCESS,
    probateHeirshipDetail
});

export const probateHeirshipUpdateAction = (params) => ({type: Types.PROBATE_HEIRSHIP_UPDATE_ACTION, params});
export const probateHeirshipUpdateSuccess = (updateData) => ({
    type: Types.PROBATE_HEIRSHIP_UPDATE_SUCCESS,
    updateData
});

export const probateHeirshipDeleteAction = (params) => ({type: Types.PROBATE_HEIRSHIP_DELETE_ACTION, params});
export const probateHeirshipDeleteSuccess = (deleteData) => ({
    type: Types.PROBATE_HEIRSHIP_DELETE_SUCCESS,
    deleteData
});

export const probateHeirshipUnlockAction = (params) => ({type: Types.PROBATE_HEIRSHIP_UNLOCK_ACTION, params});
export const probateHeirshipUnlockSuccess = (resultUnlock) => ({
    type: Types.PROBATE_HEIRSHIP_UNLOCK_SUCCESS,
    resultUnlock
});

export const probateHeirshipFavoriteAction = (params) => ({type: Types.PROBATE_HEIRSHIP_FAVORITE_ACTION, params});
export const probateHeirshipFavoriteSuccess = (favorite) => ({
    type: Types.PROBATE_HEIRSHIP_FAVORITE_SUCCESS,
    favorite
});

export const probateHeirshipDeleteFavoriteAction = (params) => ({type: Types.PROBATE_HEIRSHIP_FAVORITE_DELETE_ACTION, params});
export const probateHeirshipDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.PROBATE_HEIRSHIP_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const probateHeirshipExportFavoriteAction = (filters) => ({type: Types.PROBATE_HEIRSHIP_FAVORITE_EXPORT_ACTION, filters});
export const probateHeirshipExportFavoriteSuccess = (resultExport) => ({
    type: Types.PROBATE_HEIRSHIP_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const probateHeirshipInitStore = (params) => ({type: Types.PROBATE_HEIRSHIP_INIT, params});
export const probateHeirshipClearStore = (params) => ({type: Types.PROBATE_HEIRSHIP_FAIL, params});
