import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class DivorceResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.date_added = data?.date_added || "";
        this.date_filed = data?.date_filed || "";
        this.court_hearing_date = data?.court_hearing_date || "";
        this.county = data?.county || "";
        this.instrument_no = data?.instrument_no || "";
        this.case_no = data?.case_no || "";
        this.petitioner_first_name = data?.petitioner_first_name || "";
        this.petitioner_last_name = data?.petitioner_last_name || "";
        this.respondent_first_name = data?.respondent_first_name || "";
        this.respondent_last_name = data?.respondent_last_name || "";
        this.property_address = data?.property_address || "";
        this.property_city = data?.property_city || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.assessed_value = data?.assessed_value || "";
        this.property_awarded_to = data?.property_awarded_to || "";
        this.ownership = data?.ownership || "";
        this.owner_first_name = data?.owner_first_name || "";
        this.owner_last_name = data?.owner_last_name || "";
        this.owner_address = data?.owner_address || "";
        this.owner_city = data?.owner_city || "";
        this.owner_state = data?.owner_state || "";
        this.owner_zip_code = data?.owner_zip_code || "";
        this.property_id = data?.property_id || "";
        this.lead_type = data?.lead_type || "";
        this.email_addresses_1 = data?.email_addresses_1 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.email_addresses_6 = data?.email_addresses_6 || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.phone_numbers_1 = data?.phone_numbers_1 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.deceased = data?.deceased || "";
        this.bankrupt = data?.bankrupt || "";
        this.relative_full_name = data?.relative_full_name || "";
        this.relative_address = data?.relative_address || "";
        this.relative_city = data?.relative_city || "";
        this.relative_state = data?.relative_state || "";
        this.relative_zip = data?.relative_zip || "";
        this.relative_phone_1 = data?.relative_phone_1 || "";
        this.relative_phone_2 = data?.relative_phone_2 || "";
        this.relative_phone_3 = data?.relative_phone_3 || "";
        this.relative_email_addresses_1 = data?.relative_email_addresses_1 || "";
        this.relative_email_addresses_2 = data?.relative_email_addresses_2 || "";
        this.relative_email_addresses_3 = data?.relative_email_addresses_3 || "";
        this.lockedKeys = data?.lockedKeys || [];
        this.is_favorite = data?.is_favorite || 0;
        this.filters_off_market_disabled = data?.filters_off_market_disabled || [];

    }

    exportList() {
        return {
            id: this.id,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            date_filed: this.date_filed,
            assessed_value: this.assessed_value,
            is_favorite: this.is_favorite,
        }
    }

    exportMaster(data) {
        return {
            lead_type: data?.lead_type || [],
            ownership: data?.ownership || [],
            property_city: data?.property_city || [],
            property_state: data?.property_state || [],
            county: data?.county || [],
            filters_off_market_disabled: data?.filters_off_market_disabled || [],
        }
    }

    exportDetail() {
        return {
            date_added: this.date_added,
            date_filed: this.date_filed,
            court_hearing_date: this.court_hearing_date,
            county: this.county,
            instrument_no: this.instrument_no,
            case_no: this.case_no,
            petitioner_first_name: this.petitioner_first_name,
            petitioner_last_name: this.petitioner_last_name,
            respondent_first_name: this.respondent_first_name,
            respondent_last_name: this.respondent_last_name,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,
            assessed_value: this.assessed_value,
            property_awarded_to: this.property_awarded_to,
            ownership: this.ownership,
            owner_first_name: this.owner_first_name,
            owner_last_name: this.owner_last_name,
            owner_address: this.owner_address,
            owner_city: this.owner_city,
            owner_state: this.owner_state,
            owner_zip_code: this.owner_zip_code,
            property_id: this.property_id,
            lead_type: this.lead_type,
            email_addresses_1: this.email_addresses_1,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            email_addresses_6: this.email_addresses_6,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            phone_numbers_1: this.phone_numbers_1,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
            deceased: this.deceased,
            bankrupt: this.bankrupt,
            relative_full_name: this.relative_full_name,
            relative_address: this.relative_address,
            relative_city: this.relative_city,
            relative_state: this.relative_state,
            relative_zip: this.relative_zip,
            relative_phone_1: this.relative_phone_1,
            relative_phone_2: this.relative_phone_2,
            relative_phone_3: this.relative_phone_3,
            relative_email_addresses_1: this.relative_email_addresses_1,
            relative_email_addresses_2: this.relative_email_addresses_2,
            relative_email_addresses_3: this.relative_email_addresses_3,
            lockedKeys: this.lockedKeys,
        }
    }
}

export const column = ["is_favorite", "property_address", "property_city", "property_state", "date_filed", "assessed_value"];

export const detailColumn = {
    date_added: "Date Added",
    date_filed: "Date Filed",
    court_hearing_date: "Court Hearing Date",
    county: "County",
    instrument_no: "Instrument No",
    case_no: "Case No",
    petitioner_first_name: "Petitioner First Name",
    petitioner_last_name: "Petitioner Last Name",
    respondent_first_name: "Respondent First Name",
    respondent_last_name: "Respondent Last Name",
    property_address: "Property Address",
    property_city: "Property City",
    property_state: "Property State",
    property_zip_code: "Property Zip Code",
    assessed_value: "Assessed Value",
    property_awarded_to: "Property Awarded To",
    ownership: "Ownership",
    owner_first_name: "Owner First Name",
    owner_last_name: "Owner Last Name",
    owner_address: "Owner Address",
    owner_city: "Owner City",
    owner_state: "Owner State",
    owner_zip_code: "Owner Zip Code",
    property_id: "Property ID",
    lead_type: "Lead Type",
    email_addresses_1: "EmailAddresses1",
    email_addresses_2: "EmailAddresses2",
    email_addresses_3: "EmailAddresses3",
    email_addresses_4: "EmailAddresses4",
    email_addresses_5: "EmailAddresses5",
    email_addresses_6: "EmailAddresses6",
    cell_phones_1: "CellPhones1",
    cell_phones_2: "CellPhones2",
    cell_phones_3: "CellPhones3",
    cell_phones_4: "CellPhones4",
    cell_phones_5: "CellPhones5",
    phone_numbers_1: "PhoneNumbers1",
    phone_numbers_2: "PhoneNumbers2",
    phone_numbers_3: "PhoneNumbers3",
    phone_numbers_4: "PhoneNumbers4",
    phone_numbers_5: "PhoneNumbers5",
    deceased: "Deceased",
    bankrupt: "Bankrupt",
    relative_full_name: "Relative Full Name",
    relative_address: "Relative Address",
    relative_city: "Relative City",
    relative_state: "Relative State",
    relative_zip: "Relative Zip",
    relative_phone_1: "Relative Phone1",
    relative_phone_2: "Relative Phone2",
    relative_phone_3: "Relative Phone3",
    relative_email_addresses_1: "Relative EmailAddresses1",
    relative_email_addresses_2: "Relative EmailAddresses2",
    relative_email_addresses_3: "Relative EmailAddresses3",
};

export const fieldDetailRemove = ["lockedKeys"];

export const filtersResponse = (filters) => {
    let newDateField = (filters?.date_filed_from && filters?.date_filed_to) ? [moment(filters?.date_filed_from), moment(filters?.date_filed_to)] : []
    let newDateAdded = (filters?.date_added_from && filters?.date_added_to) ? [moment(filters?.date_added_from), moment(filters?.date_added_to)] : []
    return removeObjectNullFull({
        date_filed: newDateField,
        date_added: newDateAdded,
        assessed_value_from: checkNumber(filters?.assessed_value_from) ? filters?.assessed_value_from : "",
        assessed_value_to: checkNumber(filters?.assessed_value_to) ? filters?.assessed_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        ownership: filters?.ownership || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
    })
}

