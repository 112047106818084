import React, {createElement, useEffect, useState} from 'react';
import {Tabs, Breadcrumb, Divider} from "antd";
import {find} from "lodash";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {HomeOutlined} from '@ant-design/icons';

import {getProfile} from '../../../data/reselects/authSelector'
import {useSelector} from "react-redux";

const {TabPane} = Tabs;

const MainLayout = (props) => {
    const itemAuthProfile = useSelector(getProfile()),
        [title, setTitle] = useState(props?.title || ""),
        // [itemMenu, setItemMenu] = useState([{
        //     "group": "off-market",
        //     "name": "Pre Foreclosure",
        //     "route": "pre-foreclosure"
        // }, {"group": "off-market", "name": "Probates", "route": "probates"}, {
        //     "group": "off-market",
        //     "name": "Heirship",
        //     "route": "heirship"
        // }, {"group": "off-market", "name": "Tax Sale", "route": "tax-sale"}, {
        //     "group": "off-market",
        //     "name": "Eviction",
        //     "route": "eviction"
        // }, {"group": "off-market", "name": "Divorce", "route": "divorce"}, {
        //     "group": "off-market",
        //     "name": "Loan Modification",
        //     "route": "loan-modification"
        // }, {
        //     "group": "off-market",
        //     "name": "Appointment of Substitute Trustee",
        //     "route": "appt-of-sub-trustee"
        // }, {"group": "off-market", "name": "Lis Pendens", "route": "lis-pendens"}, {
        //     "group": "off-market",
        //     "name": "Notice Of Default",
        //     "route": "notice-of-default"
        // }, {
        //     "group": "off-market",
        //     "name": "Code Violation",
        //     "route": "code-violation"
        // }, {
        //     "group": "off-market",
        //     "name": "Delinquent Tax",
        //     "route": "delinquent-tax"
        // }]);
        [itemMenu, setItemMenu] = useState([]);

    useEffect(() => {
        if (!props?.disabledTab) {
            if (props?.pathname) {
                let generateSubMenu = (itemAuthProfile?.menu || []).map(value =>
                        value.items.map(child => Object.assign({group: value.group}, child))
                    ).reduce((l, n) => l.concat(n), []),
                    group = find(generateSubMenu, {route: (props?.pathname).replace("/", "")})?.group;
                setTitle(find(itemAuthProfile?.menu, {group: group})?.name)
                setItemMenu(generateSubMenu.filter(i => i?.group === group))
            }
        } else {
            setTitle(props?.title)
        }
    }, [itemAuthProfile?.menu, props]);
    // console.log(9999, itemMenu)
    return (
        <>
            {
                (props.disabledTab && (props?.breadcrumb || []).length > 0) && (
                    <div className="px-3 pt-4">
                        <Breadcrumb>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            {
                                props?.breadcrumb.map((i, k) => <Breadcrumb.Item key={k}>{i}</Breadcrumb.Item>)
                            }
                        </Breadcrumb>
                    </div>
                )
            }
            {
                (props.isCustomTitle) && (
                    <div className="px-5">
                        <div className="bg--white hn__content--title">
                            {createElement(props.title)}
                        </div>
                    </div>
                )
            }
            {
                (!props.disabledTab && itemMenu.length) > 0 && (
                    <div className="hn__page-content">
                        {
                            (!props.isCustomTitle) && (title &&
                                <h3 className="px-3 pt-4 hn__page-content--title">{title}</h3>)
                        }
                        <Tabs
                            activeKey={(props?.pathname).replace("/", "")}
                            onTabClick={(e) => props.history.push(`/${e}`)}
                            className="hn__tabs-menu hn__tabs--basic-menu">
                            {
                                itemMenu.map((i, k) => {
                                    return (
                                        <TabPane tab={capitalizeFirstLetter(i?.name)} key={i?.route}>
                                            {
                                                props.children
                                            }
                                        </TabPane>
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                )
            }
            {
                props.isCustomTitleTabs ? (
                    <Tabs
                        activeKey={props?.pathname}
                        onTabClick={(e) => props.history.push(e)}
                        className="hn__tabs--custom-menu">
                        {
                            (props?.listCustomTitleTabs || []).map((i, k) => {
                                return (
                                    <TabPane tab={capitalizeFirstLetter(i?.name)} key={i?.route}>
                                        {
                                            props.children
                                        }
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>
                ) : (
                    (props.disabledTab) && (
                        <div className={`px-3 pb-3 bg--content ${props?.wrapperContent || ""}`}>
                            {props.children}
                        </div>
                    )
                )
            }
        </>
    )
}

export default MainLayout
