import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {Button, Descriptions, Form, Modal, Popconfirm, Space, Spin, Upload} from "antd";
import {CONFIG_LOCAL_STORAGE} from "../../../constants/define";
import {Icon} from "../../common";
import HeaderAction from "../../common/layout/HeaderAction";
import {ActionGetItem} from "../../../data/hooks/bill";
import {ActionGetList as ActionGetListInvoices, ActionExport} from "../../../data/hooks/invoice";
import {selectItem, selectFetching} from "../../../data/reselects/billSelector";
import {selectList as listInvoices, selectFetching as invoiceFetching} from "../../../data/reselects/invoiceSelector";
import * as LocalStorage from "../../../utils/localStorage";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    setParamsUrlFilter
} from "../../../utils/functions";
import ListComponent from "../../common/ListComponent";
import {omit} from "lodash";
import InvoiceResponse, {column} from "../../../data/mapping/Response/InvoiceResponse";
import {CloudDownloadOutlined, ExclamationCircleOutlined} from "@ant-design/icons";

const ModelFields = Object.getOwnPropertyNames(new InvoiceResponse());
const Index = props => {
    const namePage = "invoice_list",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ["download"], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        componentTable = {
            download: {
                width: 100,
                align: "center",
                render: (val, record) => <CloudDownloadOutlined onClick={e => actionExport({id: record?.id, code: record?.code})} className="cursorPointer"/>
            }
        };

    const actionGetItem = ActionGetItem(),
        actionGetListInvoices = ActionGetListInvoices(),
        actionExport = ActionExport(),
        itemInvoiceFetching = useSelector(invoiceFetching()),
        itemFetching = useSelector(selectFetching()),
        itemInvoiceList = useSelector(listInvoices()),
        itemDetail = useSelector(selectItem());

    useEffect(() => {
        actionGetItem();
        actionGetListInvoices();
    }, []);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter("/", newFilters))
        actionGetListInvoices(newFilters);
    };

    useEffect(() => {
        setResultList(itemInvoiceList?.result || [])
        setPagination({
            total: itemInvoiceList?.pagination?.total || 0,
            totalPage: itemInvoiceList?.pagination?.total_page || 0,
            defaultCurrent: itemInvoiceList?.pagination?.page_index || 1,
            pageSize: itemInvoiceList?.pagination?.page_size || 10
        })
    }, [itemInvoiceList]);

    const onEditPaymentMethod = val => {
        Modal.confirm({
            title: 'Are you sure to link new card to your account?',
            icon: <ExclamationCircleOutlined/>,
            content: '',
            onOk() {
                window.location = `${process.env.REACT_APP_API_URL}/api/v1/stripe/collect-card/${LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN)}/${encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/bill/detail`)}`
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    return (
        <div>
            <HeaderAction
                isActionSignlePage
                title="Billing Detail"
                disabledFormatUpperCaseTitle
            />
            <Spin spinning={itemFetching}>
                <div className="container-fluid px-4 mt-3">
                    <Descriptions title="" bordered>
                        <Descriptions.Item label="Payment Method" span={3}>
                            <div className="clearfix">
                                <div className="float-start">{itemDetail?.card?.info} <span
                                    className="ms-2 color--disabled">{itemDetail?.card?.expired}</span></div>
                                <div className="float-end">
                                    {/*<Popconfirm*/}
                                    {/*    title="Are you sure to link new card to your account?"*/}
                                    {/*    onConfirm={() => {*/}
                                    {/*        window.location = `${process.env.REACT_APP_API_URL}/api/v1/stripe/collect-card/${LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN)}/${encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/bill/detail`)}`*/}
                                    {/*    }}*/}
                                    {/*    onCancel={e => {*/}
                                    {/*    }}*/}
                                    {/*    okText="Yes"*/}
                                    {/*    cancelText="No"*/}
                                    {/*>*/}
                                    {/*    <Icon type="edit-outlined" className="cursorPointer"/>*/}
                                    {/*</Popconfirm>*/}
                                    <Icon type="edit-outlined" className="cursorPointer"
                                          onClick={e => onEditPaymentMethod()}/>
                                </div>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Billing Detail">
                            <p className="mb-1">{itemDetail?.billing?.name}</p>
                            <p className="mb-1">{itemDetail?.billing?.email}</p>
                            <p className="mb-1">{itemDetail?.billing?.phone}</p>
                        </Descriptions.Item>
                    </Descriptions>
                    <p className="h4 my-4">Invoices</p>
                    <ListComponent
                        scroll={{x: 1000}}
                        className="custom-table"
                        loading={{spinning: itemInvoiceFetching, size: 'large'}}
                        rowKey="id"
                        dataSource={getDataSource(resultList || [], initData.modelItem)}
                        columns={columnsTable({
                            titleTable: initData.titleItemTable,
                            component: componentTable,
                            itemName: namePage
                        })}
                        pagination={{
                            total: pagination.total,
                            totalPage: pagination.totalPage,
                            current: pagination.defaultCurrent,
                            pageSize: pagination.pageSize,
                            responsive: true
                        }}
                        onChange={onTableChange}
                        componentTableList={{}}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default Index
