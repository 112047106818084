import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/noticeOfDefaultAction'
import {ActionPreForeClosureDelete} from "./data";

export const ActionMasterNoticeOfDefault = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultMasterAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultListAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultDetailAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultUpdatelAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultDeleteAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultUnlockAction(params)),
        [dispatch]
    )
}

export const ActionNoticeOfDefaultInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.noticeOfDefaultDeleteFavoriteAction(params)),
        [dispatch]
    )
}
