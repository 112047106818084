import {Alert, Checkbox, Divider, Form, Input, Modal, Pagination, Space} from "antd";
import {CloseOutlined, InfoCircleOutlined} from "@ant-design/icons";
import ListComponent from "../../../common/ListComponent";
import {columnsTable, getDataSource, removeArrayElementObject} from "../../../../utils/functions";
import React, {useEffect, useState} from "react";

import {
    ActionSubscriptionInitStore,
    ActionCreateCancelationFeedback
} from "../../../../data/hooks/subscription"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectStatusAction,
} from "../../../../data/reselects/subscriptionSelector"

import {Select, Radio} from 'antd';
import SelectCustomStyle from "../../../common/components/SelectCustomStyle";
import {useSelector} from "react-redux";
import {Icon} from "../../../common";

const {Option} = Select;

const dataOptions_1 = [
    {title: "I found many technical issues.", value: "I found many technical issues."},
    {title: "The product is too expensive.", value: "The product is too expensive."},
    {title: "I'm not sure how to use the tools and data.", value: "I'm not sure how to use the tools and data."},
    {title: "I was unable to contact any leads.", value: "I was unable to contact any leads."},
    {title: "I was unable to close a deal.", value: "I was unable to close a deal."},
    {title: "I'm no longer in the Real Estate business.", value: "I'm no longer in the Real Estate business."},
]

const dataOptions_3 = ["Very dissatisfied.", "Dissatisfied.", "Somewhat dissatisfied.", "Somewhat satisfied.", "Satisfied.", "Very satisfied."]

const CancelRenewalComponent = props => {
    const [paramsOption_1, setParamsOption_1] = useState([]),
        [statusAction, setStatusAction] = useState({}),
        [form] = Form.useForm(),
        actionCreateCancelationFeedback = ActionCreateCancelationFeedback(),
        itemStatusAction = useSelector(selectStatusAction());

    useEffect(() => {
        if (itemStatusAction?.createCancelationFeedbackSuccess) {
            setParamsOption_1([])
            form.resetFields();
            props.setIsCancelRenewal(false)
        }
    }, [itemStatusAction?.createCancelationFeedbackSuccess]);


    const handleChange = val => {
        if (val?.reason) {
            setParamsOption_1(val?.reason)
        }
    }

    const onSubmit = val => {
        actionCreateCancelationFeedback(val)
    }

    const onCheckOption1 = val => {
        let items = [...form.getFieldValue("reason")]
        if (items.includes(val)) {
            const index = items.indexOf(val);
            if (index > -1) {
                items.splice(index, 1);
            }
            form.setFieldsValue({reason: items})
            setParamsOption_1(items)
        } else {
            items.push(val);
            form.setFieldsValue({reason: items})
            setParamsOption_1(items)
        }
    }

    return (
        <Modal
            width={800}
            destroyOnClose
            visible={props.isCancelRenewal}
            onOk={() => props.setIsCancelRenewal(false)}
            onCancel={() => props.setIsCancelRenewal(false)}
            getContainer={false}
            footer={null}>
            <div className="my-subscription__cancel-renewal">
                <h3 className="fs--32px color--black">We're sorry to see you go!</h3>
                <Divider className="my-2"/>
                <div className="bg--disabled p-3 my-3 border__radius--10px">
                    <p className="fs--16px color--black mb-4">After this step, you will be contacted by your Account
                        Manager
                        to cancel
                        your subscription. <b>This
                            means you will no longer have access to our products</b> - and, hence, to the best
                        information in
                        the market to work with off market properties.</p>
                    <p className="fs--16px color--black mb-0">Before you make this final decision, <b>we’d love to hear
                        about
                        your experience
                        and how you think we
                        can improve our service</b> for other members (and you, if you decide to come back!)</p>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    className=""
                    onFinish={onSubmit}
                    onValuesChange={handleChange}
                    initialValues={{
                        point: 10
                    }}
                >
                    <div className="my-subscription__cancel-renewal--select">
                        <p className="fs--16px color--black fw-bold">
                            1. What is the main reason you decided to cancel your subscription?
                            <span className="color--disabled ms-2 fst-italic fw-normal">
                            Select all that apply.
                        </span>
                        </p>
                        <div>
                            <Form.Item
                                label=""
                                name="reason"
                                rules={[{required: true, message: 'Please input your reason'}]}
                            >
                                <SelectCustomStyle
                                    virtual={false}
                                    dropdownAlign={{offset: [null, -5]}}
                                    optionFilterProp="children"
                                    form={form}
                                    mode="multiple"
                                    // allowClear: true,
                                    style={{
                                        width: '100%'
                                    }}
                                    placeholder="Choose an option"
                                    optionLabelProp="label"
                                    dropdownClassName="my-subscription__cancel-renewal__wrapper"
                                    customOptions={{
                                        setParamsCustomState: (e) => setParamsOption_1(e)
                                    }}
                                    // options={{
                                    //     mode: "multiple",
                                    //     // allowClear: true,
                                    //     style: {
                                    //         width: '100%'
                                    //     },
                                    //     placeholder: "select one country",
                                    //     optionLabelProp: "label",
                                    //     // clearIcon: <CloseOutlined className="select-custom-style--icon-clear"/>
                                    // }}
                                >
                                    {
                                        dataOptions_1.map((i, k) => {
                                            return (
                                                <Option value={i.value} label={i.title} key={k}>
                                                    <div className="my-subscription__cancel-renewal--select-item">
                                                        <Checkbox
                                                            checked={paramsOption_1.includes(i.value)}
                                                            onChange={(e) => onCheckOption1(i.value)}
                                                            className="me-2"/>
                                                        {i.title}
                                                    </div>
                                                </Option>
                                            )
                                        })
                                    }
                                </SelectCustomStyle>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="cancel-renewal__question--item">
                        <p className="fs--16px color--black fw-bold mb-3">
                            2. Would you recommend our services?
                            <span className="color--disabled ms-2 fst-italic fw-normal">
                            1= Strongly Disagree, 10 = Strongly Agree.
                        </span>
                        </p>
                        <div className="my-subscription__cancel-renewal--form-option-2">
                            <Form.Item
                                label=""
                                name="point"
                            >
                                <Radio.Group>
                                    {
                                        new Array(10).fill(0).map((i, k) => {
                                                return <Radio.Button key={k} value={k + 1}> {k + 1}</Radio.Button>
                                            }
                                        )
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="cancel-renewal__question--item">
                        <p className="fs--16px color--black fw-bold mb-3">
                            3. How was your overall experience with Real Estate IQ?
                            <span className="color--disabled ms-2 fst-italic fw-normal">
                            Select one option.
                        </span>
                        </p>
                        <div className="my-subscription__cancel-renewal--form-option-3">
                            <Form.Item
                                label=""
                                name="experience"
                                rules={[{required: true, message: 'Please input your experience'}]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        {
                                            dataOptions_3.map((i, k) => {
                                                return (
                                                    <Radio value={i} key={k}>{i}</Radio>
                                                )
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="cancel-renewal__question--item">
                        <p className="fs--16px color--black fw-bold mb-3">
                            4. If we could do anything to keep you around, what would that be?
                        </p>
                        <div className="my-subscription__cancel-renewal--form-option-3">
                            <Form.Item
                                label=""
                                name="feedback"
                            >
                                <Input.TextArea rows={6} placeholder="Please don’t hold back. We love feedback!"/>
                            </Form.Item>
                        </div>
                    </div>
                    <div>
                        <div className="float-end">
                            <Form.Item className="text-end mb-0">
                                <button type="submit"
                                        disabled={itemStatusAction?.isCreateCancelationFeedback}
                                        className="btn btn-primary mb-0 fw-bold fs--15px my-subscription__cancel-renewal--process">
                                    Proceed to cancel my subscription {
                                    itemStatusAction?.isCreateCancelationFeedback &&
                                    <i className="fas fa-spinner fa-spin"/>
                                }
                                </button>
                            </Form.Item>
                        </div>
                        <div className="float-end">
                            <Form.Item className="text-end mb-0 me-2">
                                <button type="button"
                                        className="btn btn-primary mb-0 fs--15px my-subscription__cancel-renewal--back"
                                        onClick={() => props.setIsCancelRenewal(false)}
                                >
                                    Take me back
                                </button>
                            </Form.Item>
                        </div>
                        <div className="clearfix"/>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default CancelRenewalComponent
