import {createSelector} from 'reselect'

const stateReducer = state => state.ticketReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.statusAction
    )

export const selectTicketList = (initItems) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.data
    )

export const selectTicketDetail = (initItems) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.detail
    )
