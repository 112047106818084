import {AutoComplete, Popconfirm, Select, Spin, Table} from "antd";
import React, {useEffect, useState} from "react";
import {Icon} from "../index";
import {useHistory, useLocation} from "react-router-dom";
import {setParamsUrlFilter} from "../../../utils/functions";
import queryString from "query-string";
import {ActionLayoutMarketLeadDeleteFilters} from "../../../data/hooks/template";
import {useSelector} from "react-redux";
import {selectStatusAction} from "../../../data/reselects/templateSelector";

const ModalListFilters = props => {
    const routeMatch = useHistory(),
        routeLocation = useLocation(),
        [list, setList] = useState([]),
        actionLayoutMarketLeadDeleteFilters = ActionLayoutMarketLeadDeleteFilters(),
        itemStatusAction = useSelector(selectStatusAction());
    const column = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            render: (text, record) => (
                <>
                    <Icon type="check-circle-outlined" className="me-3 color--primary" onClick={e => {
                        // console.log(321, setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                        // routeMatch?.go(setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                        window.location = setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'}))
                        // props?.onLoadFilter(setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                    }}/>
                    <Popconfirm
                        title="Are you sure you want to delete this filter?"
                        onConfirm={e => actionLayoutMarketLeadDeleteFilters({id: props?.lead, title: record?.title})}
                        onCancel={e => {
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Icon type="delete"/>
                    </Popconfirm>
                </>
            ),
        },
    ]

    useEffect(() => {
        if ((props?.master || []).length > 0) {
            let items = [];
            props?.master?.map((i, k) => items?.push({
                key: k,
                title: i?.value,
                params: i?.params
            }))
            setList(items)
        } else {
            setList([])
        }
    }, [props?.master]);

    return (
        <Spin spinning={itemStatusAction?.isDeleteMarketLeadFilters}>
            <Table columns={column}
                   dataSource={list}
                   pagination={false}
                   loading={itemStatusAction?.isLoadingMarketLeadFilters}/>
        </Spin>
    )
}

export default ModalListFilters
