import * as Types from '../types/CodeViolation';

export const codeViolationMasterAction = (filters) => ({type: Types.CODE_VIOLATION_MASTER_ACTION, filters});
export const codeViolationMasterSuccess = (master) => ({
    type: Types.CODE_VIOLATION_MASTER_SUCCESS,
    master
});

export const codeViolationListAction = (filters) => ({type: Types.CODE_VIOLATION_LIST_ACTION, filters});
export const codeViolationListSuccess = (codeViolation) => ({
    type: Types.CODE_VIOLATION_LIST_SUCCESS,
    codeViolation
});

export const codeViolationDetailAction = (params) => ({type: Types.CODE_VIOLATION_DETAIL_ACTION, params});
export const codeViolationDetailSuccess = (codeViolationDetail) => ({
    type: Types.CODE_VIOLATION_DETAIL_SUCCESS,
    codeViolationDetail
});

export const codeViolationUpdatelAction = (params) => ({type: Types.CODE_VIOLATION_UPDATE_ACTION, params});
export const codeViolationUpdatelSuccess = (updateData) => ({
    type: Types.CODE_VIOLATION_UPDATE_SUCCESS,
    updateData
});

export const codeViolationDeleteAction = (params) => ({type: Types.CODE_VIOLATION_DELETE_ACTION, params});
export const codeViolationDeleteSuccess = (deleteData) => ({
    type: Types.CODE_VIOLATION_DELETE_SUCCESS,
    deleteData
});


export const codeViolationDownloadAction = (params) => ({type: Types.CODE_VIOLATION_DOWNLOAD_ACTION, params});
export const codeViolationDownloadSuccess = (download) => ({
    type: Types.CODE_VIOLATION_DOWNLOAD_SUCCESS,
    download
});

export const codeViolationUnlockAction = (params) => ({type: Types.CODE_VIOLATION_UNLOCK_ACTION, params});
export const codeViolationUnlockSuccess = (resultUnlock) => ({
    type: Types.CODE_VIOLATION_UNLOCK_SUCCESS,
    resultUnlock
});

export const codeViolationFavoriteAction = (params) => ({type: Types.CODE_VIOLATION_FAVORITE_ACTION, params});
export const codeViolationFavoriteSuccess = (favorite) => ({
    type: Types.CODE_VIOLATION_FAVORITE_SUCCESS,
    favorite
});

export const codeViolationDeleteFavoriteAction = (params) => ({
    type: Types.CODE_VIOLATION_FAVORITE_DELETE_ACTION,
    params
});
export const codeViolationDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.CODE_VIOLATION_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const codeViolationExportFavoriteAction = (filters) => ({
    type: Types.CODE_VIOLATION_FAVORITE_EXPORT_ACTION,
    filters
});
export const codeViolationExportFavoriteSuccess = (resultExport) => ({
    type: Types.CODE_VIOLATION_FAVORITE_EXPORT_SUCCESS,
    resultExport
});
export const codeViolationInitStore = (params) => ({type: Types.CODE_VIOLATION_INIT, params});
export const codeViolationClearStore = (params) => ({type: Types.CODE_VIOLATION_FAIL, params});
