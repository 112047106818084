export const EXCLUSIVE_CONTENT_LIST_ACTION = "EXCLUSIVE_CONTENT_LIST_ACTION";
export const EXCLUSIVE_CONTENT_LIST_SUCCESS = "EXCLUSIVE_CONTENT_LIST_SUCCESS";

export const EXCLUSIVE_CONTENT_CREATE_ACTION = "EXCLUSIVE_CONTENT_CREATE_ACTION";
export const EXCLUSIVE_CONTENT_CREATE_SUCCESS = "EXCLUSIVE_CONTENT_CREATE_SUCCESS";

export const EXCLUSIVE_CONTENT_COMMENT_ACTION = "EXCLUSIVE_CONTENT_COMMENT_ACTION";
export const EXCLUSIVE_CONTENT_COMMENT_SUCCESS = "EXCLUSIVE_CONTENT_COMMENT_SUCCESS";

export const EXCLUSIVE_CONTENT_DETAIL_ACTION = "EXCLUSIVE_CONTENT_DETAIL_ACTION";
export const EXCLUSIVE_CONTENT_DETAIL_SUCCESS = "EXCLUSIVE_CONTENT_DETAIL_SUCCESS";

export const EXCLUSIVE_CONTENT_INIT = "EXCLUSIVE_CONTENT_INIT";
export const EXCLUSIVE_CONTENT_FAIL = "EXCLUSIVE_CONTENT_FAIL";
