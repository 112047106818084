import React, {Component, useEffect, useRef, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {Avatar, Badge, Button, Divider, Dropdown, Form, Input, Menu, message, Modal, Space} from "antd";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE, ROUTE_LIST} from "../../../constants/define";
import {Icon} from "../index";
import {useSelector} from "react-redux";
import {getFetchingAuth, getProfile} from "../../../data/reselects/authSelector";
import {ActionUpdateProfile} from "../../../data/hooks/auth";

import {
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
} from '@ant-design/icons';
import {selectFilesUpload} from "../../../data/reselects/uploadSelector";
import {selectTemplateGeneral} from "../../../data/reselects/templateSelector";
import {FaBell} from "react-icons/fa";
import {GoDotFill} from "react-icons/go";

const Header = (props) => {
    const agent = "RealEstateIQ",
        [showProfile, setShowProfile] = useState(false),
        [isShowNotify, setIsShowNotify] = useState(false),
        [countNotify, setCountNotify] = useState(0),
        [avatar, setAvatar] = useState(),
        [files, setFiles] = useState({}),
        getUpdateProfile = ActionUpdateProfile(),
        itemFetching = useSelector(getFetchingAuth()),
        itemProfile = useSelector(getProfile()),
        itemGeneralTemplate = useSelector(selectTemplateGeneral()),
        itemFileUpload = useSelector(selectFilesUpload());
    const dropdownRef = useRef(null);

    useEffect(() => {
        setAvatar(itemFileUpload?.file_url)
    }, [itemFileUpload]);

    useEffect(() => {
        setAvatar(itemProfile?.avatar)
    }, [itemProfile?.avatar]);

    useEffect(() => {
        if (isShowNotify) {
            window.addEventListener('click', handleClickOutside);
        }
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isShowNotify]);
    useEffect(() => {
        if ((itemGeneralTemplate?.popup || []).length > 0) {
            let items = (itemGeneralTemplate?.popup || []).filter(i => !i?.is_read)
            setCountNotify(items.length)
        }else{
            setCountNotify(0)
        }
    }, [itemGeneralTemplate]);

    const handleClickOutside = (event) => {
        if (isShowNotify) {
            setIsShowNotify(false);
        }
    };

    const menu = () => (
        <Menu>
            <Menu.Item>
                {/*<Menu.Item onClick={() => setShowProfile(true)}>*/}
                {/*    <i className="far fa-user me-2"/> {(itemProfile?.first_name || "") + " " + (itemProfile?.last_name || "")}*/}
                <a href="/profile"
                   className="text-decoration-none fs--14px"><i className="far fa-user me-2"/> My Profile</a>
                {/*<Link to="/profile"*/}
                {/*      className="text-decoration-none fs--14px"><i className="far fa-user me-2"/> My Profile</Link>*/}
            </Menu.Item>
            <Menu.Item className="border-top"><i className="fas fa-history me-2"/><Link to="/my-subscription"
                                                                                        className="text-decoration-none fs--14px">My
                subscription</Link></Menu.Item>
            {/*<Menu.Item className="border-top"><i className="fas fa-history me-2"/><Link to="/my-transaction"*/}
            {/*                                                                            className="text-decoration-none fs--14px">My*/}
            {/*    transaction</Link></Menu.Item>*/}
            {/*<Menu.Item className="border-top"><i className="fas fa-cubes me-2"/><Link to="/package-subscription"*/}
            {/*                                                                          className="text-decoration-none fs--14px">Package*/}
            {/*    subscription</Link></Menu.Item>*/}
            <Menu.Item className="border-top">
                <i className="fas fa-cart-plus me-2"/>
                <Link to="/bill/detail"
                      className="text-decoration-none fs--14px">My Billing</Link>
            </Menu.Item>
            <Menu.Item className="border-top" onClick={() => onLogout()}><i
                className="fas fa-sign-out-alt me-2"/> Logout</Menu.Item>
        </Menu>
    );

    const onLogout = () => {
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        if (token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        }
        window.location = `${process.env.REACT_APP_AUTH0}/auth/verify?callback=${process.env.REACT_APP_BASE_URL}/auth/loyalty&agent=${agent}&action=logout`
        // props.history.go("/login")
    }

    const updateProfile = (val) => {
        if (val.password || val.confirm_password) {
            if (val.password === val.confirm_password) {
                getUpdateProfile({...val, id: props?.profile?.id})
            } else {
                message.error("Password does not match")
            }
        } else {
            getUpdateProfile({...val, id: props?.profile?.id})
        }
    }

    const onShowNotify = item => {
        props?.onShowNotify(item)
    }

    return (
        <>
            <Modal title="My Profile" destroyOnClose visible={showProfile} onOk={() => setShowProfile(false)}
                   onCancel={() => setShowProfile(false)} footer={null}>
                <Form
                    layout="vertical"
                    initialValues={{
                        email: itemProfile?.email,
                        first_name: itemProfile?.first_name,
                        last_name: itemProfile?.last_name,
                    }}
                    onFinish={updateProfile}
                    // onValuesChange={onRequiredTypeChange}
                    // requiredMark={requiredMark}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form.Item label="Email" name="email">
                                <Input disabled/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your first name'}]}
                                label="First Name"
                                name="first_name"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your last name'}]}
                                label="Last Name"
                                name="last_name"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{type: "string", min: 6}, {whitespace: true}, ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (value && (value.length > 1)) {
                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("'password' cannot be empty");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                rules={[{type: "string", min: 6}, {whitespace: true}, ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (value && (value.length > 1)) {
                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("'password' cannot be empty");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item className="text-end mb-0">
                                <Button type="primary" disabled={itemFetching} htmlType="submit" className="me-3 mb-0">
                                    Submit {itemFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                                <Button htmlType="button"
                                        className="mb-0"
                                        onClick={() => setShowProfile(false)}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <div className="hn__header">
                <div className="row">
                    <div className="col-8 text-start ps-4">
                        <div className="d-none d-lg-block">
                            <div className="row align-items-center p-t--10px">
                                <div className="col-1 pe-0 width-50px mt-2">
                                    <i className="fas fa-bars color--white cursorPointer ms-3 fs--24px hn__header--toggle"
                                       onClick={props.toggleCollapsed}/>
                                </div>
                                <div className="col-6 ps-2 py-1">
                                    <a href="https://realestateiq.co" target="_blank">
                                        <img
                                            src="/layout/logo.svg"
                                            className="" width="100px" height=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-none">
                            <div className="row align-items-center pt-3">
                                <div className="col-2 px-0">
                                    <i className="fas fa-bars color--white cursorPointer ms-3 fa-2x hn__header--toggle"
                                       onClick={props.toggleCollapsed}/>
                                </div>
                                <div className="col-10 text-center">
                                    <img
                                        src="/layout/logo.svg"
                                        className="" width="150px" height=""/>
                                </div>
                            </div>
                        </div>
                        {/*<div className="float-start hn__header--logo py-2 text-center pt-2">*/}
                        {/*    <img src="https://cdn-blnlm.nitrocdn.com/PQMiHZavNwOdtjaYEZRXHtZQWnBieyDx/assets/static/optimized/rev-8e1a71d/wp-content/uploads/2021/05/reiq-logo-2021.png" className="" width="70%" />*/}
                        {/*</div>*/}
                        {/*<div className="float-start pt-4">*/}
                        {/*    <i className="fas fa-bars color--black cursorPointer ms-3 fa-2x hn__header--toggle" onClick={props.toggleCollapsed}/>*/}
                        {/*</div>*/}
                        {/*<div className="clearfix" />*/}
                    </div>

                    <div className="col-4 my-2 text-end pe-4">
                        <Space>
                            <a href={process.env.REACT_APP_MENU_LINK_A}
                               className="color--primary text-decoration-none" target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >
                                    {process.env.REACT_APP_MENU_LINK_TITLE_A}
                                </Button>
                            </a>
                            <a href={process.env.REACT_APP_MENU_LINK_C}
                               className="color--primary text-decoration-none" target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >
                                    {process.env.REACT_APP_MENU_LINK_TITLE_C}
                                </Button>
                            </a>
                            <a href={process.env.REACT_APP_MENU_LINK_B}
                               className="color--primary text-decoration-none"
                               target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >{process.env.REACT_APP_MENU_LINK_TITLE_B}
                                </Button>
                            </a>
                            <Badge count={itemGeneralTemplate?.cart?.count || 0}
                                   className="vertical--middle cursorPointer" offset={[0, 10]}>
                                <Link to={ROUTE_LIST.MyPackages}
                                      className="">
                                    <Avatar size="large" className="bg-transparent">
                                        <i className="fa fa-shopping-cart fs--25px"/>
                                    </Avatar>
                                </Link>
                            </Badge>
                            <div className="position-relative">
                                <Badge count={countNotify}
                                       className="vertical--middle cursorPointer mx-2" offset={[0, 5]}>
                                    <FaBell size={28} className="color--white"
                                            onClick={e => {
                                                setIsShowNotify(!isShowNotify)
                                            }}/>
                                </Badge>
                                <div className={`hn__header--notify ${isShowNotify ? "" : "d-none"}`}>
                                    <p className="text-start h3 mt-3 mb-1 mx-3 color--black">Notification</p>
                                    <Divider className="my-2"/>
                                    {
                                        (itemGeneralTemplate?.popup || []).length > 0 ? (
                                            itemGeneralTemplate?.popup.map((item, index) => {
                                                return (
                                                    <div
                                                        ref={dropdownRef}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            onShowNotify(item)
                                                        }}
                                                        className="clearfix mx-1 hn__header--notify-item cursorPointer"
                                                        key={index}
                                                    >
                                                        <div className="mx-2 py-2 row align-items-center">
                                                            <div
                                                                className={`col-11 text-start color--black fs--14px ${(!Boolean(item?.is_read)) ? "fw-bold" : ""}`}>
                                                                {item?.title}
                                                            </div>
                                                            <div className="col-1 text-end">
                                                                {
                                                                    (!Boolean(item?.is_read)) ? (
                                                                        <GoDotFill className="color--red"/>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : null
                                    }
                                </div>
                            </div>
                            <Dropdown overlay={menu}>
                                <Space>
                                    <Avatar size={50} icon={props?.avatar ?
                                        <img style={{cursor: "pointer"}} width={50} height={50}
                                             src={props?.avatar} className="vertical--middle"/> :
                                        <i className="icon--avatar-user width-50px height--50px cursorPointer"/>}/>
                                    <span className="ms-2 color--white">{itemProfile?.first_name}</span>
                                </Space>

                                {/*<Avatar size={50} icon={<img style={{cursor: "pointer"}} width={50} height={50}*/}
                                {/*                             src={(itemProfile?.first_name && itemProfile?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=ffffff&name=${itemProfile?.first_name} ${itemProfile?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}/>}/>*/}
                            </Dropdown>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Header);
