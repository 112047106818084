import {Space} from "antd";

const ButtonListActionOffMarket = props => {
    return (
        <Space>
            {
                !props?.isHideFirstButton ? (
                    <button
                        className={`${props?.className || ""} off-market__button--action`} {...props.options}>{props.children || ""}</button>
                ) : ""
            }
            <button className={`${props?.className || ""} off-market__button--action`} onClick={e => {
                console.log(props?.item, `${props?.item?.property_address || ""} ${props?.item?.property_city || ""} ${props?.item?.property_state || ""}`)
                window.open(`${process.env.REACT_APP_MENU_LINK_A}?fullAddress=${props?.item?.property_address || ""} ${props?.item?.property_city || ""} ${props?.item?.property_state || ""}`, "_blank")
            }}>Comp
            </button>
        </Space>
    )
}

export default ButtonListActionOffMarket

export const ButtonListActionOffMarketNotIncludesAll = props => {
    return (
        <button className={`${props?.className || ""} off-market__button--action ms-3`} onClick={e => {
            console.log(props?.item, `${props?.item?.property_address || ""} ${props?.item?.property_city || ""} ${props?.item?.property_state || ""}`)
            // window.location =
        }}>Comp
        </button>
    )
}

