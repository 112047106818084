import {createSelector} from 'reselect'

const stateReducer = state => state.packagePaymentReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        packagePaymentReducer => packagePaymentReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        packagePaymentReducer => packagePaymentReducer.statusAction
    )

export const selectPackagePaymentCreateOrder = (initItems) =>
    createSelector(
        stateReducer,
        packagePaymentReducer => packagePaymentReducer.result
    )
