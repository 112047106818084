import React from 'react'
import {Checkbox, Divider, Tree, Input} from "antd";
import {SearchOutlined} from '@ant-design/icons';

const {Search} = Input;

const Packages = (props) => {
    return (
        <div className="col-12 col-lg-4 package-subscription__content--item-package">
            <div className="border p-3">
                <div className="package-subscription__content--list-packages-title">
                    {props?.title}
                </div>
                <Divider className="my-3"/>
                <div className="mb-3">
                    <Search placeholder="input search text" allowClear
                            enterButton={<SearchOutlined className="fs--16px"/>}
                            onSearch={() => console.log("on Search")}/>
                </div>
                <Tree
                    // checkStrictly
                    blockNode
                    className="package-subscription__content--list-packages"
                    checkable
                    onCheck={(selectedItems, event) => props.onCheck(selectedItems, event, props.parent)}
                    // onSelect={(selectedKeys, e) => console.log("===>>> OnSelect <<===", selectedKeys, e)}
                    checkedKeys={props.selectedItems}
                    // defaultExpandedKeys={['0-0-0', '0-0-1']}
                    // defaultSelectedKeys={['0-0-0', '0-0-1']}
                    // defaultCheckedKeys={['0-0-0', '0-0-1']}
                    // onSelect={onSelect}
                    // onCheck={onCheck}
                    treeData={props?.items}
                />
            </div>
        </div>
    )
}

export default Packages
