import React from 'react';
import {Tree} from "antd";

const Packages = (props) => {
    return (
        <Tree
            checkable
            // onExpand={onExpand}
            // expandedKeys={expandedKeys}
            // autoExpandParent={autoExpandParent}
            onCheck={(selectedItems, event) => props.onCheck(selectedItems, event, props.parent)}
            // checkedKeys={checkedKeys}
            // onSelect={onSelect}
            // selectedKeys={selectedKeys}
            treeData={props.items}
        />
    )
}

export default Packages
