import * as Types from '../types/Heirship';

export const heirshipMasterAction = (filters) => ({type: Types.HEIRSHIP_MASTER_ACTION, filters});
export const heirshipMasterSuccess = (master) => ({
    type: Types.HEIRSHIP_MASTER_SUCCESS,
    master
});

export const heirshipListAction = (filters) => ({type: Types.HEIRSHIP_LIST_ACTION, filters});
export const heirshipListSuccess = (heirship) => ({
    type: Types.HEIRSHIP_LIST_SUCCESS,
    heirship
});

export const heirshipDetailAction = (params) => ({type: Types.HEIRSHIP_DETAIL_ACTION, params});
export const heirshipDetailSuccess = (heirshipDetail) => ({
    type: Types.HEIRSHIP_DETAIL_SUCCESS,
    heirshipDetail
});

export const heirshipUpdatelAction = (params) => ({type: Types.HEIRSHIP_UPDATE_ACTION, params});
export const heirshipUpdatelSuccess = (updateData) => ({
    type: Types.HEIRSHIP_UPDATE_SUCCESS,
    updateData
});

export const heirshipDeleteAction = (params) => ({type: Types.HEIRSHIP_DELETE_ACTION, params});
export const heirshipDeleteSuccess = (deleteData) => ({
    type: Types.HEIRSHIP_DELETE_SUCCESS,
    deleteData
});

export const heirshipUnlockAction = (params) => ({type: Types.HEIRSHIP_UNLOCK_ACTION, params});
export const heirshipUnlockSuccess = (resultUnlock) => ({
    type: Types.HEIRSHIP_UNLOCK_SUCCESS,
    resultUnlock
});

export const heirshipFavoriteAction = (params) => ({type: Types.HEIRSHIP_FAVORITE_ACTION, params});
export const heirshipFavoriteSuccess = (favorite) => ({
    type: Types.HEIRSHIP_FAVORITE_SUCCESS,
    favorite
});

export const heirshipDeleteFavoriteAction = (params) => ({type: Types.HEIRSHIP_FAVORITE_DELETE_ACTION, params});
export const heirshipDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.HEIRSHIP_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});


export const heirshipExportFavoriteAction = (filters) => ({type: Types.HEIRSHIP_FAVORITE_EXPORT_ACTION, filters});
export const heirshipExportFavoriteSuccess = (resultExport) => ({
    type: Types.HEIRSHIP_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const heirshipInitStore = (params) => ({type: Types.HEIRSHIP_INIT, params});
export const heirshipClearStore = (params) => ({type: Types.HEIRSHIP_FAIL, params});
