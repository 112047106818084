import {createSelector} from 'reselect'

const stateReducer = state => state.codeViolationReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.statusAction
    )

export const selectMasterCodeViolation = (initItems) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.master
    )

export const selectCodeViolationList = (initItems) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.data
    )


export const selectCodeViolationDetail = (initItems) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.detail
    )
