import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/probateHeirshipAction'
import {ActionPreForeClosureDelete} from "./data";

export const ActionMasterProbateHeirship = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipMasterAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipListAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipDetailAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipUpdateAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipDeleteAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipUnlockAction(params)),
        [dispatch]
    )
}

export const ActionProbateHeirshipInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.probateHeirshipDeleteFavoriteAction(params)),
        [dispatch]
    )
}
