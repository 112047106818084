import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import PlansPricingResponse from "../mapping/Response/PlansPricingResponse";

export function actionPlansPricingMaster(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PLANS_PRICING_MASTER,
        params: params,
        parser: data => new PlansPricingResponse(data.data.result).exportMaster(),
    }).get();
}

export function actionPlansPricingDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PLANS_PRICING_DETAIL,
        parser: data => new PlansPricingResponse(data.data.result).exportDetail(),
    }).get();
}

// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.TICKET_CREATE,
//         // endpointParams: {id: encodeURIComponent(data?.id), field: encodeURIComponent(data?.field)},
//     }).post(data);
// }

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PLANS_PRICING_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
        parser: data => data.data.result,
    }).put(data);
}

export function deleteData(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PLANS_PRICING_DELETE,
        endpointParams: {id},
    }).delete();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new PlansPricingResponse(item).exportMaster()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
