import * as Types from '../types/Data';

export const dataMasterLeadTypeAction = (filters) => ({type: Types.DATA_MASTER_LEAD_TYPE_ACTION, filters});
export const dataMasterLeadTypeSuccess = (masterLeadTypes) => ({
    type: Types.DATA_MASTER_LEAD_TYPE_SUCCESS,
    masterLeadTypes
});

export const dataMasterPreForeClosureAction = (filters) => ({type: Types.DATA_MASTER_ACTION, filters});
export const dataMasterPreForeClosureSuccess = (preForeClosureMaster) => ({
    type: Types.DATA_MASTER_SUCCESS,
    preForeClosureMaster
});

export const dataPreForeClosureListAction = (filters) => ({type: Types.DATA_PRE_FORE_CLOSURE_LIST_ACTION, filters});
export const dataPreForeClosureListSuccess = (preForeClosureList) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_LIST_SUCCESS,
    preForeClosureList
});

export const dataPreForeClosureDetailAction = (params) => ({type: Types.DATA_PRE_FORE_CLOSURE_DETAIL_ACTION, params});
export const dataPreForeClosureDetailSuccess = (preForeClosureDetail) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_DETAIL_SUCCESS,
    preForeClosureDetail
});

export const dataPreForeClosureUpdateAction = (params) => ({type: Types.DATA_PRE_FORE_CLOSURE_UPDATE_ACTION, params});
export const dataPreForeClosureUpdateSuccess = (updateData) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_UPDATE_SUCCESS,
    updateData
});

export const dataPreForeClosureDeleteAction = (params) => ({type: Types.DATA_PRE_FORE_CLOSURE_DELETE_ACTION, params});
export const dataPreForeClosureDeleteSuccess = (deleteData) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_DELETE_SUCCESS,
    deleteData
});

export const dataPreForeClosureUnlockAction = (params) => ({type: Types.DATA_PRE_FORE_CLOSURE_UNLOCK_ACTION, params});
export const dataPreForeClosureUnlockSuccess = (resultUnlock) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_UNLOCK_SUCCESS,
    resultUnlock
});

export const dataPreForeClosureFavoriteAction = (params) => ({type: Types.DATA_PRE_FORE_CLOSURE_FAVORITE_ACTION, params});
export const dataPreForeClosureFavoriteSuccess = (favorite) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_FAVORITE_SUCCESS,
    favorite
});

export const dataPreForeClosureDeleteFavoriteAction = (params) => ({type: Types.DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_ACTION, params});
export const dataPreForeClosureDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const dataPreForeClosureExportFavoriteAction = (filters) => ({type: Types.DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_ACTION, filters});
export const dataPreForeClosureExportFavoriteSuccess = (resultExport) => ({
    type: Types.DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const dataInitStore = (params) => ({type: Types.DATA_INIT, params});
export const dataClearStore = (params) => ({type: Types.DATA_FAIL, params});
