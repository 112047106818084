import React, {useEffect, useState} from "react"
import {Button, Form, Input, message, Modal, Spin, Statistic} from "antd";
import {ActionInit, ActionPasswordRequest, ActionPasswordReset} from "../../../data/hooks/auth";
import {useSelector} from "react-redux";
import {getPasswordRequest, getStatusAction} from "../../../data/reselects/authSelector";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import {removeObjectNullFull} from "../../../utils/functions";

const {Countdown} = Statistic;

const ForgotPassword = props => {
    const [isForgotPassword, setIsForgotPassword] = useState(false),
        [isPasswordRequest, setIsPasswordRequest] = useState(true),
        [isPasswordReset, setIsPasswordReset] = useState(false),
        actionInit = ActionInit(),
        itemStatusAction = useSelector(getStatusAction());

    useEffect(() => {
        return () => {
            // actionInit();
        }
    }, []);

    useEffect(() => {
        if (!itemStatusAction.isPasswordResetFail) {
            setIsPasswordRequest(!itemStatusAction.passwordRequestSuccess)
            setIsPasswordReset(itemStatusAction.passwordRequestSuccess)
        }
    }, [itemStatusAction]);

    useEffect(() => {
        setIsForgotPassword(props.isForgotPassword)
    }, [props.isForgotPassword]);

    const actionModal = e => {
        setIsForgotPassword(e);
        actionInit();
        setIsPasswordRequest(true);
        setIsPasswordReset(false);
        props.actionModal(e)
    }

    const changeForgotPassword = e => {
        setIsPasswordRequest(true);
        setIsPasswordReset(false)
    }

    return (
        <Modal
            maskClosable={false}
            destroyOnClose
            title={<p
                className="fw-bold color--black fs--21px mb-0 text-center">{itemStatusAction?.passwordResetSuccess ? "Password reset done!" : isPasswordRequest ? "Forgot your password?" : (isPasswordReset && "Reset your password?")} </p>}
            visible={isForgotPassword}
            footer={null}
            onCancel={() => actionModal(false)}
        >
            {/*<PasswordReset actionModal={actionModal} changeForgotPassword={changeForgotPassword}/>*/}
            {
                (!itemStatusAction?.passwordResetSuccess && isPasswordRequest) && <PasswordRequest/>
            }
            {
                (!itemStatusAction?.passwordResetSuccess && isPasswordReset) &&
                <PasswordReset actionModal={actionModal} changeForgotPassword={changeForgotPassword}/>
            }
            {
                (itemStatusAction?.passwordResetSuccess) && (
                    <>
                        <p className="text-center color--black fs--16px">Your Password has been updated.</p>
                        <p className="text-center color--primary fs--16px mt-2 text-decoration-underline cursorPointer"
                           onClick={() => actionModal(false)}>Log In</p>
                    </>
                )
            }
        </Modal>
    )
}
export default ForgotPassword

const PasswordRequest = props => {

    const [form] = Form.useForm(),
        [onSubmit, setOnSubmit] = useState(false),
        [isCaptcha, setIsCaptcha] = useState(false),
        actionPasswordRequest = ActionPasswordRequest(),
        itemStatusAction = useSelector(getStatusAction());

    let tokenVerifyGoogle = ""

    useEffect(() => {
        setOnSubmit(itemStatusAction.isPasswordRequest)
    }, [itemStatusAction]);


    const onFinish = val => {
        setOnSubmit(true);
        if (!isCaptcha) {
            setIsCaptcha(true);
        } else {
            setIsCaptcha(false)
            actionPasswordRequest({...val, token_captcha: tokenVerifyGoogle})
            tokenVerifyGoogle = ""
        }
    }

    const handleSubmit = token => {
        tokenVerifyGoogle = token
        if (tokenVerifyGoogle) {
            form.submit()
        }
    }

    return (
        <Spin spinning={onSubmit}>
            <p className="text-center color--black fs--16px">Don’t worry! We will send you an email with a code to reset
                your password. </p>
            {
                isCaptcha && (
                    <GoogleReCaptcha onVerify={token => handleSubmit(token)}/>
                )
            }
            <Form form={form} layout="vertical" name="nest-messages" onFinish={onFinish}
                  className="forgot--wrapper-form">
                <Form.Item name="email" label="E-mail"
                           rules={[{required: true, type: 'email', message: '${label} is not a valid'}]}>
                    <Input placeholder="Please enter your email" autoFocus/>
                </Form.Item>
                <Form.Item className="button-color--transparent text-end" label="">
                    <Button type="primary" htmlType="submit" className="w-100">
                        <span className="fw-bold me-1">Send</span> Code
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}

const PasswordReset = props => {

    const [form] = Form.useForm(),
        [onSubmit, setOnSubmit] = useState(false),
        [isCaptcha, setIsCaptcha] = useState(false),
        [isSubmit, setIsSubmit] = useState(true),
        [hash, setHash] = useState(""),
        actionPasswordReset = ActionPasswordReset(),
        itemPasswordRequest = useSelector(getPasswordRequest()),
        itemStatusAction = useSelector(getStatusAction());

    let tokenVerifyGoogle = ""

    useEffect(() => {
        if (itemStatusAction.isPasswordResetFail) {
            setOnSubmit(false);
        }
    }, [itemStatusAction]);

    useEffect(() => {
        if (itemPasswordRequest) {
            setHash(itemPasswordRequest)
        }
    }, [itemPasswordRequest]);

    const onFinish = val => {
        setOnSubmit(true);
        if (!isCaptcha) {
            setIsCaptcha(true);
        } else {
            setIsCaptcha(false)
            actionPasswordReset({
                ...val,
                hash: hash,
                token_captcha: tokenVerifyGoogle
            })
            tokenVerifyGoogle = ""
        }
    }

    const handleSubmit = token => {
        tokenVerifyGoogle = token
        if (tokenVerifyGoogle) {
            form.submit()
        }
    }

    const onValuesChange = val => {
        const valuesCheck = removeObjectNullFull(form.getFieldsValue());
        if (Object.keys(valuesCheck).length === 3) {
            setIsSubmit(false)
        } else {
            setIsSubmit(true)
        }
    }

    return (
        <Spin spinning={onSubmit}>
            {
                isCaptcha && (
                    <GoogleReCaptcha onVerify={token => handleSubmit(token)}/>
                )
            }
            <Countdown title=""
                       className="text-center forgot--countdown"
                       format="mm:ss" value={Date.now() + 1000 * 60 * 10 * 2 + 1000}
                       onFinish={() => {
                           Modal.warning({
                               title: 'Notification',
                               content: 'Reset Password Request Is Expired. Please Try Again',
                               onOk: () => props.actionModal(false)
                           });
                       }}/>
            <p className="text-center color--primary fs--15px"><i>A code has been sent to your email. Please check your
                Inbox, also make sure to check your Spam folder.</i></p>
            <Form form={form}
                  layout="vertical"
                  name="nest-messages"
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  className="forgot--wrapper-form">
                <Form.Item name="otp" label="Type the 8 digit code here"
                           rules={[
                               {required: true, message: '${label} is not a valid'},
                               {max: 8, message: 'The code is not valid'},
                               {min: 8, message: 'The code is not valid'},
                           ]}>
                    <Input placeholder="Passcode From Email"/>
                </Form.Item>
                <Form.Item name="password"
                           label="New Password"
                           rules={[
                               {required: true, message: '${label} is not a valid'},
                           ]}>
                    <Input.Password placeholder="Create New Password"/>
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    label="Confirm New Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {required: true, message: '${label} is not a valid'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}>
                    <Input.Password placeholder="Confirm New Password"/>
                </Form.Item>
                <Form.Item className="button-color--transparent text-end" label="">
                    <Button type="primary" htmlType="submit" className="w-100" disabled={isSubmit}>
                        <span className="fw-bold me-1">VERIFY</span> CODE
                    </Button>
                    <p className="text-center color--primary fs--16px mt-2 text-decoration-underline cursorPointer"
                       onClick={() => props.changeForgotPassword()}>I didn’t recieve a code</p>
                </Form.Item>
            </Form>
        </Spin>
    )
}
