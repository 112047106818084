import React, {Component, Fragment, useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Switch, Route, withRouter, useHistory} from 'react-router-dom';
import {checkUserAction, refreshTokenAction} from "../data/actions/authAction";
import {logoutAction} from "../data/actions/authAction";
import {ActionLayoutGeneral, ActionLayoutReadNotify} from "../data/hooks/template";
import DEFINE, {CONFIG_LOCAL_STORAGE} from "../constants/define";
import $ from "jquery";
import * as LocalStorage from "../utils/localStorage";
import {Modal, Space, Spin, Tabs} from "antd"
import {Header} from "./common";
import Navigation from "./common/layout/Navigation";
import {selectMasterDataLeadType} from "../data/reselects/dataSelector";
import {capitalizeFirstLetter, removeObjectNull} from "../utils/functions";
import {selectFilesUpload} from "../data/reselects/uploadSelector";
import {getProfile} from "../data/reselects/authSelector";
import moment from "moment";
import {selectTemplateGeneral} from "../data/reselects/templateSelector";

const {TabPane} = Tabs;

const ContainerIndex = props => {

    const [collapsed, setCollapsed] = useState(false),
        [avatar, setAvatar] = useState(),
        [listTabs, setListTabs] = useState([]),
        [activeTab, setActiveTab] = useState(0),
        [isShowNotify, setIsShowNotify] = useState(false),
        [isHideNotify, setIsHideNotify] = useState(false),
        [popup, setPopup] = useState([]),
        itemDataMasterLeadType = useSelector(selectMasterDataLeadType()),
        actionLayoutGeneral = ActionLayoutGeneral(),
        actionLayoutReadNotify = ActionLayoutReadNotify(),
        itemProfile = useSelector(getProfile()),
        itemGeneralTemplate = useSelector(selectTemplateGeneral()),
        itemFileUpload = useSelector(selectFilesUpload());
    let history = useHistory();

    useEffect(() => {
        actionLayoutGeneral();
        history.listen((a, b) => {
            actionLayoutGeneral();
        })
    }, []);

    useEffect(() => {
        setAvatar(itemFileUpload?.file_url)
    }, [itemFileUpload]);
    useEffect(() => {
        let newPopup = [];
        if (!isHideNotify) {
            if ((itemGeneralTemplate?.popup || []).length > 0) {
                (itemGeneralTemplate?.popup || []).map(i => {
                    if (!Boolean(i?.is_read)) {
                        newPopup.push(i)
                    }
                })
                setPopup(newPopup)
                if (newPopup.length > 0) {
                    setIsShowNotify(true)
                } else {
                    setIsShowNotify(false)
                }
            } else {
                setPopup(itemGeneralTemplate?.popup || [])
                setIsShowNotify(false)
            }
        } else {
            setIsShowNotify(false)
        }
    }, [itemGeneralTemplate]);

    useEffect(() => {
        if ((popup || []).length < 1) {
            setIsShowNotify(false)
        }
    }, [popup]);

    useEffect(() => {
        setAvatar(itemProfile?.avatar)
    }, [itemProfile?.avatar]);

    useEffect(() => {
        setListTabs([...listTabs, ...Object.values(itemDataMasterLeadType)])
    }, [itemDataMasterLeadType]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    };

    useEffect(() => {
        let e = (window.innerHeight > 0 ? window.innerHeight : window.screen.height) - 1;
        $(".page-wrapper").css("min-height", e + "px");
        console.log('VERSION:', process.env.REACT_APP_VERSION);
        // props.onCheckUser();

        // const profile = LocalStorage.get(CONFIG_LOCAL_STORAGE.PROFILE);
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        // const refreshToken = LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        // const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
        //TODO: Ad-hoc fix, recheck
        if (!token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        } else
            props.onCheckUser();
    }, [])

    const onReadNotify = item => {
        let newItem = [...popup];
        if (newItem.length > 0) {
            actionLayoutReadNotify({id: newItem[0]?.id})
            newItem.splice(0, 1);
            setPopup(newItem)
        }
    }

    const onShowNotify = item => {
        setPopup([item])
        setIsShowNotify(true)
    }

    if (Object.keys(props.authLogin?.user || {}).length > 0) {
        return (
            <>
                <Modal
                    className=""
                    destroyOnClose
                    footer={null}
                    title={false}
                    width={700}
                    visible={isShowNotify}
                    onOk={() => {
                    }}
                    onCancel={() => {
                        setIsShowNotify(false)
                        setIsHideNotify(true)
                    }}
                >
                    <p className="fs--36px text-center color--black px-5 mb-2" style={{fontWeight: "600"}}>Discover Our
                        Improved
                        Portal!</p>
                    {
                        (popup.length > 0) ? (
                            <div className="overflow-hidden px-5">
                                <div className="overflow-hidden">
                                    {
                                        (popup[0]?.popup_content || []).map((item, index) => {
                                            return (
                                                <div className="clearfix mb-3" key={index}>
                                                    <div className="float-start pt-1" style={{width: "4%"}}>
                                                        <img
                                                            src={`/layout/icons/${(item?.type || "").toLowerCase() === "update" ? "notify-start.svg" : "notify-filters.svg"}`}
                                                            alt=""
                                                            className="w-100"/>
                                                    </div>
                                                    <div className="float-start px-3" style={{width: "96%"}}>
                                                        <p className="fs--20px color--black mb-2"
                                                           style={{fontWeight: 600}}>
                                                            {item?.title}
                                                        </p>
                                                        <div className="fs--16px color--black mb-0"
                                                             style={{fontWeight: 400, whiteSpace: "pre-line"}}>
                                                            {item?.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="w-75 m-auto text-center mt-3">
                        <Space>
                            <p className="d-block text-decoration-none color--primary border__radius--4px py-2 px-4 fs--16px cursorPointer"
                               style={{border: "1px solid #00A1C9", fontWeight: 600}}
                               onClick={e => {
                                   props?.history?.push("/ticket")
                                   setPopup([])
                                   setIsHideNotify(true)
                                   setIsShowNotify(false)
                               }}>Got any questions?</p>
                            <p className="d-block text-decoration-none color--primary color--white border__radius--4px py-2 px-4 fs--16px cursorPointer"
                               style={{backgroundColor: "#00A1C9", fontWeight: 600}} onClick={e => onReadNotify()}>Got
                                it</p>
                        </Space>
                    </div>
                </Modal>
                <Header toggleCollapsed={toggleCollapsed} collapsed={collapsed} avatar={avatar}
                        onShowNotify={e => onShowNotify(e)}/>
                {/*<div className="hn__navigation float-left">*/}
                <Navigation toggleCollapsed={toggleCollapsed} collapsed={collapsed} history={props.history}/>
                {/*</div>*/}
                <div className={`hn__content ${collapsed ? "hn__content--expanded" : ""}`}>
                    {
                        props.children
                    }
                </div>
            </>
        );
    } else {
        return null
    }
}

const mapStateToProps = state => {
    return {
        authLogin: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCheckUser: () => {
            dispatch(checkUserAction())
        },
        onLogoutRequest: () => {
            dispatch(logoutAction());
        },
        refreshToken: token => dispatch(refreshTokenAction(token)),
    }
};

// export default ContainerIndex;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerIndex));

