import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/Subscription';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionService';
import * as FunctionAction from '../actions/subscription';
import {message, Modal} from "antd";
import history from "../../utils/history";
import {formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import SubscriptionRequest, {filtersParams} from "../mapping/Request/SubscriptionRequest";
import CustomModalConfirm from "../../layouts/common/components/CustomModalConfirm";

const {confirm} = Modal;

function* actionSubscriptionCurrent() {
    yield takeLatest(Types.SUBSCRIPTION_CURRENT_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.currentSubscription, action.params);
        yield put(FunctionAction.subscriptionCurrentSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function* actionSubscriptionGeneral() {
    yield takeLatest(Types.SUBSCRIPTION_GENERAL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.generalSubscription, action.params);
        yield put(FunctionAction.subscriptionGeneralSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function* actionConfirmPackage() {
    yield takeLatest(Types.SUBSCRIPTION_CONFIRM_PACKAGE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.confirmPackage, filtersParams(action.params));
        yield put(FunctionAction.subscriptionConfirmPackageSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function* actionGetDetailUpgrade() {
    yield takeLatest(Types.SUBSCRIPTION_DETAIL_UPGRADE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionGetDetailUpgrade, action.params.date);
        yield put(FunctionAction.subscriptionDetailUpgradeSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function* actionGetListPackages() {
    yield takeLatest(Types.SUBSCRIPTION_LIST_PACKAGE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionGetListPackages, action.params);
        yield put(FunctionAction.subscriptionListPackageSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function* actionGetListTransactions() {
    yield takeLatest(Types.SUBSCRIPTION_LIST_TRANSACTION_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionGetListTransactions, action.params);
        yield put(FunctionAction.subscriptionListTransactionSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function* actionCancelationFeedback() {
    yield takeLatest(Types.SUBSCRIPTION_CANCELATION_FEEDBACK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.createCancelationFeedback, new SubscriptionRequest(action.params).exportCreateCancelationFeedback());
        confirm({
            width: 450,
            className: "custom--modal-confirm",
            title: '',
            destroyOnClose: true,
            icon: false,
            content: <CustomModalConfirm />,
            cancelText: null,
            cancelButtonProps: {
                className: "d-none"
            },
            okButtonProps: {
                className: "custom--modal-confirm-ok"
            }
        });
        // message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        yield put(FunctionAction.subscriptionCancelationFeedbackSuccess(true));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionSaga() {
    yield all([
        fork(actionSubscriptionCurrent),
        fork(actionGetDetailUpgrade),
        fork(actionGetListPackages),
        fork(actionConfirmPackage),
        fork(actionGetListTransactions),
        fork(actionSubscriptionGeneral),
        fork(actionCancelationFeedback),
    ])
}
