export const DATA_MASTER_LEAD_TYPE_ACTION = "DATA_MASTER_LEAD_TYPE_ACTION";
export const DATA_MASTER_LEAD_TYPE_SUCCESS = "DATA_MASTER_LEAD_TYPE_SUCCESS";

export const DATA_MASTER_ACTION = "DATA_MASTER_ACTION";
export const DATA_MASTER_SUCCESS = "DATA_MASTER_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_LIST_ACTION = "DATA_PRE_FORE_CLOSURE_LIST_ACTION";
export const DATA_PRE_FORE_CLOSURE_LIST_SUCCESS = "DATA_PRE_FORE_CLOSURE_LIST_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_DETAIL_ACTION = "DATA_PRE_FORE_CLOSURE_DETAIL_ACTION";
export const DATA_PRE_FORE_CLOSURE_DETAIL_SUCCESS = "DATA_PRE_FORE_CLOSURE_DETAIL_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_UPDATE_ACTION = "DATA_PRE_FORE_CLOSURE_UPDATE_ACTION";
export const DATA_PRE_FORE_CLOSURE_UPDATE_SUCCESS = "DATA_PRE_FORE_CLOSURE_UPDATE_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_DELETE_ACTION = "DATA_PRE_FORE_CLOSURE_DELETE_ACTION";
export const DATA_PRE_FORE_CLOSURE_DELETE_SUCCESS = "DATA_PRE_FORE_CLOSURE_DELETE_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_UNLOCK_ACTION = "DATA_PRE_FORE_CLOSURE_UNLOCK_ACTION";
export const DATA_PRE_FORE_CLOSURE_UNLOCK_SUCCESS = "DATA_PRE_FORE_CLOSURE_UNLOCK_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_FAVORITE_ACTION = "DATA_PRE_FORE_CLOSURE_FAVORITE_ACTION";
export const DATA_PRE_FORE_CLOSURE_FAVORITE_SUCCESS = "DATA_PRE_FORE_CLOSURE_FAVORITE_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_ACTION = "DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_ACTION";
export const DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_SUCCESS = "DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_SUCCESS";

export const DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_ACTION = "DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_ACTION";
export const DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_SUCCESS = "DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_SUCCESS";

export const DATA_INIT = "DATA_INIT";
export const DATA_FAIL = "DATA_FAIL";
