import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/CodeViolation';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/codeViolationService';
import * as FunctionAction from '../actions/codeViolationAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import CodeViolationRequest, {filtersParams} from "../mapping/Request/CodeViolationRequest";
import moment from "moment";
import {offMarketFiltersRequest} from "../mapping/CommonMapping";

const mime = require('mime-types');


function* actionGetMaster() {
    yield takeLatest(Types.CODE_VIOLATION_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.master, action.params);
        yield put(FunctionAction.codeViolationMasterSuccess(result));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* actionGetList() {
    yield takeLatest(Types.CODE_VIOLATION_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionList, params);
        yield put(FunctionAction.codeViolationListSuccess(result));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.CODE_VIOLATION_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionExport, params);
        const fileName = ("Code_Violation_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.codeViolationExportFavoriteSuccess("ok"));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.CODE_VIOLATION_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionDetail, action.params.id);
        yield put(FunctionAction.codeViolationDetailSuccess(result));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.CODE_VIOLATION_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.codeViolationUnlockSuccess(result));
        yield put(FunctionAction.codeViolationDetailAction({id: action.params.id}));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.CODE_VIOLATION_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new CodeViolationRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.codeViolationDetailAction({id: action.params.id}));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.CODE_VIOLATION_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.heirshipDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.codeViolationListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* actionDownloadData() {
    yield takeLatest(Types.CODE_VIOLATION_DOWNLOAD_ACTION, Func.sagaWrapper(function* (action) {
        let result = yield call(FunctionService.downloadData, action.params.id);
        window.open(result, "_blank")
        // const extension = mime.extension(result.type);
        // const fileName = ("Code_Violation_" + moment().format('DDMMYYYY') + `.${extension}`);
        // downloadFile(result, fileName, {type: extension});
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["208"]);
        yield put(FunctionAction.codeViolationDownloadSuccess("ok"));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.CODE_VIOLATION_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.codeViolationFavoriteSuccess(result));
        yield put(FunctionAction.codeViolationListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.CODE_VIOLATION_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.codeViolationDeleteFavoriteSuccess({}));
        yield put(FunctionAction.codeViolationListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.CODE_VIOLATION_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* codeViolationSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetList),
        fork(actionGetExport),
        fork(actionGetDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
        fork(actionDownloadData),
    ])
}
