import * as Types from '../types/DelinquentTax';

export const delinquentTaxMasterAction = (filters) => ({type: Types.DELINQUENT_TAX_MASTER_ACTION, filters});
export const delinquentTaxMasterSuccess = (master) => ({
    type: Types.DELINQUENT_TAX_MASTER_SUCCESS,
    master
});

export const delinquentTaxListAction = (filters) => ({type: Types.DELINQUENT_TAX_LIST_ACTION, filters});
export const delinquentTaxListSuccess = (delinquentTax) => ({
    type: Types.DELINQUENT_TAX_LIST_SUCCESS,
    delinquentTax
});

export const delinquentTaxDetailAction = (params) => ({type: Types.DELINQUENT_TAX_DETAIL_ACTION, params});
export const delinquentTaxDetailSuccess = (delinquentTaxDetail) => ({
    type: Types.DELINQUENT_TAX_DETAIL_SUCCESS,
    delinquentTaxDetail
});

export const delinquentTaxUpdatelAction = (params) => ({type: Types.DELINQUENT_TAX_UPDATE_ACTION, params});
export const delinquentTaxUpdatelSuccess = (updateData) => ({
    type: Types.DELINQUENT_TAX_UPDATE_SUCCESS,
    updateData
});

export const delinquentTaxDeleteAction = (params) => ({type: Types.DELINQUENT_TAX_DELETE_ACTION, params});
export const delinquentTaxDeleteSuccess = (deleteData) => ({
    type: Types.DELINQUENT_TAX_DELETE_SUCCESS,
    deleteData
});


export const delinquentTaxDownloadAction = (params) => ({type: Types.DELINQUENT_TAX_DOWNLOAD_ACTION, params});
export const delinquentTaxDownloadSuccess = (download) => ({
    type: Types.DELINQUENT_TAX_DOWNLOAD_SUCCESS,
    download
});

export const delinquentTaxUnlockAction = (params) => ({type: Types.DELINQUENT_TAX_UNLOCK_ACTION, params});
export const delinquentTaxUnlockSuccess = (resultUnlock) => ({
    type: Types.DELINQUENT_TAX_UNLOCK_SUCCESS,
    resultUnlock
});

export const delinquentTaxFavoriteAction = (params) => ({type: Types.DELINQUENT_TAX_FAVORITE_ACTION, params});
export const delinquentTaxFavoriteSuccess = (favorite) => ({
    type: Types.DELINQUENT_TAX_FAVORITE_SUCCESS,
    favorite
});

export const delinquentTaxDeleteFavoriteAction = (params) => ({
    type: Types.DELINQUENT_TAX_FAVORITE_DELETE_ACTION,
    params
});
export const delinquentTaxDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.DELINQUENT_TAX_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const delinquentTaxExportFavoriteAction = (filters) => ({
    type: Types.DELINQUENT_TAX_FAVORITE_EXPORT_ACTION,
    filters
});
export const delinquentTaxExportFavoriteSuccess = (resultExport) => ({
    type: Types.DELINQUENT_TAX_FAVORITE_EXPORT_SUCCESS,
    resultExport
});
export const delinquentTaxInitStore = (params) => ({type: Types.DELINQUENT_TAX_INIT, params});
export const delinquentTaxClearStore = (params) => ({type: Types.DELINQUENT_TAX_FAIL, params});
