export const LIS_PENDENS_MASTER_ACTION = "LIS_PENDENS_MASTER_ACTION";
export const LIS_PENDENS_MASTER_SUCCESS = "LIS_PENDENS_MASTER_SUCCESS";

export const LIS_PENDENS_LIST_ACTION = "LIS_PENDENS_LIST_ACTION";
export const LIS_PENDENS_LIST_SUCCESS = "LIS_PENDENS_LIST_SUCCESS";

export const LIS_PENDENS_DETAIL_ACTION = "LIS_PENDENS_DETAIL_ACTION";
export const LIS_PENDENS_DETAIL_SUCCESS = "LIS_PENDENS_DETAIL_SUCCESS";

export const LIS_PENDENS_UPDATE_ACTION = "LIS_PENDENS_UPDATE_ACTION";
export const LIS_PENDENS_UPDATE_SUCCESS = "LIS_PENDENS_UPDATE_SUCCESS";

export const LIS_PENDENS_DELETE_ACTION = "LIS_PENDENS_DELETE_ACTION";
export const LIS_PENDENS_DELETE_SUCCESS = "LIS_PENDENS_DELETE_SUCCESS";

export const LIS_PENDENS_UNLOCK_ACTION = "LIS_PENDENS_UNLOCK_ACTION";
export const LIS_PENDENS_UNLOCK_SUCCESS = "LIS_PENDENS_UNLOCK_SUCCESS";

export const LIS_PENDENS_FAVORITE_ACTION = "LIS_PENDENS_FAVORITE_ACTION";
export const LIS_PENDENS_FAVORITE_SUCCESS = "LIS_PENDENS_FAVORITE_SUCCESS";

export const LIS_PENDENS_FAVORITE_DELETE_ACTION = "LIS_PENDENS_FAVORITE_DELETE_ACTION";
export const LIS_PENDENS_FAVORITE_DELETE_SUCCESS = "LIS_PENDENS_FAVORITE_DELETE_SUCCESS";

export const LIS_PENDENS_FAVORITE_EXPORT_ACTION = "LIS_PENDENS_FAVORITE_EXPORT_ACTION";
export const LIS_PENDENS_FAVORITE_EXPORT_SUCCESS = "LIS_PENDENS_FAVORITE_EXPORT_SUCCESS";

export const LIS_PENDENS_INIT = "LIS_PENDENS_INIT";
export const LIS_PENDENS_FAIL = "LIS_PENDENS_FAIL";
