import * as Types from '../types/Ticket';

export const ticketListAction = (filters) => ({type: Types.TICKET_LIST_ACTION, filters});
export const ticketListSuccess = (list) => ({
    type: Types.TICKET_LIST_SUCCESS,
    list
});
export const ticketCreateAction = (params) => ({type: Types.TICKET_CREATE_ACTION, params});
export const ticketCreateSuccess = (resultCreate) => ({
    type: Types.TICKET_CREATE_SUCCESS,
    resultCreate
});

export const ticketCommentAction = (params) => ({type: Types.TICKET_COMMENT_ACTION, params});
export const ticketCommentSuccess = (resultComment) => ({
    type: Types.TICKET_COMMENT_SUCCESS,
    resultComment
});

export const ticketDetailAction = (params) => ({type: Types.TICKET_DETAIL_ACTION, params});
export const ticketDetailSuccess = (detail) => ({
    type: Types.TICKET_DETAIL_SUCCESS,
    detail
});

export const ticketInitStore = (params) => ({type: Types.TICKET_INIT, params});
export const ticketClearStore = (params) => ({type: Types.TICKET_FAIL, params});
