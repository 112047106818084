import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/Eviction';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/evictionService';
import * as FunctionAction from '../actions/evictionAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import EvictionRequest, {filtersParams} from "../mapping/Request/EvictionRequest";
import moment from "moment";
import {offMarketFiltersRequest} from "../mapping/CommonMapping";


function* actionGetMaster() {
    yield takeLatest(Types.EVICTION_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterEviction, action.params);
        yield put(FunctionAction.evictionMasterSuccess(result));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* actionGetList() {
    yield takeLatest(Types.EVICTION_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionEvictionList, params);
        yield put(FunctionAction.evictionListSuccess(result));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.EVICTION_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionEvictionExport, params);
        const fileName = ("Eviction_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.evictionExportFavoriteSuccess("ok"));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.EVICTION_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionEvictionDetail, action.params.id);
        yield put(FunctionAction.evictionDetailSuccess(result));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.EVICTION_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.evictionUnlockSuccess(result));
        yield put(FunctionAction.evictionDetailAction({id: action.params.id}));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.EVICTION_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new EvictionRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.evictionDetailAction({id: action.params.id}));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.EVICTION_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.heirshipDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.evictionListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.EVICTION_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.evictionFavoriteSuccess(result));
        yield put(FunctionAction.evictionListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.EVICTION_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.evictionDeleteFavoriteSuccess({}));
        yield put(FunctionAction.evictionListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.EVICTION_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* evictionSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetList),
        fork(actionGetExport),
        fork(actionGetDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
    ])
}
