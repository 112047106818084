import * as Types from '../types/Template';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isLoadingMarketLeadFilters: false,
        isCreateMarketLeadFilters: false,
        isDeleteMarketLeadFilters: false,
        isReadNotify: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    detail: {},
    general: {},
    marketLeadFilters: []
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.TEMPLATE_READ_NOTIFY_SUCCESS:
        case Types.TEMPLATE_READ_NOTIFY_ACTION:
            return {...state};
        case Types.TEMPLATE_GENERAL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TEMPLATE_GENERAL_SUCCESS:
            return {...state, isFetching: false, general: action.general, statusAction: initialState.statusAction};
        case Types.TEMPLATE_MARKET_LEAD_FILTERS_ACTION:
            return {
                ...state,
                marketLeadFilters: [],
                statusAction: {
                    ...initialState.statusAction,
                    isLoadingMarketLeadFilters: true
                }
            };
        case Types.TEMPLATE_MARKET_LEAD_CREATE_FILTERS_ACTION:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isCreateMarketLeadFilters: true
                }
            };
        case Types.TEMPLATE_MARKET_LEAD_DELETE_FILTERS_ACTION:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isDeleteMarketLeadFilters: true
                }
            };
        case Types.TEMPLATE_MARKET_LEAD_FILTERS_SUCCESS:
            return {
                ...state,
                marketLeadFilters: action.resultFilters,
                statusAction: {
                    ...initialState.statusAction,
                    isLoadingMarketLeadFilters: false
                }
            };
        case Types.TEMPLATE_MARKET_LEAD_CREATE_FILTERS_SUCCESS:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isCreateMarketLeadFilters: false
                }
            };
        case Types.TEMPLATE_MARKET_LEAD_DELETE_FILTERS_SUCCESS:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isDeleteMarketLeadFilters: false
                }
            };
        case Types.TEMPLATE_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.TEMPLATE_INIT:
            return {
                ...state,
            };
        default:
            return state;
    }
}
