import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/lisPendensAction'
import {ActionPreForeClosureDelete} from "./data";

export const ActionMasterLisPendens = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensMasterAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensListAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensDetailAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensUpdatelAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensDeleteAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensUnlockAction(params)),
        [dispatch]
    )
}

export const ActionLisPendensInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.lisPendensDeleteFavoriteAction(params)),
        [dispatch]
    )
}
