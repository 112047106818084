import React, {useState, useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import {withRouter} from 'react-router-dom';
import {DATE_TIME_FORMAT, PAGINATION} from "../../../constants/define";
import {
    columnsTable, customEditorTable, formatInt,
    genaratePaginateFilterSort, generateRowItemDetail,
    getDataSource,
    getTitleTable,
    setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import {Button, Col, DatePicker, Form, Modal, Popconfirm, Rate, Row, Select, Table} from "antd";
import queryString from 'query-string';

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import ListComponent from "../../common/ListComponent";
import FilterComponent from "./component/FilterComponent";
import MainLayout from "../../common/layout/MainLayout";
import {CustomCellTableEditor, CustomRowTableEditor} from "../../common/BodyTableComponent";
import FormEditDetailComponent from "./component/FormEditDetailComponent";
import {offMarketFiltersRequest, offMarketFiltersResponse} from "../../../data/mapping/CommonMapping";
import OffMarketCustomFilters from "../../common/components/OffMarketCustomFilters";

// ============== ACTION ==============
import {loginAction} from "../../../data/actions/authAction";
import {ActionUpdateProfile} from "../../../data/hooks/auth"
import {
    ActionDelinquentTaxList,
    ActionMasterDelinquentTax,
    ActionDelinquentTaxDetail,
    ActionDelinquentTaxInitStore,
    ActionDelinquentTaxUpdate,
    ActionDelinquentTaxDelete,
    ActionDelinquentTaxUnlock,
    ActionAddFavorite,
    ActionDeleteFavorite,
    ActionDelinquentTaxExport,
    ActionDelinquentTaxDownload
} from "../../../data/hooks/delinquentTax"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectDelinquentTaxList,
    selectMasterDelinquentTax,
    selectDelinquentTaxDetail,
    selectStatusAction
} from "../../../data/reselects/delinquentTaxSelector"
import DelinquentTaxResponse, {
    filtersResponse,
    column,
    fieldDetailRemove,
    detailColumn
} from "../../../data/mapping/Response/DelinquentTaxResponse";
import {filtersParams, FIELD_TYPE} from "../../../data/mapping/Request/DelinquentTaxRequest";
import {mappingSort} from "../../../utils/commonUtils";
import {DeleteOutlined, DownloadOutlined, InfoCircleOutlined} from "@ant-design/icons";
import ButtonListActionOffMarket from "../../common/components/ButtonListActionOffMarket";
import moment from "moment";

const ModelFields = Object.getOwnPropertyNames(new DelinquentTaxResponse())
const Index = (props) => {
    const [initData, setInitData] = useState({
        titleItemTable: getTitleTable(column, ['action'], []),
        modelItem: ModelFields,
    });
    const namePage = "delinquent_tax",
        [form] = Form.useForm(),
        [isPopupDetail, setIsPopupDetail] = useState(false),
        [lockedField, setLockedField] = useState([]),
        [getKeyPress, setGetKeyPress] = useState(),
        [idDetail, setIdDetail] = useState(""),
        [isFetching, setIsFetching] = useState(),
        [listFilters, setListFilters] = useState({}),
        [resultItem, setResultItem] = useState([]),
        [resultDetail, setResultDetail] = useState({}),
        [masterLeadType, setMasterLeadType] = useState([]),
        [masterOwnership, setMasterOwnership] = useState([]),
        [masterPropertyCity, setMasterPropertyCity] = useState([]),
        [masterPropertyState, setMasterPropertyState] = useState([]),
        [masterCounty, setMasterCounty] = useState([]),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        // ============== ACTION ==============
        getDelinquentTaxInit = ActionDelinquentTaxInitStore(),
        getDelinquentTaxList = ActionDelinquentTaxList(),
        getMasterDelinquentTax = ActionMasterDelinquentTax(),
        actionDelinquentTaxDelete = ActionDelinquentTaxDelete(),
        actionDelinquentTaxDownload = ActionDelinquentTaxDownload(),

        // ============== SELECT DATA ==============
        itemDelinquentTaxList = useSelector(selectDelinquentTaxList()),
        itemDelinquentTaxDetail = useSelector(selectDelinquentTaxDetail()),
        itemMasterDelinquentTax = useSelector(selectMasterDelinquentTax()),
        itemDataFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction());

    const componentTable = {
        // record_date: {
        //     sorter: (a, b) => a.age - b.age,
        //     sortOrder: listFilters?.order_field === "record_date" ? mappingSort(listFilters?.order_value, 1) : ""
        // },
        file_name: {
            width: 450,
            // sorter: (a, b) => a.age - b.age,
            // sortOrder: listFilters?.order_field === "record_date" ? mappingSort(listFilters?.order_value, 1) : ""
        },
        action: {
            width: 140,
            align: 'center',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => (
                <>
                    {
                        record?.is_edit_data ? (
                            <Popconfirm
                                title="Are you sure to delete?"
                                onConfirm={() => {
                                    actionDelinquentTaxDelete({id: record?.id})
                                }}
                                onCancel={e => {
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined/>
                            </Popconfirm>
                        ) : null
                    }
                    <DownloadOutlined className="ms-2" onClick={e => actionDelinquentTaxDownload({id: record?.id})}/>
                </>
            )
        },
    }

    useEffect(() => {
        getDelinquentTaxInit();
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = offMarketFiltersResponse(queryString.parse(props.location.search, {arrayFormat: 'comma'}));
            setListFilters({...listFilters, ...paramsFilters})
            form.setFieldsValue(paramsFilters)
            getDelinquentTaxList(paramsFilters);
        } else {
            // let paramsFilters = {
            //     date_added: [moment(new Date()).startOf('month'), moment(new Date()).endOf('month')]
            // }
            // setListFilters({...listFilters, ...paramsFilters})
            // form.setFieldsValue(paramsFilters)
            // props.history.replace(setParamsUrlFilter(props?.location?.pathname, offMarketFiltersRequest(paramsFilters)))
            // getDelinquentTaxList(paramsFilters)
        }
        getMasterDelinquentTax();
    }, []);

    useEffect(() => {
        setIsFetching(itemDataFetching);
    }, [itemDataFetching]);

    useEffect(() => {
        let newResult = generateRowItemDetail(itemDelinquentTaxDetail, detailColumn, fieldDetailRemove)
        setResultDetail(newResult);
        setLockedField(itemDelinquentTaxDetail?.lockedKeys);
    }, [itemDelinquentTaxDetail]);

    useEffect(() => {
        setMasterLeadType(itemMasterDelinquentTax.lead_type)
        setMasterOwnership(itemMasterDelinquentTax.ownership)
        // setMasterPropertyCity(itemMasterDelinquentTax.property_city)
        setMasterPropertyState((itemMasterDelinquentTax?.property_state || []).sort((function (a, b) {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
            return 0;
        })))
    }, [itemMasterDelinquentTax]);

    useEffect(() => {
        setResultItem(itemDelinquentTaxList.result)
        setPagination(itemDelinquentTaxList.pagination)
    }, [itemDelinquentTaxList]);

    const onTableChange = (pagination, filters, sorter) => {
        let paramsFilter = {};
        if (Object.keys(sorter).length > 0) {
            paramsFilter = {
                order_field: sorter?.order ? sorter?.field || "" : "",
                order_value: sorter?.order ? mappingSort(sorter?.order) : ""
            }
        }
        // let currentFilters = getCurrentFilters();
        filters = omit({...filters, ...listFilters, ...paramsFilter}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter, true);
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, offMarketFiltersRequest(newFilters)))
        setListFilters(newFilters);
        getDelinquentTaxList(newFilters);
    };

    const onSearch = (e) => {
        let newFilters = {...listFilters, ...e, page_index: 1}
        newFilters = omit(newFilters, ["order_field", "order_value"])
        setListFilters(newFilters)
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, offMarketFiltersRequest(newFilters)))
        getDelinquentTaxList(newFilters);
    }


    const getValueChange = (res) => {
        if (res["property_state"]) {
            let listCity = [],
                listCounty = []

            if ((form.getFieldValue("property_state") || []).length > 0) {
                (form.getFieldValue("property_state") || []).map(i => {
                    listCity = [...new Set([...itemMasterDelinquentTax.property_city[i], ...listCity])]
                    listCounty = [...new Set([...itemMasterDelinquentTax.county[i], ...listCounty])]
                })
                listCity = listCity.sort((function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }));
                listCounty = listCounty.sort((function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }))

                let oldSelectCity = [...(form.getFieldValue("property_city") || [])],
                    oldSelectCounty = [...(form.getFieldValue("county") || [])]

                if ((oldSelectCity.length > 0) && (listCity.length > 0)) {
                    let newSelectCity = []
                    listCity.map(i => {
                        oldSelectCity.map(j => {
                            if (i === j) {
                                newSelectCity.push(i)
                            }
                        })
                    })
                    form.setFieldsValue({property_city: newSelectCity})
                }
                if ((oldSelectCounty.length > 0) && (listCounty.length > 0)) {
                    let newSelectCounty = []
                    listCounty.map(i => {
                        oldSelectCounty.map(j => {
                            if (i === j) {
                                newSelectCounty.push(i)
                            }
                        })
                    })
                    form.setFieldsValue({county: newSelectCounty})
                }

                setMasterPropertyCity(listCity)
                setMasterCounty(listCounty)
            } else {
                setMasterPropertyCity([])
                form.setFieldsValue({property_city: []})
                setMasterCounty([])
                form.setFieldsValue({county: []})
            }
        }
    }

    return (
        <MainLayout pathname={props.location.pathname} history={props.history}>
            <OffMarketCustomFilters
                items={getDelinquentTaxList}
                isDisabledFiltersButton
                loading={isFetching}
                masterDisabledFields={itemMasterDelinquentTax?.filters_off_market_disabled || []}
                form={form}
                isExport={itemStatusAction.isExport}
                // onExport={onExport}
                // onExportCSV={onExportCSV}
                isGetValueChange
                getValueChange={getValueChange}
                onSearch={onSearch}
                masterOwnership={masterOwnership}
                masterPropertyState={masterPropertyState}
                masterCounty={masterCounty}
                masterPropertyCity={masterPropertyCity}
            />
            {/*<FilterComponent form={form}*/}
            {/*                 isExport={itemStatusAction.isExport}*/}
            {/*    // onExport={onExport}*/}
            {/*    // onExportCSV={onExportCSV}*/}
            {/*                 isGetValueChange*/}
            {/*                 getValueChange={getValueChange}*/}
            {/*                 onSearch={onSearch}*/}
            {/*                 masterOwnership={masterOwnership}*/}
            {/*                 masterPropertyState={masterPropertyState}*/}
            {/*                 masterCounty={masterCounty}*/}
            {/*                 masterPropertyCity={masterPropertyCity}/>*/}
            <ListComponent
                scroll={{x: 1500}}
                classParent="m-0 p-0 col-md-12"
                className="custom-table"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultItem || [], initData.modelItem)}
                columns={columnsTable({
                    titleTable: initData.titleItemTable,
                    component: componentTable,
                    itemName: namePage
                })}
                pagination={{
                    total: pagination.total,
                    totalPage: pagination.total_page,
                    current: pagination.page_index,
                    pageSize: pagination.page_size,
                    responsive: true
                }}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </MainLayout>
    )
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        auth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
