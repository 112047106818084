import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class LisPendensResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.appraised_value = data?.appraised_value || "";
        this.assessed_value = data?.assessed_value || "";
        this.bankrupt = data?.bankrupt || "";
        this.bath = data?.bath || "";
        this.bed = data?.bed || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.comments = data?.comments || "";
        this.county = data?.county || "";
        this.current_month = data?.current_month || "";
        this.current_year = data?.current_year || "";
        this.date_added = data?.date_added || "";
        this.deceased = data?.deceased || "";
        this.document_id = data?.document_id || "";
        this.email_addresses_1 = data?.email_addresses_1 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.email_addresses_6 = data?.email_addresses_6 || "";
        this.equity = data?.equity || "";
        this.estimated_equity = data?.estimated_equity || "";
        this.estimated_unpaid_balance = data?.estimated_unpaid_balance || "";
        this.garage = data?.garage || "";
        this.historical_interest_rate = data?.historical_interest_rate || "";
        this.instrument_no = data?.instrument_no || "";
        this.lead_type = data?.lead_type || "";
        this.legal_description = data?.legal_description || "";
        this.loan_expiration = data?.loan_expiration || "";
        this.loan_expiration_month = data?.loan_expiration_month || "";
        this.loan_expiration_year = data?.loan_expiration_year || "";
        this.loan_origination_month = data?.loan_origination_month || "";
        this.loan_origination_year = data?.loan_origination_year || "";
        this.mortgagee_bank_name = data?.mortgagee_bank_name || "";
        this.mortgagor_first_name = data?.mortgagor_first_name || "";
        this.mortgagor_last_name = data?.mortgagor_last_name || "";
        this.original_loan_amount = data?.original_loan_amount || "";
        this.owner_address = data?.owner_address || "";
        this.owner_city = data?.owner_city || "";
        this.owner_first_name = data?.owner_first_name || "";
        this.owner_last_name = data?.owner_last_name || "";
        this.owner_state = data?.owner_state || "";
        this.owner_zip_code = data?.owner_zip_code || "";
        this.ownership = data?.ownership || "";
        this.phone_numbers_1 = data?.phone_numbers_1 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.pool = data?.pool || "";
        this.property_address = data?.property_address || "";
        this.property_city = data?.property_city || "";
        this.property_id = data?.property_id || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.record_date = data?.record_date || "";
        this.relative_address = data?.relative_address || "";
        this.relative_city = data?.relative_city || "";
        this.relative_email_addresses_1 = data?.relative_email_addresses_1 || "";
        this.relative_email_addresses_2 = data?.relative_email_addresses_2 || "";
        this.relative_email_addresses_3 = data?.relative_email_addresses_3 || "";
        this.relative_full_name = data?.relative_full_name || "";
        this.relative_phone_1 = data?.relative_phone_1 || "";
        this.relative_phone_2 = data?.relative_phone_2 || "";
        this.relative_phone_3 = data?.relative_phone_3 || "";
        this.relative_state = data?.relative_state || "";
        this.relative_zip = data?.relative_zip || "";
        this.sq_ft = data?.sq_ft || "";
        this.subdivision = data?.subdivision || "";
        this.trustee_name = data?.trustee_name || "";
        this.yr_bt = data?.yr_bt || "";
        this.lockedKeys = data?.lockedKeys || [];
        this.is_favorite = data?.is_favorite || 0;
        this.filters_off_market_disabled = data?.filters_off_market_disabled || [];
    }

    exportList() {
        return {
            id: this.id,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            record_date: this.record_date,
            assessed_value: this.assessed_value,
            is_favorite: this.is_favorite,
        }
    }

    exportMaster(data) {
        return {
            lead_type: data?.lead_type || [],
            ownership: data?.ownership || [],
            property_city: data?.property_city || [],
            property_state: data?.property_state || [],
            county: data?.county || [],
            filters_off_market_disabled: data?.filters_off_market_disabled || [],
        }
    }

    exportDetail() {
        return {
            // id: this.id,
            document_id: this.document_id,
            date_added: this.date_added,
            record_date: this.record_date,
            county: this.county,
            mortgagor_first_name: this.mortgagor_first_name,
            mortgagor_last_name: this.mortgagor_last_name,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,
            bed: this.bed,
            bath: this.bath,
            sq_ft: this.sq_ft,
            garage: this.garage,
            pool: this.pool,
            yr_bt: this.yr_bt,
            trustee_name: this.trustee_name,
            mortgagee_bank_name: this.mortgagee_bank_name,
            estimated_equity: this.estimated_equity,
            equity: this.equity,
            estimated_unpaid_balance: this.estimated_unpaid_balance,
            original_loan_amount: this.original_loan_amount,
            historical_interest_rate: this.historical_interest_rate,
            assessed_value: this.assessed_value,
            appraised_value: this.appraised_value,
            current_month: this.current_month,
            current_year: this.current_year,
            loan_origination_month: this.loan_origination_month,
            loan_origination_year: this.loan_origination_year,
            loan_expiration: this.loan_expiration,
            loan_expiration_month: this.loan_expiration_month,
            loan_expiration_year: this.loan_expiration_year,
            instrument_no: this.instrument_no,
            legal_description: this.legal_description,
            subdivision: this.subdivision,
            ownership: this.ownership,
            owner_first_name: this.owner_first_name,
            owner_last_name: this.owner_last_name,
            owner_address: this.owner_address,
            owner_city: this.owner_city,
            owner_state: this.owner_state,
            owner_zip_code: this.owner_zip_code,
            property_id: this.property_id,
            comments: this.comments,
            lead_type: this.lead_type,
            email_addresses_1: this.email_addresses_1,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            email_addresses_6: this.email_addresses_6,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            phone_numbers_1: this.phone_numbers_1,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
            deceased: this.deceased,
            bankrupt: this.bankrupt,
            relative_full_name: this.relative_full_name,
            relative_address: this.relative_address,
            relative_city: this.relative_city,
            relative_state: this.relative_state,
            relative_zip: this.relative_zip,
            relative_phone_1: this.relative_phone_1,
            relative_phone_2: this.relative_phone_2,
            relative_phone_3: this.relative_phone_3,
            relative_email_addresses_1: this.relative_email_addresses_1,
            relative_email_addresses_2: this.relative_email_addresses_2,
            relative_email_addresses_3: this.relative_email_addresses_3,
            lockedKeys: this.lockedKeys,
        }
    }
}

export const column = ["is_favorite", "property_address", "property_city", "property_state", "record_date", "assessed_value"];
export const detailColumn = {
    document_id: "Document ID",
    date_added: "Date Added",
    record_date: "Record Date",
    county: "County",
    mortgagor_first_name: "Mortgagor First Name",
    mortgagor_last_name: "Mortgagor Last Name",
    property_address: "Property Address",
    property_city: "Property City",
    property_state: "Property State",
    property_zip_code: "Property Zip Code",
    bed: "Bed",
    bath: "Bath",
    sq_ft: "Sq Ft",
    garage: "Garage",
    pool: "Pool",
    yr_bt: "Yr Bt",
    trustee_name: "Trustee Name",
    mortgagee_bank_name: "Mortgagee Bank Name",
    estimated_equity: "Estimated Equity",
    equity: "Equity %",
    estimated_unpaid_balance: "Estimated Unpaid Balance",
    original_loan_amount: "Original Loan Amount",
    historical_interest_rate: "Historical Interest Rate",
    assessed_value: "Assessed Value",
    appraised_value: "Appraised Value",
    current_month: "Current Month",
    current_year: "Current Year",
    loan_origination_month: "Loan Origination Month",
    loan_origination_year: "Loan Origination Year",
    loan_expiration: "Loan Expiration",
    loan_expiration_month: "Loan Expiration Month",
    loan_expiration_year: "Loan Expiration Year",
    instrument_no: "Instrument No",
    legal_description: "Legal Description",
    subdivision: "Subdivision",
    ownership: "Ownership",
    owner_first_name: "Owner First Name",
    owner_last_name: "Owner Last Name",
    owner_address: "Owner Address",
    owner_city: "Owner City",
    owner_state: "Owner State",
    owner_zip_code: "Owner Zip Code",
    property_id: "Property ID",
    comments: "Comments",
    lead_type: "Lead Type",
    email_addresses_1: "EmailAddresses1",
    email_addresses_2: "EmailAddresses2",
    email_addresses_3: "EmailAddresses3",
    email_addresses_4: "EmailAddresses4",
    email_addresses_5: "EmailAddresses5",
    email_addresses_6: "EmailAddresses6",
    cell_phones_1: "CellPhones1",
    cell_phones_2: "CellPhones2",
    cell_phones_3: "CellPhones3",
    cell_phones_4: "CellPhones4",
    cell_phones_5: "CellPhones5",
    phone_numbers_1: "PhoneNumbers1",
    phone_numbers_2: "PhoneNumbers2",
    phone_numbers_3: "PhoneNumbers3",
    phone_numbers_4: "PhoneNumbers4",
    phone_numbers_5: "PhoneNumbers5",
    deceased: "Deceased",
    bankrupt: "Bankrupt",
    relative_full_name: "Relative Full Name",
    relative_address: "Relative Address",
    relative_city: "Relative City",
    relative_state: "Relative State",
    relative_zip: "Relative Zip",
    relative_phone_1: "Relative Phone1",
    relative_phone_2: "Relative Phone2",
    relative_phone_3: "Relative Phone3",
    relative_email_addresses_1: "Relative EmailAddresses1",
    relative_email_addresses_2: "Relative EmailAddresses2",
    relative_email_addresses_3: "Relative EmailAddresses3",
};

export const fieldDetailRemove = ["lockedKeys"];

export const filtersResponse = (filters) => {
    let newRecordDate = (filters?.record_date_from && filters?.record_date_to) ? [moment(filters?.record_date_from), moment(filters?.record_date_to)] : []
    return removeObjectNullFull({
        record_date: newRecordDate,
        assessed_value_from: checkNumber(filters?.assessed_value_from) ? filters?.assessed_value_from : "",
        assessed_value_to: checkNumber(filters?.assessed_value_to) ? filters?.assessed_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
    })
}

