import * as Types from '../types/LoanModification';

export const loanModificationMasterAction = (filters) => ({type: Types.LOAN_MODIFICATION_MASTER_ACTION, filters});
export const loanModificationMasterSuccess = (master) => ({
    type: Types.LOAN_MODIFICATION_MASTER_SUCCESS,
    master
});

export const loanModificationListAction = (filters) => ({type: Types.LOAN_MODIFICATION_LIST_ACTION, filters});
export const loanModificationListSuccess = (loanModification) => ({
    type: Types.LOAN_MODIFICATION_LIST_SUCCESS,
    loanModification
});

export const loanModificationDetailAction = (params) => ({type: Types.LOAN_MODIFICATION_DETAIL_ACTION, params});
export const loanModificationDetailSuccess = (loanModificationDetail) => ({
    type: Types.LOAN_MODIFICATION_DETAIL_SUCCESS,
    loanModificationDetail
});

export const loanModificationUpdateAction = (params) => ({type: Types.LOAN_MODIFICATION_UPDATE_ACTION, params});
export const loanModificationUpdateSuccess = (dataUpdate) => ({
    type: Types.LOAN_MODIFICATION_UPDATE_SUCCESS,
    dataUpdate
});

export const loanModificationDeleteAction = (params) => ({type: Types.LOAN_MODIFICATION_DELETE_ACTION, params});
export const loanModificationDeleteSuccess = (dataDelete) => ({
    type: Types.LOAN_MODIFICATION_DELETE_SUCCESS,
    dataDelete
});

export const loanModificationUnlockAction = (params) => ({type: Types.LOAN_MODIFICATION_UNLOCK_ACTION, params});
export const loanModificationUnlockSuccess = (resultUnlock) => ({
    type: Types.LOAN_MODIFICATION_UNLOCK_SUCCESS,
    resultUnlock
});

export const loanModificationFavoriteAction = (params) => ({type: Types.LOAN_MODIFICATION_FAVORITE_ACTION, params});
export const loanModificationFavoriteSuccess = (favorite) => ({
    type: Types.LOAN_MODIFICATION_FAVORITE_SUCCESS,
    favorite
});

export const loanModificationDeleteFavoriteAction = (params) => ({type: Types.LOAN_MODIFICATION_FAVORITE_DELETE_ACTION, params});
export const loanModificationDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.LOAN_MODIFICATION_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const loanModificationExportFavoriteAction = (filters) => ({type: Types.LOAN_MODIFICATION_FAVORITE_EXPORT_ACTION, filters});
export const loanModificationExportFavoriteSuccess = (resultExport) => ({
    type: Types.LOAN_MODIFICATION_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const loanModificationInitStore = (params) => ({type: Types.LOAN_MODIFICATION_INIT, params});
export const loanModificationClearStore = (params) => ({type: Types.LOAN_MODIFICATION_FAIL, params});
