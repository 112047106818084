import {combineReducers} from "redux";
import authReducer from './authReducer';
import hotelReducer from './hotelReducer';
import dataReducer from './dataReducer';
import taxSaleReducer from './taxSaleReducer';
import probateHeirshipReducer from './probateHeirshipReducer';
import heirshipReducer from './heirshipReducer';
import evictionReducer from './evictionReducer';
import divorceReducer from './divorceReducer';
import loanModificationReducer from './loanModificationReducer';
import apptOfSubTrusteeReducer from './apptOfSubTrusteeReducer';
import lisPendensReducer from './lisPendensReducer';
import noticeOfDefaultReducer from './noticeOfDefaultReducer';
import ticketReducer from './ticketReducer';
import subscriptionReducer from './subscriptionReducer';
import packagePaymentReducer from './packagePaymentReducer';
import templateReducer from './templateReducer';
import uploadReducer from './uploadReducer';
import exclusiveContentReducer from './exclusiveContentReducer';
import plansPricingReducer from './plansPricingReducer';
import billReducer from './billReducer';
import invoiceReducer from './invoiceReducer';
import codeViolationReducer from './codeViolationReducer';
import delinquentTaxReducer from './delinquentTaxReducer';

export default combineReducers({
    authReducer,
    hotelReducer,
    dataReducer,
    taxSaleReducer,
    probateHeirshipReducer,
    heirshipReducer,
    evictionReducer,
    divorceReducer,
    loanModificationReducer,
    apptOfSubTrusteeReducer,
    lisPendensReducer,
    noticeOfDefaultReducer,
    ticketReducer,
    subscriptionReducer,
    packagePaymentReducer,
    templateReducer,
    uploadReducer,
    exclusiveContentReducer,
    plansPricingReducer,
    billReducer,
    invoiceReducer,
    codeViolationReducer,
    delinquentTaxReducer,
});
