import MainLayout from "../../common/layout/MainLayout";
import {Button, Form, Input, message, Spin, Upload, Modal, Checkbox} from "antd";
import {Icon} from "../../common";
import React, {useEffect, useState} from "react";
import {ActionCheckProfile, ActionCreditHistory, ActionUpdateProfile} from "../../../data/hooks/auth";
import {ActionUpload, ActionUploadInitStore} from "../../../data/hooks/upload";
import {useSelector} from "react-redux";
import {getFetchingAuth, getProfile, getStatusAction, ItemCreditHistory} from "../../../data/reselects/authSelector";
import {selectFilesUpload, selectFetching} from "../../../data/reselects/uploadSelector";

import {LoadingOutlined, ExclamationCircleOutlined, RetweetOutlined} from '@ant-design/icons';
import SlideItem from "../../common/components/SlideItem";
import LazyImage from "../../common/components/LazyImage";
import {PAGINATION, ROUTE_LIST} from "../../../constants/define";
import {v4 as uuidv4} from 'uuid';
import ListComponent from "../../common/ListComponent";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    setParamsUrlFilter
} from "../../../utils/functions";
import {mappingSort} from "../../../utils/commonUtils";
import {omit} from "lodash";
import {filtersParams} from "../../../data/mapping/Request/DivorceRequest";
import {CreditResponse, customerCreditCols} from "../../../data/mapping/User";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const listCustomTitleTabs = [
    {
        route: ROUTE_LIST.MyProfile,
        name: "My Profile"
    },
    {
        route: ROUTE_LIST.MySubscription,
        name: "My Subscription"
    }
]

const ModelFields = Object.getOwnPropertyNames(new CreditResponse())
const Index = props => {
    const [showProfile, setShowProfile] = useState(false),
        [form] = Form.useForm(),
        [loading, setLoading] = useState(false),
        [files, setFiles] = useState({}),
        [imageUrl, setImageUrl] = useState(),
        actionUpdateProfile = ActionUpdateProfile(),
        actionUpload = ActionUpload(),
        actionUploadInitStore = ActionUploadInitStore(),
        actionCheckProfile = ActionCheckProfile(),
        itemFetching = useSelector(getFetchingAuth()),
        itemIsUpload = useSelector(selectFetching()),
        itemProfile = useSelector(getProfile()),
        itemFileUpload = useSelector(selectFilesUpload());

    const [initData, setInitData] = useState({
            titleItemTable: getTitleTable(customerCreditCols, [], []),
            modelItem: ModelFields,
        }),
        componentTable = {};

    const namePage = "credit_history",
        [resultItem, setResultItem] = useState([]),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        [listFilters, setListFilters] = useState({}),
        actionCreditHistory = ActionCreditHistory(),
        itemCreditHistory = useSelector(ItemCreditHistory()),
        itemStatusAction = useSelector(getStatusAction()),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        setResultItem(itemCreditHistory?.result || [])
        setPagination(itemCreditHistory?.pagination)
    }, [itemCreditHistory]);


    const onTableChange = (pagination, filters, sorter) => {
        let paramsFilter = {};
        if (Object.keys(sorter).length > 0) {
            paramsFilter = {
                order_field: sorter?.order ? sorter?.field || "" : "",
                order_value: sorter?.order ? mappingSort(sorter?.order) : ""
            }
        }
        // let currentFilters = getCurrentFilters();
        filters = omit({...filters, ...listFilters, ...paramsFilter}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, filtersParams(newFilters)))
        setListFilters(newFilters);
        actionCreditHistory(newFilters);
    };

    const updateProfile = (val) => {
        if (val.password || val.confirm_password) {
            if (val.password === val.confirm_password) {
                actionUpdateProfile({...val, id: props?.profile?.id, avatar: files?.file_url})
            } else {
                message.error("Password does not match")
            }
        } else {
            actionUpdateProfile({...val, id: props?.profile?.id, avatar: files?.file_url})
        }
    }

    useEffect(() => {
        actionCreditHistory();
        return () => {
            actionUploadInitStore();
            actionCheckProfile();
        }
    }, []);

    useEffect(() => {
        setLoading(itemIsUpload)
    }, [itemIsUpload]);

    useEffect(() => {
        setFiles(itemFileUpload)
    }, [itemFileUpload]);


    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoading(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    const onClearData = props => {
        form.setFieldsValue({...itemProfile, confirm_password: ""})
    }

    return (
        <MainLayout
            pathname={props.location.pathname}
            history={props.history}
            listCustomTitleTabs={listCustomTitleTabs}
            disabledTab
            isCustomTitleTabs
        >
            <Form
                form={form}
                layout="vertical"
                className=""
                initialValues={{
                    email: itemProfile?.email,
                    first_name: itemProfile?.first_name,
                    last_name: itemProfile?.last_name,
                    phone_number: itemProfile?.phone_number,
                    api_key: itemProfile?.api_key,
                    credit: itemProfile?.credit,
                    is_use_credit: itemProfile?.is_use_credit,
                }}
                onFinish={updateProfile}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={requiredMark}
            >
                <Spin spinning={itemFetching || false}>
                    <div className="container max-width--1470px ms-0 p-x--40px mt-5">
                        <div className="row">
                            <div className="col-10 ">
                                <div className="container ps-0">
                                    <div className="row bg-white px-4 pt-4 pb-1  box--shadow border__radius--5px">
                                        <div className="col-4">
                                            <Form.Item
                                                rules={[{required: true, message: 'Please input your first name'}]}
                                                label="First Name"
                                                name="first_name"
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item
                                                label="Phone number"
                                                name="phone_number"
                                                rules={[{required: true, message: 'Please input your phone number'}]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item
                                                rules={[{required: true, message: 'Please input your last name'}]}
                                                label="Last Name"
                                                name="last_name"
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[{
                                                    type: "string",
                                                    min: 6
                                                }, {whitespace: true}, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if (value && (value.length > 1)) {
                                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("'password' cannot be empty");
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })]}
                                            >
                                                <Input.Password/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item
                                                label="Confirm Password"
                                                name="confirm_password"
                                                rules={[{
                                                    type: "string",
                                                    min: 6
                                                }, {whitespace: true}, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if (value && (value.length > 1)) {
                                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("'password' cannot be empty");
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })]}
                                            >
                                                <Input.Password/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item label="Email" name="email">
                                                <Input disabled/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item label="Api Key" name="api_key">
                                                <Input disabled
                                                       addonAfter={<RetweetOutlined className="icon-ant" onClick={e => {
                                                           if (itemProfile?.api_key) {
                                                               Modal.confirm({
                                                                   title: "Notification",
                                                                   icon: <ExclamationCircleOutlined/>,
                                                                   content: "Are you sure to change new api key?",
                                                                   onOk() {
                                                                       form.setFieldsValue({api_key: (uuidv4()).replaceAll("-", "")})
                                                                   },
                                                                   onCancel() {
                                                                       // console.log('Cancel');
                                                                   },
                                                               })
                                                           } else {
                                                               form.setFieldsValue({api_key: (uuidv4()).replaceAll("-", "")})
                                                           }
                                                       }}/>}/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item label="Credit" name="credit">
                                                <Input disabled/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4">
                                            <Form.Item label=" " name="is_use_credit" valuePropName="checked">
                                                <Checkbox>Use Credit</Checkbox>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 px-0">
                                <div className="row h-100">
                                    <div className="col-12 text-center height--250px max-height--250px">
                                        <Upload
                                            name="file"
                                            listType="picture-card"
                                            className="avatar-uploader h-100 profile--upload"
                                            showUploadList={false}
                                            action={(files) => actionUpload({files, customer_id: itemProfile?.id})}
                                            customRequest={() => {
                                            }}
                                            // beforeUpload={beforeUpload}
                                            onChange={handleChange}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : (
                                                <div className="text-center">
                                                    {loading ? <>
                                                        <LoadingOutlined/>
                                                        <div style={{marginTop: 8}}>Upload</div>
                                                    </> : <>
                                                        <div className="profile__wrap mb-2">
                                                            <img
                                                                src={`${files?.file_url ? files?.file_url : (itemProfile?.avatar ? itemProfile.avatar : "/layout/icon/avatar_default_upload.svg")}`}
                                                                alt="avatar"
                                                                className="mb-3 border__radius--50 object-fit profile__wrap--avatar"/>
                                                        </div>
                                                        <span
                                                            className="color--black profile__title--avatar">{itemProfile?.username}</span>
                                                    </>}
                                                </div>
                                            )}
                                        </Upload>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <button type="button"
                                                    // disabled
                                                        className="btn btn-primary mb-0 btn-bg--disabled w-100"
                                                        onClick={() => onClearData()}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-6 ps-1">
                                                <Form.Item className="text-end mb-0">
                                                    <button type="submit" disabled={itemFetching}
                                                            className="btn btn-primary me-3 mb-0 w-100 btn-bg--primary fw-bold">
                                                        Update
                                                    </button>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Form>
            <div className="p-x--30px mt-5">
                <div className="contact-us__slide slide-dot-inside max-width--1400px bg-white">
                    <SlideItem className=""
                               settings={{
                                   dots: true,
                                   infinite: true,
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                               }}>
                        <a href="https://realestateiq.co/affiliate-program/" target="_blank">
                            <LazyImage
                                imageClass="w-100 max-width--1400px object-fit height-280px"
                                alt="asdasd"
                                placeholder={true}
                                src="/layout/banner/affiliate banner 1.png"
                                // src="https://wallpaperaccess.com/full/267434.jpg"
                            />
                        </a>
                    </SlideItem>
                </div>
            </div>
            <div className="container mt-4 max-width--1460px ms-0 p-x--40px">
                <div className="row">
                    <div className="col-12 bg-white py-3 border__radius--5px">
                        <p className="fs--20px fw-bold">Create History</p>
                        <ListComponent
                            scroll={{x: 1000}}
                            classParent="m-0 p-0 col-md-12"
                            className="custom-table"
                            marginTop={'0'} marginBottom={'0'}
                            loading={{spinning: itemStatusAction?.isFetchingCredit, size: 'large'}}
                            rowKey="id"
                            dataSource={getDataSource(resultItem || [], initData.modelItem)}
                            columns={columnsTable({
                                titleTable: initData.titleItemTable,
                                component: componentTable,
                                itemName: namePage
                            })}
                            pagination={{
                                total: pagination.total,
                                totalPage: pagination.total_page,
                                current: pagination.page_index,
                                pageSize: pagination.page_size,
                                responsive: true
                            }}
                            onChange={onTableChange}
                            componentTableList={{}}
                        />
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default Index
