import React, {useEffect, useState} from "react"
import {find, findIndex, groupBy, cloneDeep} from "lodash";
import moment from 'moment';
import queryString from 'query-string';
import MainLayout from "../../common/layout/MainLayout";
import {Button, Divider, Empty, Modal, Spin, Tree} from "antd";
import Packages from "./components/Packages";
import {ActionSubscriptionListPackage, ActionConfirmPackage} from "../../../data/hooks/subscription";
import {ActionPackageCreateOrder, ActionPackagePaymentInitStore} from "../../../data/hooks/packagePayment";
import {useSelector} from "react-redux";
import {
    selectFetching,
    selectSubscriptionListPackage,
    selectConfirmPackage
} from "../../../data/reselects/subscriptionSelector";
import {
    selectFetching as packagePaymentFetching,
    selectPackagePaymentCreateOrder,
} from "../../../data/reselects/packagePaymentSelector";
import {removeArrayElementObject, removeElementObject} from "../../../utils/functions";
import ChoosePackages from "./components/ChoosePackages";
import InfoPackages from "./components/InfoPackages";
import {TYPE_CONFIRM_PACKAGE} from "../../../data/mapping/Response/SubscriptionResponse";
import {TYPE_PACKAGE} from "../../../data/mapping/Response/PackagePaymentResponse";
import {Icon} from "../../common";

const Index = (props) => {
    const namePage = "subscription_current",
        [month, setMonth] = useState(""),
        [resultItem, setResultItem] = useState([]),
        [getSelectedItems, setSetSelectedItems] = useState([]),
        [confirmPackages, setConfirmPackages] = useState([]),
        [requestParams, setRequestParams] = useState([]),
        [keySelected, setKeySelected] = useState([]),
        [isShowDetailConfirmPackage, setIsShowDetailConfirmPackage] = useState(false),
        // ============== ACTION ==============
        actionSubscriptionListPackage = ActionSubscriptionListPackage(),
        actionConfirmPackage = ActionConfirmPackage(),
        actionPackageCreateOrder = ActionPackageCreateOrder(),
        actionPackagePaymentInitStore = ActionPackagePaymentInitStore(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemPackagePaymentFetching = useSelector(packagePaymentFetching()),
        itemConfirmPackage = useSelector(selectConfirmPackage()),
        itemPackagePaymentCreateOrder = useSelector(selectPackagePaymentCreateOrder()),
        itemListPackage = useSelector(selectSubscriptionListPackage());

    useEffect(() => {
        actionPackagePaymentInitStore({clearResult: true})
        let month = queryString.parse(props.location.search)?.month ? queryString.parse(props.location.search)?.month : moment(new Date()).format("YYYYMM");
        setMonth(month)
        actionSubscriptionListPackage({month: month});
    }, []);

    useEffect(() => {
        let month = queryString.parse(props.location.search)?.month ? queryString.parse(props.location.search)?.month : moment(new Date()).format("YYYYMM");
        setMonth(month)
        actionSubscriptionListPackage({month: month});
    }, [props.location]);

    useEffect(() => {
        setResultItem(itemListPackage?.customPackages || []);
    }, [itemListPackage]);

    useEffect(() => {
        setConfirmPackages(itemConfirmPackage);
        setIsShowDetailConfirmPackage(Object.keys(itemConfirmPackage).length > 0)
    }, [itemConfirmPackage]);

    const selectItems = props => {
        setSetSelectedItems(props)
    }

    const onRequestParams = props => {
        let rqParams = [],
            selectParams = (props || []).filter(i => i.parent === 2 && i.select && !i.disabled);
        if ((selectParams || []).length > 0) {
            rqParams = selectParams.map(i => i?.items?.id)
        }
        setRequestParams(rqParams)
    }

    const onSubscript = event => {
        actionConfirmPackage({
            month,
            packages: requestParams,
            orderType: TYPE_CONFIRM_PACKAGE.PACkAGE_LIST
        })
    }

    const onCreateOrder = (type) => {
        actionPackageCreateOrder({
            month,
            type,
            packages: requestParams,
            orderType: TYPE_CONFIRM_PACKAGE.PACkAGE_LIST,
        })
    }

    return (
        <MainLayout title={`Choose your package for ${moment(queryString.parse(props.location.search)?.month || new Date()).format("YYYY-MM")}`} disabledTab
                    breadcrumb={["My Subscription", "Detail"]}>
            <div className="package-subscription__detail">
                <Modal className="package-subscription__detail__modal-confirm-package"
                       destroyOnClose
                       footer={null}
                       title={<h4 className="text-center fw-bold mb-0">Choose your package type
                           for {moment(month).format("YYYY-MM")}</h4>}
                       width={1000}
                       visible={isShowDetailConfirmPackage}
                       onOk={() => {
                       }} onCancel={() => setIsShowDetailConfirmPackage(false)}
                >
                    {
                        isShowDetailConfirmPackage && (
                            <Spin spinning={itemPackagePaymentFetching}>
                                <div className="container pt-3 mb-4">
                                    <div className="row align-items-center ">
                                        {
                                            confirmPackages?.S && (
                                                <div
                                                    className="col-6 py-4 px-4 text-center border package-subscription__detail__modal-confirm-package--standard">
                                                    <h4 className="fw-bold mb-3">{confirmPackages?.S?.name}</h4>
                                                    <p className="mb-0">{
                                                        confirmPackages?.S?.sale_price && confirmPackages?.S?.sale_price.length > 0 && (
                                                            <span
                                                                className="fs--22px mb-0 me-2 package-subscription--price-hint"><s><span>{confirmPackages?.S?.sale_price}</span></s></span>
                                                        )
                                                    }{confirmPackages?.S?.price}</p>
                                                    <p className="mb-0 package-subscription--price-hint">{confirmPackages?.S?.message}</p>
                                                    <button disabled={itemPackagePaymentFetching}
                                                            className="btn btn-primary btn-bg--primary w-100 mt-3"
                                                            onClick={() => onCreateOrder(TYPE_PACKAGE.STANDARD)}>
                                                        Buy Now {itemPackagePaymentFetching &&
                                                    <Icon type="sync-outlined" spin/>}
                                                    </button>
                                                </div>
                                            )
                                        }
                                        {
                                            confirmPackages?.P && (
                                                <div
                                                    className="col-6 py-4 px-4 text-center border package-subscription__detail__modal-confirm-package--premium">
                                                    <h4 className="fw-bold mb-3">{confirmPackages?.P?.name}</h4>
                                                    <p className="mb-0">{
                                                        confirmPackages?.P?.sale_price && confirmPackages?.P?.sale_price.length > 0 && (
                                                            <span
                                                                className="fs--22px mb-0 me-2 package-subscription--price-hint"><s><span>{confirmPackages?.P?.sale_price}</span></s></span>
                                                        )
                                                    }{confirmPackages?.P?.price}</p>
                                                    <p className="mb-0 package-subscription--price-hint">{confirmPackages?.P?.message}</p>
                                                    <button disabled={itemPackagePaymentFetching}
                                                            className="btn btn-primary btn-bg--primary w-100 mt-3"
                                                            onClick={() => onCreateOrder(TYPE_PACKAGE.PREMIUM)}>
                                                        Buy Now {itemPackagePaymentFetching &&
                                                    <Icon type="sync-outlined" spin/>}
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Spin>
                        )
                    }
                </Modal>
                <Spin spinning={itemFetching || itemPackagePaymentFetching}>
                    <div className="package-subscription__detail__package mt-5">
                        <InfoPackages/>
                        <div className="package-subscription__content mt-4">
                            <p className="px-4">RealEstaeIQ core features</p>
                            <ChoosePackages selectItems={selectItems} onRequestParams={onRequestParams}/>
                        </div>
                    </div>
                </Spin>
                <div className="container px-0 mb-5">
                    {
                        ((getSelectedItems[3] || []).length > 0) && (
                            <div className="text-end mt-3">
                                <Button disabled={itemFetching} type="primary" htmlType="button" onClick={onSubscript}>
                                    Subscribe
                                </Button>
                            </div>
                        )
                    }
                    <div className="package-subscription__detail__package--order mt-4">
                        <h4 className="package-subscription__detail__package--order-title">
                            Your Package
                        </h4>
                        <Divider className="my-3"/>
                        {
                            ((getSelectedItems[3] || []).length > 0) ? (
                                <Tree
                                    treeData={getSelectedItems[3]}
                                />
                            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        }
                    </div>
                    {
                        ((getSelectedItems[3] || []).length > 0) && (
                            <div className="text-end mt-3">
                                <Button disabled={itemFetching} type="primary" htmlType="button" onClick={onSubscript}>
                                    Subscribe
                                </Button>
                            </div>
                        )
                    }
                </div>
            </div>
        </MainLayout>
    )
}

export default Index
