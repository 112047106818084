import {all, put} from 'redux-saga/effects';
import * as Types from '../types/Template';

import {hotelSaga} from './hotelSaga';
import {authSaga} from './authSaga';
import {dataSaga} from './dataSaga';
import {taxSaleSaga} from './taxSaleSaga';
import {probateHeirshipSaga} from './probateHeirshipSaga';
import {heirshipSaga} from './heirshipSaga';
import {evictionSaga} from './evictionSaga';
import {divorceSaga} from './divorceSaga';
import {loanModificationSaga} from './loanModificationSaga';
import {apptOfSubTrusteeSaga} from './apptOfSubTrusteeSaga';
import {lisPendensSaga} from './lisPendensSaga';
import {noticeOfDefaultSaga} from './noticeOfDefaultSaga';
import {ticketSaga} from './ticketSaga';
import {subscriptionSaga} from './subscriptionSaga';
import {packagePaymentSaga} from './packagePaymentSaga';
import {templateSaga} from './templateSaga';
import {uploadSaga} from './uploadSaga';
import {exclusiveContentSaga} from './exclusiveContentSaga';
import {plansPricingSaga} from './plansPricingSaga';
import {billSaga} from './billSaga';
import {invoiceSaga} from './invoiceSaga';
import {codeViolationSaga} from './codeViolationSaga';
import {delinquentTaxSaga} from './delinquentTaxSaga';

export default function* rootSaga() {
    yield all([
        hotelSaga(),
        authSaga(),
        dataSaga(),
        taxSaleSaga(),
        probateHeirshipSaga(),
        evictionSaga(),
        heirshipSaga(),
        divorceSaga(),
        loanModificationSaga(),
        apptOfSubTrusteeSaga(),
        lisPendensSaga(),
        noticeOfDefaultSaga(),
        ticketSaga(),
        subscriptionSaga(),
        packagePaymentSaga(),
        templateSaga(),
        uploadSaga(),
        exclusiveContentSaga(),
        plansPricingSaga(),
        billSaga(),
        invoiceSaga(),
        codeViolationSaga(),
        delinquentTaxSaga(),
    ])
}
