import * as Types from '../types/Subscription';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    isFetchingGeneral: false,
    statusAction: {
        isSubmit: false,
        isCreateCancelationFeedback: false,
        createCancelationFeedbackSuccess: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    current: {},
    detailUpgrade: {},
    listPackages: {},
    listTransactions: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    confirmPackage: {},
    general: {},
    detail: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.SUBSCRIPTION_CURRENT_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CURRENT_SUCCESS:
            return {...state, isFetching: false, current: action.current, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_DETAIL_UPGRADE_ACTION:
            return {
                ...state,
                isFetching: true,
                statusAction: initialState.statusAction,
                detailUpgrade: {},
                listPackages: {},
                listTransactions: initialState.listTransactions
            };
        case Types.SUBSCRIPTION_DETAIL_UPGRADE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                detailUpgrade: action.detailUpgrade,
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_LIST_PACKAGE_ACTION:
            return {
                ...state,
                isFetching: true,
                statusAction: initialState.statusAction,
                detailUpgrade: {},
                listPackages: {},
                listTransactions: initialState.listTransactions,
                confirmPackage: {}
            };
        case Types.SUBSCRIPTION_LIST_PACKAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listPackages: action.listPackages,
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_LIST_TRANSACTION_ACTION:
            return {
                ...state,
                isFetching: true,
                statusAction: initialState.statusAction,
                detailUpgrade: {},
                listPackages: {},
                // listTransactions: initialState.listTransactions,
                confirmPackage: {}
            };
        case Types.SUBSCRIPTION_LIST_TRANSACTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listTransactions: getResult(action.listTransactions),
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_CONFIRM_PACKAGE_ACTION:
            return {
                ...state,
                isFetching: true,
                statusAction: initialState.statusAction,
                confirmPackage: {},
                listPackages: {},
                listTransactions: initialState.listTransactions,
            };
        case Types.SUBSCRIPTION_CONFIRM_PACKAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                confirmPackage: action.confirmPackage,
            };
        case Types.SUBSCRIPTION_GENERAL_ACTION:
            return {
                ...state,
                isFetchingGeneral: true,
                statusAction: initialState.statusAction,
                general: {}
            };
        case Types.SUBSCRIPTION_GENERAL_SUCCESS:
            return {
                ...state,
                isFetchingGeneral: false,
                general: action.general,
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_CANCELATION_FEEDBACK_ACTION:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isCreateCancelationFeedback: true
                },
            };
        case Types.SUBSCRIPTION_CANCELATION_FEEDBACK_SUCCESS:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isCreateCancelationFeedback: false,
                    createCancelationFeedbackSuccess: true,
                },
            };
        case Types.SUBSCRIPTION_FAIL:
            return {
                ...state,
                errors: action.errors,
                isFetching: false,
                isFetchingGeneral: false,
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_INIT:
            return {
                ...state,
                current: action.params?.clearDetail ? state.current : initialState.current,
                detailUpgrade: action.params?.clearDetail ? state.detailUpgrade : initialState.detailUpgrade,
                listPackages: action.params?.clearPackages ? state.listPackages : initialState.listPackages,
                listTransactions: action.params?.clearTransactions ? initialState.listTransactions : state.listTransactions,
                confirmPackage: action.params?.clearDetail ? state.confirmPackage : initialState.confirmPackage,
                general: action.params?.clearGeneral ? state.general : initialState.general,
            };
        default:
            return state;
    }
}
