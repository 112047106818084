import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class ProbateHeirshipResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.date_added = data?.date_added || "";
        this.date_filed = data?.date_filed || "";
        this.county = data?.county || "";
        this.case_number = data?.case_number || "";
        this.doc_type = data?.doc_type || "";
        this.decedent_first_name = data?.decedent_first_name || "";
        this.decedent_last_name = data?.decedent_last_name || "";
        this.property_street = data?.property_street || "";
        this.property_city = data?.property_city || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.assessed_value = data?.assessed_value || "";
        // this.party_type = data?.party_type || "";
        // this.relationship = data?.relationship || "";
        this.grantee_first_name = data?.grantee_first_name || "";
        this.grantee_last_name = data?.grantee_last_name || "";
        this.mailing_street = data?.mailing_street || "";
        this.mailing_city = data?.mailing_city || "";
        this.mailing_state = data?.mailing_state || "";
        this.mailing_zip_code = data?.mailing_zip_code || "";
        this.attorney_name = data?.attorney_name || "";
        this.attorney_phone = data?.attorney_phone || "";
        this.attorney_email = data?.attorney_email || "";
        this.attorney_address = data?.attorney_address || "";
        this.property_id = data?.property_id || "";
        this.date_of_death = data?.date_of_death || "";
        this.lead_type = data?.lead_type || "";
        this.email_addresses_1 = data?.email_addresses_1 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.email_addresses_6 = data?.email_addresses_6 || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.phone_numbers_1 = data?.phone_numbers_1 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.deceased = data?.deceased || "";
        this.bankrupt = data?.bankrupt || "";
        this.relative_full_name = data?.relative_full_name || "";
        this.relative_address = data?.relative_address || "";
        this.relative_city = data?.relative_city || "";
        this.relative_state = data?.relative_state || "";
        this.relative_zip = data?.relative_zip || "";
        this.relative_phone_1 = data?.relative_phone_1 || "";
        this.relative_phone_2 = data?.relative_phone_2 || "";
        this.relative_phone_3 = data?.relative_phone_3 || "";
        this.relative_email_addresses_1 = data?.relative_email_addresses_1 || "";
        this.relative_email_addresses_2 = data?.relative_email_addresses_2 || "";
        this.relative_email_addresses_3 = data?.relative_email_addresses_3 || "";
        this.lockedKeys = data?.lockedKeys || [];
        this.is_favorite = data?.is_favorite || 0;
        this.filters_off_market_disabled = data?.filters_off_market_disabled || [];
    }

    exportList() {
        return {
            id: this.id,
            property_street: this.property_street,
            property_city: this.property_city,
            property_state: this.property_state,
            date_filed: this.date_filed,
            assessed_value: this.assessed_value,
            is_favorite: this.is_favorite,
        }
    }

    exportMaster(data) {
        return {
            lead_type: data?.lead_type || [],
            ownership: data?.ownership || [],
            property_city: data?.property_city || [],
            property_state: data?.property_state || [],
            county: data?.county || [],
            filters_off_market_disabled: data?.filters_off_market_disabled || [],
        }
    }

    exportDetail() {
        return {
            // id: this.id,
            date_added: this.date_added,
            date_filed: this.date_filed,
            county: this.county,
            case_number: this.case_number,
            doc_type: this.doc_type,
            decedent_first_name: this.decedent_first_name,
            decedent_last_name: this.decedent_last_name,
            property_street: this.property_street,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,
            assessed_value: this.assessed_value,
            // party_type: this.party_type,
            // relationship: this.relationship,
            grantee_first_name: this.grantee_first_name,
            grantee_last_name: this.grantee_last_name,
            mailing_street: this.mailing_street,
            mailing_city: this.mailing_city,
            mailing_state: this.mailing_state,
            mailing_zip_code: this.mailing_zip_code,
            attorney_name: this.attorney_name,
            attorney_phone: this.attorney_phone,
            attorney_email: this.attorney_email,
            attorney_address: this.attorney_address,
            property_id: this.property_id,
            date_of_death: this.date_of_death,
            lead_type: this.lead_type,
            email_addresses_1: this.email_addresses_1,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            email_addresses_6: this.email_addresses_6,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            phone_numbers_1: this.phone_numbers_1,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
            deceased: this.deceased,
            bankrupt: this.bankrupt,
            relative_full_name: this.relative_full_name,
            relative_address: this.relative_address,
            relative_city: this.relative_city,
            relative_state: this.relative_state,
            relative_zip: this.relative_zip,
            relative_phone_1: this.relative_phone_1,
            relative_phone_2: this.relative_phone_2,
            relative_phone_3: this.relative_phone_3,
            relative_email_addresses_1: this.relative_email_addresses_1,
            relative_email_addresses_2: this.relative_email_addresses_2,
            relative_email_addresses_3: this.relative_email_addresses_3,
            lockedKeys: this.lockedKeys,
        }
    }
}

export const column = ["is_favorite", "property_street", "property_city", "property_state", "date_filed", "assessed_value"];
export const detailColumn = {
    date_added: "Date Added",
    date_filed: "Date Filed",
    county: "County",
    case_number: "Case Number",
    doc_type: "Doc Type",
    decedent_first_name: "Decedent First Name",
    decedent_last_name: "Decedent Last Name",
    property_street: "Property Street",
    property_city: "Property City",
    property_state: "Property State",
    property_zip_code: "Property Zip Code",
    assessed_value: "Assessed Value",
    // party_type: "Party Type",
    // relationship: "Relationship",
    grantee_first_name: "Grantee First Name",
    grantee_last_name: "Grantee Last Name",
    mailing_street: "Mailing Street",
    mailing_city: "Mailing City",
    mailing_state: "Mailing State",
    mailing_zip_code: "Mailing Zip Code",
    attorney_name: "Attorney Name",
    attorney_phone: "Attorney Phone",
    attorney_email: "Attorney Email",
    attorney_address: "Attorney Address",
    property_id: "Property ID",
    date_of_death: "Date of Death",
    lead_type: "Lead Type",
    email_addresses_1: "EmailAddresses1",
    email_addresses_2: "EmailAddresses2",
    email_addresses_3: "EmailAddresses3",
    email_addresses_4: "EmailAddresses4",
    email_addresses_5: "EmailAddresses5",
    email_addresses_6: "EmailAddresses6",
    cell_phones_1: "CellPhones1",
    cell_phones_2: "CellPhones2",
    cell_phones_3: "CellPhones3",
    cell_phones_4: "CellPhones4",
    cell_phones_5: "CellPhones5",
    phone_numbers_1: "PhoneNumbers1",
    phone_numbers_2: "PhoneNumbers2",
    phone_numbers_3: "PhoneNumbers3",
    phone_numbers_4: "PhoneNumbers4",
    phone_numbers_5: "PhoneNumbers5",
    deceased: "Deceased",
    bankrupt: "Bankrupt",
    relative_full_name: "Relative Full Name",
    relative_address: "Relative Address",
    relative_city: "Relative City",
    relative_state: "Relative State",
    relative_zip: "Relative Zip",
    relative_phone_1: "Relative Phone1",
    relative_phone_2: "Relative Phone2",
    relative_phone_3: "Relative Phone3",
    relative_email_addresses_1: "Relative EmailAddresses1",
    relative_email_addresses_2: "Relative EmailAddresses2",
    relative_email_addresses_3: "Relative EmailAddresses3",
};

export const fieldDetailRemove = ["lockedKeys"];

export const filtersResponse = (filters) => {
    let newDateField = (filters?.date_filed_from && filters?.date_filed_to) ? [moment(filters?.date_filed_from), moment(filters?.date_filed_to)] : []
    let newDateAdded = (filters?.date_added_from && filters?.date_added_to) ? [moment(filters?.date_added_from), moment(filters?.date_added_to)] : []
    return removeObjectNullFull({
        date_filed: newDateField,
        date_added: newDateAdded,
        assessed_value_from: checkNumber(filters?.assessed_value_from) ? filters?.assessed_value_from : "",
        assessed_value_to: checkNumber(filters?.assessed_value_to) ? filters?.assessed_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
    })
}

