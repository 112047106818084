import {Alert, Divider, Modal, Pagination} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import ListComponent from "../../../common/ListComponent";
import {columnsTable, getDataSource} from "../../../../utils/functions";
import React from "react";

const InvoiceComponent = props => {
    return (
        <Modal
            width={1300}
            destroyOnClose
            visible={props.isInvoice}
            onOk={() => props.setIsInvoice(false)}
            onCancel={() => props.setIsInvoice(false)} footer={null}>
            <div className="py-2 mt-4">
                <Alert message={
                    <>
                        <InfoCircleOutlined/>
                        <span className="ms-2">
                                The invoice will be generated about 24 hours after we have received
                                your payment.
                            </span>
                    </>
                } type="info" showIcon className="my-subscription__invoice--alert"/>
                <div className="my-subscription__invoice--list mt-4">
                    <h3 className="color--black fs--32px">Invoices</h3>
                    <Divider className="my-2 mb-4"/>
                    <ListComponent
                        scroll={{x: 1100}}
                        classParent="m-0 p-0 col-md-12"
                        className="custom-table"
                        marginTop={'0'}
                        marginBottom={'0'}
                        loading={{spinning: props.isFetching, size: 'large'}}
                        rowKey="id"
                        dataSource={getDataSource(props.resultItemList || [], props.initData.modelItem)}
                        columns={columnsTable({
                            titleTable: props.initData.titleItemTable,
                            component: props.componentTable,
                            itemName: props.namePageTransactions
                        })}
                        componentTableList={{}}
                    />
                    <div className="text-center mt-4">
                        <Pagination className="my-subscription__invoice--pagination"
                                    defaultCurrent={props.pagination.page_index}
                                    total={props.pagination.total_page}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default InvoiceComponent
