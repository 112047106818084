import React, {useContext, createContext, useState, useEffect, useRef, useCallback} from 'react';
import {Form, Input, Popconfirm, Modal, Spin} from 'antd'
import {convertParamsToSnake} from "../../utils/functions";
import {
    CheckOutlined,
    CloseOutlined,
    SaveOutlined,
    LoadingOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {find} from 'lodash'
import moment from 'moment'
import {DATE_TIME_FORMAT} from "../../constants/define";
import {useSelector} from "react-redux";
import {getProfile} from "../../data/reselects/authSelector";
import {FIELD_TYPE} from "../../data/mapping/Request/DataRequest";
import FormEditDetailComponent from "./FormEditDetailComponent";
import {selectStatusAction as selectSubmitTaxSale} from "../../data/reselects/taxSaleSelector";
import {selectStatusAction as selectSubmitProbateHeirship} from "../../data/reselects/probateHeirshipSelector";
import {selectStatusAction as selectSubmitloanModification} from "../../data/reselects/loanModificationSelector";
import {selectStatusAction as selectSubmitHeirship} from "../../data/reselects/heirshipSelector";
import {selectStatusAction as selectSubmitEviction} from "../../data/reselects/evictionSelector";
import {selectStatusAction as selectSubmitDivorce} from "../../data/reselects/divorceSelector";
import {selectStatusAction as selectSubmitData} from "../../data/reselects/dataSelector";
import {selectStatusAction as selectSubmitApptOfSubTrustee} from "../../data/reselects/apptOfSubTrusteeSelector";
import {selectStatusAction as selectSubmitLisPendens} from "../../data/reselects/lisPendensSelector";
import {selectStatusAction as selectSubmitNoticeOfDefault} from "../../data/reselects/noticeOfDefaultSelector";

const EditableContext = createContext(null);
let enableRow

export const CustomRowTableEditor = ({props, onEditDetail}) => {
    const [form] = Form.useForm();

    const onSave = (val) => {
        onEditDetail(val)
    }

    return (
        <Form form={form} component={false} onFinish={onSave}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
}

export const CustomCellTableEditor = ({
                                          props, FIELD_TYPE, getKeyPress, lockedField, isUnlock = false,
                                          actionUnlock = () => {
                                          }, unlockField = "", idDetail
                                      }
) => {
    const [editing, setEditing] = useState(false),
        form = useContext(EditableContext),
        inputRef = useRef(null),
        itemTaxSaleSubmit = useSelector(selectSubmitTaxSale()),
        itemProbateHeirshipSubmit = useSelector(selectSubmitProbateHeirship()),
        itemLoanModificationSubmit = useSelector(selectSubmitloanModification()),
        itemHeirshipSubmit = useSelector(selectSubmitHeirship()),
        itemEvictionSubmit = useSelector(selectSubmitEviction()),
        itemDivorceSubmit = useSelector(selectSubmitDivorce()),
        itemDataSubmit = useSelector(selectSubmitData()),
        itemApptOfSubTrusteeSubmit = useSelector(selectSubmitApptOfSubTrustee()),
        itemLisPendensSubmit = useSelector(selectSubmitLisPendens()),
        itemNoticeOfDefaultSubmit = useSelector(selectSubmitNoticeOfDefault()),
        profile = useSelector(getProfile());
    let childNode = props.children;

    useEffect(() => {
        if (editing && inputRef?.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (getKeyPress) {
            if (getKeyPress?.type === "keydown" && getKeyPress?.keyCode === 27) {
                setEditing(false)
                enableRow = false
            }
        }
    }, [getKeyPress]);

    const convertFieldDataValue = (val, key, listConditions) => {
        let newValue = "";
        if (key) {
            if (Object.keys(listConditions).filter(i => listConditions[i].includes("NUMBER")).includes(key)) {
                newValue = ((val || "") + "").replace(/[^\d\.-]/g, "");
            } else if (Object.keys(listConditions).filter(i => listConditions[i].includes("DATE")).includes(key)) {
                newValue = val ? moment(val, DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : ""
            } else if (Object.keys(listConditions).filter(i => listConditions[i].includes("DATE_TIME")).includes(key)) {
                newValue = val ? moment(val, DATE_TIME_FORMAT.DATE_TIME) : ""
            } else {
                newValue = val;
            }
        }
        return newValue
    }

    const toggleEdit = () => {
        if (profile?.is_edit_data) {
            setEditing(true);
            enableRow = true
            if (!editing) {
                form.setFieldsValue({
                    [props.record?.param]: convertFieldDataValue(props.record[props.dataindex], props.record?.param, FIELD_TYPE),
                });
            }
        }
    };

    const onCancel = () => {
        setEditing(false)
        enableRow = false;
    }

    const onSubmitEdit = () => {
        return (itemTaxSaleSubmit.isSubmit || itemProbateHeirshipSubmit.isSubmit || itemLoanModificationSubmit.isSubmit || itemHeirshipSubmit.isSubmit || itemEvictionSubmit.isSubmit || itemDivorceSubmit.isSubmit || itemDataSubmit.isSubmit || itemApptOfSubTrusteeSubmit.isSubmit || itemLisPendensSubmit.isSubmit || itemNoticeOfDefaultSubmit.isSubmit)
    }

    const onUnlock = (props) => {
        const result = Modal.confirm({
            title: 'Notification',
            icon: <ExclamationCircleOutlined/>,
            content: 'Are you sure you want to unlock this data? You will be charged for this data.',
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk() {
                actionUnlock(props)
            }
        });
    }

    if (props.editable) {
        childNode = (editing && !(FIELD_TYPE[props.record?.param] || []).includes("DISABLED")) ? (
            <div onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    onCancel()
                }
            }}>
                <Spin spinning={onSubmitEdit()} wrapperClassName="float-start width-93">
                    <FormEditDetailComponent
                        {...props}
                        inputRef={inputRef}
                        form={form}
                        onCancel={onCancel}
                        fieldType={FIELD_TYPE}
                        // isSubmit={onSubmitEdit()}
                    />
                </Spin>
                <div className="float-end width-5">
                    {
                        onSubmitEdit() ?
                            <LoadingOutlined className="cursorPointer mb--5px" style={{color: "#52c41a"}}/> :
                            <CheckOutlined className="cursorPointer mb--5px" style={{color: "#52c41a"}}
                                           onClick={(e) => {
                                               inputRef.current.blur();
                                               form.submit()
                                           }}/>
                    }
                </div>
                <div className="clearfix"/>
            </div>
        ) : (
            <div
                className="editable-cell-value-wrap"
            >
                {
                    lockedField.includes(props.record?.param) ? (
                        <div
                            className={`detail--unlock ${(unlockField === props.record?.param) ? "detail--disabled" : ""}`}
                            onClick={() => onUnlock({id: idDetail, field: props.record?.param})}>
                            <i className="fas fa-unlock-alt"/><span className="ms-2">UNLOCK DATA</span>
                            {
                                (isUnlock && (unlockField === props.record?.param)) && (
                                    <LoadingOutlined className="cursorPointer ms-2" style={{color: "#52c41a"}}/>
                                )
                            }
                        </div>
                    ) : (
                        props.children
                    )
                }
            </div>
        );
    }

    return <td {...props} onClick={(e) => {
        props.editable && toggleEdit();
    }}>{childNode}</td>

    // if (fieldDetailRemove.includes(props.record?.param)) {
    //     return null
    // } else {
    //     return <td {...props} onClick={(e) => {
    //         props.editable && toggleEdit();
    //     }}>{childNode}</td>
    // }
}
