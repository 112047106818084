import * as Types from '../types/DelinquentTax';
import {PAGINATION} from '../../constants/define';
import {getResultListing} from "../../utils/functions";
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    detail: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.DELINQUENT_TAX_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResultListing(action.delinquentTax),
                statusAction: initialState.statusAction
            };
        case Types.DELINQUENT_TAX_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                detail: action.delinquentTaxDetail,
                statusAction: initialState.statusAction
            };
        case Types.DELINQUENT_TAX_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.DELINQUENT_TAX_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.DELINQUENT_TAX_DELETE_ACTION:
        case Types.DELINQUENT_TAX_DOWNLOAD_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_DOWNLOAD_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_FAVORITE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_FAVORITE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_FAVORITE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_FAVORITE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_FAVORITE_EXPORT_ACTION:
            return {...state, isFetching: true, statusAction: {...initialState.statusAction, isExport: true}};
        case Types.DELINQUENT_TAX_FAVORITE_EXPORT_SUCCESS:
            return {...state, isFetching: false, statusAction: {...initialState.statusAction, isExport: false}};
        case Types.DELINQUENT_TAX_UNLOCK_ACTION:
            return {
                ...state,
                statusAction: {...initialState.statusAction, isUnlock: true, unlockField: action.params.field}
            };
        case Types.DELINQUENT_TAX_UNLOCK_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isUnlock: false, unlockField: ""}};
        case Types.DELINQUENT_TAX_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.DELINQUENT_TAX_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail
            };
        default:
            return state;
    }
}
