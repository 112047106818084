import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Collapse,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber,
    Select,
    Space,
    Radio, Spin, Tooltip
} from "antd";
import {Icon} from "../";
import ButtonFilterOffMarket from "./ButtonFilterOffMarket";
import TAG_DEFINE from "../../../constants/common";
import {MdKeyboardArrowLeft, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from "react-icons/md";
import {BiLeftArrowAlt} from "react-icons/bi";
import {AiOutlineQuestionCircle} from "react-icons/ai";

const {RangePicker} = DatePicker;
const {Option} = Select;

const OffMarketCustomFilters = (props) => {
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const changeValueField = (res) => {
        if (res["is_favorite"]) {
            props.form.setFieldsValue({
                is_favorite: 1
            })
        }
        if (props.isGetValueChange) {
            props.getValueChange(res)
        }
    }

    useEffect(() => {
    }, []);

    return (
        <div className="off-market-filters__wrapper">
            <Form
                form={props.form}
                onFinish={props.onSearch}
                layout="vertical"
                onValuesChange={(res) => changeValueField(res)}
                // onFieldsChange={(changedFields, allFields) => onChangeField(changedFields, allFields)}
            >
                <div id="off-market-filters-id">
                    <Drawer
                        width={500}
                        title="Advanced filters"
                        className="off-market-filters"
                        placement="right"
                        onClose={e => setIsOpenFilters(false)}
                        open={isOpenFilters}
                        getContainer={e => document.getElementById("off-market-filters-id")}
                    >
                        <p className="fs--18px fw-bold color--black">Filter by</p>
                        <Spin spinning={props?.loading}>
                            <Collapse
                                style={{
                                    marginBottom: "70px"
                                }}
                                defaultActiveKey={["property_type", "property_characteristics", "valuation_and_equity", "date_filed", "occupancy"]}
                                ghost
                                expandIcon={({isActive}) => isActive ? <MdOutlineKeyboardArrowUp/> :
                                    <MdOutlineKeyboardArrowDown/>}
                            >
                                <Collapse.Panel
                                    className="off-market-filters--property-type"
                                    header={<p className="mb-0 fw-bold color--black fs--14px">Property Type</p>}
                                    key="property_type"
                                >
                                    <Form.Item
                                        className="mb-0"
                                        name="property_type"
                                        label=""
                                    >
                                        <Checkbox.Group
                                            disabled={(props?.masterDisabledFields || [])?.includes("property_type")}>
                                            <Checkbox
                                                value="Residential"
                                                className="fs--14px"
                                                style={{
                                                    lineHeight: '32px',
                                                }}
                                            >
                                                Residential
                                            </Checkbox>
                                            <Checkbox
                                                value="Commercial"
                                                className="fs--14px"
                                                style={{
                                                    lineHeight: '32px',
                                                }}
                                            >
                                                Commercial
                                            </Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Collapse.Panel>
                                <Divider className="py-0 mt-2"/>
                                <Collapse.Panel
                                    header={<p className="mb-0 fw-bold color--black fs--14px">Property
                                        Characteristics</p>}
                                    key="property_characteristics"
                                >
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="square_feet"
                                                label="Square Feet"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["square_feet", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("square_feet")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["square_feet", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("square_feet")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="year_built"
                                                label="Year Built"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["year_built", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("year_built")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["year_built", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("year_built")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="bedrooms"
                                                label="Bedrooms"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["bedrooms", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("bedrooms")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["bedrooms", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("bedrooms")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="bathrooms"
                                                label="Bathrooms"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["bathrooms", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("bathrooms")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["bathrooms", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("bathrooms")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Collapse.Panel>
                                <Divider className="py-0 mt-2"/>
                                <Collapse.Panel
                                    header={<p className="mb-0 fw-bold color--black fs--14px">Valuation and Equity</p>}
                                    key="valuation_and_equity"
                                >
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="estimated_value"
                                                label="Estimated Value"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["estimated_value", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("estimated_value")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["estimated_value", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("estimated_value")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="assessed_value"
                                                label="Assessed Value"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["assessed_value", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("assessed_value")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["assessed_value", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("assessed_value")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="estimated_equity"
                                                label="Estimated Equity"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["estimated_equity", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("estimated_equity")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["estimated_equity", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("estimated_equity")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="adjudged_value"
                                                label="Adjudged Value"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["adjudged_value", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("adjudged_value")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["adjudged_value", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("adjudged_value")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="minimum_bid"
                                                label="Minimum Bid"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["minimum_bid", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("minimum_bid")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["minimum_bid", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("minimum_bid")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6">
                                            <Form.Item
                                                className="mb-0"
                                                name="appraised_value"
                                                label="Appraised Value"
                                            >
                                                <Input.Group compact className="mb-3">
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["appraised_value", "min"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Min"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("appraised_value")}/>
                                                    </Form.Item>
                                                    <div className="mx-1 pt-1">
                                                        <MdKeyboardArrowLeft size="20"/>
                                                    </div>
                                                    <Form.Item
                                                        className="mb-0"
                                                        style={{width: "40%"}}
                                                        name={["appraised_value", "max"]}
                                                        label=""
                                                    >
                                                        <Input
                                                            placeholder="Max"
                                                            disabled={(props?.masterDisabledFields || [])?.includes("appraised_value")}/>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Collapse.Panel>
                                <Divider className="py-0 mt-2"/>
                                <Collapse.Panel
                                    header={<p className="mb-0 fw-bold color--black fs--14px">Date Filed</p>}
                                    key="date_filed"
                                >
                                    <div className="row align-items-center off-market-filters--datepicker-range">
                                        <div className="col-12 position-relative">
                                            <img src="./layout/calendar.svg" style={{
                                                position: "absolute",
                                                top: 17,
                                                bottom: 0,
                                                left: 25,
                                                margin: "auto",
                                                zIndex: 9
                                            }}/>
                                            <img src="./layout/calendar.svg" style={{
                                                position: "absolute",
                                                top: 17,
                                                bottom: 0,
                                                left: 65,
                                                right: 0,
                                                margin: "auto",
                                                zIndex: 9
                                            }}/>
                                            <Form.Item
                                                className="mb-3"
                                                name="date_added"
                                                label={<p className="mb-0 color--black fs--14px">Date Added <Tooltip
                                                    title="Date the data has been uploaded to the portal."><AiOutlineQuestionCircle/></Tooltip>
                                                </p>}
                                            >
                                                <RangePicker style={{width: "100%"}} bordered={false}
                                                             disabled={(props?.masterDisabledFields || [])?.includes("date_added")}/>
                                            </Form.Item>
                                            <span style={{
                                                position: "absolute",
                                                top: "6px",
                                                right: "3px",
                                                left: 0,
                                                bottom: 0,
                                                margin: "auto",
                                                width: "10px",
                                                height: "10px",
                                                fontWeight: "bold",
                                                color: "black"
                                            }}>to</span>
                                        </div>
                                        {/*<div className="col-12 position-relative">*/}
                                        {/*    <img src="./layout/calendar.svg" style={{*/}
                                        {/*        position: "absolute",*/}
                                        {/*        top: 17,*/}
                                        {/*        bottom: 0,*/}
                                        {/*        left: 25,*/}
                                        {/*        margin: "auto",*/}
                                        {/*        zIndex: 9*/}
                                        {/*    }}/>*/}
                                        {/*    <img src="./layout/calendar.svg" style={{*/}
                                        {/*        position: "absolute",*/}
                                        {/*        top: 17,*/}
                                        {/*        bottom: 0,*/}
                                        {/*        left: 65,*/}
                                        {/*        right: 0,*/}
                                        {/*        margin: "auto",*/}
                                        {/*        zIndex: 9*/}
                                        {/*    }}/>*/}
                                        {/*    <Form.Item*/}
                                        {/*        className="mb-3"*/}
                                        {/*        name="date_filed"*/}
                                        {/*        label={<p className="mb-0 color--black fs--14px">Date Filed <Tooltip*/}
                                        {/*            title="Date the document was filed in the county recorder's office."><AiOutlineQuestionCircle/></Tooltip>*/}
                                        {/*        </p>}*/}
                                        {/*    >*/}
                                        {/*        <RangePicker style={{width: "100%"}} bordered={false}*/}
                                        {/*                     disabled={(props?.masterDisabledFields || [])?.includes("date_filed")}/>*/}
                                        {/*    </Form.Item>*/}
                                        {/*    <span style={{*/}
                                        {/*        position: "absolute",*/}
                                        {/*        top: "6px",*/}
                                        {/*        right: "3px",*/}
                                        {/*        left: 0,*/}
                                        {/*        bottom: 0,*/}
                                        {/*        margin: "auto",*/}
                                        {/*        width: "10px",*/}
                                        {/*        height: "10px",*/}
                                        {/*        fontWeight: "bold",*/}
                                        {/*        color: "black"*/}
                                        {/*    }}>to</span>*/}
                                        {/*</div>*/}

                                        <div className="col-12 position-relative">
                                            <img src="./layout/calendar.svg" style={{
                                                position: "absolute",
                                                top: 17,
                                                bottom: 0,
                                                left: 25,
                                                margin: "auto",
                                                zIndex: 9
                                            }}/>
                                            <img src="./layout/calendar.svg" style={{
                                                position: "absolute",
                                                top: 17,
                                                bottom: 0,
                                                left: 65,
                                                right: 0,
                                                margin: "auto",
                                                zIndex: 9
                                            }}/>
                                            <Form.Item
                                                className="mb-3"
                                                name="auction_date"
                                                label={<p className="mb-0 color--black fs--14px">Auction Date <Tooltip
                                                    title="Date and time when property sale will take place."><AiOutlineQuestionCircle/></Tooltip>
                                                </p>}
                                            >
                                                <RangePicker style={{width: "100%"}} bordered={false}
                                                             disabled={(props?.masterDisabledFields || [])?.includes("auction_date")}/>
                                            </Form.Item>
                                            <span style={{
                                                position: "absolute",
                                                top: "6px",
                                                right: "3px",
                                                left: 0,
                                                bottom: 0,
                                                margin: "auto",
                                                width: "10px",
                                                height: "10px",
                                                fontWeight: "bold",
                                                color: "black"
                                            }}>to</span>
                                        </div>
                                        <div className="col-12 position-relative">
                                            <img src="./layout/calendar.svg" style={{
                                                position: "absolute",
                                                top: 33,
                                                bottom: 0,
                                                left: 25,
                                                margin: "auto",
                                                zIndex: 9
                                            }}/>
                                            <img src="./layout/calendar.svg" style={{
                                                position: "absolute",
                                                top: 33,
                                                bottom: 0,
                                                left: 65,
                                                right: 0,
                                                margin: "auto",
                                                zIndex: 9
                                            }}/>
                                            <Form.Item
                                                className="mb-0"
                                                name="record_date"
                                                label={<p className="mb-0 color--black fs--14px">Record Date <Tooltip
                                                    title="Date the document was filed in the county recorder's office."><AiOutlineQuestionCircle/></Tooltip>
                                                </p>}
                                            >
                                                <RangePicker style={{width: "100%"}} bordered={false}
                                                             disabled={(props?.masterDisabledFields || [])?.includes("record_date")}/>
                                            </Form.Item>
                                            <span style={{
                                                position: "absolute",
                                                top: "20px",
                                                right: "3px",
                                                left: 0,
                                                bottom: 0,
                                                margin: "auto",
                                                width: "10px",
                                                height: "10px",
                                                fontWeight: "bold",
                                                color: "black"
                                            }}>to</span>
                                        </div>
                                    </div>
                                </Collapse.Panel>
                                <Divider className="py-0 mt-2"/>
                                <Collapse.Panel
                                    className="off-market-filters--property-type"
                                    header={<p className="mb-0 fw-bold color--black fs--14px">Occupancy</p>}
                                    key="occupancy"
                                >
                                    <Form.Item
                                        className="mb-3"
                                        name="owner_occupied"
                                        label="Owner Occupied"
                                    >
                                        <Radio.Group
                                            disabled={(props?.masterDisabledFields || [])?.includes("owner_occupied")}>
                                            <Radio value="yes">Yes</Radio>
                                            <Radio value="no">No</Radio>
                                            <Radio value="n/a">N/A</Radio>
                                            <Radio value="any">Any</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {/*<Form.Item*/}
                                    {/*    className="mb-0"*/}
                                    {/*    name="status_occupied"*/}
                                    {/*    label="Status"*/}
                                    {/*>*/}
                                    {/*    <Radio.Group*/}
                                    {/*        disabled={(props?.masterDisabledFields || [])?.includes("status_occupied")}>*/}
                                    {/*        <Radio value="occupied">Occupied</Radio>*/}
                                    {/*        <Radio value="vacant">Vacant</Radio>*/}
                                    {/*        <Radio value="n/a">N/A</Radio>*/}
                                    {/*        <Radio value="any">Any</Radio>*/}
                                    {/*    </Radio.Group>*/}
                                    {/*</Form.Item>*/}
                                </Collapse.Panel>
                                <Divider className="py-0 mt-2 mb-5"/>
                            </Collapse>
                        </Spin>
                        <div className="off-market-filters--submit">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <Space className="cursorPointer" onClick={e => setIsOpenFilters(false)}>
                                        <BiLeftArrowAlt className="color--primary" size={20}/>
                                        <p className="color--primary fs--16px mt-1">Go Back</p>
                                    </Space>
                                </div>
                                <div className="col-6 text-end">
                                    <Button type="primary"
                                            htmlType="button"
                                            disabled={props?.loading}
                                            style={{borderRadius: "4px"}}
                                            className="height--42px p-x--20px"
                                            onClick={e => props?.form.submit()}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
                <div className="row filter-component align-items-center">
                    <div className="col-12 col-lg-3 mt-0">
                        <Form.Item label="State" name="property_state">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="State"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterPropertyState && props.masterPropertyState.length > 0 ? props.masterPropertyState.map((item, k) => {
                                        return <Option key={k} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-2 mt-0">
                        <Form.Item label="City" name="property_city">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder={((props.form.getFieldValue("property_state") || []).length > 0) ? "City" : "First select the state"}
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterPropertyCity && props.masterPropertyCity.length > 0 ? props.masterPropertyCity.map((item, k) => {
                                        return <Option key={item} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-2 mt-0">
                        <Form.Item label="County" name="county">
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder={((props.form.getFieldValue("property_state") || []).length > 0) ? "County" : "First select the state"}
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterCounty && props.masterCounty.length > 0 ? props.masterCounty.map((item, k) => {
                                        return <Option key={item} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-5 mt-0" style={{paddingTop: "27px"}}>
                        <Space>
                            {
                                !props?.isDisabledFiltersButton ? (
                                    <Button
                                        style={{borderRadius: "4px", borderColor: "#00A1C9"}}
                                        type="default"
                                        htmlType="button"
                                        className="height--41px p-x--20px color--primary"
                                        onClick={e => {
                                            setIsOpenFilters(true)
                                        }}>
                                        <Space>
                                            <img src="./layout/icon-filters.svg" width={25} height={25}/>
                                            <p className="fs--16px mb-0 color--primary pt-1 ms-1 fw-bold">Filters</p>
                                        </Space>
                                    </Button>
                                ) : null
                            }
                            {/*<p className="fs--14px cursorPointer color--black text-decoration-underline mx-3">Clear*/}
                            {/*    All</p>*/}
                            {/*<Select*/}
                            {/*    className="off-market-filters--select-action"*/}
                            {/*    placeholder="Actions"*/}
                            {/*    style={{*/}
                            {/*        width: 120,*/}
                            {/*    }}*/}
                            {/*    onChange={e => {*/}
                            {/*    }}*/}
                            {/*    options={[*/}
                            {/*        {*/}
                            {/*            value: '0',*/}
                            {/*            label: 'Export CSV',*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            value: '1',*/}
                            {/*            label: 'Export XLSX',*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            value: 'disabled',*/}
                            {/*            disabled: true,*/}
                            {/*            label: 'Disabled',*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            value: '2',*/}
                            {/*            label: 'Edit Columns',*/}
                            {/*        },*/}
                            {/*    ]}*/}
                            {/*/>*/}
                            {/*<Button type="primary"*/}
                            {/*        htmlType="button"*/}
                            {/*        disabled={props?.loading}*/}
                            {/*        style={{borderRadius: "4px"}}*/}
                            {/*        className="height--42px p-x--20px border-0"*/}
                            {/*        onClick={e => props?.form.submit()}>*/}
                            {/*    Search*/}
                            {/*</Button>*/}
                        </Space>
                    </div>
                    {/*<div className="col-12 col-lg-3 mt-0 max__width--400px">*/}
                    {/*    <Form.Item className="button-color--transparent" label=" ">*/}
                    {/*        <Button type="primary" htmlType="submit" className="w-100 fs--14px fw-bold mt-1">*/}
                    {/*            Search*/}
                    {/*        </Button>*/}
                    {/*    </Form.Item>*/}
                    {/*</div>*/}
                </div>
                <ButtonFilterOffMarket
                    items={props?.items}
                    onExport={() => props.onExport()}
                    onExportCSV={() => props.onExportCSV()}
                    isExport={props.isExport}
                    isFetching={props.isFetching}/>
            </Form>
        </div>
    )
}

export default OffMarketCustomFilters
