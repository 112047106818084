import * as Types from '../types/Template';

export const templateGeneralAction = (filters) => ({type: Types.TEMPLATE_GENERAL_ACTION, filters});
export const templateGeneralSuccess = (general) => ({
    type: Types.TEMPLATE_GENERAL_SUCCESS,
    general
});


export const templateMarketLeadFiltersAction = (params) => ({type: Types.TEMPLATE_MARKET_LEAD_FILTERS_ACTION, params});
export const templateMarketLeadFiltersSuccess = (resultFilters) => ({
    type: Types.TEMPLATE_MARKET_LEAD_FILTERS_SUCCESS,
    resultFilters
});

export const templateMarketLeadCreateFiltersAction = (params) => ({
    type: Types.TEMPLATE_MARKET_LEAD_CREATE_FILTERS_ACTION,
    params
});
export const templateMarketLeadCreateFiltersSuccess = (createFilter) => ({
    type: Types.TEMPLATE_MARKET_LEAD_CREATE_FILTERS_SUCCESS,
    createFilter
});

export const templateMarketLeadDeleteFiltersAction = (params) => ({
    type: Types.TEMPLATE_MARKET_LEAD_DELETE_FILTERS_ACTION,
    params
});
export const templateMarketLeadDeleteFiltersSuccess = (deleteFilter) => ({
    type: Types.TEMPLATE_MARKET_LEAD_DELETE_FILTERS_SUCCESS,
    deleteFilter
});

export const templateReadNotifyAction = (params) => ({
    type: Types.TEMPLATE_READ_NOTIFY_ACTION,
    params
});
export const templateReadNotifySuccess = (readNotify) => ({
    type: Types.TEMPLATE_READ_NOTIFY_SUCCESS,
    readNotify
});

export const templateInitStore = (params) => ({type: Types.TEMPLATE_INIT, params});
export const templateClearStore = (params) => ({type: Types.TEMPLATE_FAIL, params});
