import React, {useEffect, useState} from "react";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    setParamsUrlFilter
} from "../../../utils/functions";
import MainLayout from "../../common/layout/MainLayout";
import moment from "moment";
import queryString from "query-string";

// ============== ACTION ==============
import {
    ActionSubscriptionListTransaction,
    ActionSubscriptionInitStore
} from "../../../data/hooks/subscription"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectSubscriptionListTransaction,
    selectStatusAction
} from "../../../data/reselects/subscriptionSelector"
import {ListTransactionsResponse, transactionColumn} from "../../../data/mapping/Response/SubscriptionResponse";
import {mappingSort} from "../../../utils/commonUtils";
import {useSelector} from "react-redux";
import ListComponent from "../../common/ListComponent";
import {PAGINATION} from "../../../constants/define";
import {omit} from "lodash";
import {filtersParams} from "../../../data/mapping/Request/ApptOfSubTrusteeRequest";

const ModelFields = Object.getOwnPropertyNames(new ListTransactionsResponse())
const Index = props => {
    const [initData, setInitData] = useState({
            titleItemTable: getTitleTable(transactionColumn, [], ["id"]),
            modelItem: ModelFields,
        }),
        [isFetching, setIsFetching] = useState(),
        [resultItem, setResultItem] = useState([]),
        [listFilters, setListFilters] = useState({}),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        itemDataFetching = useSelector(selectFetching()),
        actionSubscriptionListTransaction = ActionSubscriptionListTransaction(),
        actionSubscriptionInitStore = ActionSubscriptionInitStore(),
        itemSubscriptionListTransaction = useSelector(selectSubscriptionListTransaction()),
        namePage = "subscription_transactions";

    const componentTable = {
        total_amount: {
            width: 200
        },
        discount: {
            width: 200
        },
        subscription_type: {
            width: 250
        },
        subscribe_date: {
            width: 300
        },
        month: {
            width: 120
        },
    };

    useEffect(() => {
        actionSubscriptionListTransaction();

        return () => {
            actionSubscriptionInitStore({clearTransactions: true})
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemDataFetching);
    }, [itemDataFetching]);

    useEffect(() => {
        setResultItem(itemSubscriptionListTransaction.result)
        setPagination(itemSubscriptionListTransaction.pagination)
    }, [itemSubscriptionListTransaction]);

    const onTableChange = (pagination, filters, sorter) => {
        let paramsFilter = {};
        if (Object.keys(sorter).length > 0) {
            paramsFilter = {
                order_field: sorter?.order ? sorter?.field || "" : "",
                order_value: sorter?.order ? mappingSort(sorter?.order) : ""
            }
        }
        filters = omit({...filters, ...listFilters, ...paramsFilter}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, filtersParams(newFilters)))
        setListFilters(newFilters);
        actionSubscriptionListTransaction(newFilters);
    };

    return (
        <MainLayout title={`My transactions list`} disabledTab
                    breadcrumb={["My Transactions"]}>
            <ListComponent
                scroll={{x: 1300}}
                classParent="m-0 p-0 mt-5"
                className="custom-table"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultItem || [], initData.modelItem)}
                columns={columnsTable({
                    titleTable: initData.titleItemTable,
                    component: componentTable,
                    itemName: namePage
                })}
                pagination={{
                    total: pagination.total,
                    totalPage: pagination.total_page,
                    current: pagination.page_index,
                    pageSize: pagination.page_size,
                    responsive: true
                }}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </MainLayout>
    )
}

export default Index
