import {createSelector} from 'reselect'

const stateReducer = state => state.noticeOfDefaultReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        noticeOfDefaultReducer => noticeOfDefaultReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        noticeOfDefaultReducer => noticeOfDefaultReducer.statusAction
    )

export const selectMasterNoticeOfDefault = (initItems) =>
    createSelector(
        stateReducer,
        noticeOfDefaultReducer => noticeOfDefaultReducer.master
    )

export const selectNoticeOfDefaultList = (initItems) =>
    createSelector(
        stateReducer,
        noticeOfDefaultReducer => noticeOfDefaultReducer.data
    )


export const selectNoticeOfDefaultDetail = (initItems) =>
    createSelector(
        stateReducer,
        noticeOfDefaultReducer => noticeOfDefaultReducer.detail
    )
