import * as Types from '../types/Divorce';
import {PAGINATION} from '../../constants/define';
import {getResultListing} from "../../utils/functions";
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        },
        limit_download: ""
    },
    detail: {},
    master: {}
};


export default (state = initialState, action) => {
    switch (action.type) {
        case Types.DIVORCE_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DIVORCE_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.DIVORCE_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DIVORCE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResultListing(action.divorce),
                statusAction: initialState.statusAction
            };
        case Types.DIVORCE_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DIVORCE_DETAIL_SUCCESS:
            return {...state, isFetching: false, detail: action.divorceDetail, statusAction: initialState.statusAction};
        case Types.DIVORCE_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.DIVORCE_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.DIVORCE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DIVORCE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DIVORCE_FAVORITE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DIVORCE_FAVORITE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DIVORCE_FAVORITE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DIVORCE_FAVORITE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DIVORCE_FAVORITE_EXPORT_ACTION:
            return {...state, isFetching: true, statusAction: {...initialState.statusAction, isExport: true}};
        case Types.DIVORCE_FAVORITE_EXPORT_SUCCESS:
            return {...state, isFetching: false, statusAction: {...initialState.statusAction, isExport: false}};
        case Types.DIVORCE_UNLOCK_ACTION:
            return {
                ...state,
                statusAction: {...initialState.statusAction, isUnlock: true, unlockField: action.params.field}
            };
        case Types.DIVORCE_UNLOCK_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isUnlock: false, unlockField: ""}};
        case Types.DIVORCE_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.DIVORCE_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail
            };
        default:
            return state;
    }
}
