import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class SubscriptionRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.reason = data?.reason || [];
        this.point = data?.point || "";
        this.experience = data?.experience || "";
        this.feedback = data?.feedback || "";
    }

    exportCreateCancelationFeedback() {
        return {
            reason: this.reason,
            point: this.point,
            experience: this.experience,
            feedback: this.feedback,
        }
    }

}

export const FIELD_TYPE = {
    adjudged_value: ["NUMBER"],
    auction_date_time: ["DATE_TIME"],
    date_added: ["DATE"],
    lead_type: ["DISABLED"],
    min_bid: ["NUMBER"],
    property_city: ["REQUIRED"],
    property_state: ["REQUIRED"],
    property_street: ["REQUIRED"],
    property_zip_code: ["REQUIRED"]
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        month: filters?.month || "",
        package_ids: filters?.packages || [],
        order_type: filters?.orderType || ""
    })
}
