import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {withRouter} from 'react-router-dom';
import PreForeClosureComponent from "./pre-foreclosure";
import {loginAction} from "../../data/actions/authAction";
import {ActionCreditHistory, ActionUpdateProfile} from "../../data/hooks/auth"
import {PAGINATION, ROUTE_LIST} from "../../constants/define";
import {Collapse, Divider, Rate} from "antd";
import SlideItem from "../common/components/SlideItem";
import LazyImage from "../common/components/LazyImage";
import MainLayout from "../common/layout/MainLayout";
import {getProfile, ItemCreditHistory, getStatusAction} from "../../data/reselects/authSelector";
import {
    columnsTable,
    formatInt,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    setParamsUrlFilter
} from "../../utils/functions";
import ListComponent from "../common/ListComponent";
import {CreditResponse, customerCreditCols} from "../../data/mapping/User";
import {mappingSort} from "../../utils/commonUtils";
import ButtonListActionOffMarket from "../common/components/ButtonListActionOffMarket";
import {omit} from "lodash";
import {filtersParams} from "../../data/mapping/Request/DivorceRequest";
import {selectTemplateGeneral} from "../../data/reselects/templateSelector";

const ModelFields = Object.getOwnPropertyNames(new CreditResponse())
const Index = (props) => {
    const [initData, setInitData] = useState({
            titleItemTable: getTitleTable(customerCreditCols, [], []),
            modelItem: ModelFields,
        }),
        componentTable = {};


    const namePage = "credit_history",
        [resultItem, setResultItem] = useState([]),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        [listFilters, setListFilters] = useState({}),
        actionCreditHistory = ActionCreditHistory(),
        itemCreditHistory = useSelector(ItemCreditHistory()),
        itemTemplateGeneral = useSelector(selectTemplateGeneral()),
        itemStatusAction = useSelector(getStatusAction()),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        actionCreditHistory()
        // props.history.push(ROUTE_LIST.PreForeClosure)
    }, []);

    useEffect(() => {
        setResultItem(itemCreditHistory?.result || [])
        setPagination(itemCreditHistory?.pagination)
    }, [itemCreditHistory]);


    const onTableChange = (pagination, filters, sorter) => {
        let paramsFilter = {};
        if (Object.keys(sorter).length > 0) {
            paramsFilter = {
                order_field: sorter?.order ? sorter?.field || "" : "",
                order_value: sorter?.order ? mappingSort(sorter?.order) : ""
            }
        }
        // let currentFilters = getCurrentFilters();
        filters = omit({...filters, ...listFilters, ...paramsFilter}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, filtersParams(newFilters)))
        setListFilters(newFilters);
        actionCreditHistory(newFilters);
    };

    return (
        <MainLayout
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px pb-3">
                        <h4 className="pt-4 color--black fs--20px">Home <span className='fw-bold'>| Welcome to Real Estate IQ's Portal, {itemAuthProfile?.first_name} {itemAuthProfile?.last_name}!</span>
                        </h4>
                    </div>
            }
            disabledTab
        >
            <div className="container mt-4 home max-width--1460px ms-0 p-x--40px">
                <div className="row">
                    <div className="col-8">
                        <div className="container ps-0">
                            <div
                                className="row align-items-center bg-white py-4 home__content border__radius--10px box--shadow height--215px overflow-hidden">
                                <div className="col-9 pe-2 ps-4">
                                    <p className="fs--18px mb-4">In this portal, you will find our latest off-market
                                        properties
                                        divided into more than
                                        seven different types of lists to start flipping houses, wholesaling, or buy and
                                        hold.</p>
                                    <p className="fs--18px">Leads are updated daily, meaning you will get the freshest
                                        data,
                                        mined straight from
                                        the courthouses! <span className="fw-bold fs--18px">Are you ready to find some deals?</span>
                                    </p>
                                </div>
                                <div className="col-3">
                                    <img alt="" className="img-fluid" src="/layout/pages/home/home-pocket.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (itemTemplateGeneral?.announcement || []).length > 0 ? (
                            <div
                                className="col-4  bg-white py-4 home__content border__radius--10px box--shadow align-items-center height--215px overflow-hidden">
                                <SlideItem className=""
                                           settings={{
                                               dots: true,
                                               infinite: true,
                                               slidesToShow: 1,
                                               slidesToScroll: 1,
                                           }}>
                                    {
                                        (itemTemplateGeneral?.announcement || []).map((i, k) => {
                                            return (
                                                <div className="container px-0" key={k}>
                                                    <div
                                                        className="row align-items-center ">
                                                        <div className="col-12 ps-4 text-center py-5">
                                                            <p className="fs--18px mb-3">
                                                                {i?.title}
                                                            </p>
                                                            <div className="text-center">
                                                                {/*<button type="button"*/}
                                                                {/*        className="home__content--button mt-3"*/}
                                                                {/*        onClick={() => props.history.push(i?.url)}>Contact*/}
                                                                {/*    Us*/}
                                                                {/*</button>*/}
                                                                <a href={i?.url} target="_blank" className="home__content--button mt-3 text-decoration-none">Detail</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </SlideItem>
                            </div>
                        ) : (
                            <div className="col-4">
                                <div className="container px-0">
                                    <div
                                        className="row bg-white py-4 home__content border__radius--10px box--shadow align-items-center height--215px overflow-hidden">
                                        <div className="col-7 ps-4">
                                            <p className="fs--18px">
                                                If you have any questions you can always contact us, and <span
                                                className="fw-bold fs--18px">we will reach out right
                                        away.</span>
                                            </p>
                                            <div className="text-center">
                                                <button type="button" className="home__content--button mt-3"
                                                        onClick={() => props.history.push(ROUTE_LIST.ContactUs)}>Contact
                                                    Us
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <img alt="" className="img-fluid" src="/layout/pages/home/home-email.svg"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="p-x--30px mt-4">
                <div className="home__slide slide-dot-inside max-width--1400px">
                    <SlideItem className="box--shadow"
                               settings={{
                                   dots: true,
                                   infinite: true,
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                               }}>

                        {
                            ((itemTemplateGeneral?.banners || []).length > 0) ? (
                                (itemTemplateGeneral?.banners || []).map((i, k) => {
                                    return (
                                        <div key={k}>
                                            <a href={i?.url} target="_blank">
                                                <LazyImage
                                                    imageClass="w-100 max-width--1400px object-fit height-300px"
                                                    alt=""
                                                    placeholder={true}
                                                    src={i?.banner}
                                                    // src="https://wallpaperaccess.com/full/267434.jpg"
                                                />
                                            </a>
                                        </div>
                                    )
                                })
                            ) : null
                        }
                        {/*<div>*/}
                        {/*    <a href="https://realestateiq.co/free-ebooks/" target="_blank">*/}
                        {/*        <LazyImage*/}
                        {/*            imageClass="w-100 max-width--1400px object-fit height-280px"*/}
                        {/*            alt=""*/}
                        {/*            placeholder={true}*/}
                        {/*            src="/layout/pages/home/home-banner2.png"*/}
                        {/*            // src="https://wallpaperaccess.com/full/267434.jpg"*/}
                        {/*        />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <a href="https://community.realestateiq.co/blog/" target="_blank">*/}
                        {/*        <LazyImage*/}
                        {/*            imageClass="w-100 max-width--1400px object-fit height-280px"*/}
                        {/*            alt=""*/}
                        {/*            placeholder={true}*/}
                        {/*            src="/layout/pages/home/home-banner3.png"*/}
                        {/*            // src="https://wallpaperaccess.com/full/267434.jpg"*/}
                        {/*        />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </SlideItem>
                </div>
            </div>
        </MainLayout>
    )
    // return <PreForeClosureComponent {...props} key={Date.now()} getUpdateProfile={getUpdateProfile}/>
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        auth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
