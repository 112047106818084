import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class DelinquentTaxRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {

    }

    exportUpdate(params) {
    }
}

export const FIELD_TYPE = {}

export const filtersParams = (filters) => {
    let newRecordDate = (filters?.date_added || []).length > 0 ? [moment(filters?.date_added[0]).format("YYYY-MM-DD"), moment(filters?.date_added[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        date_added_from: newRecordDate[0],
        date_added_to: newRecordDate[1],
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        // is_favorite: +filters?.is_favorite || "",
        // is_export: filters?.is_export || "",
        // is_csv: filters?.is_csv || "",
    })
}
