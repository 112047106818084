import * as Types from '../types/Invoice';

export const invoiceMasterAction = (filters) => ({type: Types.INVOICE_MASTER_ACTION, filters});
export const invoiceMasterSuccess = (master) => ({
    type: Types.INVOICE_MASTER_SUCCESS,
    master
});

export const invoiceListAction = (filters) => ({type: Types.INVOICE_LIST_ACTION, filters});
export const invoiceListSuccess = (list) => ({
    type: Types.INVOICE_LIST_SUCCESS,
    list
});

export const invoiceDetailAction = (params) => ({type: Types.INVOICE_DETAIL_ACTION, params});
export const invoiceDetailSuccess = (item) => ({
    type: Types.INVOICE_DETAIL_SUCCESS,
    item
});

export const invoiceUpdateAction = (params) => ({type: Types.INVOICE_UPDATE_ACTION, params});
export const invoiceUpdateSuccess = (updateData) => ({
    type: Types.INVOICE_UPDATE_SUCCESS,
    updateData
});

export const invoiceCreateAction = (params) => ({type: Types.INVOICE_CREATE_ACTION, params});
export const invoiceCreateSuccess = (createData) => ({
    type: Types.INVOICE_CREATE_SUCCESS,
    createData
});

export const invoiceExportAction = (filters) => ({type: Types.INVOICE_EXPORT_ACTION, filters});
export const invoiceExportSuccess = (exportData) => ({
    type: Types.INVOICE_EXPORT_SUCCESS,
    exportData
});

export const invoiceInitStore = (params) => ({type: Types.INVOICE_INIT, params});
export const invoiceClearStore = (params) => ({type: Types.INVOICE_FAIL, params});
