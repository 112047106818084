import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TemplateResponse from "../mapping/Response/TemplateResponse";

export function templateGeneral() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_GENERAL,
        parser: data => new TemplateResponse(data.data.result).exportGeneral(),
    }).get();
}


export function templateMarketLeadFilters(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_MARKET_LEAD_FILTER,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new TemplateResponse(data.data.result).exportMarketLeadFilters(),
    }).get();
}

export function templateMarketLeadCreateFilters(data, lead) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_MARKET_LEAD_CREATE,
        endpointParams: {id: encodeURIComponent(lead)},
        parser: data => data.data.result,
    }).post(data);
}

export function templateReadNotify(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_READ_NOTIFY,
        endpointParams: {id},
        parser: data => data.data.result,
    }).post({});
}

export function templateMarketLeadDeleteFilters(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_MARKET_LEAD_DELETE,
        endpointParams: {id: encodeURIComponent(data?.id), title: encodeURIComponent(data?.title)},
        parser: data => data.data.result,
    }).delete();
}
