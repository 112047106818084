import {Button, Form, Input, Modal, Select} from "antd";
import {useEffect, useState} from "react";
import {TICKET} from "../../../../data/mapping/Response/TicketResponse";

const ModalCreate = props => {
    const [isModal, setIsModal] = useState(false),
        [isFetching, setIsFetching] = useState(false),
        [statusAction, setStatusAction] = useState({}),
        [form] = Form.useForm();

    useEffect(() => {
        setIsModal(props?.isModal)
    }, [props?.isModal]);

    useEffect(() => {
        setIsFetching(props?.isFetching)
    }, [props?.isFetching]);

    useEffect(() => {
        setStatusAction(props.statusAction)
        if (props.statusAction?.isSuccess) {
            props.setIsModal(props.statusAction?.isSubmit)
            form.resetFields();
        }
    }, [props.statusAction]);

    const onFinish = val => {
        props.actionTicketCreate(val)
    }

    return (
        <Modal
            destroyOnClose
            title="Create ticket"
            visible={isModal}
            closable={false}
            maskClosable={false}
            onOk={() => form.submit()}
            onCancel={() => props.setIsModal(false)}
            confirmLoading={isFetching}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            max: 200,
                            message: "Maximum 200 characters",
                            required: true
                        }]}>
                    <Input/>
                </Form.Item>
                <div id="ticket__modal-create">
                    <Form.Item name="type" label="Type" rules={[{required: true}]}>
                        <Select
                            placeholder="Select type ticket"
                            getPopupContainer={() => document.getElementById('ticket__modal-create')}
                        >
                            {
                                Object.keys(TICKET.TYPE).map((item, k) => {
                                    return <Select.Option key={k} value={item}>{TICKET.TYPE[item].title}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item name="message" label="Message" rules={[{required: true}]}>
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalCreate
