import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/PackagePayment';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/packagePaymentService';
import * as FunctionAction from '../actions/packagePayment';
import {message} from "antd";
import history from "../../utils/history";
import {formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import {filtersParams} from "../mapping/Request/PackagePaymentRequest";

function* actionPackagePaymentCreateOrder() {
    yield takeLatest(Types.PACKAGE_PAYMENT_CREATE_ORDER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.createOrder, filtersParams(action.params));
        // console.log(1, result?.url)
        window.location = result?.url
        // yield put(FunctionAction.packagePaymentCreateOrderSuccess(result));
    }, errorHandle(Types.PACKAGE_PAYMENT_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* packagePaymentSaga() {
    yield all([
        fork(actionPackagePaymentCreateOrder),
    ])
}
