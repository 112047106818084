import React from 'react';
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Select} from "antd";
import {Icon} from "../../../common";
import ButtonFilterOffMarket from "../../../common/components/ButtonFilterOffMarket";
import TAG_DEFINE from "../../../../constants/common";

const {RangePicker} = DatePicker;
const {Option} = Select;

const FilterComponent = (props) => {
    const changeValueField = (res) => {
        if (res["is_favorite"]) {
            props.form.setFieldsValue({
                is_favorite: 1
            })
        }
        if (props.isGetValueChange) {
            props.getValueChange(res)
        }
    }
    return (
        <div className="">
            <Form
                form={props.form}
                onFinish={props.onSearch}
                layout="vertical"
                onValuesChange={(res) => changeValueField(res)}
                // onFieldsChange={(changedFields, allFields) => onChangeField(changedFields, allFields)}
            >
                <div className="row filter-component">
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="Date Filed" name="date_filed"
                                   tooltip={TAG_DEFINE.FORM.FILTERS_OFF_MARKET.date_filed}>
                            <RangePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="Date Added" name="date_added"
                                   tooltip={TAG_DEFINE.FORM.FILTERS_OFF_MARKET.date_added}>
                            <RangePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="Ownership" name="ownership">
                            <Select
                                showSearch
                                placeholder="Ownership"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterOwnership && props.masterOwnership.length > 0 ? props.masterOwnership.map((item, k) => {
                                        return <Option key={k} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="State" name="property_state">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="State"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterPropertyState && props.masterPropertyState.length > 0 ? props.masterPropertyState.map((item, k) => {
                                        return <Option key={k} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="City" name="property_city">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder={((props.form.getFieldValue("property_state") || []).length > 0) ? "City" : "First select the state"}
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterPropertyCity && props.masterPropertyCity.length > 0 ? props.masterPropertyCity.map((item, k) => {
                                        return <Option key={item} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="County" name="county">
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder={((props.form.getFieldValue("property_state") || []).length > 0) ? "County" : "First select the state"}
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.masterCounty && props.masterCounty.length > 0 ? props.masterCounty.map((item, k) => {
                                        return <Option key={item} value={item}>{item}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-lg-3 mt-0 max__width--400px">
                        <Form.Item label="Appraised Value" tooltip={TAG_DEFINE.FORM.FILTERS_OFF_MARKET.assessed_value}>
                            <div className="row">
                                <Input.Group compact className="w-100">
                                    <div className="col-6">
                                        <Form.Item name="appraised_value_from">
                                            <InputNumber
                                                className="w-100"
                                                style={{textAlign: 'center'}}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                placeholder="Minimum"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item name="appraised_value_to">
                                            <InputNumber
                                                className="w-100"
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                placeholder="Maximum"
                                            />
                                        </Form.Item>
                                    </div>
                                </Input.Group>
                            </div>
                        </Form.Item>
                    </div>
                    {/*<div className="col-12 col-lg-3 mt-0 max__width--400px">*/}
                    {/*    <Form.Item className="button-color--transparent" label=" ">*/}
                    {/*        <Button type="primary" htmlType="submit" className="w-100 fs--14px fw-bold mt-1">*/}
                    {/*            Search*/}
                    {/*        </Button>*/}
                    {/*    </Form.Item>*/}
                    {/*</div>*/}
                </div>
                <ButtonFilterOffMarket
                    onExport={() => props.onExport()}
                    onExportCSV={() => props.onExportCSV()}
                    isExport={props.isExport}
                    isFetching={props.isFetching}
                />
            </Form>
        </div>
    )
}

export default FilterComponent
