import React, {useState, useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import {withRouter} from 'react-router-dom';
import {DATE_TIME_FORMAT, PAGINATION} from "../../../constants/define";
import {
    columnsTable, customEditorTable, formatInt,
    genaratePaginateFilterSort, generateRowItemDetail,
    getDataSource,
    getTitleTable, removeElementFromArray,
    setParamsUrlFilter
} from "../../../utils/functions";
import {omit, remove} from "lodash";
import {Button, Col, DatePicker, Form, Modal, Popconfirm, Rate, Row, Select, Table} from "antd";
import queryString from 'query-string';
import moment from 'moment';

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import ListComponent from "../../common/ListComponent";
import FilterComponent from "./component/FilterComponent";
import MainLayout from "../../common/layout/MainLayout";
import {CustomCellTableEditor, CustomRowTableEditor} from "../../common/BodyTableComponent";
import FormEditDetailComponent from "./component/FormEditDetailComponent";
import {offMarketFiltersRequest, offMarketFiltersResponse} from "../../../data/mapping/CommonMapping";
import OffMarketCustomFilters from "../../common/components/OffMarketCustomFilters";

// ============== ACTION ==============
import {loginAction} from "../../../data/actions/authAction";
import {ActionUpdateProfile} from "../../../data/hooks/auth"
import {
    ActionApptOfSubTrusteeList,
    ActionMasterApptOfSubTrustee,
    ActionApptOfSubTrusteeDetail,
    ActionApptOfSubTrusteeInitStore,
    ActionApptOfSubTrusteeDelete,
    ActionApptOfSubTrusteeUpdate,
    ActionApptOfSubTrusteeUnlock,
    ActionAddFavorite,
    ActionDeleteFavorite,
    ActionApptOfSubTrusteeExport
} from "../../../data/hooks/apptOfSubTrustee"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectApptOfSubTrusteeList,
    selectMasterApptOfSubTrustee,
    selectApptOfSubTrusteeDetail,
    selectStatusAction
} from "../../../data/reselects/apptOfSubTrusteeSelector"
import ApptOfSubTrusteeResponse, {
    filtersResponse,
    column,
    detailColumn,
    fieldDetailRemove
} from "../../../data/mapping/Response/ApptOfSubTrusteeResponse";
import {filtersParams, FIELD_TYPE} from "../../../data/mapping/Request/ApptOfSubTrusteeRequest";
import {mappingSort} from "../../../utils/commonUtils";
import {InfoCircleOutlined} from "@ant-design/icons";
import ButtonListActionOffMarket from "../../common/components/ButtonListActionOffMarket";
import TAG_DEFINE from "../../../constants/common";

const ModelFields = Object.getOwnPropertyNames(new ApptOfSubTrusteeResponse())
const Index = (props) => {
    const [initData, setInitData] = useState({
        titleItemTable: getTitleTable(column, ['action'], []),
        modelItem: ModelFields,
    });
    const namePage = "appt_of_sub_trustee",
        [form] = Form.useForm(),
        [isPopupDetail, setIsPopupDetail] = useState(false),
        [lockedField, setLockedField] = useState([]),
        [getKeyPress, setGetKeyPress] = useState(),
        [idDetail, setIdDetail] = useState(""),
        [isFetching, setIsFetching] = useState(),
        [listFilters, setListFilters] = useState({}),
        [resultItem, setResultItem] = useState([]),
        [resultDetail, setResultDetail] = useState({}),
        [masterLeadType, setMasterLeadType] = useState([]),
        [masterOwnership, setMasterOwnership] = useState([]),
        [masterPropertyCity, setMasterPropertyCity] = useState([]),
        [masterPropertyState, setMasterPropertyState] = useState([]),
        [masterCounty, setMasterCounty] = useState([]),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        // ============== ACTION ==============
        getApptOfSubTrusteeInit = ActionApptOfSubTrusteeInitStore(),
        getApptOfSubTrusteeList = ActionApptOfSubTrusteeList(),
        getApptOfSubTrusteeDetail = ActionApptOfSubTrusteeDetail(),
        getMasterApptOfSubTrustee = ActionMasterApptOfSubTrustee(),
        actionApptOfSubTrusteeDelete = ActionApptOfSubTrusteeDelete(),
        actionApptOfSubTrusteeUpdate = ActionApptOfSubTrusteeUpdate(),
        actionApptOfSubTrusteeUnlock = ActionApptOfSubTrusteeUnlock(),
        actionApptOfSubTrusteeExport = ActionApptOfSubTrusteeExport(),
        actionAddFavorite = ActionAddFavorite(),
        actionDeleteFavorite = ActionDeleteFavorite(),
        // ============== SELECT DATA ==============
        itemApptOfSubTrusteeList = useSelector(selectApptOfSubTrusteeList()),
        itemApptOfSubTrusteeDetail = useSelector(selectApptOfSubTrusteeDetail()),
        itemMasterApptOfSubTrustee = useSelector(selectMasterApptOfSubTrustee()),
        itemDataFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction());

    const componentTable = {
        date_filed: {
            sorter: (a, b) => a.age - b.age,
            sortOrder: listFilters?.order_field === "date_filed" ? mappingSort(listFilters?.order_value, 1) : ""
        },
        appraised_value: {
            sorter: (a, b) => a.age - b.age,
            sortOrder: listFilters?.order_field === "appraised_value" ? mappingSort(listFilters?.order_value, 1) : ""
        },
        estimated_equity: {
            sorter: (a, b) => a.age - b.age,
            sortOrder: listFilters?.order_field === "estimated_equity" ? mappingSort(listFilters?.order_value, 1) : ""
        },
        equity: {
            sorter: (a, b) => a.age - b.age,
            sortOrder: listFilters?.order_field === "equity" ? mappingSort(listFilters?.order_value, 1) : ""
        },
        action: {
            width: 140,
            align: 'center',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => <ButtonListActionOffMarket item={record} options={{
                onClick: () => {
                    setIsPopupDetail(true)
                    setIdDetail(record?.id)
                    getApptOfSubTrusteeDetail({id: record?.id})
                }
            }}>Details</ButtonListActionOffMarket>,
        },
        is_favorite: {
            align: 'center',
            key: 'is_favorite',
            render: (text, record) => <Rate count={1} value={formatInt(text)} onChange={(e) => e ? actionAddFavorite({
                id: record?.id,
                paramsFilters: listFilters
            }) : actionDeleteFavorite({id: record?.id, paramsFilters: listFilters})}/>,
        }
    }

    useEffect(() => {
        getApptOfSubTrusteeInit();
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = offMarketFiltersResponse(queryString.parse(props.location.search, {arrayFormat: 'comma'}));
            setListFilters({...listFilters, ...paramsFilters})
            form.setFieldsValue(paramsFilters)
            getApptOfSubTrusteeList(paramsFilters);
        } else {
            let paramsFilters = {
                date_added: [moment().subtract(30, 'days'), moment()]
            }
            setListFilters({...listFilters, ...paramsFilters})
            form.setFieldsValue(paramsFilters)
            props.history.replace(setParamsUrlFilter(props?.location?.pathname, offMarketFiltersRequest(paramsFilters)))
            getApptOfSubTrusteeList(paramsFilters);
            // console.log(333333333, paramsFilters)
        }
        getMasterApptOfSubTrustee();
    }, []);

    useEffect(() => {
        setIsFetching(itemDataFetching);
    }, [itemDataFetching]);

    useEffect(() => {
        let newResult = generateRowItemDetail(itemApptOfSubTrusteeDetail, detailColumn, fieldDetailRemove)
        setResultDetail(newResult);
        setLockedField(itemApptOfSubTrusteeDetail?.lockedKeys);
    }, [itemApptOfSubTrusteeDetail]);

    useEffect(() => {
        setMasterLeadType(itemMasterApptOfSubTrustee.lead_type)
        setMasterOwnership(itemMasterApptOfSubTrustee.ownership)
        // setMasterPropertyCity(itemMasterApptOfSubTrustee.property_city)
        setMasterPropertyState((itemMasterApptOfSubTrustee?.property_state || []).sort((function (a, b) {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
            return 0;
        })))
    }, [itemMasterApptOfSubTrustee]);

    useEffect(() => {
        setResultItem(itemApptOfSubTrusteeList.result)
        setPagination(itemApptOfSubTrusteeList.pagination)
    }, [itemApptOfSubTrusteeList]);

    const onTableChange = (pagination, filters, sorter) => {
        let paramsFilter = {};
        if (Object.keys(sorter).length > 0) {
            paramsFilter = {
                order_field: sorter?.order ? sorter?.field || "" : "",
                order_value: sorter?.order ? mappingSort(sorter?.order) : ""
            }
        }
        // let currentFilters = getCurrentFilters();
        filters = omit({...filters, ...listFilters, ...paramsFilter}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter, true);
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, offMarketFiltersRequest(newFilters)))
        setListFilters(newFilters);
        getApptOfSubTrusteeList(newFilters);
    };

    const onSearch = (e) => {
        let newFilters = {...listFilters, ...e, page_index: 1}
        newFilters = omit(newFilters, ["order_field", "order_value"])
        setListFilters(newFilters)
        props.history.replace(setParamsUrlFilter(props?.location?.pathname, offMarketFiltersRequest(newFilters)))
        getApptOfSubTrusteeList(newFilters);
    }

    const detailOnClose = (e) => {
        setGetKeyPress(e);
        if (e.type === "keydown" && e.keyCode === 27) {
            return
        }
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = filtersResponse(queryString.parse(props.location.search, {arrayFormat: 'comma'}));
            setListFilters({...listFilters, ...paramsFilters})
            form.setFieldsValue(paramsFilters)
            getApptOfSubTrusteeList(paramsFilters);
        } else {
            getApptOfSubTrusteeList();
        }
        setIsPopupDetail(false);
        setIdDetail("");
        getApptOfSubTrusteeInit({clearDetail: true})
    }

    const onEditDetail = (val) => {
        actionApptOfSubTrusteeUpdate({...val, id: idDetail})
    }

    const getValueChange = (res) => {
        if (res["property_state"]) {
            let listCity = [],
                listCounty = []

            if ((form.getFieldValue("property_state") || []).length > 0) {
                (form.getFieldValue("property_state") || []).map(i => {
                    listCity = [...new Set([...itemMasterApptOfSubTrustee.property_city[i], ...listCity])]
                    listCounty = [...new Set([...itemMasterApptOfSubTrustee.county[i], ...listCounty])]
                })
                listCity = listCity.sort((function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }));
                listCounty = listCounty.sort((function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }))

                let oldSelectCity = [...(form.getFieldValue("property_city") || [])],
                    oldSelectCounty = [...(form.getFieldValue("county") || [])]

                if ((oldSelectCity.length > 0) && (listCity.length > 0)) {
                    let newSelectCity = []
                    listCity.map(i => {
                        oldSelectCity.map(j => {
                            if (i === j) {
                                newSelectCity.push(i)
                            }
                        })
                    })
                    form.setFieldsValue({property_city: newSelectCity})
                }
                if ((oldSelectCounty.length > 0) && (listCounty.length > 0)) {
                    let newSelectCounty = []
                    listCounty.map(i => {
                        oldSelectCounty.map(j => {
                            if (i === j) {
                                newSelectCounty.push(i)
                            }
                        })
                    })
                    form.setFieldsValue({county: newSelectCounty})
                }

                setMasterPropertyCity(listCity)
                setMasterCounty(listCounty)
            } else {
                setMasterPropertyCity([])
                form.setFieldsValue({property_city: []})
                setMasterCounty([])
                form.setFieldsValue({county: []})
            }
        }
    }

    const onExport = (props) => {
        let val = form.getFieldsValue();
        let newFilters = {...listFilters, ...val, is_export: true}
        newFilters = omit(newFilters, ["order_field", "order_value"])
        actionApptOfSubTrusteeExport(newFilters);
    }

    const onExportCSV = (props) => {
        let val = form.getFieldsValue();
        let newFilters = {...listFilters, ...val, is_export: true, is_csv: true}
        newFilters = omit(newFilters, ["order_field", "order_value"])
        actionApptOfSubTrusteeExport(newFilters);
    }

    return (
        <MainLayout pathname={props.location.pathname} history={props.history}>
            {/*<HeaderAction title="Pre-foreclosure"/>*/}
            <Modal width="50%" title="Details" destroyOnClose visible={isPopupDetail}
                   maskClosable={false}
                   wrapClassName="pt-0 preForeClosure__popup--detail"
                   footer={<>
                       {
                           props?.auth?.user?.is_edit_data && (
                               <Popconfirm
                                   title="Are you sure to delete this task?"
                                   onConfirm={() => {
                                       let paramsFilters;
                                       if (Object.keys(queryString.parse(props.location.search)).length > 0) {
                                           paramsFilters = filtersResponse(queryString.parse(props.location.search, {arrayFormat: 'comma'}));
                                       }
                                       setIsPopupDetail(false)
                                       actionApptOfSubTrusteeDelete({id: idDetail, paramsFilters})
                                       setIdDetail("")
                                   }}
                                   onCancel={() => {
                                   }}
                                   okText="Yes"
                                   cancelText="No"
                               >
                                   <Button type="primary" danger>DELETE</Button>
                               </Popconfirm>
                           )
                       }
                       <Button type="primary" onClick={detailOnClose}>OK</Button>
                   </>}
                   onCancel={detailOnClose}
            >
                <ListComponent
                    components={{
                        body: {
                            row: (props) => CustomRowTableEditor({props, onEditDetail}),
                            cell: (props) => CustomCellTableEditor({
                                props,
                                FormEditDetailComponent,
                                FIELD_TYPE,
                                isFetching,
                                actionUnlock: (params) => actionApptOfSubTrusteeUnlock(params),
                                idDetail,
                                lockedField,
                                getKeyPress,
                                isUnlock: itemStatusAction.isUnlock,
                                unlockField: itemStatusAction.unlockField,
                            }),
                        },
                    }}
                    columns={customEditorTable({
                        titleTable: ["title", "value"],
                        component: {
                            title: {
                                width: 300
                            },
                            value: {
                                width: 300
                            }
                        },
                        itemName: namePage
                    }, ["value"])}
                    className="custom-table"
                    loading={{spinning: isFetching, size: 'large'}}
                    rowKey="key"
                    dataSource={resultDetail}
                    pagination={false}
                    sticky
                    scroll={{x: "max-content"}}/>
            </Modal>
            <OffMarketCustomFilters
                items={itemApptOfSubTrusteeList}
                loading={isFetching}
                masterDisabledFields={itemMasterApptOfSubTrustee?.filters_off_market_disabled || []}
                form={form}
                isExport={itemStatusAction.isExport}
                onExport={onExport}
                onExportCSV={onExportCSV}
                isGetValueChange
                getValueChange={getValueChange}
                onSearch={onSearch}
                masterOwnership={masterOwnership}
                masterPropertyState={masterPropertyState}
                masterCounty={masterCounty}
                masterPropertyCity={masterPropertyCity}
            />
            {/*<FilterComponent form={form}*/}
            {/*                 isExport={itemStatusAction.isExport}*/}
            {/*                 onExport={onExport}*/}
            {/*                 onExportCSV={onExportCSV}*/}
            {/*                 isGetValueChange*/}
            {/*                 getValueChange={getValueChange}*/}
            {/*                 onSearch={onSearch}*/}
            {/*                 masterOwnership={masterOwnership}*/}
            {/*                 masterPropertyState={masterPropertyState}*/}
            {/*                 masterCounty={masterCounty}*/}
            {/*                 masterPropertyCity={masterPropertyCity}/>*/}
            <ListComponent
                scroll={{x: 1500}}
                classParent="m-0 p-0 col-md-12"
                className="custom-table"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultItem || [], initData.modelItem)}
                columns={columnsTable({
                    titleTable: initData.titleItemTable,
                    component: componentTable,
                    itemName: namePage
                })}
                pagination={{
                    total: pagination.total,
                    totalPage: pagination.total_page,
                    current: pagination.page_index,
                    pageSize: pagination.page_size,
                    responsive: true
                }}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </MainLayout>
    )
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        auth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
