import * as Types from '../types/PackagePayment';

export const packagePaymentCreateOrderAction = (params) => ({type: Types.PACKAGE_PAYMENT_CREATE_ORDER_ACTION, params});
export const packagePaymentCreateOrderSuccess = (createOrder) => ({
    type: Types.PACKAGE_PAYMENT_CREATE_ORDER_SUCCESS,
    createOrder
});

export const packagePaymentInitStore = (params) => ({type: Types.PACKAGE_PAYMENT_INIT, params});
export const packagePaymentClearStore = (params) => ({type: Types.PACKAGE_PAYMENT_FAIL, params});
