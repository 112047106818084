import React from 'react'
import MainLayout from "../../common/layout/MainLayout";
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import {LoadingOutlined} from "@ant-design/icons";
import SlideItem from "../../common/components/SlideItem";
import LazyImage from "../../common/components/LazyImage";
import {Divider} from "antd";

const AnyReactComponent = ({text}) => <div>{text}</div>;
const maps = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3342.892328532684!2d-96.839549784001!3d33.085617175491436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c3b58f8f43cf1%3A0x566f9b06be7d0787!2s7950%20Legacy%20Dr%2C%20Plano%2C%20TX%2075024%2C%20Hoa%20K%E1%BB%B3!5e0!3m2!1svi!2s!4v1644120763871!5m2!1svi!2s'
const Index = props => {

    return (
        <MainLayout
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px pb-3">
                        <h4 className="pt-4 color--black fs--20px">Contact Us <span className='fw-bold'>| Want to get in touch? We're happy to help!</span>
                        </h4>
                    </div>
            }
            disabledTab
        >
            <div className="container mt-3 contact-us max-width--1450px ms-0 p-x--30px">
                <div className="row py-3">
                    <div className="col-6">
                        <div className="contact-us__info--open mb-4">
                            <p className="mb-0 color--main fw-bold fs--28px">Reach out to our Customer Success team:</p>
                            <p className="color--primary fs--28px">Monday through friday, <span className="fw-bold">from 8 AM to 6 PM (CT).</span>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 text-center mb-4">
                                <div className="contact-us__info--item mx-0 py-3 px-1 height--160px">
                                    <i className="icon--phone width-50px mb-2"/>
                                    <p className="contact-us__info--item-description my-0 fw-bold">844-929-3325</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 text-center mb-4">
                                <div className="contact-us__info--item mx-0 py-3 px-1 height--160px">
                                    <i className="icon--send width-65px mb-1"/>
                                    <p className="contact-us__info--item-description my-0 fw-bold">customersuccess @realestateiq.co</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 text-center mb-4">
                                <div className="contact-us__info--item mx-0 py-3 px-1 height--160px">
                                    <i className="icon--location width-40px mb-2"/>
                                    <p className="contact-us__info--item-description my-0 fw-bold">7950 Legacy Dr, Plano, TX 75024, US</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="contact-us__maps overflow-hidden height--308px">
                            {/*<MyMapComponent*/}
                            {/*    isMarkerShown*/}
                            {/*    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQobXUpRZJ0XgGgukeRCOOzXIO_J-Nwt0&callback=initMap"*/}
                            {/*    loadingElement={<div style={{height: `100%`}}/>}*/}
                            {/*    containerElement={<div style={{height: `300px`}}/>}*/}
                            {/*    mapElement={<div style={{height: `100%`}}/>}*/}
                            {/*/>*/}
                            <img src="/layout/map.png" alt="" className="object-fit w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-x--30px mt-4">
                <div className="contact-us__slide slide-dot-inside max-width--1390px bg-white">
                    <SlideItem className=""
                               settings={{
                                   dots: true,
                                   infinite: true,
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                               }}>
                        <div>
                            <LazyImage
                                imageClass="w-100 max-width--1400px object-fit height-280px"
                                alt="asdasd"
                                placeholder={true}
                                src="/layout/pages/contact/contact-us.png"
                                // src="https://wallpaperaccess.com/full/267434.jpg"
                            />
                        </div>
                        <div>
                            <LazyImage
                                imageClass="w-100 max-width--1400px object-fit height-280px"
                                alt="asdasd"
                                placeholder={true}
                                src="/layout/pages/contact/profile.png"
                                // src="https://wallpaperaccess.com/full/267434.jpg"
                            />
                        </div>
                    </SlideItem>
                </div>
            </div>
        </MainLayout>
    )
}

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={18}
        defaultCenter={{lat: 33.085695, lng: -96.837215}}
    >
        {props.isMarkerShown && <Marker icon={{
            url: '/maps-logo.png',
            scaledSize: new window.google.maps.Size(50, 60),
        }} position={{lat: 33.085695, lng: -96.837215}}/>}
    </GoogleMap>
))

const Iframe = (
    {
        source
    }
    ) => {

        if (!source) {
            return <LoadingOutlined className="cursorPointer mb--5px" style={{color: "#52c41a"}}/>;
        }

        const src = source;
        return (
            // basic bootstrap classes. you can change with yours.
            <div className="col-md-12">
                <div className="emdeb-responsive">
                    <iframe src={src} width="100%" height="450" loading="lazy"></iframe>
                </div>
            </div>
        );
    }
;

export default Index
