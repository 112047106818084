import * as Types from '../types/PackagePayment';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    current: {},
    result: {},
    master: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.PACKAGE_PAYMENT_CREATE_ORDER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PACKAGE_PAYMENT_CREATE_ORDER_SUCCESS:
            return {...state, isFetching: false, result: action.createOrder, statusAction: initialState.statusAction};
        case Types.PACKAGE_PAYMENT_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.PACKAGE_PAYMENT_INIT:
            return {
                ...state,
                result: action.params?.clearResult ? state.result : initialState.result,
            };
        default:
            return state;
    }
}
