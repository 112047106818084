export const APPT_OF_SUB_TRUSTEE_MASTER_ACTION = "APPT_OF_SUB_TRUSTEE_MASTER_ACTION";
export const APPT_OF_SUB_TRUSTEE_MASTER_SUCCESS = "APPT_OF_SUB_TRUSTEE_MASTER_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_LIST_ACTION = "APPT_OF_SUB_TRUSTEE_LIST_ACTION";
export const APPT_OF_SUB_TRUSTEE_LIST_SUCCESS = "APPT_OF_SUB_TRUSTEE_LIST_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_DETAIL_ACTION = "APPT_OF_SUB_TRUSTEE_DETAIL_ACTION";
export const APPT_OF_SUB_TRUSTEE_DETAIL_SUCCESS = "APPT_OF_SUB_TRUSTEE_DETAIL_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_UPDATE_ACTION = "APPT_OF_SUB_TRUSTEE_UPDATE_ACTION";
export const APPT_OF_SUB_TRUSTEE_UPDATE_SUCCESS = "APPT_OF_SUB_TRUSTEE_UPDATE_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_DELETE_ACTION = "APPT_OF_SUB_TRUSTEE_DELETE_ACTION";
export const APPT_OF_SUB_TRUSTEE_DELETE_SUCCESS = "APPT_OF_SUB_TRUSTEE_DELETE_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_UNLOCK_ACTION = "APPT_OF_SUB_TRUSTEE_UNLOCK_ACTION";
export const APPT_OF_SUB_TRUSTEE_UNLOCK_SUCCESS = "APPT_OF_SUB_TRUSTEE_UNLOCK_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_FAVORITE_ACTION = "APPT_OF_SUB_TRUSTEE_FAVORITE_ACTION";
export const APPT_OF_SUB_TRUSTEE_FAVORITE_SUCCESS = "APPT_OF_SUB_TRUSTEE_FAVORITE_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_ACTION = "APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_ACTION";
export const APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_SUCCESS = "APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_ACTION = "APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_ACTION";
export const APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_SUCCESS = "APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_SUCCESS";

export const APPT_OF_SUB_TRUSTEE_INIT = "APPT_OF_SUB_TRUSTEE_INIT";
export const APPT_OF_SUB_TRUSTEE_FAIL = "APPT_OF_SUB_TRUSTEE_FAIL";
