import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import PackagePaymentResponse from "../mapping/Response/PackagePaymentResponse";

export function createOrder(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PACKAGE_PAYMENT_CREATE_ORDER_V2,
        // params: removeObjectNull({ city_id }),
        parser: data => new PackagePaymentResponse(data.data.result).exportResultCreateOrder(),
    }).post(data);
}
