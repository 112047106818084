import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import {PreForeClosure} from "../mapping/Response/DataResponse";
import {actionApptOfSubTrusteeExport} from "./apptOfSubTrusteeService";

export function masterDataLeadType() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_MASTER_LEAD_TYPE,
        // params: removeObjectNull({ city_id }),
        parser: data => data.data.result,
    }).get();
}

export function masterDataPreForeClosure() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_MASTER_PRE_FORE_CLOSURE,
        // params: removeObjectNull({ city_id }),
        parser: data => new PreForeClosure().exportMasterPreForeClosure(data.data.result),
    }).get();
}

export function actionDataPreForeClosureList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionDataPreForeClosureExport(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_LIST,
        params: params,
        isDownload: true,
        parser: data => data,
    }).get();
}

export function actionDataPreForeClosureDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_DETAIL,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new PreForeClosure(data.data.result).exportDetail(),
    }).get();
}

export function unlock(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_UNLOCK,
        endpointParams: {id: encodeURIComponent(data?.id), field: encodeURIComponent(data?.field)},
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).put(data);
}

export function deleteData(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_DELETE,
        endpointParams: {id: encodeURIComponent(id)},
    }).delete();
}


export function addFavorite(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_ADD_FAVORITE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).post(data);
}

export function deleteFavorite(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_PRE_FORE_CLOSURE_DELETE_FAVORITE,
        endpointParams: {id: encodeURIComponent(id)},
    }).delete();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new PreForeClosure(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
