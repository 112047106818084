import React, {Fragment} from "react";
import {PAGINATION} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import {Dropdown, Empty, Menu, Pagination, Space, Table, Tag} from "antd";
import Icon from "./Icon";
import {DownOutlined, SmileOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../utils/functions";

const ListComponent = (props) => {

    const onChange = (pagination, filters, sorter) => {
        props.onChange(pagination, filters, sorter);
    };
    const pagination = props.pagination && !(props.pagination.total <= PAGINATION.pageSize) ? props.pagination : false;
    if (pagination) {
        pagination.showTotal = (total, range) => `${range[0]}-${range[1]} ${TAG_DEFINE.PAGINATE.of} ${total} ${TAG_DEFINE.PAGINATE.items}`;
        pagination.showSizeChanger = true;
        pagination.showQuickJumper = true;
        pagination.hideOnSinglePage = false;
        pagination.locale = {
            items_per_page: TAG_DEFINE.PAGINATE.itemsPerPage,
            jump_to: TAG_DEFINE.PAGINATE.jumpTo,
        }
    }

    return (
        <Fragment>
            <div
                className={`row hms-list-component mt-${props.marginTop || 2} ${props.classParent ? props.classParent : ''}`}>
                <div className={`col-md-12 ${props.classChildren ? props.classChildren : ''}`}>
                    <div className={`ibox mb-${props.marginBottom || '4'}`}>
                        <div className="ibox-content" style={{padding: 0}}>
                            {props.componentTableList && (props.componentTableList.left !== undefined || props.componentTableList.right !== undefined) &&
                            <div className={`row mb-3`}>
                                <div className={`col-md-6`}>
                                    {
                                        props.componentTableList.left
                                    }
                                </div>
                                <div className={`col-md-6 text-right`}>
                                    {
                                        props.componentTableList.right
                                    }
                                </div>
                            </div>
                            }
                            <Table {...props}
                                   locale={{
                                       emptyText: <Empty description={TAG_DEFINE.VALIDATION.nullData}/>,
                                       filterConfirm: <Icon type="search"/>,
                                       filterReset: <Tag color="#f5222d">Hủy</Tag>,
                                   }}
                                   onChange={onChange}
                                // pagination={pagination}
                                   pagination={!props.disabledCustomPagination ? false : pagination}
                            />
                            {
                                (!props.disabledCustomPagination && (pagination.total > 1)) && (
                                    <div className="row my-4 custom-pagination">
                                        <div className="col-4">
                                            <span className="fs--14px color--black">Showing</span>: <Dropdown overlay={
                                            <Menu
                                                defaultSelectedKeys={['10']}
                                                selectedKeys={pagination.pageSize+""}
                                            >
                                                <Menu.Item key={"10"} className="" onClick={() => props.onChange({
                                                    pageSize: 10
                                                }, {}, {})}>
                                                    10 {props?.titlePageSize || "Leads"}
                                                </Menu.Item>
                                                <Menu.Item key={"20"} className="" onClick={() => props.onChange({
                                                    pageSize: 20
                                                }, {}, {})}>
                                                    20 {props?.titlePageSize || "Leads"}
                                                </Menu.Item>
                                                <Menu.Item key={"30"} className="" onClick={() => props.onChange({
                                                    pageSize: 30
                                                }, {}, {})}>
                                                    30 {props?.titlePageSize || "Leads"}
                                                </Menu.Item>
                                                <Menu.Item key={"50"} className="" onClick={() => props.onChange({
                                                    pageSize: 50
                                                }, {}, {})}>
                                                    50 {props?.titlePageSize || "Leads"}
                                                </Menu.Item>
                                                <Menu.Item key={"100"} className="" onClick={() => props.onChange({
                                                    pageSize: 100
                                                }, {}, {})}>
                                                    100 {props?.titlePageSize || "Leads"}
                                                </Menu.Item>
                                            </Menu>
                                        }>
                                            <a onClick={e => e.preventDefault()}>
                                                <Space>
                                                    <span
                                                        className="custom-pagination--current-page-size">{pagination.pageSize}</span>{props?.titlePageSize || "Leads"}
                                                    <DownOutlined/>
                                                </Space>
                                            </a>
                                        </Dropdown>
                                        </div>
                                        <div className="col-8">
                                            <Pagination
                                                total={pagination.total}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                pageSize={pagination.pageSize}
                                                current={pagination.current}
                                                totalPage={pagination.totalPage}
                                                onChange={(page, pageSize) => props.onChange({
                                                    current: page,
                                                    pageSize
                                                }, {}, {})}
                                                showQuickJumper
                                                responsive
                                                showSizeChanger={false}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default ListComponent
