import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/authAction'

export const ActionLogin = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loginAction(params)),
        [dispatch]
    )
}

export const ActionUpdateProfile = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.updateProfileAction(params)),
        [dispatch]
    )
}

export const ActionCheckProfile = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.checkUserAction(params)),
        [dispatch]
    )
}

export const ActionPasswordRequest = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.passwordRequestAction(params)),
        [dispatch]
    )
}

export const ActionPasswordReset = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.passwordResetAction(params)),
        [dispatch]
    )
}

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.initAction(params)),
        [dispatch]
    )
}

export const ActionCreditHistory = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.creditHistoryAction(params)),
        [dispatch]
    )
}
