import {createSelector} from 'reselect'

const stateReducer = state => state.templateReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        templateReducer => templateReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        templateReducer => templateReducer.statusAction
    )

export const selectTemplateGeneral = (initItems) =>
    createSelector(
        stateReducer,
        templateReducer => templateReducer.general
    )


export const selectTemplateMarketLeadFilters = (initItems) =>
    createSelector(
        stateReducer,
        templateReducer => templateReducer.marketLeadFilters
    )
