import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {capitalizeFirstLetter, formatInt} from "../../../utils/functions";

export default class PlansPricingResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.state = this.generateState(data?.state || {});
        this.packages = this.generatePackages(data?.packages || {});

        this.monthly_package = this.generateDetailItemPackage(data?.monthly_package || []);
        this.current_subscription = this.generateCurrentSubscription(data?.current_subscription || []);
        this.yearly_package = this.generateDetailItemPackage(data?.yearly_package || []);
        this.quarterly_package = this.generateDetailItemPackage(data?.quarterly_package || []);
        this.monthly_total = data?.monthly_total || "";
        this.quarterly_total = data?.quarterly_total || "";
        this.yearly_total = data?.yearly_total || "";
        this.monthly_total_price = data?.monthly_total_price || "";
        this.quarterly_total_price = data?.quarterly_total_price || "";
        this.yearly_total_price = data?.yearly_total_price || "";
        this.recurring_monthly_total = data?.recurring_monthly_total || "";
        this.recurring_quarterly_total = data?.recurring_quarterly_total || "";
        this.recurring_yearly_total = data?.recurring_yearly_total || "";
        this.discount_value = data?.discount_value || "";
        this.credit = data?.credit || "";
        this.current_credit = data?.current_credit || "";
        this.is_use_credit = formatInt(data?.is_use_credit);
        this.total = data?.total || "";
        this.total_price = data?.total_price || "";
    }

    generateState(items = {}) {
        let newItems = [];
        if (Object?.keys(items).length > 0) {
            Object?.keys(items).map(i => {
                newItems = {
                    ...newItems,
                    [i]: {
                        code: items[i]?.code || [],
                        state: this.generateOptionSelect(items[i]?.state || []),
                        city: this.generateOptionSelect(items[i]?.city || []),
                        metro: this.generateOptionSelect(items[i]?.metro || []),
                        county: this.generateOptionSelect(items[i]?.county || []),
                    }
                }
            })
        }
        return newItems
    }

    generateCurrentSubscription(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => {
                newItems.push({
                    custom_state: i?.custom_state,
                    id: i?.id,
                    key: i?.id,
                    name: i?.name,
                    period_type: i?.period_type,
                    price: i?.price,
                    subscription_type: i?.subscription_type,
                    price_raw: i?.price_raw,
                })
            })
        }
        return newItems
    }

    generateOptionSelect(items = []) {
        let newItems = []
        if (items.length > 0) {
            items.map(i => {
                newItems.push({
                    title: capitalizeFirstLetter(i),
                    value: i
                })
            })
        }
        return newItems
    }

    generatePackages(items = {}) {
        let newItems = [];
        if (Object?.keys(items).length > 0) {
            Object?.keys(items).map(i => {
                newItems = {
                    ...newItems,
                    [i.toUpperCase()]: {
                        id: items[i]?.id || "",
                        key: items[i]?.key || "",
                        type: items[i]?.type || "",
                        period_type: items[i]?.period_type || "",
                        package_type: items[i]?.package_type || "",
                        description: {
                            checked: items[i]?.description?.checked || [],
                            unchecked: items[i]?.description?.unchecked || [],
                        },
                        price: items[i]?.price || "",
                    }
                }
            })
        }
        return newItems
    }

    generateDetailItemPackage(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => {
                newItems.push({
                    id: i?.id || "",
                    name: i?.name || "",
                    price: i?.price || "",
                })
            })
        }
        return newItems
    }

    exportMaster() {
        return {
            state: this.state,
            packages: this.packages,
        }
    }

    exportDetail() {
        return {
            monthly_package: this.monthly_package,
            yearly_package: this.yearly_package,
            quarterly_package: this.quarterly_package,
            monthly_total: this.monthly_total,
            quarterly_total: this.quarterly_total,
            yearly_total: this.yearly_total,
            monthly_total_price: this.monthly_total_price,
            quarterly_total_price: this.quarterly_total_price,
            yearly_total_price: this.yearly_total_price,
            recurring_monthly_total: this.recurring_monthly_total,
            recurring_quarterly_total: this.recurring_quarterly_total,
            recurring_yearly_total: this.recurring_yearly_total,
            discount_value: this.discount_value,
            credit: this.credit,
            current_credit: this.current_credit,
            is_use_credit: this.is_use_credit,
            total: this.total,
            total_price: this.total_price,
            current_subscription: this.current_subscription,
        }
    }

}
