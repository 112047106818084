import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/plansPricingAction'

export const ActionPlansPricingMaster = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingMasterAction(params)),
        [dispatch]
    )
}

export const ActionPlansPricingUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingUpdateAction(params)),
        [dispatch]
    )
}

export const ActionPlansPricingDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingDetailAction(params)),
        [dispatch]
    )
}

export const ActionPlansPricingDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingDeleteAction(params)),
        [dispatch]
    )
}

export const ActionPlansPricingInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingInitStore(params)),
        [dispatch]
    )
}
