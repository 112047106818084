import {createSelector} from 'reselect'

const stateReducer = state => state.apptOfSubTrusteeReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        apptOfSubTrusteeReducer => apptOfSubTrusteeReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        apptOfSubTrusteeReducer => apptOfSubTrusteeReducer.statusAction
    )

export const selectMasterApptOfSubTrustee = (initItems) =>
    createSelector(
        stateReducer,
        apptOfSubTrusteeReducer => apptOfSubTrusteeReducer.master
    )

export const selectApptOfSubTrusteeList = (initItems) =>
    createSelector(
        stateReducer,
        apptOfSubTrusteeReducer => apptOfSubTrusteeReducer.data
    )


export const selectApptOfSubTrusteeDetail = (initItems) =>
    createSelector(
        stateReducer,
        apptOfSubTrusteeReducer => apptOfSubTrusteeReducer.detail
    )
