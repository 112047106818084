import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/Divorce';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/divorceService';
import * as FunctionAction from '../actions/divorceAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import DivorceRequest, {filtersParams} from "../mapping/Request/DivorceRequest";
import moment from "moment";
import {offMarketFiltersRequest} from "../mapping/CommonMapping";

function* actionGetMaster() {
    yield takeLatest(Types.DIVORCE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterDivorce, action.params);
        yield put(FunctionAction.divorceMasterSuccess(result));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* actionGetList() {
    yield takeLatest(Types.DIVORCE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionDivorceList, params);
        yield put(FunctionAction.divorceListSuccess(result));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.DIVORCE_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionDivorceExport, params);
        const fileName = ("Divorce_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.divorceExportFavoriteSuccess("ok"));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.DIVORCE_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionDivorceDetail, action.params.id);
        yield put(FunctionAction.divorceDetailSuccess(result));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.DIVORCE_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.divorceUnlockSuccess(result));
        yield put(FunctionAction.divorceDetailAction({id: action.params.id}));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.DIVORCE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new DivorceRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.divorceDetailAction({id: action.params.id}));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.DIVORCE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.heirshipDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.divorceListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.DIVORCE_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.divorceFavoriteSuccess(result));
        yield put(FunctionAction.divorceListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.DIVORCE_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.divorceDeleteFavoriteSuccess({}));
        yield put(FunctionAction.divorceListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.DIVORCE_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* divorceSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetList),
        fork(actionGetExport),
        fork(actionGetDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
    ])
}
