import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/LisPendens';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/lisPendensService';
import * as FunctionAction from '../actions/lisPendensAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import LisPendensRequest, {filtersParams} from "../mapping/Request/LisPendensRequest";
import moment from "moment";
import {offMarketFiltersRequest} from "../mapping/CommonMapping";


function* actionGetMaster() {
    yield takeLatest(Types.LIS_PENDENS_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.master, action.params);
        yield put(FunctionAction.lisPendensMasterSuccess(result));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* actionGetList() {
    yield takeLatest(Types.LIS_PENDENS_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionList, params);
        yield put(FunctionAction.lisPendensListSuccess(result));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.LIS_PENDENS_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionExport, params);
        const fileName = ("List_Pendens_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.lisPendensExportFavoriteSuccess("ok"));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.LIS_PENDENS_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionDetail, action.params.id);
        yield put(FunctionAction.lisPendensDetailSuccess(result));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.LIS_PENDENS_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.lisPendensUnlockSuccess(result));
        yield put(FunctionAction.lisPendensDetailAction({id: action.params.id}));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.LIS_PENDENS_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new LisPendensRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.lisPendensDetailAction({id: action.params.id}));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.LIS_PENDENS_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.heirshipDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.lisPendensListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.LIS_PENDENS_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.lisPendensFavoriteSuccess(result));
        yield put(FunctionAction.lisPendensListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.LIS_PENDENS_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.lisPendensDeleteFavoriteSuccess({}));
        yield put(FunctionAction.lisPendensListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.LIS_PENDENS_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* lisPendensSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetList),
        fork(actionGetExport),
        fork(actionGetDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
    ])
}
