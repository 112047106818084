import * as Types from '../types/Subscription';

export const subscriptionCurrentAction = (filters) => ({type: Types.SUBSCRIPTION_CURRENT_ACTION, filters});
export const subscriptionCurrentSuccess = (current) => ({
    type: Types.SUBSCRIPTION_CURRENT_SUCCESS,
    current
});

export const subscriptionDetailUpgradeAction = (params) => ({type: Types.SUBSCRIPTION_DETAIL_UPGRADE_ACTION, params});
export const subscriptionDetailUpgradeSuccess = (detailUpgrade) => ({
    type: Types.SUBSCRIPTION_DETAIL_UPGRADE_SUCCESS,
    detailUpgrade
});

export const subscriptionListPackageAction = (params) => ({type: Types.SUBSCRIPTION_LIST_PACKAGE_ACTION, params});
export const subscriptionListPackageSuccess = (listPackages) => ({
    type: Types.SUBSCRIPTION_LIST_PACKAGE_SUCCESS,
    listPackages
});

export const subscriptionGeneralAction = (params) => ({type: Types.SUBSCRIPTION_GENERAL_ACTION, params});
export const subscriptionGeneralSuccess = (general) => ({
    type: Types.SUBSCRIPTION_GENERAL_SUCCESS,
    general
});

export const subscriptionListTransactionAction = (params) => ({
    type: Types.SUBSCRIPTION_LIST_TRANSACTION_ACTION,
    params
});
export const subscriptionListTransactionSuccess = (listTransactions) => ({
    type: Types.SUBSCRIPTION_LIST_TRANSACTION_SUCCESS,
    listTransactions
});

export const subscriptionConfirmPackageAction = (params) => ({type: Types.SUBSCRIPTION_CONFIRM_PACKAGE_ACTION, params});
export const subscriptionConfirmPackageSuccess = (confirmPackage) => ({
    type: Types.SUBSCRIPTION_CONFIRM_PACKAGE_SUCCESS,
    confirmPackage
});

export const subscriptionCancelationFeedbackAction = (params) => ({
    type: Types.SUBSCRIPTION_CANCELATION_FEEDBACK_ACTION,
    params
});
export const subscriptionCancelationFeedbackSuccess = (cancelationFeedback) => ({
    type: Types.SUBSCRIPTION_CANCELATION_FEEDBACK_SUCCESS,
    cancelationFeedback
});

export const subscriptionInitStore = (params) => ({type: Types.SUBSCRIPTION_INIT, params});
export const subscriptionClearStore = (params) => ({type: Types.SUBSCRIPTION_FAIL, params});
