import React from 'react';

const routes = [
    {
        is_title: true,
        is_redirect: false,
        title: "Trang Chủ",
        group: "home",
        path: '',
        exact: false,
        icon: 'dashboard',
        show: false,
        sub_menu: [
            {
                title: "Trang chủ",
                is_redirect: false,
                group: "home",
                path: '/',
                icon: 'icon-handbag',
                exact: false,
                show: false,
            }
        ]
    },
];

export default routes;
