import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import ExclusiveContentResponse from "../mapping/Response/ExclusiveContentResponse";

export function actionExclusiveContentList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.EXCLUSIVE_CONTENT_LIST,
        params: params,
        parser: data => new ExclusiveContentResponse(data.data.result).exportExclusiveContent(),
    }).get();
}
