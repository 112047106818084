import * as Types from '../types/LisPendens';

export const lisPendensMasterAction = (filters) => ({type: Types.LIS_PENDENS_MASTER_ACTION, filters});
export const lisPendensMasterSuccess = (master) => ({
    type: Types.LIS_PENDENS_MASTER_SUCCESS,
    master
});

export const lisPendensListAction = (filters) => ({type: Types.LIS_PENDENS_LIST_ACTION, filters});
export const lisPendensListSuccess = (lisPendens) => ({
    type: Types.LIS_PENDENS_LIST_SUCCESS,
    lisPendens
});

export const lisPendensDetailAction = (params) => ({type: Types.LIS_PENDENS_DETAIL_ACTION, params});
export const lisPendensDetailSuccess = (lisPendensDetail) => ({
    type: Types.LIS_PENDENS_DETAIL_SUCCESS,
    lisPendensDetail
});

export const lisPendensUpdatelAction = (params) => ({type: Types.LIS_PENDENS_UPDATE_ACTION, params});
export const lisPendensUpdatelSuccess = (updateData) => ({
    type: Types.LIS_PENDENS_UPDATE_SUCCESS,
    updateData
});

export const lisPendensDeleteAction = (params) => ({type: Types.LIS_PENDENS_DELETE_ACTION, params});
export const lisPendensDeleteSuccess = (deleteData) => ({
    type: Types.LIS_PENDENS_DELETE_SUCCESS,
    deleteData
});

export const lisPendensUnlockAction = (params) => ({type: Types.LIS_PENDENS_UNLOCK_ACTION, params});
export const lisPendensUnlockSuccess = (resultUnlock) => ({
    type: Types.LIS_PENDENS_UNLOCK_SUCCESS,
    resultUnlock
});

export const lisPendensFavoriteAction = (params) => ({type: Types.LIS_PENDENS_FAVORITE_ACTION, params});
export const lisPendensFavoriteSuccess = (favorite) => ({
    type: Types.LIS_PENDENS_FAVORITE_SUCCESS,
    favorite
});

export const lisPendensDeleteFavoriteAction = (params) => ({type: Types.LIS_PENDENS_FAVORITE_DELETE_ACTION, params});
export const lisPendensDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.LIS_PENDENS_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const lisPendensExportFavoriteAction = (filters) => ({type: Types.LIS_PENDENS_FAVORITE_EXPORT_ACTION, filters});
export const lisPendensExportFavoriteSuccess = (result) => ({
    type: Types.LIS_PENDENS_FAVORITE_EXPORT_SUCCESS,
    result
});

export const lisPendensInitStore = (params) => ({type: Types.LIS_PENDENS_INIT, params});
export const lisPendensClearStore = (params) => ({type: Types.LIS_PENDENS_FAIL, params});
