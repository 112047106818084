export const DELINQUENT_TAX_MASTER_ACTION = "DELINQUENT_TAX_MASTER_ACTION";
export const DELINQUENT_TAX_MASTER_SUCCESS = "DELINQUENT_TAX_MASTER_SUCCESS";

export const DELINQUENT_TAX_LIST_ACTION = "DELINQUENT_TAX_LIST_ACTION";
export const DELINQUENT_TAX_LIST_SUCCESS = "DELINQUENT_TAX_LIST_SUCCESS";

export const DELINQUENT_TAX_DETAIL_ACTION = "DELINQUENT_TAX_DETAIL_ACTION";
export const DELINQUENT_TAX_DETAIL_SUCCESS = "DELINQUENT_TAX_DETAIL_SUCCESS";

export const DELINQUENT_TAX_UPDATE_ACTION = "DELINQUENT_TAX_UPDATE_ACTION";
export const DELINQUENT_TAX_UPDATE_SUCCESS = "DELINQUENT_TAX_UPDATE_SUCCESS";

export const DELINQUENT_TAX_DELETE_ACTION = "DELINQUENT_TAX_DELETE_ACTION";
export const DELINQUENT_TAX_DELETE_SUCCESS = "DELINQUENT_TAX_DELETE_SUCCESS";

export const DELINQUENT_TAX_UNLOCK_ACTION = "DELINQUENT_TAX_UNLOCK_ACTION";
export const DELINQUENT_TAX_UNLOCK_SUCCESS = "DELINQUENT_TAX_UNLOCK_SUCCESS";

export const DELINQUENT_TAX_FAVORITE_ACTION = "DELINQUENT_TAX_FAVORITE_ACTION";
export const DELINQUENT_TAX_FAVORITE_SUCCESS = "DELINQUENT_TAX_FAVORITE_SUCCESS";

export const DELINQUENT_TAX_FAVORITE_DELETE_ACTION = "DELINQUENT_TAX_FAVORITE_DELETE_ACTION";
export const DELINQUENT_TAX_FAVORITE_DELETE_SUCCESS = "DELINQUENT_TAX_FAVORITE_DELETE_SUCCESS";

export const DELINQUENT_TAX_FAVORITE_EXPORT_ACTION = "DELINQUENT_TAX_FAVORITE_EXPORT_ACTION";
export const DELINQUENT_TAX_FAVORITE_EXPORT_SUCCESS = "DELINQUENT_TAX_FAVORITE_EXPORT_SUCCESS";

export const DELINQUENT_TAX_DOWNLOAD_ACTION = "DELINQUENT_TAX_DOWNLOAD_ACTION";
export const DELINQUENT_TAX_DOWNLOAD_SUCCESS = "DELINQUENT_TAX_DOWNLOAD_SUCCESS";

export const DELINQUENT_TAX_INIT = "DELINQUENT_TAX_INIT";
export const DELINQUENT_TAX_FAIL = "DELINQUENT_TAX_FAIL";
