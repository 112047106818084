import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
    Table,
    Tag,
    Space,
    PageHeader,
    DatePicker,
    Select,
    Button,
    Form,
    Input,
    Modal,
    Col,
    Row,
    Spin,
    Alert,
    notification,
    Dropdown,
    Avatar,
    Menu, message
} from 'antd';
import {DownOutlined, UserOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import '../assets/PreForeClosure.css';
import * as LocalStorage from "../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../constants/define";
import {useSelector} from "react-redux";
import {getProfile, getFetchingAuth} from "../data/reselects/authSelector";
import {Icon} from "../layouts/common";

const {RangePicker} = DatePicker;
const {Option} = Select;

const DEFAULT_PAGING = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100', '200'],
    total: 0,
    pageSize: 10,
    current: 1,
    position: ["topRight", "bottomRight"]
};

const PreForeClosureComponent = (props) => {
    const [form] = Form.useForm(),
        [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState([]);
    const [masterLeadType, setMasterLeadType] = useState([]);
    const [masterOwnership, setMasterOwnership] = useState([]);
    const [masterPropertyCity, setMasterPropertyCity] = useState([]);
    const [pagination, setPagination] = useState(DEFAULT_PAGING);
    const [filterStr, setFilterStr] = useState("");
    const [itemPopup, setItemPopup] = useState({});
    const [loading, setLoading] = useState(false),
        [showProfile, setShowProfile] = useState(false),
        itemProfile = useSelector(getProfile()),
        itemFetching = useSelector(getFetchingAuth());

    const menu = () => (
        <Menu>
            <Menu.Item onClick={() => setShowProfile(true)}>
                {(props?.auth?.user?.first_name || "") + " " + (props?.auth?.user?.last_name || "")}
            </Menu.Item>
            <Menu.Item onClick={() => onLogout()}>Logout</Menu.Item>
        </Menu>
    );

    const columnPopups = [
        {
            title: 'Title',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
        }
    ]

    const mappingTitle = {
        "document_id": "Document ID",
        "date_added": "Date Added",
        "record_date": "Record Date",
        "auction_date_time": "Auction Date/time",
        "country": "County",
        "mortgagor_first_name": "Mortgagor First Name",
        "mortgagor_last_name": "Mortgagor Last Name",
        "property_address": "Property Address",
        "property_city": "Property City",
        "property_zip_code": "Property Zip Code",
        "bed": "Bed",
        "bath": "Bath",
        "sq_ft": "Sq Ft",
        "garage": "Garage",
        "pool": "Pool",
        "yr_bt": "Yr Bt",
        "trustee_name": "Trustee Name",
        "mortgagee_bank_name": "Mortgagee Bank Name",
        "estimated_equity": "Estimated Equity",
        "equity": "Equity %",
        "estimated_unpaid_balance": "Estimated Unpaid Balance",
        "original_loan_amount": "Original Loan Amount",
        "historical_interest_rate": "Historical Interest Rate",
        "assessed_value": "Assessed Value",
        "appraised_value": "Appraised Value",
        "current_month": "Current Month",
        "current_year": "Current Year",
        "loan_origination_month": "Loan Origination Month",
        "loan_origination_year": "Loan Origination Year",
        "loan_type": "Loan Type",
        "loan_modification": "Loan Modification",
        "loan_expiration": "Loan Expiration",
        "loan_expiration_month": "Loan Expiration Month",
        "loan_expiration_year": "Loan Expiration Year",
        "instrument_no": "Instrument No",
        "legal_description": "Legal Description",
        "subdivision": "Subdivision",
        "ownership": "Ownership",
        "owner_first_name": "Owner First Name",
        "owner_last_name": "Owner Last Name",
        "owner_address": "Owner Address",
        "owner_city": "Owner City",
        "owner_state": "Owner State",
        "owner_zip_code": "Owner Zip Code",
        "property_id": "Property ID",
        "comment": "Comments",
        "lead_type": "Lead Type"
    }

    const columns = [
        {
            title: 'Property Address',
            dataIndex: 'property_address',
            key: 'property_address'
        },
        {
            title: 'Property City',
            dataIndex: 'property_city',
            key: 'property_city'
        },
        {
            title: 'Yr Bt',
            dataIndex: 'yr_bt',
            key: 'yr_bt',
        },
        {
            title: 'Estimated Equity',
            dataIndex: 'estimated_equity',
            key: 'estimated_equity',
        },
        {
            title: 'Equity %',
            dataIndex: 'equity',
            key: 'equity',
        },
        {
            title: 'Appraised Value',
            dataIndex: 'appraised_value',
            key: 'equity',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => <Button onClick={onViewDetail.bind(this, record)}>View Detail</Button>,
        },
    ];
//
    const onChangeField = (changedFields, allFields) => {
        let filter = "";
        allFields.forEach((field) => {
            if (field && field.name && field.name.length > 0) {
                switch (field.name[0]) {
                    case "auction_date":
                        if (field.value && field.value.length > 1) {
                            filter += "&auction_date_from=" + field.value[0].format("YYYY-MM-DD") + "&auction_date_to=" + field.value[1].format("YYYY-MM-DD");
                        }
                        break;
                    case "date_added":
                        if (field.value && field.value.length > 1) {
                            filter += "&date_added_from=" + field.value[0].format("YYYY-MM-DD") + "&date_added_to=" + field.value[1].format("YYYY-MM-DD");
                        }
                        break;
                    case "record_date":
                        if (field.value && field.value.length > 1) {
                            filter += "&record_date_from=" + field.value[0].format("YYYY-MM-DD") + "&record_date_to=" + field.value[1].format("YYYY-MM-DD");
                        }
                        break;
                    case "lead_type":
                        if (field.value && field.value.length > 0) {
                            filter += "&lead_type=" + field.value;
                        }
                        break;
                    case "ownership":
                        if (field.value && field.value.length > 0) {
                            filter += "&ownership=" + field.value;
                        }
                        break;
                    case "property_city":
                        if (field.value && field.value.length > 0) {
                            filter += "&property_city=" + field.value;
                        }
                        break;
                }
            }
        });
        setFilterStr(filter);
    }

    const openNotificationError = (placement = "topRight") => {
        notification.error({
            message: `Error`,
            description:
                'System Error. Please try again later!',
            placement,
        });
    };

    const onViewDetail = (record) => {
        if (record && record.id) {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pre-foreclosure/${record.id}`, {
                headers: {
                    'Authorization': `Bearer ${LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN)}`
                }
            })
                .then(res => {
                    if (res.data && res.data.status === "SUCCESS") {
                        setItemPopup(Object.entries(res.data.data.result).map(([key, value]) => ({
                            key: mappingTitle[key],
                            value
                        })));
                        setLoading(false);
                        setIsModalVisible(true);
                    } else {
                        setLoading(false);
                        openNotificationError();
                    }
                }).catch(e => {
                console.log(e);
                openNotificationError();
                setLoading(false);
            });
        }
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setItemPopup({});
    }

    const resetData = () => {
        setData([]);
        setPagination(DEFAULT_PAGING);
    };

    const resetMasterData = () => {
        setMasterLeadType([]);
        setMasterOwnership([]);
        setMasterPropertyCity([]);
    }

    const getData = (pageObj = DEFAULT_PAGING) => {
        setLoading(true);
        resetData();
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pre-foreclosure?page_size=${pageObj.pageSize}&page_index=${pageObj.current}${filterStr}`, {
            headers: {
                'Authorization': `Bearer ${LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN)}`
            }
        })
            .then(res => {
                if (res.data && res.data.status === "SUCCESS" && res.data.data.result.length > 0) {
                    setData(res.data.data.result);
                    setPagination({
                        ...pagination,
                        current: res.data.data.page_index,
                        pageSize: res.data.data.page_size,
                        total: res.data.data.total
                    })
                } else if (!res.data || res.data.status !== "SUCCESS") {
                    openNotificationError();
                }
                setLoading(false);
            }).catch(e => {
            console.log(e);
            setLoading(false);
            openNotificationError();
        });
    }

    const getMasterData = () => {
        resetMasterData();
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/master/pre-foreclosure`, {
            headers: {
                'Authorization': `Bearer ${LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN)}`
            }
        })
            .then(res => {
                if (res.data && res.data.status === "SUCCESS" && res.data.data.result) {
                    setMasterLeadType(res.data.data.result.lead_type);
                    setMasterOwnership(res.data.data.result.ownership);
                    setMasterPropertyCity(res.data.data.result.property_city);
                } else if (!res.data || res.data.status !== "SUCCESS") {
                    openNotificationError();
                }
            }).catch(e => {
            console.log(e);
            openNotificationError();
        });
    }

    useEffect(() => {
        // getData();
        getMasterData();
    }, []);

    // useEffect(() => {
    //     getData({pageSize: pagination.pageSize, current: 1});
    // }, [filterStr]);

    const onLogout = () => {
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        if (token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        }
        props.history.go("/login")
    }

    const updateProfile = (val) => {
        if (val.password || val.confirm_password) {
            if (val.password === val.confirm_password) {
                props.getUpdateProfile({...val, id: props?.profile?.id})
            } else {
                message.error("Password does not match")
            }
        } else {
            props.getUpdateProfile({...val, id: props?.profile?.id})
        }
    }

    return (
        <>
            <div className="pre-fore-closure">
                <Modal width="45%" title="Detail" destroyOnClose visible={isModalVisible} onOk={handleOk}
                       onCancel={handleOk}
                       cancelButtonProps={{style: {display: 'none'}}}>
                    <Table columns={columnPopups} dataSource={itemPopup} pagination={false} sticky
                           scroll={{x: "max-content"}}/>
                </Modal>
                <PageHeader
                    className="site-page-header"
                    extra={[]}
                >
                    <div className="filter">
                        <Form
                            form={form}
                            onFinish={() => getData({pageSize: pagination.pageSize, current: 1})}
                            layout="vertical"
                            onFieldsChange={(changedFields, allFields) => onChangeField(changedFields, allFields)}
                        >
                            <Row gutter={16}>
                                <Col className="gutter-row">
                                    <Form.Item label="Auction Date" name="auction_date"
                                               tooltip="Filter By Auction Date">
                                        <RangePicker style={{width: "100%"}}/>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row">
                                    <Form.Item label="Date Added" name="date_added" tooltip="Filter By Date Added">
                                        <RangePicker style={{width: "100%"}}/>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row">
                                    <Form.Item label="Date Recorded" name="record_date"
                                               tooltip="Filter By Date Recorded">
                                        <RangePicker style={{width: "100%"}}/>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row">
                                    <Form.Item rules={[{required: true, message: 'Please select Lead Type'}]}
                                               label="Lead Type" name="lead_type" tooltip="Filter By Lead Type">
                                        <Select
                                            showSearch
                                            style={{width: "150px"}}
                                            placeholder="Lead Type"
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                masterLeadType && masterLeadType.length > 0 ? masterLeadType.map((item) => {
                                                    return <Option value={item}>{item}</Option>
                                                }) : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row">
                                    <Form.Item label="Ownership" name="ownership" tooltip="Filter By Ownership">
                                        <Select
                                            showSearch
                                            style={{width: "200px"}}
                                            placeholder="Ownership"
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                masterOwnership && masterOwnership.length > 0 ? masterOwnership.map((item) => {
                                                    return <Option value={item}>{item}</Option>
                                                }) : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row">
                                    <Form.Item label="Property City" name="property_city"
                                               tooltip="Filter By Property City">
                                        <Select
                                            showSearch
                                            style={{width: "120px"}}
                                            placeholder="Property City"
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                masterPropertyCity && masterPropertyCity.length > 0 ? masterPropertyCity.map((item) => {
                                                    return <Option value={item}>{item}</Option>
                                                }) : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row">
                                    <Form.Item className="" label=" ">
                                        <Button type="primary" htmlType="submit">
                                            Search
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <Spin spinning={loading}>
                        <Table columns={columns} style={{"min-height": "100%"}} dataSource={data}
                               pagination={pagination} onChange={getData} sticky scroll={{x: "max-content"}}/>
                    </Spin>
                </PageHeader>
            </div>
            <section>
                <div style={{
                    width: "100%",
                    backgroundColor: "#f0f0f0",
                    height: "90px",
                    paddingTop: "29px",
                    textAlign: "center"
                }}>
                    <p className="design" style={{color: "#999999"}}>
                        Version 4.1 |
                        <a href="terms-of-use.php" target="_blank" style={{color: "#999999"}}> Terms of Use</a> |
                        <a href="privacy-policy.php" target="_blank" style={{color: "#999999"}}> Privacy Policy</a>
                        <br/>©&nbsp;2021 Deal Finder LLC. All Rights Reserved.
                    </p>
                </div>
            </section>
        </>
    )
};

export default PreForeClosureComponent;
