import MainLayout from "../../common/layout/MainLayout";
import {Avatar, Button, Divider, List, Modal, Popconfirm, Spin, Tag, Tooltip} from "antd";

// ============== LAYOUT ==============

// ============== ACTION ==============
import {
    ActionExclusiveContentList,
    ActionTicketInitStore,
} from "../../../data/hooks/exclusiveContent"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectExclusiveContentList,
    selectStatusAction
} from "../../../data/reselects/exclusiveContentSelector"
import React, {Fragment, useEffect, useState} from "react";
import {PAGINATION} from "../../../constants/define";
import {useSelector} from "react-redux";
import {TICKET} from "../../../data/mapping/Response/TicketResponse";
import {getProfile} from "../../../data/reselects/authSelector";
import {truncate} from "lodash";
import queryString from "query-string";
import {filtersResponse} from "../../../data/mapping/Response/EvictionResponse";

const Index = props => {
    const namePage = "exclusive_content",
        [resultItem, setResultItem] = useState([]),
        [isModal, setIsModal] = useState(false),
        [isPlayMedia, setIsPlayMedia] = useState(false),
        [urlPlay, setUrlPlay] = useState(""),
        [isFetching, setIsFetching] = useState(false),
        [statusAction, setStatusAction] = useState({}),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        // ============== ACTION ==============
        actionExclusiveContentList = ActionExclusiveContentList(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemExclusiveContent = useSelector(selectExclusiveContentList()),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        actionExclusiveContentList();
    }, []);

    useEffect(() => {
        setResultItem(itemExclusiveContent.posts);
    }, [itemExclusiveContent.posts]);

    const onPlayMedia = url => {
        setUrlPlay((url.split("/"))[(url.split("/")).length - 1])
        setIsPlayMedia(true)
    }

    return (
        <MainLayout
            wrapperContent="min-height--750px"
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px mb-3 pb-3">
                        <h4 className="pt-4 color--black fs--20px">
                            Trainings & Webinars
                            <span
                                className='fw-bold'> | Welcome to our Trainings & Webinars platform for Real Estate IQ clients</span>
                        </h4>
                    </div>
            }
            disabledTab
        >
            <Modal width="50%"
                   title={false}
                   destroyOnClose
                   visible={isPlayMedia}
                   wrapClassName="pt-0 exclusive-content--popup"
                   footer={false}
                   onCancel={() => {
                       setUrlPlay("");
                       setIsPlayMedia(false);
                   }}
            >
                {
                    urlPlay && (
                        <iframe width="100%" height="500" src={`https://www.youtube.com/embed/${urlPlay}`}/>
                    )
                }
            </Modal>
            <div className="container max-width--1450px p-x--30px ms-0">
                {
                    ((resultItem || []).length > 0) && (resultItem || []).map((i, k) => {
                        return (
                            <div key={k} className="row mb-4">
                                <div className="col-12">
                                    <p className="exclusive-content--title mb-2">
                                        <span className="color--main exclusive-content--title-tag">{i?.title}</span>
                                    </p>
                                </div>
                                {
                                    ((i?.groups || []).length > 0) ? (i?.groups || []).map((j, p) => {
                                        return (
                                            <Fragment key={p}>
                                                <div className="col-12 mb-3">
                                                    <p className="exclusive-content--title-short color--main mb-0">
                                                        <i className="fa fa-arrow-right me-2 color--foreign"/> {j?.title}
                                                    </p>
                                                </div>
                                                {
                                                    ((j?.items || []).length > 0) && (j?.items || []).map((itemGroup, kGroup) => {
                                                        return (
                                                            <ItemsContent
                                                                onPlayMedia={(e) => onPlayMedia(e)}
                                                                key={kGroup}
                                                                item={itemGroup}/>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }) : ((i?.items || []).length > 0) && (i?.items || []).map((item, kItem) => {
                                        return (
                                            <ItemsContent
                                                onPlayMedia={(e) => onPlayMedia(e)}
                                                key={kItem}
                                                item={item}/>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </MainLayout>
    )
}

export default Index

const ItemsContent = props => {
    const [item, setItem] = useState({});

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    const onPlayMedia = url => {
        props?.onPlayMedia(url)
    }

    return (
        <div className="col-3 mb-4">
            <div className="exclusive-content__item">
                <div className="exclusive-content__item--img cursorPointer"
                     onClick={() => onPlayMedia(item?.video_url)}>
                    <img
                        src={item?.thumbnail}
                        alt={item?.title} className="img-responsive"/>
                </div>
                <div className="exclusive-content__item--content">
                    <p className="exclusive-content__item--title cursorPointer"
                       onClick={() => onPlayMedia(item?.video_url)}>
                        <Tooltip title={item?.title}>
                            {truncate(item?.title, {
                                length: 55,
                                separator: "..."
                            })}
                        </Tooltip>
                    </p>
                    <p className="exclusive-content__item--description">
                        <Tooltip title={item?.description}>{truncate(item?.description, {
                            length: 55,
                            separator: "..."
                        })}
                        </Tooltip>
                    </p>
                </div>
                <div className="exclusive-content__item--footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-2 text-center">
                                <img
                                    src={item?.footer_icon}
                                    alt="" className="width--25px height--25px"/>
                            </div>
                            <div className="col-8">
                                {((item?.footer_description || "").replaceAll("\\n", "\n").split("\n")).map(str => <p
                                    className="fs--14px color--black fw-bold mb-0">{str}</p>)}
                            </div>
                            <div className="col-2  text-center">
                                <img
                                    src={item?.footer_hot}
                                    alt="" className="width--25px height--25px"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
