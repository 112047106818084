export const DIVORCE_MASTER_ACTION = "DIVORCE_MASTER_ACTION";
export const DIVORCE_MASTER_SUCCESS = "DIVORCE_MASTER_SUCCESS";

export const DIVORCE_LIST_ACTION = "DIVORCE_LIST_ACTION";
export const DIVORCE_LIST_SUCCESS = "DIVORCE_LIST_SUCCESS";

export const DIVORCE_DETAIL_ACTION = "DIVORCE_DETAIL_ACTION";
export const DIVORCE_DETAIL_SUCCESS = "DIVORCE_DETAIL_SUCCESS";

export const DIVORCE_UPDATE_ACTION = "DIVORCE_UPDATE_ACTION";
export const DIVORCE_UPDATE_SUCCESS = "DIVORCE_UPDATE_SUCCESS";

export const DIVORCE_DELETE_ACTION = "DIVORCE_DELETE_ACTION";
export const DIVORCE_DELETE_SUCCESS = "DIVORCE_DELETE_SUCCESS";

export const DIVORCE_UNLOCK_ACTION = "DIVORCE_UNLOCK_ACTION";
export const DIVORCE_UNLOCK_SUCCESS = "DIVORCE_UNLOCK_SUCCESS";

export const DIVORCE_FAVORITE_ACTION = "DIVORCE_FAVORITE_ACTION";
export const DIVORCE_FAVORITE_SUCCESS = "DIVORCE_FAVORITE_SUCCESS";

export const DIVORCE_FAVORITE_DELETE_ACTION = "DIVORCE_FAVORITE_DELETE_ACTION";
export const DIVORCE_FAVORITE_DELETE_SUCCESS = "DIVORCE_FAVORITE_DELETE_SUCCESS";

export const DIVORCE_FAVORITE_EXPORT_ACTION = "DIVORCE_FAVORITE_EXPORT_ACTION";
export const DIVORCE_FAVORITE_EXPORT_SUCCESS = "DIVORCE_FAVORITE_EXPORT_SUCCESS";

export const DIVORCE_INIT = "DIVORCE_INIT";
export const DIVORCE_FAIL = "DIVORCE_FAIL";
