import moment from "moment";
import MainLayout from "../../common/layout/MainLayout";
import {Button, Result} from "antd";
import {useEffect, useState} from "react";
import queryString from "query-string";
import {filtersResponse} from "../../../data/mapping/Response/ApptOfSubTrusteeResponse";

const ResultComponent = props => {

    const [message, setMessage] = useState({
        status: "404",
        title: "404",
        subTitle: "Sorry, the page you visited does not exist.",
    });

    useEffect(() => {
        const {match} = props;
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'}),
                type = paramsFilters?.type
            if (type.toLowerCase() === "f") {
                setMessage({
                    status: "error",
                    title: "Payment fail",
                    subTitle: "We are so sorry that there is something wrong with your card. Please check again or contact administrator.",
                })
            }
            type = paramsFilters?.type
            if (type.toLowerCase() === "s") {
                setMessage({
                    status: "success",
                    title: "Payment success",
                    subTitle: "Please check your subscription info for more detail.",
                })
            }
        }
    }, [props.location]);

    return (
        <MainLayout title="" disabledTab
                    breadcrumb={["Payment Result"]}>
            <Result
                status={message.status}
                title={message.title}
                subTitle={message.subTitle}
                extra={[
                    <Button type="default" onClick={() => props.history.push("/")}>Home</Button>,
                    <Button type="primary" onClick={() => props.history.push("/my-subscription")}>My
                        Subscription</Button>,
                ]}
            />
        </MainLayout>
    )
}

export default ResultComponent
