export const HEIRSHIP_MASTER_ACTION = "HEIRSHIP_MASTER_ACTION";
export const HEIRSHIP_MASTER_SUCCESS = "HEIRSHIP_MASTER_SUCCESS";

export const HEIRSHIP_LIST_ACTION = "HEIRSHIP_LIST_ACTION";
export const HEIRSHIP_LIST_SUCCESS = "HEIRSHIP_LIST_SUCCESS";

export const HEIRSHIP_DETAIL_ACTION = "HEIRSHIP_DETAIL_ACTION";
export const HEIRSHIP_DETAIL_SUCCESS = "HEIRSHIP_DETAIL_SUCCESS";

export const HEIRSHIP_UPDATE_ACTION = "HEIRSHIP_UPDATE_ACTION";
export const HEIRSHIP_UPDATE_SUCCESS = "HEIRSHIP_UPDATE_SUCCESS";

export const HEIRSHIP_DELETE_ACTION = "HEIRSHIP_DELETE_ACTION";
export const HEIRSHIP_DELETE_SUCCESS = "HEIRSHIP_DELETE_SUCCESS";

export const HEIRSHIP_UNLOCK_ACTION = "HEIRSHIP_UNLOCK_ACTION";
export const HEIRSHIP_UNLOCK_SUCCESS = "HEIRSHIP_UNLOCK_SUCCESS";

export const HEIRSHIP_FAVORITE_ACTION = "HEIRSHIP_FAVORITE_ACTION";
export const HEIRSHIP_FAVORITE_SUCCESS = "HEIRSHIP_FAVORITE_SUCCESS";

export const HEIRSHIP_FAVORITE_DELETE_ACTION = "HEIRSHIP_FAVORITE_DELETE_ACTION";
export const HEIRSHIP_FAVORITE_DELETE_SUCCESS = "HEIRSHIP_FAVORITE_DELETE_SUCCESS";

export const HEIRSHIP_FAVORITE_EXPORT_ACTION = "HEIRSHIP_FAVORITE_EXPORT_ACTION";
export const HEIRSHIP_FAVORITE_EXPORT_SUCCESS = "HEIRSHIP_FAVORITE_EXPORT_SUCCESS";

export const HEIRSHIP_INIT = "HEIRSHIP_INIT";
export const HEIRSHIP_FAIL = "HEIRSHIP_FAIL";
