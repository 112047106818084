import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/dataAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.dataInitStore()),
        [dispatch]
    )
}

export const ActionMasterDataLeadType = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataMasterLeadTypeAction(params)),
        [dispatch]
    )
}

export const ActionMasterDataPreForeClosure = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataMasterPreForeClosureAction(params)),
        [dispatch]
    )
}

export const ActionPreForeClosureList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureListAction(params)),
        [dispatch]
    )
}

export const ActionPreForeClosureExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionPreForeClosureDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureDetailAction(params)),
        [dispatch]
    )
}

export const ActionPreForeClosureUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureUpdateAction(params)),
        [dispatch]
    )
}

export const ActionPreForeClosureDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureDeleteAction(params)),
        [dispatch]
    )
}

export const ActionPreForeClosureUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureUnlockAction(params)),
        [dispatch]
    )
}

export const ActionDataInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataPreForeClosureDeleteFavoriteAction(params)),
        [dispatch]
    )
}
