import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/divorceAction'

export const ActionMasterDivorce = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceMasterAction(params)),
        [dispatch]
    )
}

export const ActionDivorceList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceListAction(params)),
        [dispatch]
    )
}

export const ActionDivorceExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDivorceDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceDetailAction(params)),
        [dispatch]
    )
}

export const ActionDivorceUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceUpdateAction(params)),
        [dispatch]
    )
}

export const ActionDivorceDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceDeleteAction(params)),
        [dispatch]
    )
}

export const ActionDivorceUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceUnlockAction(params)),
        [dispatch]
    )
}

export const ActionDivorceInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.divorceDeleteFavoriteAction(params)),
        [dispatch]
    )
}
