import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/exclusiveContentAction'

export const ActionExclusiveContentList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.exclusiveContentListAction(params)),
        [dispatch]
    )
}

export const ActionTicketInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.exclusiveContentInitStore(params)),
        [dispatch]
    )
}
