import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TicketResponse from "../mapping/Response/TicketResponse";

export function actionTicketList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TICKET_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionTicketDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TICKET_DETAIL,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new TicketResponse(data.data.result).exportDetail(),
    }).get();
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TICKET_CREATE,
        parser: data => data.data.result
        // endpointParams: {id: encodeURIComponent(data?.id), field: encodeURIComponent(data?.field)},
    }).post(data);
}

export function comment(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TICKET_COMMENT,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).post(data);
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TicketResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
