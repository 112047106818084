import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class LoanModificationRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.appraised_value = data?.appraised_value || "";
        this.assessed_value = data?.assessed_value || "";
        this.bankrupt = data?.bankrupt || "";
        this.bath = data?.bath || "";
        this.bed = data?.bed || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.comments = data?.comments || "";
        this.county = data?.county || "";
        this.current_month = data?.current_month || "";
        this.current_year = data?.current_year || "";
        this.date_added = data?.date_added || "";
        this.date_filed = data?.date_filed || "";
        this.deceased = data?.deceased || "";
        this.email_addresses_1 = data?.email_addresses_1 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.email_addresses_6 = data?.email_addresses_6 || "";
        this.equity = data?.equity || "";
        this.estimated_equity = data?.estimated_equity || "";
        this.estimated_unpaid_balance = data?.estimated_unpaid_balance || "";
        this.file_number = data?.file_number || "";
        this.garage = data?.garage || "";
        this.historical_interest_rate = data?.historical_interest_rate || "";
        this.interest_rate = data?.interest_rate || "";
        this.interest_rate_date_change = data?.interest_rate_date_change || "";
        this.lead_type = data?.lead_type || "";
        this.legal_description = data?.legal_description || "";
        this.loan_modification_instrument_number = data?.loan_modification_instrument_number || "";
        this.loan_origination_month = data?.loan_origination_month || "";
        this.loan_origination_year = data?.loan_origination_year || "";
        this.maturity_date = data?.maturity_date || "";
        this.monthly_escrow_payment_amount = data?.monthly_escrow_payment_amount || "";
        this.monthly_principal_interest_payment = data?.monthly_principal_interest_payment || "";
        this.months_to_pay = data?.months_to_pay || "";
        this.mortgagee_bank_name = data?.mortgagee_bank_name || "";
        this.mortgagor_first_name = data?.mortgagor_first_name || "";
        this.mortgagor_last_name = data?.mortgagor_last_name || "";
        this.new_principal_balance = data?.new_principal_balance || "";
        this.original_loan_amount = data?.original_loan_amount || "";
        this.owner_address = data?.owner_address || "";
        this.owner_city = data?.owner_city || "";
        this.owner_first_name = data?.owner_first_name || "";
        this.owner_last_name = data?.owner_last_name || "";
        this.owner_state = data?.owner_state || "";
        this.owner_zip_code = data?.owner_zip_code || "";
        this.ownership = data?.ownership || "";
        this.payment_begins_on = data?.payment_begins_on || "";
        this.phone_numbers_1 = data?.phone_numbers_1 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.pool = data?.pool || "";
        this.property_address = data?.property_address || "";
        this.property_city = data?.property_city || "";
        this.property_id = data?.property_id || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.relative_address = data?.relative_address || "";
        this.relative_city = data?.relative_city || "";
        this.relative_email_addresses_1 = data?.relative_email_addresses_1 || "";
        this.relative_email_addresses_2 = data?.relative_email_addresses_2 || "";
        this.relative_email_addresses_3 = data?.relative_email_addresses_3 || "";
        this.relative_full_name = data?.relative_full_name || "";
        this.relative_phone_1 = data?.relative_phone_1 || "";
        this.relative_phone_2 = data?.relative_phone_2 || "";
        this.relative_phone_3 = data?.relative_phone_3 || "";
        this.relative_state = data?.relative_state || "";
        this.relative_zip = data?.relative_zip || "";
        this.sq_ft = data?.sq_ft || "";
        this.subdivision = data?.subdivision || "";
        this.total_monthly_payment = data?.total_monthly_payment || "";
        this.yr_bt = data?.yr_bt || "";
    }

    exportUpdate(params) {
        let newParams = params
        if (Object.keys(params || []).includes("date_added")) {
            newParams.date_added = params?.date_added ? moment(params?.date_added).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        if (Object.keys(params || []).includes("date_filed")) {
            newParams.date_filed = params?.date_filed ? moment(params?.date_filed).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        if (Object.keys(params || []).includes("maturity_date")) {
            newParams.maturity_date = params?.maturity_date ? moment(params?.maturity_date).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        if (Object.keys(params || []).includes("interest_rate_date_change")) {
            newParams.interest_rate_date_change = params?.interest_rate_date_change ? moment(params?.interest_rate_date_change).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        if (Object.keys(params || []).includes("payment_begins_on")) {
            newParams.payment_begins_on = params?.payment_begins_on ? moment(params?.payment_begins_on).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        return newParams
    }
}

export const FIELD_TYPE = {
    appraised_value: ["NUMBER"],
    assessed_value: ["NUMBER"],
    bath: ["NUMBER"],
    bed: ["NUMBER"],
    current_month: ["NUMBER"],
    current_year: ["NUMBER"],
    date_added: ["DATE"],
    date_filed: ["DATE"],
    equity: ["NUMBER"],
    estimated_equity: ["NUMBER"],
    estimated_unpaid_balance: ["NUMBER"],
    garage: ["NUMBER"],
    historical_interest_rate: ["NUMBER"],
    interest_rate: ["NUMBER"],
    interest_rate_date_change: ["NUMBER"],
    lead_type: ["DISABLED"],
    loan_origination_month: ["NUMBER"],
    loan_origination_year: ["NUMBER"],
    maturity_date: ["DATE"],
    monthly_escrow_payment_amount: ["NUMBER"],
    monthly_principal_interest_payment: ["NUMBER"],
    months_to_pay: ["NUMBER"],
    new_principal_balance: ["NUMBER"],
    original_loan_amount: ["NUMBER"],
    payment_begins_on: ["DATE"],
    property_address: ["REQUIRED"],
    property_city: ["REQUIRED"],
    property_state: ["REQUIRED"],
    property_zip_code: ["REQUIRED"],
    sq_ft: ["NUMBER"],
    total_monthly_payment: ["NUMBER"],
    yr_bt: ["NUMBER"]
}

export const filtersParams = (filters) => {
    let newDateField = (filters?.date_filed || []).length > 0 ? [moment(filters?.date_filed[0]).format("YYYY-MM-DD"), moment(filters?.date_filed[1]).format("YYYY-MM-DD")] : ["", ""]
    let newDateAdded = (filters?.date_added || []).length > 0 ? [moment(filters?.date_added[0]).format("YYYY-MM-DD"), moment(filters?.date_added[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        date_filed_from: newDateField[0],
        date_filed_to: newDateField[1],
        date_added_from: newDateAdded[0],
        date_added_to: newDateAdded[1],
        appraised_value_from: checkNumber(filters?.appraised_value_from) ? filters?.appraised_value_from : "",
        appraised_value_to: checkNumber(filters?.appraised_value_to) ? filters?.appraised_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        ownership: filters?.ownership || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
        is_export: filters?.is_export || "",
        is_csv: filters?.is_csv || "",
    })
}
