import {createSelector} from 'reselect'

const stateReducer = state => state.taxSaleReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        taxSaleReducer => taxSaleReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        taxSaleReducer => taxSaleReducer.statusAction
    )

export const selectMasterTaxSale = (initItems) =>
    createSelector(
        stateReducer,
        taxSaleReducer => taxSaleReducer.master
    )

export const selectTaxSaleList = (initItems) =>
    createSelector(
        stateReducer,
        taxSaleReducer => taxSaleReducer.data
    )


export const selectTaxSaleDetail = (initItems) =>
    createSelector(
        stateReducer,
        taxSaleReducer => taxSaleReducer.detail
    )
