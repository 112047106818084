import {createSelector} from 'reselect'

const stateReducer = state => state.divorceReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        divorceReducer => divorceReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        divorceReducer => divorceReducer.statusAction
    )

export const selectMasterDivorce = (initItems) =>
    createSelector(
        stateReducer,
        divorceReducer => divorceReducer.master
    )

export const selectDivorceList = (initItems) =>
    createSelector(
        stateReducer,
        divorceReducer => divorceReducer.data
    )


export const selectDivorceDetail = (initItems) =>
    createSelector(
        stateReducer,
        divorceReducer => divorceReducer.detail
    )
