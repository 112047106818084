import MainLayout from "../../common/layout/MainLayout";
import {Avatar, Button, Divider, List, Spin, Tag} from "antd";

// ============== LAYOUT ==============

// ============== ACTION ==============
import {
    ActionTicketComment,
    ActionTicketDetail,
    ActionTicketInitStore,
    ActionTicketList,
    ActionTicketCreate
} from "../../../data/hooks/ticket"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectTicketList,
    selectTicketDetail,
    selectStatusAction
} from "../../../data/reselects/ticketSelector"
import React, {useEffect, useState} from "react";
import {PAGINATION} from "../../../constants/define";
import {useSelector} from "react-redux";
import {TICKET} from "../../../data/mapping/Response/TicketResponse";
import ModalCreate from "./components/ModalCreate";
import {getProfile} from "../../../data/reselects/authSelector";

const Index = props => {
    const namePage = "ticket",
        [resultItem, setResultItem] = useState([]),
        [isModal, setIsModal] = useState(false),
        [isFetching, setIsFetching] = useState(false),
        [statusAction, setStatusAction] = useState({}),
        [pagination, setPagination] = useState({
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }),
        // ============== ACTION ==============
        actionTicketList = ActionTicketList(),
        actionTicketCreate = ActionTicketCreate(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemTicketList = useSelector(selectTicketList()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        actionTicketList({})
    }, []);

    useEffect(() => {
        setStatusAction(itemStatusAction)
    }, [itemStatusAction]);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        setResultItem(itemTicketList.result)
        setPagination(itemTicketList.pagination)
    }, [itemTicketList]);

    return (
        <MainLayout
            wrapperContent="min-height--750px"
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px mb-3 pb-3">
                        <h4 className="pt-4 color--black fs--20px">
                            Contact Us
                            <span className='fw-bold'> | Having any trouble? Let us know!</span>
                        </h4>
                    </div>
            }
            disabledTab
        >
            <Spin spinning={isFetching}>
                <ModalCreate
                    isFetching={isFetching}
                    isModal={isModal}
                    setIsModal={(e) => setIsModal(e)}
                    actionTicketCreate={(e) => actionTicketCreate(e)}
                    statusAction={statusAction}
                />
                <div className="container max-width--1450px p-x--30px ms-0">
                    <div className="text-center pe-0 max-width--750px m-auto mt-5">
                        <p className="fs--38px mb-2 color--main fw-bold">Reach out to our Customer Success team</p>
                        <p className="fs--24px color--main">
                            If you have any problems or doubt, <span
                            className="color--main fw-bold">create a ticket</span> and {itemAuthProfile?.account_manager?.name}, <br/>your account
                            manager will
                            asist you as soon as possible.
                        </p>
                        <Button type="primary" htmlType="button" onClick={() => setIsModal(true)} className="mt-1">
                            Create Ticket
                        </Button>
                    </div>
                    <div className="mt-3 px-lg-3 ticket__list mt-4">
                        <List
                            dataSource={resultItem}
                            renderItem={item => (
                                <List.Item key={item.id} className="ticket__list--item"
                                           onClick={() => props.history.push(`/ticket/${item.id}`)}>
                                    <List.Item.Meta
                                        avatar={TICKET.TYPE[(item?.type).toUpperCase()] ?
                                            <>
                                                <i className={`fas ${(TICKET.TYPE[(item?.type).toUpperCase()])?.icon}`}/>
                                                {
                                                    item?.is_unread && <div className="ticket__list--unread"/>
                                                }
                                            </> :
                                            <>
                                                <i className="fas fa-ticket-alt"/>
                                                {
                                                    item?.is_unread && <div className="ticket__list--unread"/>
                                                }
                                            </>}
                                        title={
                                            <span className="fs--18px">
                                        {item?.title}
                                                <Tag color={TICKET.TYPE_COLOR[(item?.type).toUpperCase()]}
                                                     className="ms-2 fs--18px">{item?.type}</Tag>
                                    </span>
                                        }
                                        description={
                                            <span>Created {item?.created}
                                                <i className="fas fa-comment ms-3 me-1 fs--14px"/>{item?.comments}</span>}
                                    />
                                    <div className="">
                                <span className="me-3 fs--14px"
                                      style={{color: TICKET.STATUS_COLOR[item.status]}}>{item.status_explain}</span> -
                                        <span className="color--description ms-3 fs--14px">#{item?.code}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </Spin>
            <div className="clearfix ticket__footer--wrapper">
                <div className="float-end">
                    <ul className="ticket__footer--info">
                        <li className="ticket__footer--item"><img src="/layout/icon/ticket/phone.svg" className="me-2"/>844-929-
                            3325
                        </li>
                        <li className="ticket__footer--item mx-3 ticket__footer--item-border"><img
                            src="/layout/icon/ticket/email.svg" className="me-2"/>customersuccess@realestateiq.co
                        </li>
                        <li className="ticket__footer--item"><img src="/layout/icon/ticket/location.svg"
                                                                  className="me-2"/>7950 Legacy Dr, Plano, TX 75024, US
                        </li>
                    </ul>
                </div>
                <div className="float-end">
                    Contact Us | <span className="fw-bold">Monday to Friday, from 8 AM to 6 PM (CT)</span>
                </div>
            </div>
        </MainLayout>
    )
}

export default Index
