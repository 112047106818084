import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/heirshipAction'
import {ActionPreForeClosureDelete} from "./data";

export const ActionMasterHeirship = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipMasterAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipListAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipDetailAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipUpdatelAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipDeleteAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipUnlockAction(params)),
        [dispatch]
    )
}

export const ActionHeirshipInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.heirshipDeleteFavoriteAction(params)),
        [dispatch]
    )
}
