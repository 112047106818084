import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/PlansPricing';
import * as FunctionService from '../services/plansPricingService';
import * as FunctionAction from '../actions/plansPricingAction';
import {
    templateGeneralAction
} from '../actions/templateAction';
import PlansPricingRequest from "../mapping/Request/PlansPricingRequest";
import TAG_DEFINE from "../../constants/common";
import {message} from 'antd';

function* actionGetMaster() {
    yield takeLatest(Types.PLANS_PRICING_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionPlansPricingMaster, action?.filters);
        yield put(FunctionAction.plansPricingMasterSuccess(result));
    }, errorHandle(Types.PLANS_PRICING_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.PLANS_PRICING_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionPlansPricingDetail, action.params);
        yield put(FunctionAction.plansPricingDetailSuccess(result));
    }, errorHandle(Types.PLANS_PRICING_FAIL)))
}

function* actionUpdate() {
    yield takeLatest(Types.PLANS_PRICING_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const paramSubmit = new PlansPricingRequest(action.params).exportCreate(),
            result = yield call(FunctionService.update, paramSubmit);
        message.success(result);
        yield put(FunctionAction.plansPricingUpdateSuccess(result));
        yield put(templateGeneralAction());
    }, errorHandle(Types.PLANS_PRICING_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.PLANS_PRICING_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        yield put(FunctionAction.plansPricingDetailAction());
        yield put(templateGeneralAction());
    }, errorHandle(Types.PLANS_PRICING_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* plansPricingSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionUpdate),
        fork(actionGetDetail),
        fork(deleteData),
    ])
}
