import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";
import {object} from "prop-types";

export default class SubscriptionResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.subscription = this.getItemSubscription(data?.subscription);
        this.current_unlock = data?.current_unlock || "";
        this.current_subscription = data?.current_subscription || "";
        this.total = {
            price: data?.total?.price || "",
            hint: data?.total?.hint || "",
        }
        this.wording_confirm_package = {
            month: data?.month || "",
            S: {
                name: data?.S?.name || "",
                sale_price: data?.S?.sale_price || "",
                price: data?.S?.price || "",
                message: data?.S?.message || "",
            },
            P: {
                name: data?.P?.name || "",
                sale_price: data?.P?.sale_price || "",
                price: data?.P?.price || "",
                message: data?.P?.message || "",
            }
        }
    }

    getItemSubscription(subscription = {}) {
        let newSubscription = {};
        if (Object.keys(subscription).length > 0) {
            Object.keys(subscription).map(i => {
                let items = [];
                if (subscription[i].length > 0) {
                    subscription[i].map(j => {
                        items.push({
                            package_name: j?.package_name || "",
                            subscription_type: j?.subscription_type || "",
                            subscription_type_explain: j?.subscription_type_explain || "",
                        })
                    })
                }
                newSubscription = {
                    ...newSubscription,
                    [i]: items
                }
            })
        }
        return newSubscription
    }

    exportCurrent() {
        return {
            subscription: this.subscription,
            current_unlock: this.current_unlock,
            current_subscription: this.current_subscription,
            total: this.total,
        }
    }

    exportWordingConfirmPackage() {
        return this.wording_confirm_package;
    }
}

export const filtersResponse = (filters) => {
    let newDateField = (filters?.date_filed_from && filters?.date_filed_to) ? [moment(filters?.date_filed_from), moment(filters?.date_filed_to)] : []
    let newDateAdded = (filters?.date_added_from && filters?.date_added_to) ? [moment(filters?.date_added_from), moment(filters?.date_added_to)] : []
    return removeObjectNullFull({
        date_filed: newDateField,
        date_added: newDateAdded,
        assessed_value_from: checkNumber(filters?.assessed_value_from) ? filters?.assessed_value_from : "",
        assessed_value_to: checkNumber(filters?.assessed_value_to) ? filters?.assessed_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class DetailUpgradeResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.month = data?.month;
        this.packages = this.generatePackage(data?.subscription || {});
        this.customPackages = this.generatePackage(data?.subscription || {}, true);
    }

    generatePackage(packages = {}, isCustom) {
        let newPackages = {};
        if (Object.keys(packages || {}).length > 0) {
            Object.keys(packages).map(i => {
                newPackages = {
                    ...newPackages,
                    [i]: this.generateLeadType(packages[i], isCustom, i)
                }
            })
        }
        return newPackages
    }

    generateLeadType(leadTypes = {}, isCustom, parentKey = "") {
        let newLeadTypes = isCustom ? [] : {};
        if (!isCustom) {
            if (Object.keys(leadTypes || {}).length > 0) {
                Object.keys(leadTypes).map(i => {
                    newLeadTypes = {
                        ...newLeadTypes,
                        [i]: this.generateItems(leadTypes[i])
                    }
                })
            }
        } else {
            if (Object.keys(leadTypes || {}).length > 0) {
                Object.keys(leadTypes).map((i, k) => {
                    let countDisabled = 0,
                        childrenItem = this.generateItems(leadTypes[i], isCustom, i);
                    childrenItem.map(i => {
                        if (i.disableCheckbox) {
                            countDisabled = countDisabled + 1;
                        }
                    })
                    newLeadTypes.push({
                        title: <span className="fw-bold">{i}</span>,
                        parent: 1,
                        // disabled: childrenItem.length === countDisabled,
                        disableCheckbox: childrenItem.length === countDisabled,
                        key: `${(parentKey || "").replaceAll(" ", "")}-${(i || "").replaceAll(" ", "")}-${k}`,
                        children: childrenItem,
                        [i]: this.generateItems(leadTypes[i])
                    })
                })
            }
        }
        return newLeadTypes
    }

    generateItems(items = {}, isCustom, parentKey = "") {
        let newItems = isCustom ? [] : {};
        if (!isCustom) {
            if (Object.keys(items).length > 0) {
                Object.keys(items).map(i => {
                    let itemState = [];
                    if (items[i] && (items[i] || []).length > 0) {
                        items[i].map(i => itemState.push({
                            subscription_id: i?.subscription_id || "",
                            package_id: i?.package_id || "",
                            county: i?.county || "",
                            is_state_only: i?.is_state_only || 0,
                            is_upgrade: i?.is_upgrade || false,
                        }))
                    }
                    newItems = {
                        ...newItems,
                        [i]: itemState
                    }
                })
            }
        } else {
            if (Object.keys(items).length > 0) {
                Object.keys(items).map((i, k) => {
                    let itemState = [],
                        countDisabled = 0;
                    if (items[i] && (items[i] || []).length > 0) {
                        items[i].map((j, p) => {
                            if (!(j?.is_upgrade)) {
                                countDisabled = countDisabled + 1
                            }
                            itemState.push({
                                title: j?.is_state_only ? "ALL" : j?.county,
                                // disabled: !(j?.is_upgrade),
                                disableCheckbox: !(j?.is_upgrade),
                                parent: 3,
                                key: `${(parentKey || "").replaceAll(" ", "")}-${(i || "").replaceAll(" ", "")}-${j?.is_state_only ? "ALL" : j?.county}-${p}`,
                                items: {
                                    subscription_id: j?.subscription_id || "",
                                    package_id: j?.package_id || "",
                                    county: j?.county || "",
                                    is_state_only: j?.is_state_only || 0,
                                    is_upgrade: j?.is_upgrade || false,
                                }
                            })
                        })
                    }
                    newItems.push({
                        title: i,
                        // disabled: (items[i] || []).length === countDisabled,
                        disableCheckbox: (items[i] || []).length === countDisabled,
                        parent: 2,
                        key: `${(parentKey || "").replaceAll(" ", "")}-${(i || "").replaceAll(" ", "")}-${k}`,
                        children: itemState,
                        items: items[i]
                    })
                })
            }
        }
        return newItems
    }

    exportDetail() {
        return {
            packages: this.packages,
            customPackages: this.customPackages,
            month: this.month
        }
    }
}

export class ListPackagesResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.month = data?.month;
        this.list_packages = this.generateLeadType(data?.packages || {});
        this.custome_list_packages = this.generateLeadType(data?.packages || {}, true, {
            offMarket: false,
            states: false,
            counties: false
        });
        this.off_markets = this.generateLeadType(data?.packages || {}, true, {
            offMarket: false,
            states: true,
            counties: true
        });
        this.states = this.generateLeadType(data?.packages || {}, true, {
            offMarket: false,
            states: false,
            counties: true
        });
        this.counties = this.generateLeadType(data?.packages || {}, true, {
            offMarket: false,
            states: false,
            counties: false
        });
    }

    generateLeadType(leadTypes = {}, isCustom, disabledChildren) {
        let newLeadTypes = isCustom ? [] : {};
        if (!isCustom) {
            if (Object.keys(leadTypes || {}).length > 0) {
                Object.keys(leadTypes).map(i => {
                    newLeadTypes = {
                        ...newLeadTypes,
                        [i]: this.generateItems(leadTypes[i])
                    }
                })
            }
        } else {
            if (Object.keys(leadTypes || {}).length > 0) {
                Object.keys(leadTypes).map((i, k) => {
                    newLeadTypes.push({
                        title: <span className="fw-bold">{i}</span>,
                        parent: 0,
                        key: `${(i || "").replaceAll(" ", "")}__${k}`,
                        children: !disabledChildren?.states ? this.generateItems(leadTypes[i], isCustom, `${(i || "").replaceAll(" ", "")}__${k}`, disabledChildren) : [],
                        [i]: this.generateItems(leadTypes[i])
                    })
                })
            }
        }
        return newLeadTypes
    }

    generateItems(items = {}, isCustom, parentKey = "", disabledChildren) {
        let newItems = isCustom ? [] : {};
        if (!isCustom) {
            if (Object.keys(items).length > 0) {
                Object.keys(items).map(i => {
                    let itemState = [];
                    if (items[i] && (items[i] || []).length > 0) {
                        items[i].map(i => itemState.push({
                            subscription_id: i?.subscription_id || "",
                            package_id: i?.package_id || "",
                            county: i?.county || "",
                            is_state_only: i?.is_state_only || 0,
                            is_subscribed: i?.is_subscribed || false,
                        }))
                    }
                    newItems = {
                        ...newItems,
                        [i]: itemState
                    }
                })
            }
        } else {
            if (Object.keys(items).length > 0) {
                Object.keys(items).map((i, k) => {
                    let itemState = [];
                    if (items[i] && (items[i] || []).length > 0) {
                        items[i].map((j, p) => {
                            itemState.push({
                                title: j?.is_state_only ? "ALL" : j?.county,
                                disabled: j?.is_subscribed,
                                off_market_key: parentKey,
                                state_key: `${(parentKey || "").replaceAll(" ", "")}__${(i || "").replaceAll(" ", "")}__${k}`,
                                parent: 2,
                                key: `${(parentKey || "").replaceAll(" ", "")}__${(i || "").replaceAll(" ", "")}__${j?.is_state_only ? "ALL" : (j?.county || "").replaceAll(" ", "")}__${p}`,
                                items: {
                                    id: j?.id || "",
                                    subscription_id: j?.subscription_id || "",
                                    package_id: j?.package_id || "",
                                    county: j?.county || "",
                                    is_state_only: j?.is_state_only || 0,
                                    is_subscribed: j?.is_subscribed || false,
                                }
                            })
                        })
                    }
                    newItems.push({
                        title: i,
                        parent: 1,
                        off_market_key: parentKey,
                        key: `${(parentKey || "").replaceAll(" ", "")}__${(i || "").replaceAll(" ", "")}__${k}`,
                        children: !disabledChildren?.counties ? itemState : [],
                        items: items[i]
                    })
                })
            }
        }
        return newItems
    }

    exportListPackages() {
        return {
            packages: this.list_packages,
            customPackages: this.custome_list_packages,
            month: this.month,
            off_markets: this.off_markets,
            states: this.states,
            counties: this.counties,
        }
    }
}

export class ListTransactionsResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.code = data?.code || "";
        this.price = data?.price || 0;
        this.discount = data?.discount || 0;
        this.total_amount = data?.total_amount || 0;
        this.month = data?.month || "";
        this.type = data?.type || "";
        this.subscription_type = data?.subscription ? (data?.subscription).join(" , ") : "";
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.subscribe_date = data?.subscribe_date ? moment(data?.subscribe_date).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        this.updated_date = data?.updated_date || "";
        this.packages = this.getPackages(data?.packages || [])
    }

    getPackages(packages = []) {
        let newPackages = []
        if (packages.length > 0) {
            packages.map(i => newPackages.push({
                key: i?.id || "",
                id: i?.id || "",
                lead_type: i?.lead_type || "",
                state: i?.state || "",
                county: i?.county || "",
            }))
        }
        return newPackages
    }

    exportListTransactions() {
        return {
            id: this.id,
            code: this.code,
            price: this.price,
            discount: this.discount,
            total_amount: this.total_amount,
            month: this.month,
            type: this.type,
            subscription_type: this.subscription_type,
            status: this.status,
            status_explain: this.status_explain,
            subscribe_date: this.subscribe_date,
            updated_date: this.updated_date,
            packages: this.packages,
        }
    }
}

export class GeneralResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.subscription = this.generateSubscription(data?.subscription || {});
        this.hint = {
            title: data?.hint?.title || "",
            message: data?.hint?.message || "",
        };
        this.last_payment = data?.last_payment ? moment(data?.last_payment).format(DATE_TIME_FORMAT.SHORT_DATE_US) : ""
        this.next_payment = this.generateNextPayment(data?.next_payment)
    }

    generateNextPayment(dates = []) {
        let newDates = [];
        if (dates.length > 0) {
            dates.map(i => {
                newDates.push(`${moment(i?.next_payment_date).format(DATE_TIME_FORMAT.SHORT_DATE_US)} (${i?.total})`)
            })
        }
        return newDates.join(" - ")
    }

    generateSubscription(subs = {}) {
        let newSubs = {};
        if (Object.keys(subs).length > 0) {
            Object.keys(subs).map(i => newSubs = {...newSubs, [i]: subs[i]})
        }
        return newSubs
    }

    exportGeneral() {
        return {
            subscription: this.subscription,
            hint: this.hint,
            last_payment: this.last_payment,
            next_payment: this.next_payment,
        }
    }
}

export const transactionColumn = ["id", "code", "type", "subscription_type", "status", "status_explain", "total_amount", "subscribe_date"]

export const TYPE_CONFIRM_PACKAGE = {
    DETAIL: "U",
    PACkAGE_LIST: "B"
}

