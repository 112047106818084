import * as Types from '../types/ApptOfSubTrustee';

export const apptOfSubTrusteeMasterAction = (filters) => ({type: Types.APPT_OF_SUB_TRUSTEE_MASTER_ACTION, filters});
export const apptOfSubTrusteeMasterSuccess = (master) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_MASTER_SUCCESS,
    master
});

export const apptOfSubTrusteeListAction = (filters) => ({type: Types.APPT_OF_SUB_TRUSTEE_LIST_ACTION, filters});
export const apptOfSubTrusteeListSuccess = (apptOfSubTrustee) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_LIST_SUCCESS,
    apptOfSubTrustee
});

export const apptOfSubTrusteeDetailAction = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_DETAIL_ACTION, params});
export const apptOfSubTrusteeDetailSuccess = (apptOfSubTrusteeDetail) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_DETAIL_SUCCESS,
    apptOfSubTrusteeDetail
});

export const apptOfSubTrusteeUpdateAction = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_UPDATE_ACTION, params});
export const apptOfSubTrusteeUpdateSuccess = (dataUpdate) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_UPDATE_SUCCESS,
    dataUpdate
});

export const apptOfSubTrusteeDeleteAction = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_DELETE_ACTION, params});
export const apptOfSubTrusteeDeleteSuccess = (dataDelete) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_DELETE_SUCCESS,
    dataDelete
});

export const apptOfSubTrusteeUnlockAction = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_UNLOCK_ACTION, params});
export const apptOfSubTrusteeUnlockSuccess = (resultUnlock) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_UNLOCK_SUCCESS,
    resultUnlock
});

export const apptOfSubTrusteeFavoriteAction = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_FAVORITE_ACTION, params});
export const apptOfSubTrusteeFavoriteSuccess = (favorite) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_FAVORITE_SUCCESS,
    favorite
});

export const apptOfSubTrusteeDeleteFavoriteAction = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_ACTION, params});
export const apptOfSubTrusteeDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const apptOfSubTrusteeExportFavoriteAction = (filters) => ({type: Types.APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_ACTION, filters});
export const apptOfSubTrusteeExportFavoriteSuccess = (resultExport) => ({
    type: Types.APPT_OF_SUB_TRUSTEE_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const apptOfSubTrusteeInitStore = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_INIT, params});
export const apptOfSubTrusteeClearStore = (params) => ({type: Types.APPT_OF_SUB_TRUSTEE_FAIL, params});
