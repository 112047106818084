import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/LoanModification';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/loanModificationService';
import * as FunctionAction from '../actions/loanModificationAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {downloadFile, formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import LoanModificationRequest, {filtersParams} from "../mapping/Request/LoanModificationRequest";
import moment from "moment";
import {offMarketFiltersRequest} from "../mapping/CommonMapping";


function* actionGetMaster() {
    yield takeLatest(Types.LOAN_MODIFICATION_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterLoanModification, action.params);
        yield put(FunctionAction.loanModificationMasterSuccess(result));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* actionGetList() {
    yield takeLatest(Types.LOAN_MODIFICATION_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionLoanModificationList, params);
        yield put(FunctionAction.loanModificationListSuccess(result));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* actionGetExport() {
    yield takeLatest(Types.LOAN_MODIFICATION_FAVORITE_EXPORT_ACTION, Func.sagaWrapper(function* (action) {
        const params = offMarketFiltersRequest(action.filters || {});
        const result = yield call(FunctionService.actionLoanModificationExport, params);
        const fileName = ("Loan_Modification_" + moment().format('DDMMYYYY') + (params?.is_csv ? ".csv" : ".xlsx"));
        downloadFile(result, fileName, {type: params?.is_csv ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        yield put(FunctionAction.loanModificationExportFavoriteSuccess("ok"));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* actionGetDetail() {
    yield takeLatest(Types.LOAN_MODIFICATION_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionLoanModificationDetail, action.params.id);
        yield put(FunctionAction.loanModificationDetailSuccess(result));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* actionUnlock() {
    yield takeLatest(Types.LOAN_MODIFICATION_UNLOCK_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.unlock, action.params);
        yield put(FunctionAction.loanModificationUnlockSuccess(result));
        yield put(FunctionAction.loanModificationDetailAction({id: action.params.id}));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.LOAN_MODIFICATION_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new LoanModificationRequest().exportUpdate(action.params);
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.loanModificationDetailAction({id: action.params.id}));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* deleteData() {
    yield takeLatest(Types.LOAN_MODIFICATION_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        // yield put(FunctionAction.heirshipDeleteSuccess(action.params.filters || {}));
        yield put(FunctionAction.loanModificationListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* actionAddFavorite() {
    yield takeLatest(Types.LOAN_MODIFICATION_FAVORITE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.addFavorite, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.loanModificationFavoriteSuccess(result));
        yield put(FunctionAction.loanModificationListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function* deleteFavorite() {
    yield takeLatest(Types.LOAN_MODIFICATION_FAVORITE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFavorite, action.params.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["206"]);
        yield put(FunctionAction.loanModificationDeleteFavoriteSuccess({}));
        yield put(FunctionAction.loanModificationListAction({...action.params.paramsFilters}));
    }, errorHandle(Types.LOAN_MODIFICATION_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* loanModificationSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetList),
        fork(actionGetExport),
        fork(actionGetDetail),
        fork(updateAction),
        fork(deleteData),
        fork(actionUnlock),
        fork(actionAddFavorite),
        fork(deleteFavorite),
    ])
}
