export const SUBSCRIPTION_CURRENT_ACTION = "SUBSCRIPTION_CURRENT_ACTION";
export const SUBSCRIPTION_CURRENT_SUCCESS = "SUBSCRIPTION_CURRENT_SUCCESS";

export const SUBSCRIPTION_DETAIL_UPGRADE_ACTION = "SUBSCRIPTION_DETAIL_UPGRADE_ACTION";
export const SUBSCRIPTION_DETAIL_UPGRADE_SUCCESS = "SUBSCRIPTION_DETAIL_UPGRADE_SUCCESS";

export const SUBSCRIPTION_LIST_PACKAGE_ACTION = "SUBSCRIPTION_LIST_PACKAGE_ACTION";
export const SUBSCRIPTION_LIST_PACKAGE_SUCCESS = "SUBSCRIPTION_LIST_PACKAGE_SUCCESS";

export const SUBSCRIPTION_LIST_TRANSACTION_ACTION = "SUBSCRIPTION_LIST_TRANSACTION_ACTION";
export const SUBSCRIPTION_LIST_TRANSACTION_SUCCESS = "SUBSCRIPTION_LIST_TRANSACTION_SUCCESS";

export const SUBSCRIPTION_CONFIRM_PACKAGE_ACTION = "SUBSCRIPTION_CONFIRM_PACKAGE_ACTION";
export const SUBSCRIPTION_CONFIRM_PACKAGE_SUCCESS = "SUBSCRIPTION_CONFIRM_PACKAGE_SUCCESS";

export const SUBSCRIPTION_GENERAL_ACTION = "SUBSCRIPTION_GENERAL_ACTION";
export const SUBSCRIPTION_GENERAL_SUCCESS = "SUBSCRIPTION_GENERAL_SUCCESS";

export const SUBSCRIPTION_CANCELATION_FEEDBACK_ACTION = "SUBSCRIPTION_CANCELATION_FEEDBACK_ACTION";
export const SUBSCRIPTION_CANCELATION_FEEDBACK_SUCCESS = "SUBSCRIPTION_CANCELATION_FEEDBACK_SUCCESS";

export const SUBSCRIPTION_INIT = "SUBSCRIPTION_INIT";
export const SUBSCRIPTION_FAIL = "SUBSCRIPTION_FAIL";
