import * as Types from '../types/Auth';
import {LOGIN_INIT} from "../types/Auth";
import {PAGINATION} from "../../constants/define";

const initState = {
    logged: !!(localStorage.getItem('hn_utk')),
    user: {},
    statusAction: {
        isPasswordRequest: false,
        passwordRequestSuccess: false,
        isPasswordReset: false,
        passwordResetSuccess: false,
        isPasswordResetFail: false,
        isFetchingCredit: false,
    },
    hashForgotPassword: "",
    creditHistory: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
}

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initState, action) => {
    switch (action.type) {
        case Types.UPDATE_PROFILE_ACTION:
            return {...state, registerSuccess: false, isFetching: true, statusAction: initState.statusAction};
        case Types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                registerSuccess: false,
                isFetching: false,
                data: action.updateProfile,
                statusAction: initState.statusAction
            };
        case Types.LOGIN_ACTION:
            return {
                creditHistory: initState.creditHistory,
                logged: false,
                registerSuccess: false,
                logging: true,
                action: "LOGIN",
                statusAction: initState.statusAction
            };
        case Types.REGISTER_ACTION:
            return {logged: false, registerSuccess: false, logging: true, statusAction: initState.statusAction};
        case Types.REGISTER_SUCCESS:
            return {logged: false, registerSuccess: true, logging: false, statusAction: initState.statusAction};
        // return {logged: falseregisterSuccess: false,,logging: true,action: "LOGIN", statusAction: initState.statusAction};
        case Types.LOGOUT_ACTION:
            return {creditHistory: initState.creditHistory, logged: true, statusAction: initState.statusAction};
        case Types.CHECK_USER_ACTION:
            return {
                creditHistory: initState.creditHistory,
                logged: true,
                registerSuccess: false,
                logging: true,
                statusAction: initState.statusAction
            };
        case Types.CHECK_USER_SUCCESS:
            return {
                ...state,
                logged: true,
                user: action.user,
                registerSuccess: false,
                logging: false,
                statusAction: initState.statusAction
            };
        case Types.PASSWORD_REQUEST_ACTION:
            return {
                ...state, logged: false, registerSuccess: false, logging: false, statusAction: {
                    ...initState.statusAction,
                    isPasswordRequest: true
                }
            };
        case Types.PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                logged: false,
                registerSuccess: false,
                logging: false,
                statusAction: {
                    ...initState.statusAction,
                    isPasswordRequest: false,
                    passwordRequestSuccess: true
                },
                hash: action.hash.hash
            };
        case Types.PASSWORD_RESET_ACTION:
            return {
                ...state, logged: false, registerSuccess: false, logging: false, statusAction: {
                    ...initState.statusAction,
                    passwordRequestSuccess: true,
                    isPasswordRequest: true
                }
            };
        case Types.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                logged: false,
                registerSuccess: false,
                logging: false,
                statusAction: {
                    ...initState.statusAction,
                    isPasswordReset: false,
                    passwordRequestSuccess: true,
                    passwordResetSuccess: true,
                },
            };
        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                logged: true,
                registerSuccess: false,
                logging: false,
                action: "SUCCESS",
                statusAction: initState.statusAction
            };
        case Types.CREDIT_HISTORY_ACTION:
            return {
                ...state,
                statusAction: {
                    ...initState.statusAction,
                    isFetchingCredit: true,
                },
                creditHistory: initState.creditHistory
            };
        case Types.CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                statusAction: {
                    ...initState.statusAction,
                },
                creditHistory: getResult(action.creditHistory)
            };
        case Types.LOGIN_FAILED:
            return {
                creditHistory: initState.creditHistory,
                logged: false,
                registerSuccess: false,
                logging: false,
                action: "FAIL",
                statusAction: {
                    ...initState.statusAction,
                    isPasswordResetFail: true
                }
            };
        case Types.LOGOUT_SUCCESS:
            return {creditHistory: initState.creditHistory, logged: false, statusAction: initState.statusAction};
        case Types.LOGIN_INIT:
            return initState;
        default:
            return state;
    }
}
