import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class TaxSaleRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.adjudged_value = data?.adjudged_value || "";
        this.auction_date_time = data?.auction_date_time ? moment(data?.auction_date_time).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        this.bankrupt = data?.bankrupt || "";
        this.case_number = data?.case_number || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.city = data?.city || "";
        this.county = data?.county || "";
        this.date_added = data?.date_added ? moment(data?.date_added).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        this.deceased = data?.deceased || "";
        this.email_addresses_1 = data?.email_addresses_1 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.email_addresses_6 = data?.email_addresses_6 || "";
        this.est_value = data?.est_value || "";
        this.lead_type = data?.lead_type || "";
        this.min_bid = data?.min_bid || "";
        this.mortgagor_owner_last_name = data?.mortgagor_owner_last_name || "";
        this.mortgagor_owner_name = data?.mortgagor_owner_name || "";
        this.owner_address = data?.owner_address || "";
        this.owner_first_name = data?.owner_first_name || "";
        this.owner_last_name = data?.owner_last_name || "";
        this.ownership = data?.ownership || "";
        this.phone_numbers_1 = data?.phone_numbers_1 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.precinct = data?.precinct || "";
        this.property_city = data?.property_city || "";
        this.property_id = data?.property_id || "";
        this.property_state = data?.property_state || "";
        this.property_street = data?.property_street || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.relative_address = data?.relative_address || "";
        this.relative_city = data?.relative_city || "";
        this.relative_email_addresses_1 = data?.relative_email_addresses_1 || "";
        this.relative_email_addresses_2 = data?.relative_email_addresses_2 || "";
        this.relative_email_addresses_3 = data?.relative_email_addresses_3 || "";
        this.relative_full_name = data?.relative_full_name || "";
        this.relative_phone_1 = data?.relative_phone_1 || "";
        this.relative_phone_2 = data?.relative_phone_2 || "";
        this.relative_phone_3 = data?.relative_phone_3 || "";
        this.relative_state = data?.relative_state || "";
        this.relative_zip = data?.relative_zip || "";
        this.state = data?.state || "";
        this.status = data?.status || "";
        this.zip_code = data?.zip_code || "";
    }

    exportUpdate(params) {
        let newParams = params
        if (Object.keys(params || []).includes("date_added")) {
            newParams.date_added = params?.date_added ? moment(params?.date_added).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        if (Object.keys(params || []).includes("auction_date_time")) {
            newParams.auction_date_time = params?.auction_date_time ? moment(params?.auction_date_time).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        }
        return newParams
    }
}

export const FIELD_TYPE = {
    adjudged_value: ["NUMBER"],
    auction_date_time: ["DATE_TIME"],
    date_added: ["DATE"],
    lead_type: ["DISABLED"],
    min_bid: ["NUMBER"],
    property_city: ["REQUIRED"],
    property_state: ["REQUIRED"],
    property_street: ["REQUIRED"],
    property_zip_code: ["REQUIRED"]
}

export const filtersParams = (filters) => {
    let newAuctionDate = (filters?.auction_date || []).length > 0 ? [moment(filters?.auction_date[0]).format("YYYY-MM-DD"), moment(filters?.auction_date[1]).format("YYYY-MM-DD")] : ["", ""]
    let newDateAdded = (filters?.date_added || []).length > 0 ? [moment(filters?.date_added[0]).format("YYYY-MM-DD"), moment(filters?.date_added[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        auction_date_from: newAuctionDate[0],
        auction_date_to: newAuctionDate[1],
        date_added_from: newDateAdded[0],
        date_added_to: newDateAdded[1],
        min_bid_from: checkNumber(filters?.min_bid_from) ? filters?.min_bid_from : "",
        min_bid_to: checkNumber(filters?.min_bid_to) ? filters?.min_bid_to : "",
        adjudged_value_from: checkNumber(filters?.adjudged_value_from) ? filters?.adjudged_value_from : "",
        adjudged_value_to: checkNumber(filters?.adjudged_value_to) ? filters?.adjudged_value_to : "",
        ownership: filters?.ownership || "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
        is_export: filters?.is_export || "",
        is_csv: filters?.is_csv || "",
    })
}
