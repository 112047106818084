import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionResponse, {
    DetailUpgradeResponse,
    ListPackagesResponse,
    ListTransactionsResponse,
    GeneralResponse
} from "../mapping/Response/SubscriptionResponse";

export function currentSubscription() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CURRENT,
        // params: removeObjectNull({ city_id }),
        parser: data => new SubscriptionResponse(data.data.result).exportCurrent(),
    }).get();
}


export function generalSubscription() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_GENERAL,
        // params: removeObjectNull({ city_id }),
        parser: data => new GeneralResponse(data.data.result).exportGeneral(),
    }).get();
}

export function confirmPackage(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_WORDING_CONFIRM_PACKAGE,
        // params: removeObjectNull({ city_id }),
        parser: data => new SubscriptionResponse(data.data.result).exportWordingConfirmPackage(),
    }).post(data);
}

export function actionGetDetailUpgrade(date) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_DETAIL_UPGRADE,
        endpointParams: {date: encodeURIComponent(date)},
        parser: data => new DetailUpgradeResponse(data.data.result).exportDetail(),
    }).get();
}


export function actionGetListPackages(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_LIST_PACKAGES,
        params: params,
        // endpointParams: {month: encodeURIComponent(month)},
        parser: data => new ListPackagesResponse(data.data.result).exportListPackages(),
    }).get();
}

export function actionGetListTransactions(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_LIST_TRANSACTIONS,
        params: params,
        // endpointParams: {month: encodeURIComponent(month)},
        parser: dataParser,
    }).get();
}

export function createCancelationFeedback(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CANCELATION_FEEDBACK,
        // params: removeObjectNull({ city_id }),
        // parser: data => new SubscriptionResponse(data.data.result).exportWordingConfirmPackage(),
    }).post(data);
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new ListTransactionsResponse(item).exportListTransactions()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
