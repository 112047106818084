import * as Types from '../types/ExclusiveContent';

export const exclusiveContentListAction = (filters) => ({type: Types.EXCLUSIVE_CONTENT_LIST_ACTION, filters});
export const exclusiveContentListSuccess = (list) => ({
    type: Types.EXCLUSIVE_CONTENT_LIST_SUCCESS,
    list
});
export const exclusiveContentCreateAction = (params) => ({type: Types.EXCLUSIVE_CONTENT_CREATE_ACTION, params});
export const exclusiveContentCreateSuccess = (resultCreate) => ({
    type: Types.EXCLUSIVE_CONTENT_CREATE_SUCCESS,
    resultCreate
});

export const exclusiveContentDetailAction = (params) => ({type: Types.EXCLUSIVE_CONTENT_DETAIL_ACTION, params});
export const exclusiveContentDetailSuccess = (detail) => ({
    type: Types.EXCLUSIVE_CONTENT_DETAIL_SUCCESS,
    detail
});

export const exclusiveContentInitStore = (params) => ({type: Types.EXCLUSIVE_CONTENT_INIT, params});
export const exclusiveContentClearStore = (params) => ({type: Types.EXCLUSIVE_CONTENT_FAIL, params});
