import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class EvictionRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.assessed_value = data?.assessed_value || "";
        this.bankrupt = data?.bankrupt || "";
        this.bath = data?.bath || "";
        this.bed = data?.bed || "";
        this.case_number = data?.case_number || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.city = data?.city || "";
        this.county = data?.county || "";
        this.date_added = data?.date_added ? moment(data?.date_added).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        this.date_filed = data?.date_filed ? moment(data?.date_filed).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        this.deceased = data?.deceased || "";
        this.email_addresses_1 = data?.email_addresses_1 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.email_addresses_6 = data?.email_addresses_6 || "";
        this.garage = data?.garage || "";
        this.landlord_address = data?.landlord_address || "";
        this.landlord_first_name = data?.landlord_first_name || "";
        this.landlord_last_name = data?.landlord_last_name || "";
        this.landlord_phone = data?.landlord_phone || "";
        this.lead_type = data?.lead_type || "";
        this.phone_numbers_1 = data?.phone_numbers_1 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.pool = data?.pool || "";
        this.property_address = data?.property_address || "";
        this.property_city = data?.property_city || "";
        this.property_id = data?.property_id || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.relative_address = data?.relative_address || "";
        this.relative_city = data?.relative_city || "";
        this.relative_email_addresses_1 = data?.relative_email_addresses_1 || "";
        this.relative_email_addresses_2 = data?.relative_email_addresses_2 || "";
        this.relative_email_addresses_3 = data?.relative_email_addresses_3 || "";
        this.relative_full_name = data?.relative_full_name || "";
        this.relative_phone_1 = data?.relative_phone_1 || "";
        this.relative_phone_2 = data?.relative_phone_2 || "";
        this.relative_phone_3 = data?.relative_phone_3 || "";
        this.relative_state = data?.relative_state || "";
        this.relative_zip = data?.relative_zip || "";
        this.sq_ft = data?.sq_ft || "";
        this.state = data?.state || "";
        this.tenant_first_name = data?.tenant_first_name || "";
        this.tenant_last_name = data?.tenant_last_name || "";
        this.yr_bt = data?.yr_bt || "";
        this.zip_code = data?.zip_code || "";
    }

    exportUpdate(params) {
        let newParams = params
        if (Object.keys(params || []).includes("date_added")) {
            newParams.date_added = params?.date_added ? moment(params?.date_added).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        if (Object.keys(params || []).includes("date_filed")) {
            newParams.date_filed = params?.date_filed ? moment(params?.date_filed).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        }
        return newParams
    }
}

export const FIELD_TYPE = {
    assessed_value: ["NUMBER"],
    bath: ["NUMBER"],
    bed: ["NUMBER"],
    date_added: ["DATE"],
    date_filed: ["DATE"],
    garage: ["NUMBER"],
    lead_type: ["DISABLED"],
    property_address: ["REQUIRED"],
    property_city: ["REQUIRED"],
    property_state: ["REQUIRED"],
    property_zip_code: ["REQUIRED"],
    sq_ft: ["NUMBER"],
    yr_bt: ["NUMBER"]
}

export const filtersParams = (filters) => {
    let newDateField = (filters?.date_filed || []).length > 0 ? [moment(filters?.date_filed[0]).format("YYYY-MM-DD"), moment(filters?.date_filed[1]).format("YYYY-MM-DD")] : ["", ""]
    let newDateAdded = (filters?.date_added || []).length > 0 ? [moment(filters?.date_added[0]).format("YYYY-MM-DD"), moment(filters?.date_added[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        date_filed_from: newDateField[0],
        date_filed_to: newDateField[1],
        date_added_from: newDateAdded[0],
        date_added_to: newDateAdded[1],
        assessed_value_from: checkNumber(filters?.assessed_value_from) ? filters?.assessed_value_from : "",
        assessed_value_to: checkNumber(filters?.assessed_value_to) ? filters?.assessed_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
        is_favorite: +filters?.is_favorite || "",
        is_export: filters?.is_export || "",
        is_csv: filters?.is_csv || "",
    })
}
