import {createSelector} from 'reselect'

const stateReducer = state => state.exclusiveContentReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.statusAction
    )

export const selectExclusiveContentList = (initItems) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.data
    )
