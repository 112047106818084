import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class TicketResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.key = data?.id || "";
        this.id = data?.id || "";
        this.code = data?.code || "";
        this.title = data?.title || "";
        this.type = data?.type || "";
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.comments = data?.comments || "";
        this.created = data?.created || "";
        this.is_unread = data?.is_unread || false;
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        this.updated_at = data?.updated_at ? moment(data?.updated_at).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        this.messages = this.getMessage(data?.messages)
    }

    getMessage(message = []) {
        let newMessage = [];
        if (message.length > 0) {
            message.map(i => newMessage.push({
                user: {
                    id: i?.user?.id || "",
                    first_name: i?.user?.first_name || "",
                    last_name: i?.user?.last_name || "",
                    is_admin: i?.user?.is_admin || 0,
                },
                message: i?.message || "",
                attachments: i?.attachments || [],
                created_at: i?.created_at ? moment(i?.created_at).format(DATE_TIME_FORMAT.DATE_TIME) : "",
            }))
        }
        return newMessage
    }

    exportList() {
        return {
            id: this.id,
            code: this.code,
            title: this.title,
            type: this.type,
            status: this.status,
            status_explain: this.status_explain,
            comments: this.comments,
            created: this.created,
            is_unread: this.is_unread,
        }
    }

    exportDetail() {
        return {
            key: this.key,
            id: this.id,
            code: this.code,
            title: this.title,
            type: this.type,
            messages: this.messages,
            status: this.status,
            status_explain: this.status_explain,
            created_at: this.created_at,
            updated_at: this.updated_at,
        }
    }
}

export const TICKET = {
    STATUS: {
        OPEN: 0,
        ASSIGN: 1,
        PROCESS: 2,
        CLOSE: 3,
        REJECT: 4
    },
    STATUS_EXPLAIN: {
        0: "OPEN",
        1: "ASSIGNED",
        2: "PROCESSING",
        3: "CLOSE",
        4: "REJECT"
    },
    TYPE: {
        GENERAL: {
            title: "General",
            icon: "fa-ticket-alt",
            key: "General"
        },
        ACCOUNT: {
            title: "Account",
            icon: "fa-user",
            key: "Account"
        },
        DATA: {
            title: "Data",
            icon: "fa-database",
            key: "Data"
        },
        SUBSCRIPTION: {
            title: "Subscription",
            icon: "fa-box",
            key: "Subscription"
        },
    },
    STATUS_COLOR: ["#01579b", "#00838f", "#2e7d32", "#424242", "#ff8f00"],
    TYPE_COLOR: {
        GENERAL: "#173D60",
        ACCOUNT: "#00A1C9",
        DATA: "#F7C830",
        SUBSCRIPTION: "#095F93"
    }
}

