import * as Types from '../types/Eviction';

export const evictionMasterAction = (filters) => ({type: Types.EVICTION_MASTER_ACTION, filters});
export const evictionMasterSuccess = (master) => ({
    type: Types.EVICTION_MASTER_SUCCESS,
    master
});

export const evictionListAction = (filters) => ({type: Types.EVICTION_LIST_ACTION, filters});
export const evictionListSuccess = (eviction) => ({
    type: Types.EVICTION_LIST_SUCCESS,
    eviction
});

export const evictionDetailAction = (params) => ({type: Types.EVICTION_DETAIL_ACTION, params});
export const evictionDetailSuccess = (evictionDetail) => ({
    type: Types.EVICTION_DETAIL_SUCCESS,
    evictionDetail
});

export const evictionUpdatelAction = (params) => ({type: Types.EVICTION_UPDATE_ACTION, params});
export const evictionUpdatelSuccess = (updateData) => ({
    type: Types.EVICTION_UPDATE_SUCCESS,
    updateData
});

export const evictionDeleteAction = (params) => ({type: Types.EVICTION_DELETE_ACTION, params});
export const evictionDeleteSuccess = (deleteData) => ({
    type: Types.EVICTION_DELETE_SUCCESS,
    deleteData
});

export const evictionUnlockAction = (params) => ({type: Types.EVICTION_UNLOCK_ACTION, params});
export const evictionUnlockSuccess = (resultUnlock) => ({
    type: Types.EVICTION_UNLOCK_SUCCESS,
    resultUnlock
});

export const evictionFavoriteAction = (params) => ({type: Types.EVICTION_FAVORITE_ACTION, params});
export const evictionFavoriteSuccess = (favorite) => ({
    type: Types.EVICTION_FAVORITE_SUCCESS,
    favorite
});

export const evictionDeleteFavoriteAction = (params) => ({type: Types.EVICTION_FAVORITE_DELETE_ACTION, params});
export const evictionDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.EVICTION_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const evictionExportFavoriteAction = (filters) => ({type: Types.EVICTION_FAVORITE_EXPORT_ACTION, filters});
export const evictionExportFavoriteSuccess = (resultExport) => ({
    type: Types.EVICTION_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const evictionInitStore = (params) => ({type: Types.EVICTION_INIT, params});
export const evictionClearStore = (params) => ({type: Types.EVICTION_FAIL, params});
