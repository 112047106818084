import {createSelector} from 'reselect'

const stateReducer = state => state.loanModificationReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        loanModificationReducer => loanModificationReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        loanModificationReducer => loanModificationReducer.statusAction
    )

export const selectMasterLoanModification = (initItems) =>
    createSelector(
        stateReducer,
        loanModificationReducer => loanModificationReducer.master
    )

export const selectLoanModificationList = (initItems) =>
    createSelector(
        stateReducer,
        loanModificationReducer => loanModificationReducer.data
    )


export const selectLoanModificationDetail = (initItems) =>
    createSelector(
        stateReducer,
        loanModificationReducer => loanModificationReducer.detail
    )
