import * as Types from '../types/PlansPricing';

export const plansPricingListAction = (filters) => ({type: Types.PLANS_PRICING_LIST_ACTION, filters});
export const plansPricingListSuccess = (list) => ({
    type: Types.PLANS_PRICING_LIST_SUCCESS,
    list
});
export const plansPricingMasterAction = (filters) => ({type: Types.PLANS_PRICING_MASTER_ACTION, filters});
export const plansPricingMasterSuccess = (master) => ({
    type: Types.PLANS_PRICING_MASTER_SUCCESS,
    master
});
export const plansPricingCreateAction = (params) => ({type: Types.PLANS_PRICING_CREATE_ACTION, params});
export const plansPricingCreateSuccess = (resultCreate) => ({
    type: Types.PLANS_PRICING_CREATE_SUCCESS,
    resultCreate
});

export const plansPricingUpdateAction = (params) => ({type: Types.PLANS_PRICING_UPDATE_ACTION, params});
export const plansPricingUpdateSuccess = (resultUpdate) => ({
    type: Types.PLANS_PRICING_UPDATE_SUCCESS,
    resultUpdate
});

export const plansPricingDetailAction = (params) => ({type: Types.PLANS_PRICING_DETAIL_ACTION, params});
export const plansPricingDetailSuccess = (detail) => ({
    type: Types.PLANS_PRICING_DETAIL_SUCCESS,
    detail
});

export const plansPricingDeleteAction = (params) => ({type: Types.PLANS_PRICING_DELETE_ACTION, params});
export const plansPricingDeleteSuccess = (resultDelete) => ({
    type: Types.PLANS_PRICING_DELETE_SUCCESS,
    resultDelete
});

export const plansPricingInitStore = (params) => ({type: Types.PLANS_PRICING_INIT, params});
export const plansPricingClearStore = (params) => ({type: Types.PLANS_PRICING_FAIL, params});
