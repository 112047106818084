import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/ticketAction'

export const ActionTicketList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketListAction(params)),
        [dispatch]
    )
}

export const ActionTicketCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketCreateAction(params)),
        [dispatch]
    )
}

export const ActionTicketComment = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketCommentAction(params)),
        [dispatch]
    )
}

export const ActionTicketDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketDetailAction(params)),
        [dispatch]
    )
}

export const ActionTicketInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketInitStore(params)),
        [dispatch]
    )
}
