import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/codeViolationAction'
import {ActionPreForeClosureDelete} from "./data";

export const ActionMasterCodeViolation = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationMasterAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationListAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationDetailAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationUpdatelAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationDeleteAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationDownload = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationDownloadAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationUnlockAction(params)),
        [dispatch]
    )
}

export const ActionCodeViolationInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.codeViolationDeleteFavoriteAction(params)),
        [dispatch]
    )
}
