import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class PackagePaymentResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.order_id = data?.order_id || "";
        this.url = data?.url || "";
    }

    exportResultCreateOrder() {
        return {
            order_id: this.order_id,
            url: this.url,
        }
    }
}

export const filtersResponse = (filters) => {
    let newDateField = (filters?.date_filed_from && filters?.date_filed_to) ? [moment(filters?.date_filed_from), moment(filters?.date_filed_to)] : []
    let newDateAdded = (filters?.date_added_from && filters?.date_added_to) ? [moment(filters?.date_added_from), moment(filters?.date_added_to)] : []
    return removeObjectNullFull({
        date_filed: newDateField,
        date_added: newDateAdded,
        assessed_value_from: checkNumber(filters?.assessed_value_from) ? filters?.assessed_value_from : "",
        assessed_value_to: checkNumber(filters?.assessed_value_to) ? filters?.assessed_value_to : "",
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export const TYPE_PACKAGE = {
    STANDARD: "S",
    PREMIUM: "P"
}
