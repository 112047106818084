const Login = props => {
    const agent = "RealEstateIQ"
    return (
        <div className="hn__authentication container">
            <div className="hn__authentication--screen">
                <div className="hn__authentication--screen__content">
                    <div className="text-center mt-5 color--white">
                        <div className="my-3">
                            <img src="./layout/logo-login.svg"
                                 className="" width="200px"/>
                        </div>
                        <p className="mb-0 text-center">Please login to access</p>
                        <hr/>
                    </div>
                    <div className="px-3 mt-4">
                        <a href={`${process.env.REACT_APP_AUTH0}/auth/verify?callback=${process.env.REACT_APP_BASE_URL}/auth/loyalty&agent=${agent}`}
                           className="button hn__authentication--login__submit">
                            <span className="button__text">Log In Now</span>
                            <i className="button__icon fas fa-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
