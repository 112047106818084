import * as Types from '../types/TaxSale';

export const taxSaleMasterAction = (filters) => ({type: Types.TAX_SALE_MASTER_ACTION, filters});
export const taxSaleMasterSuccess = (master) => ({
    type: Types.TAX_SALE_MASTER_SUCCESS,
    master
});

export const taxSaleListAction = (filters) => ({type: Types.TAX_SALE_LIST_ACTION, filters});
export const taxSaleListSuccess = (taxSale) => ({
    type: Types.TAX_SALE_LIST_SUCCESS,
    taxSale
});

export const taxSaleDetailAction = (params) => ({type: Types.TAX_SALE_DETAIL_ACTION, params});
export const taxSaleDetailSuccess = (taxSaleDetail) => ({
    type: Types.TAX_SALE_DETAIL_SUCCESS,
    taxSaleDetail
});

export const taxSaleUpdateAction = (params) => ({type: Types.TAX_SALE_UPDATE_ACTION, params});
export const taxSaleUpdateSuccess = (updateData) => ({
    type: Types.TAX_SALE_UPDATE_SUCCESS,
    updateData
});

export const taxSaleDeleteAction = (params) => ({type: Types.TAX_SALE_DELETE_ACTION, params});
export const taxSaleDeleteSuccess = (deleteData) => ({
    type: Types.TAX_SALE_DELETE_SUCCESS,
    deleteData
});

export const taxSaleUnlockAction = (params) => ({type: Types.TAX_SALE_UNLOCK_ACTION, params});
export const taxSaleUnlockSuccess = (resultUnlock) => ({
    type: Types.TAX_SALE_UNLOCK_SUCCESS,
    resultUnlock
});

export const taxSaleFavoriteAction = (params) => ({type: Types.TAX_SALE_FAVORITE_ACTION, params});
export const taxSaleFavoriteSuccess = (favorite) => ({
    type: Types.TAX_SALE_FAVORITE_SUCCESS,
    favorite
});

export const taxSaleDeleteFavoriteAction = (params) => ({type: Types.TAX_SALE_FAVORITE_DELETE_ACTION, params});
export const taxSaleDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.TAX_SALE_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const taxSaleExportFavoriteAction = (filters) => ({type: Types.TAX_SALE_FAVORITE_EXPORT_ACTION, filters});
export const taxSaleExportFavoriteSuccess = (resultExport) => ({
    type: Types.TAX_SALE_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const taxSaleInitStore = (params) => ({type: Types.TAX_SALE_INIT, params});
export const taxSaleClearStore = (params) => ({type: Types.TAX_SALE_FAIL, params});
