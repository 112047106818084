export const TAX_SALE_MASTER_ACTION = "TAX_SALE_MASTER_ACTION";
export const TAX_SALE_MASTER_SUCCESS = "TAX_SALE_MASTER_SUCCESS";

export const TAX_SALE_LIST_ACTION = "TAX_SALE_LIST_ACTION";
export const TAX_SALE_LIST_SUCCESS = "TAX_SALE_LIST_SUCCESS";

export const TAX_SALE_DETAIL_ACTION = "TAX_SALE_DETAIL_ACTION";
export const TAX_SALE_DETAIL_SUCCESS = "TAX_SALE_DETAIL_SUCCESS";

export const TAX_SALE_UPDATE_ACTION = "TAX_SALE_UPDATE_ACTION";
export const TAX_SALE_UPDATE_SUCCESS = "TAX_SALE_UPDATE_SUCCESS";

export const TAX_SALE_DELETE_ACTION = "TAX_SALE_DELETE_ACTION";
export const TAX_SALE_DELETE_SUCCESS = "TAX_SALE_DELETE_SUCCESS";

export const TAX_SALE_UNLOCK_ACTION = "TAX_SALE_UNLOCK_ACTION";
export const TAX_SALE_UNLOCK_SUCCESS = "TAX_SALE_UNLOCK_SUCCESS";

export const TAX_SALE_FAVORITE_ACTION = "TAX_SALE_FAVORITE_ACTION";
export const TAX_SALE_FAVORITE_SUCCESS = "TAX_SALE_FAVORITE_SUCCESS";

export const TAX_SALE_FAVORITE_DELETE_ACTION = "TAX_SALE_FAVORITE_DELETE_ACTION";
export const TAX_SALE_FAVORITE_DELETE_SUCCESS = "TAX_SALE_FAVORITE_DELETE_SUCCESS";

export const TAX_SALE_FAVORITE_EXPORT_ACTION = "TAX_SALE_FAVORITE_EXPORT_ACTION";
export const TAX_SALE_FAVORITE_EXPORT_SUCCESS = "TAX_SALE_FAVORITE_EXPORT_SUCCESS";

export const TAX_SALE_INIT = "TAX_SALE_INIT";
export const TAX_SALE_FAIL = "TAX_SALE_FAIL";
