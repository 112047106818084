import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/loanModificationAction'

export const ActionMasterLoanModification = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationMasterAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationListAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationDetailAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationUpdateAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationDeleteAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationUnlockAction(params)),
        [dispatch]
    )
}

export const ActionLoanModificationInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.loanModificationDeleteFavoriteAction(params)),
        [dispatch]
    )
}
