import React from 'react';
import {Form, Input, Popconfirm, InputNumber, DatePicker} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import TAG_DEFINE from "../../../../constants/common";
import {isNumber} from "../../../../utils/validation";

const FormEditDetailComponent = (props) => {
    if ((Object.keys(props.fieldType).filter(i => ((props.fieldType[i] || []).includes("DATE_TIME")))).includes(props.record?.param)) {
        return (
            <Form.Item
                className="float-start width-93"
                style={{
                    margin: 0,
                }}
                name={props.record?.param}
                rules={[
                    {
                        required: (props.fieldType[props.record?.param] || []).includes("REQUIRED"),
                        message: `${props.title} is required.`,
                    },
                ]}
            >
                <DatePicker ref={props.inputRef} className="w-100" showTime/>
            </Form.Item>
        )
    } else if ((Object.keys(props.fieldType).filter(i => ((props.fieldType[i] || []).includes("DATE")))).includes(props.record?.param)) {
        return (
            <Form.Item
                className="float-start width-93"
                style={{
                    margin: 0,
                }}
                name={props.record?.param}
                rules={[
                    {
                        required: (props.fieldType[props.record?.param] || []).includes("REQUIRED"),
                        message: `${props.title} is required.`,
                    },
                ]}
            >
                <DatePicker ref={props.inputRef} className="w-100"/>
            </Form.Item>
        )
    } else if ((Object.keys(props.fieldType).filter(i => ((props.fieldType[i] || []).includes("NUMBER")))).includes(props.record?.param)) {
        return (
            <Form.Item
                className="float-start width-93"
                style={{
                    margin: 0,
                }}
                name={props.record?.param}
                rules={[
                    {
                        required: (props.fieldType[props.record?.param] || []).includes("REQUIRED"),
                        whitespace: true,
                        message: `${props.title} is required.`,
                    },
                    ({getFieldValue}) => ({
                        validator(rule, value) {
                            if (value) {
                                if (/^[0-9.-]+$/i.test(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(TAG_DEFINE.VALIDATION.statusCode.code["2"].replace('%s', props.title));
                            } else {
                                return Promise.resolve();
                            }
                        },
                    })
                ]}
            >
                <Input ref={props.inputRef} className="w-100"
                       onPressEnter={props.form.submit}
                       disabled={props.isFetching}/>
                {/*<InputNumber ref={props.inputRef} onPressEnter={props.form.submit} className="w-100"*/}
                {/*             disabled={props.isFetching}/>*/}
            </Form.Item>
        )
    } else if ((Object.keys(props.fieldType).filter(i => ((props.fieldType[i] || []).includes("DISABLED")))).includes(props.record?.param)) {
        return (
            <Form.Item
                className="float-start width-93"
                style={{
                    margin: 0,
                }}
                name={props.record?.param}
                rules={[
                    {
                        required: (props.fieldType[props.record?.param] || []).includes("REQUIRED"),
                        whitespace: true,
                        message: `${props.title} is required.`,
                    },
                ]}
            >
                <Input onPressEnter={props.form.submit} ref={props.inputRef} disabled className="w-100"/>
            </Form.Item>
        )
    } else {
        return (
            <Form.Item
                className="float-start width-93"
                style={{
                    margin: 0,
                }}
                name={props.record?.param}
                rules={[
                    {
                        required: (props.fieldType[props.record?.param] || []).includes("REQUIRED"),
                        whitespace: true,
                        message: `${props.title} is required.`,
                    },
                ]}
            >
                <Input onPressEnter={props.form.submit} ref={props.inputRef} className="w-100"
                       disabled={props.isFetching}/>
            </Form.Item>
        )
    }
}

export default FormEditDetailComponent
