import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class DelinquentTaxResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.file_name = data?.file_name || "";
        this.date_added = data?.date_added || "";
        this.property_city = data?.property_city || "";
        this.property_state = data?.property_state || "";
        this.property_county = data?.property_county || "";
        this.is_edit_data = data?.is_edit_data;
        this.filters_off_market_disabled = data?.filters_off_market_disabled || [];
    }

    exportList() {
        return {
            id: this.id,
            file_name: this.file_name,
            date_added: this.date_added,
            property_city: this.property_city,
            property_state: this.property_state,
            property_county: this.property_county,
            is_edit_data: this.is_edit_data,
        }
    }

    exportMaster(data) {
        return {
            property_city: data?.property_city || [],
            property_state: data?.property_state || [],
            county: data?.county || [],
            filters_off_market_disabled: data?.filters_off_market_disabled || [],
        }
    }

    exportDetail() {
        return {}
    }
}

export const column = ["file_name", "date_added", "property_state", "property_city", "property_county"];
export const detailColumn = {}

export const fieldDetailRemove = [""];

export const filtersResponse = (filters) => {
    let newRecordDate = (filters?.date_added_from && filters?.date_added_to) ? [moment(filters?.date_added_from), moment(filters?.date_added_to)] : []
    return removeObjectNullFull({
        date_added: newRecordDate,
        property_city: filters?.property_city || "",
        property_state: filters?.property_state || "",
        county: filters?.county || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

