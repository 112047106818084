import {createSelector} from 'reselect'

const stateReducer = state => state.delinquentTaxReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.statusAction
    )

export const selectMasterDelinquentTax = (initItems) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.master
    )

export const selectDelinquentTaxList = (initItems) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.data
    )


export const selectDelinquentTaxDetail = (initItems) =>
    createSelector(
        stateReducer,
        codeViolationReducer => codeViolationReducer.detail
    )
