import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class ExclusiveContentResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.posts = this.generatePost(data || []);
    }

    generatePost(posts = []) {
        let newPosts = [];
        if (posts.length > 0) {
            posts.map(i => {
                newPosts.push({
                    title: i?.title || "",
                    items: this.generateItems(i?.items || []),
                    groups: this.generateGroups(i?.groups || []),
                })
            })
        }
        return newPosts
    }

    generateItems(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => {
                newItems.push({
                    id: i?.id || "",
                    thumbnail: i?.thumbnail || "",
                    video_url: i?.video_url || "",
                    title: i?.title || "",
                    description: i?.description || "",
                    footer_icon: i?.footer_icon || "",
                    footer_hot: i?.footer_hot || "",
                    footer_description: i?.footer_description || "",
                })
            })
        }
        return newItems
    }

    generateGroups(groups = []) {
        let newGroups = []
        if (groups.length > 0) {
            groups.map(i => {
                newGroups.push({
                    title: i?.title || "",
                    items: this.generateItems(i?.items || [])
                })
            })
        }
        return newGroups
    }

    exportExclusiveContent() {
        return {
            posts: this.posts
        }
    }
}
