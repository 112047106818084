import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.isFetching
    )

export const selectFetchingGeneral = (initData) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.isFetchingGeneral
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.statusAction
    )

export const selectSubscriptionCurrent = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.current
    )

export const selectSubscriptionGeneral = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.general
    )

export const selectSubscriptionListPackage = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.listPackages
    )

export const selectSubscriptionListTransaction = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.listTransactions
    )

export const selectConfirmPackage = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.confirmPackage
    )

export const selectSubscriptionDetailUpgrade = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionReducer => subscriptionReducer.detailUpgrade
    )
