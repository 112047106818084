import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/evictionAction'

export const ActionMasterEviction = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionMasterAction(params)),
        [dispatch]
    )
}

export const ActionEvictionList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionListAction(params)),
        [dispatch]
    )
}

export const ActionEvictionExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionEvictionDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionDetailAction(params)),
        [dispatch]
    )
}

export const ActionEvictionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionUpdatelAction(params)),
        [dispatch]
    )
}

export const ActionEvictionDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionDeleteAction(params)),
        [dispatch]
    )
}

export const ActionEvictionUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionUnlockAction(params)),
        [dispatch]
    )
}

export const ActionEvictionInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.evictionDeleteFavoriteAction(params)),
        [dispatch]
    )
}
