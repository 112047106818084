import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/apptOfSubTrusteeAction'

export const ActionMasterApptOfSubTrustee = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeMasterAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeListAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeDetailAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeUpdateAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeDeleteAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeUnlockAction(params)),
        [dispatch]
    )
}

export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeDeleteFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionApptOfSubTrusteeInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.apptOfSubTrusteeInitStore(params)),
        [dispatch]
    )
}
