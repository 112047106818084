import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class TemplateResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.ticket = {
            unread: data?.ticket?.unread || 0
        }
        this.cart = {
            count: data?.cart?.count || 0
        }
        this.marketLeadFilters = this.generateMarketLeadFilters(data || {})
        this.announcement = this.generateItemsAnnouncement(data?.announcement)
        this.banners = data?.banner || []
        this.popup = data?.popup || []
    }

    generateItemsAnnouncement(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => {
                newItems.push({
                    id: i?.id || "",
                    title: i?.title || "",
                    url: i?.url || "",
                    popup_image_url: i?.popup_image_url || "",
                    startTime: moment(i?.startTime).format(DATE_TIME_FORMAT.DATE_TIME) || "",
                    endTime: moment(i?.endTime).format(DATE_TIME_FORMAT.DATE_TIME) || ""
                })
            })
        }
        return newItems
    }

    generateMarketLeadFilters(filters = {}) {
        let newFilters = []
        if (Object?.keys(filters).length > 0) {
            Object?.keys(filters).map(i => newFilters.push({
                value: i,
                params: filters[i]
            }))
        }
        return newFilters
    }

    exportGeneral() {
        return {
            ticket: this.ticket,
            cart: this.cart,
            announcement: this.announcement,
            banners: this.banners,
            popup: this.popup
        }
    }

    exportMarketLeadFilters() {
        return {
            listFilters: this.marketLeadFilters
        }
    }
}
