import {createSelector} from 'reselect'

const stateReducer = state => state.heirshipReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        heirshipReducer => heirshipReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        heirshipReducer => heirshipReducer.statusAction
    )

export const selectMasterHeirship = (initItems) =>
    createSelector(
        stateReducer,
        heirshipReducer => heirshipReducer.master
    )

export const selectHeirshipList = (initItems) =>
    createSelector(
        stateReducer,
        heirshipReducer => heirshipReducer.data
    )


export const selectHeirshipDetail = (initItems) =>
    createSelector(
        stateReducer,
        heirshipReducer => heirshipReducer.detail
    )
