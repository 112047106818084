import {AutoComplete, Button, message, Spin} from "antd";
import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {ActionLayoutMarketLeadCreateFilters} from "../../../data/hooks/template";
import {useSelector} from "react-redux";
import {selectStatusAction} from "../../../data/reselects/templateSelector";
import {Icon} from "../index";

const ModalSaveFilters = props => {
    const [optionSelect, setOptionSelect] = useState(queryString.parse(props.routeMatch?.location?.search)),
        [title, setTitle] = useState(),
        [isSubmit, setIsSubmit] = useState(false),
        [isUpdate, setIsUpdate] = useState(false),
        actionLayoutMarketLeadCreateFilters = ActionLayoutMarketLeadCreateFilters(),
        itemStatusAction = useSelector(selectStatusAction());

    useEffect(() => {
        setOptionSelect(queryString.parse(props.routeMatch?.location?.search))
    }, [props.routeMatch]);

    useEffect(() => {
        if (isSubmit) {
            props?.isSubmit(itemStatusAction?.isCreateMarketLeadFilters)
        }
    }, [itemStatusAction]);

    const onSelect = (value, option) => {
        setIsUpdate(true)
        setTitle(value)
    };

    const handleSearch = value => {
        setIsUpdate(false)
        setTitle(value)
    };

    const onSave = v => {
        if (props?.lead) {
            if (title) {
                if (queryString.stringify(queryString.parse(props.routeMatch?.location?.search))) {
                    setIsUpdate(false)
                    actionLayoutMarketLeadCreateFilters({
                        title,
                        value: queryString.stringify(queryString.parse(props.routeMatch?.location?.search)),
                        lead: props?.lead
                    })
                    setIsSubmit(true);
                } else {
                    message.error("Please choose your filters")
                }
            } else {
                message.error("Please input your title")
            }
        }
    }

    return (
        <>
            <p className="mb-0 fw-bold color--black">Title filter</p>
            <Spin spinning={itemStatusAction?.isCreateMarketLeadFilters}>
                <AutoComplete
                    style={{
                        width: "100%",
                        marginTop: "5px"
                    }}
                    allowClear
                    options={props?.master}
                    placeholder="Input or Select your title filter"
                    filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={onSelect}
                    onSearch={handleSearch}
                />
                <div className="row mt-4">
                    <div className="col-12 text-end">
                        <Button type="default" htmlType="button" className="" onClick={props.onClose}>
                            Close
                        </Button>
                        <Button type="primary" htmlType="button" className="ms-2" onClick={onSave}>
                            {
                                isUpdate ? "Save" : "Create Filter"
                            }
                            {
                                itemStatusAction?.isCreateMarketLeadFilters &&
                                <Icon style={{fontSize: "5px"}} type="sync-outlined" spin/>
                            }
                        </Button>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default ModalSaveFilters
