export default class TemplateRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.value = data?.value || "";
    }

    exportCreateFilters() {
        return {
            title: this.title,
            value: this.value,
        }
    }
}
