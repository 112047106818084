import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import routers from "../../../routers";
import {find} from "lodash";
import {Menu, Button, Drawer} from 'antd';
import Icon, {
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined, StepForwardOutlined, RightOutlined,
} from '@ant-design/icons';
import {ActionMasterDataLeadType} from "../../../data/hooks/data";
import {useSelector} from "react-redux";
import {selectMasterDataLeadType} from "../../../data/reselects/dataSelector";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {getProfile} from "../../../data/reselects/authSelector";
import {selectTemplateGeneral} from "../../../data/reselects/templateSelector";

const {SubMenu} = Menu;

const Navigation = (props) => {
    const [itemMenu, setItemMenu] = useState([]),
        itemDataMasterLeadType = useSelector(selectMasterDataLeadType()),
        getDataMasterLeadType = ActionMasterDataLeadType(),
        itemAuthProfile = useSelector(getProfile()),
        itemGeneralTemplate = useSelector(selectTemplateGeneral())

    useEffect(() => {
        if (Object.keys(itemDataMasterLeadType).length < 1) {
            getDataMasterLeadType()
        }
    }, []);

    useEffect(() => {
        // let newMenu = [];
        // Object.keys(itemDataMasterLeadType).map(i => newMenu.push({
        //     title: itemDataMasterLeadType[i],
        //     url: `/${itemDataMasterLeadType[i]}`
        // }))
        setItemMenu(itemAuthProfile?.menu || [])
    }, [itemAuthProfile.menu]);
    // const [collapsed, setCollapsed] = useState(false);
    //
    // const toggleCollapsed = () => {
    //     setCollapsed(!collapsed)
    // };

    const renderMenuItem = (item) => {
        let arrayMenu = [];
        if (item.length > 0) {
            item.map((i, k) => arrayMenu.push(
                <Menu.Item key={`/${i.route}`} className="">
                    <Link to={`/${i.route}`}
                          className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                </Menu.Item>
            ))
        }
        return arrayMenu
    }

    return (
        <>
            <div className="hn__navigation d-none d-lg-block" style={{width: props?.collapsed ? 50 : 230}}>
                {
                    !props?.collapsed && (
                        <div className="hn__navigation--banner-visit-website d-none d-lg-block">
                            <a href="https://realestateiq.co/" target="_blank">
                                <img src="/layout/banner-visit-website.svg" alt=""/>
                            </a>
                        </div>
                    )
                }
                <div className="hn__navigation--footer-powered d-none d-lg-block">
                    <a href="https://megalithinc.com" target="_blank" className="text-decoration-none">
                        Powered by Megalith Solutions
                    </a>
                </div>
                {/*<div className={`hn__navigation d-none ${props?.collapsed ? "" : "d-lg-block"}`} style={{width: 230}}>*/}
                {
                    itemMenu.length > 0 && (
                        <Menu
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props?.location?.pathname]}
                            mode="vertical"
                            theme="dark"
                            inlineCollapsed={props?.collapsed}
                            className="pt-3"
                        >
                            {
                                itemMenu.map((i, k) => {
                                    if ((i?.items || []).length > 0) {
                                        return (
                                            <SubMenu
                                                popupClassName={`${props?.collapsed ? "left--60px" : ""} hn__navigation--hide-all-popup-submenu`}
                                                key={i?.group} title={
                                                <>
                                                    <i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-2" : "me-2"}`}/>
                                                    {
                                                        props.collapsed ?
                                                            <i className={`icon--arrow-right ${props?.collapsed ? "me-2" : ""}`}/> : ""
                                                    }
                                                    {!props?.collapsed && i?.name}
                                                </>
                                            } className="fs--18px hn__navigation--hide-all-submenu"
                                                onTitleClick={() => props.history.push(`/${i?.route}`)}>
                                                {renderMenuItem(i?.items || [])}
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={`/${i.route}`} className="">
                                                <Link to={`/${i.route}`}
                                                      className="fs--18px text-decoration-none">
                                                    <i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-3" : "me-2"}`}/>
                                                    {!props?.collapsed && capitalizeFirstLetter(i.name)}
                                                    {
                                                        ((i?.route === "ticket") && (itemGeneralTemplate?.ticket?.unread || 0) > 0) && (
                                                            <div className="float-end">
                                                                <span
                                                                    className="menu__support--count">{itemGeneralTemplate?.ticket?.unread || 0}</span>
                                                            </div>
                                                        )
                                                    }
                                                </Link>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    )
                }
            </div>
            <Drawer title={<img src="./layout/logo.svg" width="200px" height="50px"/>}
                    className="hn__navigation--mobile"
                    placement="left" onClose={props.toggleCollapsed} visible={props?.collapsed}>
                {
                    itemMenu.length > 0 && (
                        <Menu
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props?.location?.pathname]}
                            mode="inline"
                            theme="dark"
                        >
                            {
                                itemMenu.map((i, k) => {
                                    if ((i?.items || []).length > 0) {
                                        return (
                                            <SubMenu key={i?.group} title={i?.name} className="fs--18px">
                                                {renderMenuItem(i?.items || [])}
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={`/${i.route}`} className="">
                                                <Link to={`/${i.route}`}
                                                      className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    )
                }
            </Drawer>
        </>
    );
};

export default withRouter(Navigation);
