import {createSelector} from 'reselect'

const stateReducer = state => state.lisPendensReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        lisPendensReducer => lisPendensReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        lisPendensReducer => lisPendensReducer.statusAction
    )

export const selectMasterLisPendens = (initItems) =>
    createSelector(
        stateReducer,
        lisPendensReducer => lisPendensReducer.master
    )

export const selectLisPendensList = (initItems) =>
    createSelector(
        stateReducer,
        lisPendensReducer => lisPendensReducer.data
    )


export const selectLisPendensDetail = (initItems) =>
    createSelector(
        stateReducer,
        lisPendensReducer => lisPendensReducer.detail
    )
