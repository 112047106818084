import {createSelector} from 'reselect'

const stateReducer = state => state.evictionReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        evictionReducer => evictionReducer.isFetching
    )

export const selectMasterEviction = (initItems) =>
    createSelector(
        stateReducer,
        evictionReducer => evictionReducer.master
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        evictionReducer => evictionReducer.statusAction
    )

export const selectEvictionList = (initItems) =>
    createSelector(
        stateReducer,
        evictionReducer => evictionReducer.data
    )


export const selectEvictionDetail = (initItems) =>
    createSelector(
        stateReducer,
        evictionReducer => evictionReducer.detail
    )
