import React, {useEffect, useState} from "react";
import MainLayout from "../../common/layout/MainLayout";
import {Button, Tree, Spin, Modal} from "antd";
import {ActionConfirmPackage, ActionSubscriptionDetailUpgrade} from "../../../data/hooks/subscription";
import {useSelector} from "react-redux";
import {
    selectSubscriptionDetailUpgrade,
    selectFetching,
    selectConfirmPackage
} from "../../../data/reselects/subscriptionSelector";
import Packages from "./components/Packages";
import moment from "moment";
import {ROUTE_LIST} from "../../../constants/define";
import {find} from "lodash";
import {TYPE_PACKAGE} from "../../../data/mapping/Response/PackagePaymentResponse";
import {selectFetching as packagePaymentFetching} from "../../../data/reselects/packagePaymentSelector";
import {ActionPackageCreateOrder} from "../../../data/hooks/packagePayment";
import {TYPE_CONFIRM_PACKAGE} from "../../../data/mapping/Response/SubscriptionResponse";
import {Icon} from "../../common";


const Detail = (props) => {
    const [resultItems, setResultItems] = useState({}),
        [isShowDetailConfirmPackage, setIsShowDetailConfirmPackage] = useState(false),
        [getSelectItems, setGetSelectItems] = useState([]),
        [requestParams, setRequestParams] = useState([]),
        [confirmPackages, setConfirmPackages] = useState([]),
        // ============== ACTION ==============
        actionSubscriptionDetailUpgrade = ActionSubscriptionDetailUpgrade(),
        actionPackageCreateOrder = ActionPackageCreateOrder(),
        actionConfirmPackage = ActionConfirmPackage(),
        // ============== SELECT DATA ==============
        itemPackagePaymentFetching = useSelector(packagePaymentFetching()),
        itemFetching = useSelector(selectFetching()),
        itemDetailUpgrade = useSelector(selectSubscriptionDetailUpgrade()),
        itemConfirmPackage = useSelector(selectConfirmPackage());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            actionSubscriptionDetailUpgrade({date: match.params?.date});
        }
    }, [props.match]);

    useEffect(() => {
        setConfirmPackages(itemConfirmPackage);
        setIsShowDetailConfirmPackage(Object.keys(itemConfirmPackage).length > 0)
    }, [itemConfirmPackage]);

    useEffect(() => {
        setResultItems(itemDetailUpgrade?.customPackages)
    }, [itemDetailUpgrade]);

    const onCheck = (checkedItems, event, parent) => {
        let items = [],
            listNodes = [...event?.checkedNodes];
        items[parent] = listNodes.filter(i => i.parent === 3);
        setGetSelectItems(items)

        let rqParams = [],
            selectParams = listNodes.filter(i => i.parent === 3);
        if ((selectParams || []).length > 0) {
            rqParams = selectParams.map(i => i?.items?.package_id)
        }
        setRequestParams(rqParams)
    }

    const onCreateOrder = (type) => {
        actionPackageCreateOrder({
            month: props.match.params?.date,
            type,
            packages: requestParams,
            orderType: TYPE_CONFIRM_PACKAGE.DETAIL,
        })
    }

    const onSubscript = event => {
        actionConfirmPackage({
            month: props.match.params?.date,
            packages: requestParams,
            orderType: TYPE_CONFIRM_PACKAGE.DETAIL
        })
    }
    // console.log(321, getSelectItems)
    return (
        <MainLayout title={`Your packages in ${moment(props.match.params?.date).format("YYYY-MM")}`} disabledTab
                    breadcrumb={["My Subscription", "Detail"]}>
            <Modal className="package-subscription__detail__modal-confirm-package"
                   destroyOnClose
                   footer={null}
                   title={<h4 className="text-center fw-bold mb-0">Choose your package type
                       for {moment(props.match.params?.date).format("YYYY-MM")}</h4>}
                   width={1000}
                   visible={isShowDetailConfirmPackage}
                   onOk={() => {
                   }} onCancel={() => setIsShowDetailConfirmPackage(false)}
            >
                {
                    isShowDetailConfirmPackage && (
                        <Spin spinning={itemPackagePaymentFetching}>
                            <div className="container pt-3 mb-4">
                                <div className="row align-items-center ">
                                    {
                                        confirmPackages?.P && (
                                            <div
                                                className="col-12 py-4 px-4 text-center border package-subscription__detail__modal-confirm-package--premium">
                                                <h4 className="fw-bold mb-3">{confirmPackages?.P?.name}</h4>
                                                <p className="mb-0">{
                                                    confirmPackages?.P?.sale_price && confirmPackages?.P?.sale_price.length > 0 && (
                                                        <span
                                                            className="fs--22px mb-0 me-2 package-subscription--price-hint"><s><span>{confirmPackages?.P?.sale_price}</span></s></span>
                                                    )
                                                }{confirmPackages?.P?.price}</p>
                                                <p className="mb-0 package-subscription--price-hint">{confirmPackages?.P?.message}</p>
                                                <button disabled={itemPackagePaymentFetching}
                                                        className="btn btn-primary btn-bg--primary w-100 mt-3"
                                                        onClick={() => onCreateOrder(TYPE_PACKAGE.PREMIUM)}>
                                                    Buy Now {itemPackagePaymentFetching &&
                                                <Icon type="sync-outlined" spin/>}
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Spin>
                    )
                }
            </Modal>
            <Spin spinning={itemFetching}>
                {
                    Object.keys(itemDetailUpgrade).length > 0 && (
                        <div className="my-subscription__detail__package mt-5">
                            <div className="container">
                                <div className="row my-subscription__detail__package--wrapper">
                                    <div className="col-12 col-lg-4 my-subscription__detail__package--item">
                                        <div className="my-subscription__detail__package--title">
                                            <h4 className="text-center fw-bold mb-2">Free-Trial</h4>
                                            <p className="text-center m-0 p-0">Trial</p>
                                            {/*{*/}
                                            {/*    getSelectItems[0] && getSelectItems[0].length > 0 && (*/}
                                            {/*        <div className="text-center mt-2">*/}
                                            {/*            <Button type="primary" htmlType="button">*/}
                                            {/*                Upgrade*/}
                                            {/*            </Button>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                        </div>
                                        <div className="my-subscription__detail__package--content mt-4">
                                            <Packages onCheck={onCheck} parent={0} items={resultItems?.T}/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 my-subscription__detail__package--item">
                                        <div className="my-subscription__detail__package--title">
                                            <h4 className="text-center fw-bold mb-2">Standard</h4>
                                            <p className="text-center m-0 p-0">Limited</p>
                                            {
                                                getSelectItems[1] && getSelectItems[1].length > 0 && (
                                                    <div className="text-center mt-2">
                                                        <Button type="primary" htmlType="button"
                                                                onClick={() => onSubscript()}>
                                                            Upgrade
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="my-subscription__detail__package--content mt-4">
                                            <Packages onCheck={onCheck} parent={1} items={resultItems?.S}/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 my-subscription__detail__package--item">
                                        <div className="my-subscription__detail__package--title">
                                            <h4 className="text-center fw-bold mb-2">Premium</h4>
                                            <p className="text-center m-0 p-0">Unlimited</p>
                                            {/*<div className="text-center mt-2">*/}
                                            {/*    <Button type="primary" htmlType="button">*/}
                                            {/*        Upgrade*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="my-subscription__detail__package--content mt-4">
                                            <Packages onCheck={onCheck} parent={2} items={resultItems?.P}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button className="btn btn-primary btn-bg--foreign my-subscription--btn-buy_now"
                                        onClick={() => props.history.push(`${ROUTE_LIST.PackageSubscription}?month=${props.match.params?.date}`)}>Buy
                                    Now
                                </button>
                            </div>
                        </div>
                    )
                }
            </Spin>
        </MainLayout>
    )
}

export default Detail
