import * as Types from '../types/PlansPricing';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isSuccess: false,
        unlockField: "",
    },
    all: [],
    errors: [],
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    detail: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.PLANS_PRICING_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.list),
                statusAction: initialState.statusAction
            };
        case Types.PLANS_PRICING_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_MASTER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                master: action.master,
                statusAction: initialState.statusAction
            };
        case Types.PLANS_PRICING_CREATE_ACTION:
            return {
                ...state, isFetching: true,
                statusAction: {
                    ...initialState.statusAction,
                    isSubmit: true
                }
            };
        case Types.PLANS_PRICING_CREATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                statusAction: {
                    ...initialState.statusAction,
                    isSuccess: true
                }
            };
        case Types.PLANS_PRICING_UPDATE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_UPDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                statusAction: initialState.statusAction
            };
        case Types.PLANS_PRICING_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_DELETE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                statusAction: initialState.statusAction
            };
        case Types.PLANS_PRICING_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_DETAIL_SUCCESS:
            return {...state, isFetching: false, detail: action.detail, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.PLANS_PRICING_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail
            };
        default:
            return state;
    }
}
