import React from 'react'
import MainLayout from "../../common/layout/MainLayout";
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import {CaretRightOutlined, LoadingOutlined, SettingOutlined} from "@ant-design/icons";
import SlideItem from "../../common/components/SlideItem";
import LazyImage from "../../common/components/LazyImage";
import {Divider, Collapse} from "antd";
import {ROUTE_LIST} from "../../../constants/define";

const {Panel} = Collapse;

const Index = props => {

    return (
        <MainLayout
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px pb-3">
                        <h4 className="pt-4 color--black fs--20px">FAQ <span className='fw-bold'>| You’ve got questions? We have the answers!</span>
                        </h4>
                    </div>
            }
            disabledTab
        >
            <div className="p-x--30px mt-4">
                <div className="faq__slide slide-dot-inside max-width--1400px bg-white">
                    <SlideItem className=""
                               settings={{
                                   dots: true,
                                   infinite: true,
                                   slidesToShow: 1,
                                   slidesToScroll: 1,
                               }}>
                        <div>
                            <LazyImage
                                imageClass="w-100 max-width--1400px object-fit height-280px"
                                alt="asdasd"
                                placeholder={true}
                                src="/layout/pages/faq/faq.png"
                                // src="https://wallpaperaccess.com/full/267434.jpg"
                            />
                        </div>
                    </SlideItem>
                </div>
            </div>
            <div className="container mt-3 faq max-width--1460px ms-0 p-x--40px">
                <div className="row">
                    <div className="col-12 bg-white py-4 faq__content border__radius--10px">
                        <Collapse
                            bordered={false}
                            expandIcon={({isActive}) => <button
                                className={`faq__content--collapse${isActive ? "-rotate" : ""}`}/>}
                            expandIconPosition="right"
                        >
                            <Panel header="How do I get started?" key="1">
                                <p>You can start by navigating our home page, where you will find some tutorials on how
                                    to use the platform and our main features.</p>
                                <p>We also encourage you to start playing with the Off-market Leads Section, where you
                                    can:</p>
                                <ul>
                                    <li>Choose the type of list you want to check.</li>
                                    <li>Filter the data accordingly to your needs.</li>
                                    <li>Choose the State, City and/or County you are interested in.</li>
                                    <li>Click "Search" to work on the leads you filtered for your investing stage.</li>
                                    <li>Remember: you are able to export the information as a .xlsx file if you need
                                        it.
                                    </li>
                                </ul>
                                <p><span className="fs--14px fw-bold">Not sure which option is best for you?</span> Our
                                    Account Specialists are ready to answer
                                    all your questions and show you our platform, so feel free to <a
                                        className="faq__content--link" href={ROUTE_LIST.ContactUs} target="_blank">contact
                                        us</a>. They'll
                                    get back to you by the next business day.</p>
                            </Panel>
                            <Panel header="How often are the leads updated?" key="2">
                                <p>Our off-market listings are updated from Monday to Friday on our platform.</p>
                            </Panel>
                            <Panel header="How do I see my billing information and invoices?" key="3">
                                <p>You can easily see your invoices on our portal. Once you're logged in, just click
                                    on <a
                                        className="faq__content--link" href={ROUTE_LIST.MySubscription} target="_blank">My
                                        Subscription</a> at the top right corner on your Profile Icon, and then on
                                    "Invoices"
                                    – you can access your list at "Check invoice history."
                                </p>
                            </Panel>
                            <Panel
                                header="What's the difference between Standard and Premium? How do I upgrade the plan?"
                                key="4">
                                <p>The Premium version comes with skip tracing information built-in, which means you
                                    won't have to pay separately for this service! If you subscribed to the Premium Off
                                    Market Leads, you will receive all the data included in the standard product and, in
                                    addition, up to 5 landline phone numbers, up to 5 mobile phone numbers, up to 5
                                    email addresses, the contact information of a relative and bankruptcy and death
                                    notices.
                                </p>
                                <p>
                                    If you subscribed to our Standard Plan and want to upgrade to Premium, you can raise
                                    a ticket to our Customer Success Team, so they can contact you and upgrade your plan
                                    right away!
                                </p>
                            </Panel>
                            <Panel header="How can I raise a ticket?" key="5">
                                <p>
                                    In the <a
                                    className="faq__content--link" href={ROUTE_LIST.Ticket}
                                    target="_blank">Support</a> section, you do it by clicking on the “Create Ticket”
                                    button. A
                                    pop-up will appear, where you can select the type of issue you want to address,
                                    provide us with your message, and finally, by clicking OK, the ticket will be
                                    automatically sent to our Customer Success Team.
                                </p>
                            </Panel>
                            <Panel header="How can I update my billing information? Can I do it on the Portal?" key="6">
                                <p>
                                    Unfortunately, as of the moment, we don't have an online option for updating your
                                    billing information. You can always contact us through the <a
                                    className="faq__content--link" href={ROUTE_LIST.Ticket}
                                    target="_blank">Support</a> section in the
                                    portal by raising a ticket or by email, and we will be more than happy to assist
                                    you.
                                </p>
                            </Panel>
                            <Panel header="How can I change my password?" key="7">
                                <p>You can change your password by going into <a
                                    className="faq__content--link" href={ROUTE_LIST.MyProfile} target="_blank">My
                                    Profile</a> on the User Menu at the top
                                    right corner of the platform. You can also contact us by raising a ticket or through
                                    our chatbot, where we will assist you in resetting or changing your password.
                                </p>
                                <p>
                                    If you forgot your password: click the 'Forgot Password?' link on the Real Estate IQ
                                    Login page. You will receive an email with a Code you need to fill in to change to a
                                    new password. Please check your INBOX and also make sure to check your SPAM folder
                                    to find the Code.
                                </p>
                            </Panel>
                            <Panel header="How do I cancel my subscription?" key="8">
                                <p>
                                    You can easily cancel your Real Estate IQ account from our Portal. Once you're
                                    logged in, just click on <a
                                    className="faq__content--link" href={ROUTE_LIST.MySubscription}
                                    target="_blank">My Subscription</a> at the top right corner and then on "Cancel
                                    my subscription". Make sure you do this a couple of days before your next billing so
                                    you won't be charged. And remember that if you have any doubts or concerns, you can
                                    always reach out to our Customer Success Team. They'll be happy to help!
                                </p>
                            </Panel>
                            <Panel header="What's the best way to learn how to use Real Estate IQ´s tools?" key="9">
                                <p>
                                    You can register to attend our free weekly <a
                                    className="faq__content--link" href="https://realestateiq.co/events/"
                                    target="_blank">webinars</a> and learn revolutionizing
                                    techniques to become an expert in finding great deals! We will share Real Estate
                                    IQ's most powerful tools for much easier and more accurate deal-finding.
                                </p>
                                <p>
                                    We also invite you to navigate our <a
                                    className="faq__content--link" href="/"
                                    target="_blank">blog</a> and download some the free <a
                                    className="faq__content--link" href="https://realestateiq.co/free-ebooks/"
                                    target="_blank">e-books</a> we
                                    created especially for investors. Here is where you will find tips, trends, and
                                    tools that will help you generate sales and turn yourself into a successful real
                                    estate investor.
                                </p>
                            </Panel>
                            <Panel header="Can I keep up-to-date with your news?" key="10">
                                <p>
                                    Sure! You can follow us on <a
                                    className="faq__content--link" href="https://www.instagram.com/realestateiqco/"
                                    target="_blank">social media</a> and subscribe to our newsletter to keep
                                    updated on our news. We'd love to have you on board!
                                </p>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
export default Index
