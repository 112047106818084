import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/ExclusiveContent';
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/exclusiveContentService';
import * as FunctionAction from '../actions/exclusiveContentAction';
// import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import TicketRequest from "../mapping/Request/TicketRequest";

function* actionGetList() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionExclusiveContentList, action?.filters);
        yield put(FunctionAction.exclusiveContentListSuccess(result));
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* exclusiveContentSaga() {
    yield all([
        fork(actionGetList),
    ])
}
