export const PROBATE_HEIRSHIP_MASTER_ACTION = "PROBATE_HEIRSHIP_MASTER_ACTION";
export const PROBATE_HEIRSHIP_MASTER_SUCCESS = "PROBATE_HEIRSHIP_MASTER_SUCCESS";

export const PROBATE_HEIRSHIP_LIST_ACTION = "PROBATE_HEIRSHIP_LIST_ACTION";
export const PROBATE_HEIRSHIP_LIST_SUCCESS = "PROBATE_HEIRSHIP_LIST_SUCCESS";

export const PROBATE_HEIRSHIP_DETAIL_ACTION = "PROBATE_HEIRSHIP_DETAIL_ACTION";
export const PROBATE_HEIRSHIP_DETAIL_SUCCESS = "PROBATE_HEIRSHIP_DETAIL_SUCCESS";

export const PROBATE_HEIRSHIP_UPDATE_ACTION = "PROBATE_HEIRSHIP_UPDATE_ACTION";
export const PROBATE_HEIRSHIP_UPDATE_SUCCESS = "PROBATE_HEIRSHIP_UPDATE_SUCCESS";

export const PROBATE_HEIRSHIP_DELETE_ACTION = "PROBATE_HEIRSHIP_DELETE_ACTION";
export const PROBATE_HEIRSHIP_DELETE_SUCCESS = "PROBATE_HEIRSHIP_DELETE_SUCCESS";

export const PROBATE_HEIRSHIP_UNLOCK_ACTION = "PROBATE_HEIRSHIP_UNLOCK_ACTION";
export const PROBATE_HEIRSHIP_UNLOCK_SUCCESS = "PROBATE_HEIRSHIP_UNLOCK_SUCCESS";

export const PROBATE_HEIRSHIP_FAVORITE_ACTION = "PROBATE_HEIRSHIP_FAVORITE_ACTION";
export const PROBATE_HEIRSHIP_FAVORITE_SUCCESS = "PROBATE_HEIRSHIP_FAVORITE_SUCCESS";

export const PROBATE_HEIRSHIP_FAVORITE_DELETE_ACTION = "PROBATE_HEIRSHIP_FAVORITE_DELETE_ACTION";
export const PROBATE_HEIRSHIP_FAVORITE_DELETE_SUCCESS = "PROBATE_HEIRSHIP_FAVORITE_DELETE_SUCCESS";

export const PROBATE_HEIRSHIP_FAVORITE_EXPORT_ACTION = "PROBATE_HEIRSHIP_FAVORITE_EXPORT_ACTION";
export const PROBATE_HEIRSHIP_FAVORITE_EXPORT_SUCCESS = "PROBATE_HEIRSHIP_FAVORITE_EXPORT_SUCCESS";

export const PROBATE_HEIRSHIP_INIT = "PROBATE_HEIRSHIP_INIT";
export const PROBATE_HEIRSHIP_FAIL = "PROBATE_HEIRSHIP_FAIL";
