import React, {useEffect, useState} from "react";
import MainLayout from "../../common/layout/MainLayout";
import {Card, Checkbox, Divider, Form, Popconfirm, Spin, Table} from "antd";
import {CloseCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {
    ActionPlansPricingMaster,
    ActionPlansPricingDetail,
    ActionPlansPricingDelete
} from "../../../data/hooks/plansPricing";
import {useSelector} from "react-redux";
import moment from "moment";
import {
    selectPlansPricingMaster,
    selectPlansPricingDetail,
    selectFetching
} from "../../../data/reselects/plansPricingSelector";
import {
    selectFetching as paymentIsFetching
} from "../../../data/reselects/packagePaymentSelector";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {ActionPackageCreateOrder} from "../../../data/hooks/packagePayment";

const Index = props => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [isCredit, setIsCredit] = useState(false),
        [totalSelectPrice, setTotalSelectPrice] = useState(0),
        [disabledCheckboxCredit, setDisabledCheckboxCredit] = useState({
            inputCheckbox: false,
            disabled: false
        }),
        [nextTime, setNextTime] = useState({
            month: "",
            quarterly: "",
            year: ""
        }),
        [selectedRowKeys, setSelectedRowKeys] = useState([]),
        actionPlansPricingMaster = ActionPlansPricingMaster(),
        actionPackageCreateOrder = ActionPackageCreateOrder(),
        actionPlansPricingDetail = ActionPlansPricingDetail(),
        actionPlansPricingDelete = ActionPlansPricingDelete(),
        itemFetching = useSelector(selectFetching()),
        itemFetchingPaymentCreateOrder = useSelector(paymentIsFetching()),
        itemPlansPricingMaster = useSelector(selectPlansPricingMaster()),
        itemPlansPricingDetail = useSelector(selectPlansPricingDetail());

    useEffect(() => {
        actionPlansPricingDetail();
        setNextTime({
            month: moment(new Date()).add(1, "month").format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT),
            quarterly: moment(new Date()).add(3, "month").format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT),
            year: moment(new Date()).add(1, "year").format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT)
        })
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);
    useEffect(() => {
        let checkbox = false,
            disabled = false;
        if (itemPlansPricingDetail?.credit === "$0") {
            setIsCredit(false)
            disabled = true
        } else if (itemPlansPricingDetail?.is_use_credit === 1) {
            setIsCredit(true)
            checkbox = true
            disabled = true
        } else {
            // setIsCredit(true)
            // checkbox = true
            disabled = false
        }

        setIsCredit(checkbox)

        setDisabledCheckboxCredit({
            inputCheckbox: checkbox,
            disabled: disabled
        })
    }, [itemPlansPricingDetail]);
    // useEffect(() => {
    //
    //     setDisabledCheckboxCredit({
    //         ...disabledCheckboxCredit,
    //         inputCheckbox: isCredit,
    //     })
    // }, [isCredit]);

    const onRemove = id => {
        actionPlansPricingDelete(id)
    }

    const confirmRemove = (e, id) => {
        onRemove(id)
    }

    const onSelectChange = (newSelectedRowKeys, items) => {
        setTotalSelectPrice(items.reduce((total, product) => {
            return total + product.price_raw;
        }, 0))
        console.log(newSelectedRowKeys.join(","))
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <MainLayout
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px pb-3">
                        <h4 className="pt-4 color--black fs--20px">My Cart
                        </h4>
                    </div>
            }
            disabledTab
        >
            <Spin spinning={isFetching}>
                <div className="container mt-4 faq max-width--1460px ms-0 p-x--40px">
                    <div className="row">
                        <div className="col-8">
                            <Card title={
                                <>
                                    <i className="fa fa-shopping-cart me-2 vertical--middle color--main"/>
                                    <span className="fs--20px fw-bold vertical--middle color--main">Cart</span>
                                    <p className="mb-0 mt-2 fs--14px">*Note: You can only buy and checkout City or
                                        County package if you already <br/>have an active of Metro or State subscription
                                        or a Metro or State package in your current cart.</p>
                                </>
                            }>
                                {
                                    (itemPlansPricingDetail?.monthly_package || []).map((i, k) => {
                                        return (
                                            <div className="row mb-2 align-items-center" key={k}>
                                                <div className="col-1 pe-0 text-center">
                                                    <Popconfirm
                                                        title="Are you sure to delete?"
                                                        onConfirm={(e) => confirmRemove(e, i?.id)}
                                                        // onCancel={() => {
                                                        // }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <CloseCircleOutlined className="fs--18px"/>
                                                    </Popconfirm>
                                                </div>
                                                <div className="col-7 ps-0 color--primary fw-bold">
                                                    {i?.name}
                                                </div>
                                                <div className="col-4 text-end color--black">
                                                    {i?.price}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    (itemPlansPricingDetail?.quarterly_package || []).map((i, k) => {
                                        return (
                                            <div className="row mb-2 align-items-center" key={k}>
                                                <div className="col-1 pe-0 text-center">
                                                    <Popconfirm
                                                        title="Are you sure to delete?"
                                                        onConfirm={(e) => confirmRemove(e, i?.id)}
                                                        // onCancel={() => {
                                                        // }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <CloseCircleOutlined className="fs--18px"/>
                                                    </Popconfirm>
                                                </div>
                                                <div className="col-7 ps-0 color--primary fw-bold">
                                                    {i?.name}
                                                </div>
                                                <div className="col-4 text-end color--black">
                                                    {i?.price}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    (itemPlansPricingDetail?.yearly_package || []).map((i, k) => {
                                        return (
                                            <div className="row mb-2 align-items-center" key={k}>
                                                <div className="col-1 pe-0 text-center">
                                                    <Popconfirm
                                                        title="Are you sure to delete?"
                                                        onConfirm={(e) => confirmRemove(e, i?.id)}
                                                        // onCancel={() => {
                                                        // }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <CloseCircleOutlined className="fs--18px"/>
                                                    </Popconfirm>
                                                </div>
                                                <div className="col-7 ps-0 color--primary fw-bold">
                                                    {i?.name}
                                                </div>
                                                <div className="col-4 text-end color--black">
                                                    {i?.price}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Card>

                            <div className="mt-4 bg-white p-3">
                                <p className="fs--25px">Change with your current subscriptions</p>
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={itemPlansPricingDetail?.current_subscription || []}
                                    pagination={false}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <Card title={<span className="fw-bold color--black">Cart Totals</span>}>
                                {
                                    itemPlansPricingDetail?.monthly_total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Monthly Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                {itemPlansPricingDetail?.monthly_total}
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                {
                                    itemPlansPricingDetail?.quarterly_total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Quarterly Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                {itemPlansPricingDetail?.quarterly_total}
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                {
                                    itemPlansPricingDetail?.yearly_total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Yearly Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                {itemPlansPricingDetail?.yearly_total}
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                {
                                    itemPlansPricingDetail?.discount_value && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Discount
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                {itemPlansPricingDetail?.discount_value}
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                {
                                    totalSelectPrice > 0 ? (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Exchange Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                -${totalSelectPrice}
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    ) : null
                                }
                                {
                                    itemPlansPricingDetail?.total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                {totalSelectPrice > 0 ? (itemPlansPricingDetail?.total_price - totalSelectPrice) < 0 ? "$0" : `$${parseFloat((itemPlansPricingDetail?.total_price - totalSelectPrice).toFixed(2))}` : itemPlansPricingDetail?.total}
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                <div className="row">
                                    <div className="col-6 color--black fw-bold">
                                        Use Credit: ({itemPlansPricingDetail?.credit})
                                    </div>
                                    <div className="col-6 color--black text-end">
                                        <Checkbox checked={isCredit}
                                                  disabled={disabledCheckboxCredit?.disabled}
                                                  onChange={e => setIsCredit(e?.target?.checked)}/>
                                    </div>
                                    <Divider className="mt-2"/>
                                </div>
                                <div className="row">
                                    <div className="col-12 color--black fw-bold">
                                        Recurring totals
                                    </div>
                                    <Divider className="mt-2"/>
                                </div>
                                {
                                    itemPlansPricingDetail?.recurring_monthly_total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Recurring Monthly Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                <p className="mb-0 color--black fs--15px">{itemPlansPricingDetail?.recurring_monthly_total}</p>
                                                <p className="mb-0 color--black fs--15px color--description">Next
                                                    renewal: {nextTime?.month}</p>
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                {
                                    itemPlansPricingDetail?.recurring_quarterly_total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Recurring Quarterly Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                <p className="mb-0 color--black fs--15px">{itemPlansPricingDetail?.recurring_quarterly_total}</p>
                                                <p className="mb-0 color--black fs--15px color--description">Next
                                                    renewal: {nextTime?.quarterly}</p>
                                            </div>
                                            <Divider className="mt-2"/>
                                        </div>
                                    )
                                }
                                {
                                    itemPlansPricingDetail?.recurring_yearly_total && (
                                        <div className="row">
                                            <div className="col-6 color--black">
                                                Recurring Yearly Total
                                            </div>
                                            <div className="col-6 color--black text-end">
                                                <p className="mb-0 color--black fs--15px">{itemPlansPricingDetail?.recurring_yearly_total}</p>
                                                <p className="mb-0 color--black fs--15px color--description">Next
                                                    renewal: {nextTime?.year}</p>
                                            </div>
                                        </div>
                                    )
                                }
                                <p className={`cursorPointer w-100 ${itemFetchingPaymentCreateOrder ? "bg--disabled" : "bg--primary"} text-center mt-3 color--white py-2 fw-bold border__radius--6px`}
                                   onClick={() => {
                                       if (!itemFetchingPaymentCreateOrder) {
                                           actionPackageCreateOrder({
                                               is_credit: isCredit,
                                               exchange_package_ids: selectedRowKeys.join(",")
                                           })
                                       }
                                   }}>
                                    Proceed to checkout
                                    {
                                        itemFetchingPaymentCreateOrder && (
                                            <LoadingOutlined className="cursorPointer ms-2"/>
                                        )
                                    }
                                </p>
                            </Card>
                        </div>
                    </div>
                </div>
            </Spin>
        </MainLayout>
    )
}

export default Index

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Price',
        dataIndex: 'price',
    },
];
