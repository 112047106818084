import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Func from "../../utils/functions";
import * as Types from '../types/Template';
import * as FunctionService from '../services/templateService';
import * as FunctionAction from '../actions/templateAction';
import TemplateRequest from "../mapping/Request/TemplateRequest";
import {message} from "antd";
import {templateReadNotify} from "../services/templateService";

export function* actionGetMaster() {
    yield takeLatest(Types.TEMPLATE_GENERAL_ACTION, Func.sagaWrapper(function* (action) {
        yield delay(500)
        const result = yield call(FunctionService.templateGeneral, action.params);
        yield put(FunctionAction.templateGeneralSuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

export function* actionGetMarketLeadFilters() {
    yield takeLatest(Types.TEMPLATE_MARKET_LEAD_FILTERS_ACTION, Func.sagaWrapper(function* (action) {
        yield delay(500)
        const result = yield call(FunctionService.templateMarketLeadFilters, action.params?.id);
        yield put(FunctionAction.templateMarketLeadFiltersSuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

export function* actionReadNotify() {
    yield takeLatest(Types.TEMPLATE_READ_NOTIFY_ACTION, Func.sagaWrapper(function* (action) {
        yield delay(500)
        const result = yield call(FunctionService.templateReadNotify, action.params?.id);
        yield put(FunctionAction.templateReadNotifySuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

export function* actionGetMarketLeadCreateFilters() {
    yield takeLatest(Types.TEMPLATE_MARKET_LEAD_CREATE_FILTERS_ACTION, Func.sagaWrapper(function* (action) {
        yield delay(500)
        const result = yield call(FunctionService.templateMarketLeadCreateFilters, new TemplateRequest(action.params).exportCreateFilters(), action.params?.lead);
        message.success(result)
        yield put(FunctionAction.templateMarketLeadCreateFiltersSuccess(result));
        yield put(FunctionAction.templateMarketLeadFiltersAction({id: action.params?.lead}));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

export function* actionGetMarketLeadDeleteFilters() {
    yield takeLatest(Types.TEMPLATE_MARKET_LEAD_DELETE_FILTERS_ACTION, Func.sagaWrapper(function* (action) {
        yield delay(500)
        const result = yield call(FunctionService.templateMarketLeadDeleteFilters, action.params);
        message.success(result)
        yield put(FunctionAction.templateMarketLeadDeleteFiltersSuccess(result));
        yield put(FunctionAction.templateMarketLeadFiltersAction({id: action.params?.id}));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* templateSaga() {
    yield all([
        fork(actionGetMaster),
        fork(actionGetMarketLeadFilters),
        fork(actionGetMarketLeadCreateFilters),
        fork(actionGetMarketLeadDeleteFilters),
        fork(actionReadNotify),
    ])
}
