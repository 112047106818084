export const INVOICE_MASTER_ACTION = "INVOICE_MASTER_ACTION";
export const INVOICE_MASTER_SUCCESS = "INVOICE_MASTER_SUCCESS";

export const INVOICE_LIST_ACTION = "INVOICE_LIST_ACTION";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";

export const INVOICE_DETAIL_ACTION = "INVOICE_DETAIL_ACTION";
export const INVOICE_DETAIL_SUCCESS = "INVOICE_DETAIL_SUCCESS";

export const INVOICE_UPDATE_ACTION = "INVOICE_UPDATE_ACTION";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";

export const INVOICE_CREATE_ACTION = "INVOICE_CREATE_ACTION";
export const INVOICE_CREATE_SUCCESS = "INVOICE_CREATE_SUCCESS";

export const INVOICE_EXPORT_ACTION = "INVOICE_EXPORT_ACTION";
export const INVOICE_EXPORT_SUCCESS = "INVOICE_EXPORT_SUCCESS";

export const INVOICE_INIT = "INVOICE_INIT";
export const INVOICE_FAIL = "INVOICE_FAIL";
