import {Divider} from "antd";

const CustomModalConfirm = props => {
    return (
        <div>
            <div className="text-center">
                <img src="/layout/icon/popup-success.svg"/>
                <h3 className="mt-2 color--black fw-bold">All Done!</h3>
            </div>
            <Divider/>
            <div className="text-center fs--20px color--black width-90 m-auto mb-2">
               <span className="fw-bold fs--20px color--black"> We’re sorry to see you go!</span> You will be contacted by your Account Manager to cancel your subscription as soon as possible.
            </div>
        </div>
    )
}

export default CustomModalConfirm
