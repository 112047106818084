import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/delinquentTaxAction'

export const ActionMasterDelinquentTax = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxMasterAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxListAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxExport = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxExportFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxDetailAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxUpdatelAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxDeleteAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxDownload = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxDownloadAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxUnlock = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxUnlockAction(params)),
        [dispatch]
    )
}

export const ActionDelinquentTaxInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxInitStore(params)),
        [dispatch]
    )
}


export const ActionAddFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxFavoriteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteFavorite = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.delinquentTaxDeleteFavoriteAction(params)),
        [dispatch]
    )
}
