import {Button, Checkbox, Form, Modal, Space} from "antd";
import {Icon} from "../index";
import React, {useEffect, useState} from "react";
import ModalListFilters from "./ModalListFilters";
import ModalSaveFilters from "./ModalSaveFilters";
import {useRouteMatch, useHistory} from "react-router-dom";
import {ActionLayoutMarketLeadFilters} from "../../../data/hooks/template";
import {useSelector} from "react-redux";
import {selectStatusAction, selectTemplateMarketLeadFilters} from "../../../data/reselects/templateSelector";
import {selectMasterDataLeadType} from "../../../data/reselects/dataSelector";

const ButtonFilterOffMarket = props => {
    const routeMatch = useRouteMatch(),
        routeHistory = useHistory(),
        [isOpenModal, setIsOpenModal] = useState(false),
        [modalSubmit, setModalSubmit] = useState(false),
        [limitDownloadText, setLimitDownloadText] = useState(""),
        [lead, setLead] = useState(""),
        [typeModal, setTypeModal] = useState(""),
        actionLayoutMarketLeadFilters = ActionLayoutMarketLeadFilters(),
        itemMarketLeadFilters = useSelector(selectTemplateMarketLeadFilters()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemDataMasterLeadType = useSelector(selectMasterDataLeadType());

    useEffect(() => {
        const itemsLead = ((routeMatch?.path)?.replace("/", ""));
        if (itemsLead) {
            setLead(itemsLead)
            actionLayoutMarketLeadFilters({id: itemsLead})
        }
    }, [itemDataMasterLeadType]);

    useEffect(() => {
        setLimitDownloadText(props?.items?.limit_download)
    }, [props?.items?.limit_download])

    return (
        <>
            <Modal
                // width={1300}
                title={typeModal === "SF" ? "Save Your Filters" : "List Filters"}
                destroyOnClose
                visible={isOpenModal}
                onOk={() => setIsOpenModal(false)}
                onCancel={() => setIsOpenModal(false)}
                footer={null}
            >
                {
                    typeModal === "SF" ? <ModalSaveFilters
                            isSubmit={e => setIsOpenModal(e)}
                            lead={lead}
                            routeMatch={routeHistory}
                            onClose={e => setIsOpenModal(false)}
                            master={itemMarketLeadFilters?.listFilters || []}/> :
                        <ModalListFilters
                            lead={lead}
                            routeMatch={routeHistory}
                            onClose={e => setIsOpenModal(false)}
                            master={itemMarketLeadFilters?.listFilters || []}/>
                }
            </Modal>
            <div className="row mb-2">
                <div className="col-12 col-lg-3 mt-0 p-x--30px">
                    {
                        !props?.disabledFilters ? (
                            <Space>
                                <Button type="primary" htmlType="button" className="height--42px p-x--20px"
                                        onClick={e => {
                                            setTypeModal("F");
                                            setIsOpenModal(true)
                                        }}>
                                    Filters
                                </Button>
                                <Button type="primary" htmlType="submit" className="height--42px p-x--20px"
                                        onClick={e => {
                                            setTypeModal("SF");
                                            setIsOpenModal(true)
                                        }}>
                                    Save Current Filters
                                </Button>
                            </Space>
                        ) : null
                    }
                </div>
                <div className="col-12 col-lg-9 mt-0 p-x--30px">
                    <div className="d-none d-lg-inline text-end">
                        {/*<div className="row">*/}
                        {
                            !props?.disabledExport ? (
                                <>
                                    <div className="float-end px-0 text-end">
                                        <Form.Item className="button-color--transparent mb-3 d-inline" label="">
                                            <Button type="primary"
                                                    htmlType="button"
                                                    className="bg--primary border-0 height--42px p-x--20px"
                                                    onClick={() => props.onExport()}>
                                                <img src="./layout/exit.svg" className="me-2 width-17px height--17px"
                                                     style={{marginTop: "-6px"}}/>Export XLSX{props.isExport &&
                                                <Icon style={{fontSize: "5px"}} type="sync-outlined" spin/>}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                    <div className="float-end me-2">
                                        <Form.Item className="button-color--transparent mb-3 d-inline" label="">
                                            <Button type="primary"
                                                    htmlType="button"
                                                    className="bg--primary border-0 height--42px p-x--20px"
                                                    onClick={() => props.onExportCSV()}>
                                                <img src="./layout/exit.svg" className="me-2 width-17px height--17px"
                                                     style={{marginTop: "-6px"}}/>Export CSV{props.isExport &&
                                                <Icon style={{fontSize: "5px"}} type="sync-outlined" spin/>}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </>
                            ) : null
                        }
                        <div className="float-end me-2">
                            <Form.Item className="button-color--transparent mb-3 d-inline" label="">
                                <Button type="primary" htmlType="submit" className="height--42px p-x--20px">
                                    Search
                                </Button>
                            </Form.Item>
                        </div>
                        {
                            !props?.disabledFavorite ? (
                                <div className="float-end text-end px-0 me-2">
                                    <Form.Item className="mb-3" name="is_favorite" valuePropName="checked">
                                        <Checkbox>Show only favorite</Checkbox>
                                    </Form.Item>
                                </div>
                            ) : null
                        }
                        <div className="float-end me-3 py-1 color--black fs--16px">
                            {limitDownloadText}
                        </div>
                        {/*</div>*/}
                    </div>
                    <div className="clearfix"/>
                    <div className="d-lg-none">
                        <Form.Item className="w-100">
                            <Form.Item className="mb-3" name="is_favorite" valuePropName="checked">
                                <Checkbox>Show only favorite</Checkbox>
                            </Form.Item>
                            <Button type="primary" htmlType="button" className="w-100 bg--primary border-0 mb-2"
                                    onClick={() => props.onExport()}>
                                <img src="./layout/exit.svg" className="me-2"
                                     style={{marginTop: "-4px"}}/> Export {props.isFetching &&
                                <Icon style={{fontSize: "5px"}} type="sync-outlined" spin/>}
                            </Button>
                            <Button type="primary" htmlType="submit" className="w-100">
                                Search
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ButtonFilterOffMarket
