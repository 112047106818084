export const PLANS_PRICING_LIST_ACTION = "PLANS_PRICING_LIST_ACTION";
export const PLANS_PRICING_LIST_SUCCESS = "PLANS_PRICING_LIST_SUCCESS";

export const PLANS_PRICING_MASTER_ACTION = "PLANS_PRICING_MASTER_ACTION";
export const PLANS_PRICING_MASTER_SUCCESS = "PLANS_PRICING_MASTER_SUCCESS";

export const PLANS_PRICING_CREATE_ACTION = "PLANS_PRICING_CREATE_ACTION";
export const PLANS_PRICING_CREATE_SUCCESS = "PLANS_PRICING_CREATE_SUCCESS";

export const PLANS_PRICING_UPDATE_ACTION = "PLANS_PRICING_UPDATE_ACTION";
export const PLANS_PRICING_UPDATE_SUCCESS = "PLANS_PRICING_UPDATE_SUCCESS";

export const PLANS_PRICING_DETAIL_ACTION = "PLANS_PRICING_DETAIL_ACTION";
export const PLANS_PRICING_DETAIL_SUCCESS = "PLANS_PRICING_DETAIL_SUCCESS";

export const PLANS_PRICING_DELETE_ACTION = "PLANS_PRICING_DELETE_ACTION";
export const PLANS_PRICING_DELETE_SUCCESS = "PLANS_PRICING_DELETE_SUCCESS";

export const PLANS_PRICING_INIT = "PLANS_PRICING_INIT";
export const PLANS_PRICING_FAIL = "PLANS_PRICING_FAIL";
