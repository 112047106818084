import Index from "./pages";
import Login from "./Login";
import Test from "./pages/test";
import PreForeClosure from "./pages/pre-foreclosure";
import TaxSale from "./pages/taxSale";
import ProbateHeirship from "./pages/probateHeirship";
import Heirship from "./pages/heirship";
import Eviction from "./pages/eviction";
import Divorce from "./pages/divorce";
import LoanModification from "./pages/loanModification";
import ApptOfSubTrustee from "./pages/apptOfSubTrustee";
import LisPendens from "./pages/lisPendens";
import NoticeOfDefault from "./pages/noticeOfDefault";
import Ticket from "./pages/ticket";
import MySubscription from "./pages/mySubscription";
import MySubscriptionDetail from "./pages/mySubscription/detail";
import PackageSubscription from "./pages/packageSubscription";
import TransactionSubscription from "./pages/transactionSubscription";
import PaymentResult from "./pages/payment/result";
import ContactUs from "./pages/contactUs";
import TicketDetail from "./pages/ticket/detail";
import MyProfile from "./pages/myProfile";
import ExclusiveContent from "./pages/exclusiveContent";
import Faq from "./pages/faq";
import PlansPricing from "./pages/plansPricing";
import MyPackages from "./pages/myPackages";
import Bill from "./pages/bill";
import Error403 from "./403";
import Error from "./Error";
import Authentication from "./pages/authentication";
import CodeViolation from "./pages/codeViolation";
import DelinquentTax from "./pages/delinquentTax";
// import Login from "../Login";

export default {
    Index,
    Login,
    Test,
    PreForeClosure,
    TaxSale,
    ProbateHeirship,
    Heirship,
    Divorce,
    Eviction,
    LoanModification,
    ApptOfSubTrustee,
    LisPendens,
    NoticeOfDefault,
    MySubscription,
    MySubscriptionDetail,
    PackageSubscription,
    TransactionSubscription,
    PaymentResult,
    ContactUs,
    TicketDetail,
    Ticket,
    MyProfile,
    Faq,
    Error403,
    Error,
    ExclusiveContent,
    PlansPricing,
    MyPackages,
    Bill,
    Authentication,
    CodeViolation,
    DelinquentTax,
    // Login,
};
