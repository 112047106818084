import {Badge, Checkbox, Select} from "antd";
import React from "react";
import {CaretDownOutlined, CloseOutlined} from "@ant-design/icons";
import {omit} from "lodash";

const {Option} = Select;

const SelectCustomStyle = props => {
    const [params, setParams] = React.useState({});
    React.useEffect(() => {
        setParams({
            ...params,
            ...omit(props, ['customOptions'])
        })
    }, [props]);

    const onClear = val => {
        props.form.setFieldsValue({
            [props.id]: []
        })
        props.customOptions.setParamsCustomState([])
    }

    return (
        <div className="select-custom-style" id="custom-selector-antd">
            <Select
                getPopupContainer={() => document.getElementById("custom-selector-antd")}
                {
                    ...params
                }
            >
                {
                    params.children
                }
            </Select>
            <CaretDownOutlined className="select-custom-style--icon-arrow"/>
            {
                ((props?.value || []).length > 0) && (
                    <>
                        <CloseOutlined className="select-custom-style--icon-clear cursorPointer"
                                       onClick={() => onClear()}/>
                        <Badge className="select-custom-style--icon-count"
                               count={(props?.value || []).length > 0 ? (props?.value || []).length : 0}/>
                    </>
                )
            }
        </div>
    )
}

export default SelectCustomStyle
