import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/templateAction'
import {templateReadNotifyAction} from "../actions/templateAction";

export const ActionLayoutGeneral = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateGeneralAction(params)),
        [dispatch]
    )
}

export const ActionLayoutMarketLeadFilters = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateMarketLeadFiltersAction(params)),
        [dispatch]
    )
}

export const ActionLayoutMarketLeadCreateFilters = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateMarketLeadCreateFiltersAction(params)),
        [dispatch]
    )
}

export const ActionLayoutMarketLeadDeleteFilters = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateMarketLeadDeleteFiltersAction(params)),
        [dispatch]
    )
}

export const ActionLayoutReadNotify = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateReadNotifyAction(params)),
        [dispatch]
    )
}
