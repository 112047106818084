import React, {useEffect, useState} from "react";
import MainLayout from "../../common/layout/MainLayout";
import {Divider, Form, Input, Modal, Popconfirm, Radio, Select, Space, Spin} from "antd";
import {
    ActionPlansPricingMaster,
    ActionPlansPricingUpdate,
    ActionPlansPricingDetail
} from "../../../data/hooks/plansPricing"
import {selectPlansPricingMaster, selectFetching} from "../../../data/reselects/plansPricingSelector"
import {useSelector} from "react-redux";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {debounce} from "lodash";
import {IoIosArrowRoundForward, IoIosInformationCircleOutline} from "react-icons/io";
import {GoArrowUpRight} from "react-icons/go";
import ModalSaveFilters from "../../common/components/ModalSaveFilters";
import ModalListFilters from "../../common/components/ModalListFilters";
import {ActionTicketCreate} from "../../../data/hooks/ticket";

const PlansPricing = props => {
    const [form] = Form.useForm(),
        [dimensions, setDimensions] = React.useState({
            height: window.innerHeight,
            width: window.innerWidth
        }),
        [isFetching, setIsFetching] = useState(false),
        [isOpenPolicy, setIsOpenPolicy] = useState(false),
        [tabStateActive, setTabStateActive] = useState(),
        [disabledSubmit, setDisabledSubmit] = useState({
            city: true,
            metro: true,
            state: true,
            county: true,
        }),
        [cityDescription, setCityDescription] = useState({checked: [], unchecked: []}),
        [metroDescription, setMetroDescription] = useState({checked: [], unchecked: []}),
        [stateDescription, setStateDescription] = useState({checked: [], unchecked: []}),
        [countyDescription, setCountyDescription] = useState({checked: [], unchecked: []}),
        actionPlansPricingUpdate = ActionPlansPricingUpdate(),
        actionPlansPricingMaster = ActionPlansPricingMaster(),
        itemFetching = useSelector(selectFetching()),
        itemPlansPricingMaster = useSelector(selectPlansPricingMaster()),
        actionTicketCreate = ActionTicketCreate();

    useEffect(() => {
        const debouncedHandleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    useEffect(() => {
        actionPlansPricingMaster();
        form.setFieldsValue({
            time: "quarterly",
            level: "standard"
        })
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        form.resetFields(['select_city', 'select_metro', 'select_state']);
        if (Object?.keys(itemPlansPricingMaster).length > 0) {
            setDisabledSubmit({
                city: true,
                county: true,
                metro: true,
                state: false,
            })
            form.setFieldsValue({
                state: Object?.keys(itemPlansPricingMaster?.state || {})[0],
                code: itemPlansPricingMaster?.state[Object?.keys(itemPlansPricingMaster?.state || {})[0]].code,
                select_state: [(itemPlansPricingMaster?.state[Object?.keys(itemPlansPricingMaster?.state || {})[0] || ""]?.state || [])[0]?.value],
                package_id: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.id
            })
            setTabStateActive(Object?.keys(itemPlansPricingMaster?.state || {})[0])
            setCityDescription({
                checked: itemPlansPricingMaster?.packages[(`CITY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`CITY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || []
            })
            setMetroDescription({
                checked: itemPlansPricingMaster?.packages[(`METRO_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`METRO_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
            setStateDescription({
                checked: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
            setCountyDescription({
                checked: itemPlansPricingMaster?.packages[(`COUNTY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`COUNTY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
        }
    }, [itemPlansPricingMaster]);

    const onSearch = val => {
        // console.log("%c ====================== SUBMIT ======================", "color: yellow", val)
        actionPlansPricingUpdate(val)
    }

    const changeValueField = res => {
        if (res?.time) {
            setCityDescription({
                checked: itemPlansPricingMaster?.packages[(`CITY_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`CITY_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || []
            })
            setMetroDescription({
                checked: itemPlansPricingMaster?.packages[(`METRO_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`METRO_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
            setStateDescription({
                checked: itemPlansPricingMaster?.packages[(`STATE_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`STATE_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
            setCountyDescription({
                checked: itemPlansPricingMaster?.packages[(`COUNTY_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`COUNTY_${res?.time || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
        } else if (res?.level) {
            setCityDescription({
                checked: itemPlansPricingMaster?.packages[(`CITY_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`CITY_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.unchecked || []
            })
            setMetroDescription({
                checked: itemPlansPricingMaster?.packages[(`METRO_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`METRO_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
            setStateDescription({
                checked: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
            setCountyDescription({
                checked: itemPlansPricingMaster?.packages[(`COUNTY_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.checked || [],
                unchecked: itemPlansPricingMaster?.packages[(`COUNTY_${form.getFieldValue("time") || "" || ""}_${res?.level || ""}`).toUpperCase()]?.description?.unchecked || [],
            })
        } else if (res?.select_city) {
            if ((res?.select_city || []).length > 0) {
                setDisabledSubmit({
                    city: false,
                    metro: true,
                    state: true,
                    county: true,
                })
                form.setFieldsValue({
                    select_metro: [],
                    select_state: [],
                    select_county: []
                })
            } else {
                setDisabledSubmit({
                    city: true,
                    metro: true,
                    state: true,
                    county: true,
                })
            }
        } else if (res?.select_metro) {
            if ((res?.select_metro || []).length > 0) {
                setDisabledSubmit({
                    city: true,
                    metro: false,
                    state: true,
                    county: true,
                })
                form.setFieldsValue({
                    select_city: [],
                    select_state: [],
                    select_county: []
                })
            } else {
                setDisabledSubmit({
                    city: true,
                    metro: true,
                    state: true,
                    county: true,
                })
            }
        } else if (res?.select_state) {
            if ((res?.select_state || []).length > 0) {
                setDisabledSubmit({
                    city: true,
                    county: true,
                    metro: true,
                    state: false,
                })
                form.setFieldsValue({
                    select_city: [],
                    select_metro: [],
                    select_county: []
                })
            } else {
                setDisabledSubmit({
                    city: true,
                    metro: true,
                    state: true,
                    county: true,
                })
            }
        } else if (res?.select_county) {
            if ((res?.select_county || []).length > 0) {
                setDisabledSubmit({
                    city: true,
                    metro: true,
                    state: true,
                    county: false,
                })
                form.setFieldsValue({
                    select_city: [],
                    select_metro: [],
                    select_state: []
                })
            } else {
                setDisabledSubmit({
                    city: true,
                    metro: true,
                    state: true,
                    county: true,
                })
            }
        }
    }

    const onChangeState = val => {
        form.resetFields(['select_city', 'select_metro', 'select_county']);
        form.setFieldsValue({
            // state: [val.value],
            select_city: [],
            select_metro: [],
            select_county: [],
            code: itemPlansPricingMaster?.state[val].code,
            select_state: [(itemPlansPricingMaster?.state[val || ""]?.state || [])[0]?.value],
            package_id: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.id
        });
        setDisabledSubmit({
            city: true,
            county: true,
            metro: true,
            state: false,
        })
        setTabStateActive(val);
    }

    return (
        <MainLayout
            isCustomTitle
            title={
                () =>
                    <div className="overflow-hidden p-x--20px pb-3">
                        <h4 className="pt-4 color--black fs--20px">Plans & Pricing
                        </h4>
                    </div>
            }
            disabledTab
        >
            <Modal
                width={690}
                title={null}
                destroyOnClose
                visible={isOpenPolicy}
                onOk={() => setIsOpenPolicy(false)}
                onCancel={() => setIsOpenPolicy(false)}
                footer={null}
            >
                <p className="fs--36px text-center mb-5 color--black">90 Day Guarantee</p>
                <p className="fs--20px mb-3 color--black">Thank you for choosing Real Estate IQ</p>
                <div style={{
                    position: "relative"
                }}>
                    <div style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "80px",
                        background: "linear-gradient(transparent, rgba(255, 255, 255, 0.9))",
                        zIndex: 5
                    }}/>
                    <p className="color--black fs--16px" style={{
                        maxHeight: "380px",
                        overflowY: "scroll",
                        paddingBottom: "50px"
                    }}>
                        Our 90-day guarantee is designed to ensure your success in finding deal opportunities in the
                        real
                        estate market. By subscribing to our Off Market Leads service, you agree to the following terms
                        and
                        conditions:<br/><br/>

                        <span className="fw-bold">Attendance Requirement:</span> To qualify for the 90-day guarantee,
                        you
                        must actively engage in our program by attending Friday Open House sessions and participating in
                        weekly coaching sessions. Your commitment to attending these events is crucial for the success
                        of
                        our campaign and your journey toward achieving your goals.<br/><br/>

                        <span className="fw-bold">Survey and Follow-up Session:</span> Upon attending the Friday Open
                        House
                        sessions, you are required to complete a survey provided as part of the campaign. Additionally,
                        you
                        must book a follow-up session to discuss your progress and strategies moving forward.<br/><br/>

                        <span
                            className="fw-bold">8-Week Challenge:</span> While participants are encouraged to work
                        independently to achieve their goals, if there are no noticeable results within the first two
                        months, you are required to seek assistance from our team of coaches. We offer an 8-week
                        challenge
                        to provide personalized guidance and support. If, after this assistance, there are still no
                        results,
                        we guarantee a refund.<br/><br/>

                        To qualify for a refund after the 90-day period, you must have completed all the previous steps
                        outlined in this policy.<br/><br/>

                        We are committed to your success and look forward to supporting you on your journey in the real
                        estate market.
                    </p>
                </div>
                <div className="text-center m-auto mt-5"
                     style={{paddingRight: "98px", paddingLeft: "98px", width: "90%"}}>
                    <div
                        onClick={() => {
                            props.history.push("/ticket")
                        }}
                        className="color--primary bg--white me-3 float-start cursorPointer"
                        style={{
                            border: "1px solid #00B7E3",
                            borderRadius: "3px",
                            width: "179px",
                            paddingTop: "10px",
                            height: "45px",
                            paddingBottom: "10px",
                            fontSize: "16px",
                            marginLeft: "30px"
                        }}>Got any questions?
                    </div>
                    <div
                        onClick={() => setIsOpenPolicy(false)}
                        className="bg--primary color--white border-0 float-end cursorPointer"
                        style={{
                            width: "121px",
                            height: "45px",
                            borderRadius: "3px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            fontSize: "16px",
                            marginRight: "30px"
                        }}
                    >Got it
                    </div>
                    <div className="clearfix"/>
                    <p className="fs--11px color--gray mt-2">By subscribing, you agree to our <span
                        className="text-decoration-underline cursorPointer"
                        onClick={() => window.open("https://realestateiq.co/terms-and-conditions/", '_blank')}>terms of use</span> and <span
                        className="text-decoration-underline cursorPointer"
                        onClick={() => window.open("https://realestateiq.co/privacy-policy/", '_blank')}>privacy policy.</span>
                    </p>
                </div>
            </Modal>
            <Spin spinning={isFetching}>
                <Form
                    form={form}
                    onFinish={onSearch}
                    layout="vertical"
                    onValuesChange={(res) => changeValueField(res)}
                >
                    <Form.Item label="" name="state" className="d-none">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="" name="code" className="d-none">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="" name="package_id" className="d-none">
                        <Input/>
                    </Form.Item>
                    <div className="">
                        {/*<div className="plans-pricing__tabs-state">*/}
                        {/*    <div className="">*/}
                        {/*        <div className="clearfix">*/}
                        {/*            {*/}
                        {/*                Object?.keys(itemPlansPricingMaster?.state || {}).map((i, k) => {*/}
                        {/*                    return (*/}
                        {/*                        <div*/}
                        {/*                            className={`d-inline-block width-300px text-uppercase fs--20px fw-bold text-center cursorPointer ${(tabStateActive === i) ? "color--primary" : ""}`}*/}
                        {/*                            key={k}*/}
                        {/*                            onClick={() => {*/}
                        {/*                                form.resetFields(['select_city', 'select_metro', 'select_state', 'select_county']);*/}
                        {/*                                form.setFieldsValue({*/}
                        {/*                                    state: i,*/}
                        {/*                                    code: itemPlansPricingMaster?.state[i].code*/}
                        {/*                                });*/}
                        {/*                                setTabStateActive(i);*/}
                        {/*                            }}*/}
                        {/*                        >*/}
                        {/*                            {i}*/}
                        {/*                        </div>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="clearfix"/>*/}
                        {/*</div>*/}
                        {/*<Divider className="my-0 mb-3"/>*/}
                        <div className="container">
                            <div className="row py-3">
                                <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                                    {
                                        Object?.keys(itemPlansPricingMaster?.state || {}).length > 0 && (
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="Please Select State"
                                                onChange={e => onChangeState(e)}
                                                defaultValue={Object?.keys(itemPlansPricingMaster?.state || {})[0]}
                                            >
                                                {
                                                    Object?.keys(itemPlansPricingMaster?.state || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{i}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )
                                    }
                                </div>
                                <div className="col-12 col-lg-9">
                                    <div className="plans-pricing__segment text-end">
                                        <Space className="text-center">
                                            <Form.Item label="" name="time" className="">
                                                <Radio.Group buttonStyle="solid">
                                                    <Radio.Button value="monthly">Monthly</Radio.Button>
                                                    <Radio.Button value="quarterly">Quarterly</Radio.Button>
                                                    <Radio.Button value="yearly">
                                                        Yearly
                                                    </Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item label="" name="level" className="">
                                                <Radio.Group buttonStyle="solid">
                                                    <Radio.Button value="standard">Standard</Radio.Button>
                                                    <Radio.Button value="premium">
                                                        Premium
                                                    </Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="plans-pricing__packages">
                            <div className="container">
                                <div className="row max-width--865px m-auto">
                                    {/*<div className="col-12 mb-3">*/}
                                    {/*    <p className="fs--14px">*Note: You can only buy and checkout City or County*/}
                                    {/*        package if you already*/}
                                    {/*        have an active of Metro or State subscription or a Metro or State package in*/}
                                    {/*        your current cart.</p>*/}
                                    {/*</div>*/}
                                    {/*  ===================== METRO & STATE ===================== */}
                                    <div
                                        className={`mb-3 col-sm-12 max-width--450px col-lg-6`}>
                                        <div
                                            className={`plans-pricing__packages--item ${(form.getFieldValue("level") || "").toLowerCase() === "premium" ? "plans-pricing__packages--item-premium" : ""}`}>
                                            <div className="plans-pricing__packages--title">
                                                <div className="clearfix">
                                                    <div
                                                        className="plans-pricing__packages--title-content vertical--middle">
                                                        <p className="mb-0 fs--28px fw-bold color--black">Metro</p>
                                                        {/*<p className="mb-0">Mót Popular</p>*/}
                                                    </div>
                                                    <div
                                                        className="plans-pricing__packages--title-price">
                                                        <span className="fw-bold fs--56px color--black">
                                                                ${(Object?.keys(itemPlansPricingMaster?.packages || {}).length > 0) && itemPlansPricingMaster?.packages[(`METRO_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.price}
                                                            +</span>/<span className="fs--20px color--black">
                                                            {((form.getFieldValue("time") || "").toLowerCase()).substr(0, ((form.getFieldValue("time") || "").toLowerCase()).length - 2)}
                                                        </span>
                                                    </div>
                                                    <p className="my-2 fs--18px color--black">Unlock local
                                                        opportunities: start investing in real estate right away</p>
                                                </div>
                                            </div>
                                            <div className="plans-pricing__packages--select mt-3">
                                                <Form.Item label="" name="select_metro">
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{width: '100%'}}
                                                        placeholder="Please Select Metro"
                                                        // defaultValue={['a10', 'c12']}
                                                        // onChange={handleChange}
                                                    >
                                                        {
                                                            tabStateActive && (itemPlansPricingMaster?.state[tabStateActive || ""]?.metro || [])?.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.value}>{i?.title}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <p className={`plans-pricing__packages--submit ${disabledSubmit?.metro ? "bg--disabled color--description" : ""}`}
                                               onClick={() => {
                                                   if (!disabledSubmit?.metro) {
                                                       form.resetFields(['select_city', "select_state"])
                                                       form.setFieldsValue({package_id: itemPlansPricingMaster?.packages[(`METRO_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.id})
                                                       form.submit();
                                                   }
                                               }}>Add to cart</p>
                                            <Divider className="my-4" style={{
                                                borderColor: "#C4C4C4"
                                            }}/>
                                            <div className="mb-3">
                                                <p className="fw-bold fs--18px color--black mb-4">Includes</p>
                                                <ul className="list-style-none">
                                                    {
                                                        metroDescription?.checked?.map((i, k) => {
                                                            return (
                                                                <li className="clearfix mb-2" key={k}>
                                                                    <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--primary"/>
                                                                    <p className="float-start color--black fs--18px vertical--middle width-80">
                                                                        {i}
                                                                    </p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        metroDescription?.unchecked?.map((i, k) => {
                                                            return (
                                                                <li className="clearfix mb-2" key={k}>
                                                                    <i className="float-start fa fa-window-close fs--23px vertical--middle me-2 color--disabled"/>
                                                                    <p className="float-start fs--18px vertical--middle width-80 color--disabled">
                                                                        {i}
                                                                    </p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`mb-3 col-sm-12 max-width--450px col-lg-6`}>
                                        <div
                                            className={`plans-pricing__packages--item ${(form.getFieldValue("level") || "").toLowerCase() === "premium" ? "plans-pricing__packages--item-premium" : ""}`}>
                                            <div className="plans-pricing__packages--title">
                                                <div className="clearfix">
                                                    <div
                                                        className="plans-pricing__packages--title-content vertical--middle">
                                                        <p className="mb-0 fs--28px fw-bold color--black">State</p>
                                                        {/*<p className="mb-0">Mót Popular</p>*/}
                                                    </div>
                                                    <div
                                                        className="plans-pricing__packages--title-price">
                                                        <span className="fw-bold fs--56px color--black">
                                                                ${(Object?.keys(itemPlansPricingMaster?.packages || {}).length > 0) && itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.price}
                                                            +</span>/<span className="fs--20px color--black">
                                                            {((form.getFieldValue("time") || "").toLowerCase()).substr(0, ((form.getFieldValue("time") || "").toLowerCase()).length - 2)}
                                                        </span>
                                                    </div>
                                                    <p className="my-2 fs--18px color--black">Expand your reach: gain
                                                        access to hot preforeclosure leads statewide</p>
                                                </div>
                                            </div>
                                            <div className="plans-pricing__packages--select mt-3">
                                                <Form.Item label="" name="select_state">
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{width: '100%'}}
                                                        placeholder="Please Select State"
                                                        // defaultValue={['a10', 'c12']}
                                                        // onChange={handleChange}
                                                    >
                                                        {
                                                            tabStateActive && (itemPlansPricingMaster?.state[tabStateActive || ""]?.state || [])?.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.value}>{i?.title}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <p className={`plans-pricing__packages--submit ${disabledSubmit?.state ? "bg--disabled color--description" : ""}`}
                                               onClick={() => {
                                                   if (!disabledSubmit?.state) {
                                                       form.resetFields(['select_city', "select_metro"]);
                                                       form.setFieldsValue({package_id: itemPlansPricingMaster?.packages[(`STATE_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.id})
                                                       form.submit();
                                                   }
                                               }}>Add to cart</p>
                                            <Divider className="my-4" style={{
                                                borderColor: "#C4C4C4"
                                            }}/>
                                            <div className="mb-3">
                                                <p className="fw-bold fs--18px color--black mb-4">Includes</p>
                                                <ul className="list-style-none">
                                                    {
                                                        stateDescription?.checked?.map((i, k) => {
                                                            return (
                                                                <li className="clearfix mb-2" key={k}>
                                                                    <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--primary"/>
                                                                    <p className="float-start color--black fs--18px vertical--middle width-80">
                                                                        {i}
                                                                    </p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        stateDescription?.unchecked?.map((i, k) => {
                                                            return (
                                                                <li className="clearfix mb-2" key={k}>
                                                                    <i className="float-start fa fa-window-close fs--23px vertical--middle me-2 color--disabled"/>
                                                                    <p className="float-start fs--18px vertical--middle width-80 color--disabled">
                                                                        {i}
                                                                    </p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  ===================== CITY + COUNTY ===================== */}
                                    <div
                                        className={`mb-3 col-sm-12 max-width--450px col-lg-6`}>
                                        <div
                                            className={`plans-pricing__packages--item plans-pricing__packages--item-type2 ${(form.getFieldValue("level") || "").toLowerCase() === "premium" ? "plans-pricing__packages--item-premium" : ""}`}>
                                            <div className="plans-pricing__packages--title">
                                                <div className="clearfix">
                                                    <div
                                                        className="plans-pricing__packages--title-content vertical--middle">
                                                        <p className="mb-0 fs--28px fw-bold color--black">City Add
                                                            on</p>
                                                    </div>
                                                    <div
                                                        className="plans-pricing__packages--title-price">
                                                        <span className="fw-bold fs--56px color--black">
                                                                ${(Object?.keys(itemPlansPricingMaster?.packages || {}).length > 0) && itemPlansPricingMaster?.packages[(`CITY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.price}
                                                            +</span>/<span className="fs--20px color--black">
                                                            {((form.getFieldValue("time") || "").toLowerCase()).substr(0, ((form.getFieldValue("time") || "").toLowerCase()).length - 2)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="plans-pricing__packages--select mt-3">
                                                <Form.Item label="" name="select_city">
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{width: '100%'}}
                                                        placeholder="Please Select City"
                                                        // defaultValue={['a10', 'c12']}
                                                        // onChange={handleChange}
                                                    >
                                                        {
                                                            tabStateActive && (itemPlansPricingMaster?.state[tabStateActive || ""]?.city || [])?.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.value}>{i?.title}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <p className="my-2 fs--16px color--gray">*Requires active subscription or
                                                Metro/State package in current cart for checkout.</p>
                                            <p className={`mt-3 plans-pricing__packages--submit-type2 ${disabledSubmit?.city ? "bg--disabled-2 plans-pricing__packages--submit-type2-disabled" : ""}`}
                                               onClick={() => {
                                                   if (!disabledSubmit?.city) {
                                                       form.resetFields(['select_metro', "select_state"])
                                                       form.setFieldsValue({package_id: itemPlansPricingMaster?.packages[(`CITY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.id})
                                                       form.submit();
                                                   }
                                               }}>Add to cart</p>
                                        </div>
                                    </div>
                                    <div
                                        className={`mb-3 col-sm-12 max-width--450px col-lg-6`}>
                                        <div
                                            className={`plans-pricing__packages--item plans-pricing__packages--item-type2 ${(form.getFieldValue("level") || "").toLowerCase() === "premium" ? "plans-pricing__packages--item-premium" : ""}`}>
                                            <div className="plans-pricing__packages--title">
                                                <div className="clearfix">
                                                    <div
                                                        className="plans-pricing__packages--title-content vertical--middle">
                                                        <p className="mb-0 fs--28px fw-bold color--black">County Add
                                                            on</p>
                                                    </div>
                                                    <div
                                                        className="plans-pricing__packages--title-price">
                                                        <span className="fw-bold fs--56px color--black">
                                                                ${(Object?.keys(itemPlansPricingMaster?.packages || {}).length > 0) && itemPlansPricingMaster?.packages[(`COUNTY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.price}
                                                            +</span>/<span className="fs--20px color--black">
                                                            {((form.getFieldValue("time") || "").toLowerCase()).substr(0, ((form.getFieldValue("time") || "").toLowerCase()).length - 2)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="plans-pricing__packages--select mt-3">
                                                <Form.Item label="" name="select_county">
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{width: '100%'}}
                                                        placeholder="Please Select County"
                                                        // defaultValue={['a10', 'c12']}
                                                        // onChange={handleChange}
                                                    >
                                                        {
                                                            tabStateActive && (itemPlansPricingMaster?.state[tabStateActive || ""]?.county || [])?.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.value}>{i?.title}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <p className="my-2 fs--16px color--gray">*Requires active subscription or
                                                Metro/State package in current cart for checkout.</p>
                                            <p className={`mt-3 plans-pricing__packages--submit-type2 ${disabledSubmit?.county ? "bg--disabled-2 plans-pricing__packages--submit-type2-disabled" : ""}`}
                                               onClick={() => {
                                                   if (!disabledSubmit?.county) {
                                                       form.resetFields(['select_metro', "select_state", "select_city"])
                                                       form.setFieldsValue({package_id: itemPlansPricingMaster?.packages[(`COUNTY_${form.getFieldValue("time") || ""}_${form.getFieldValue("level") || ""}`).toUpperCase()]?.id})
                                                       form.submit();
                                                   }
                                               }}>Add to cart</p>
                                        </div>
                                    </div>

                                    {/*  ===================== Banner ===================== */}
                                    <div className="col-12 mb-3 position-relative">
                                        <img
                                            className="w-100 cursorPointer"
                                            src="./layout/banner/plans-pricing-banner.jpg"/>
                                        <div className="plans-pricing__packages--banner-content">
                                            <Popconfirm
                                                title={() => {
                                                    return (
                                                        <div>Ready to kickstart your Real Estate journey with our Free
                                                            Fresh Start Kit? <span className='fw-bold'>Confirm your request now!</span>
                                                        </div>
                                                    )
                                                }}
                                                onConfirm={() => actionTicketCreate({
                                                    "title": "Free Fresh Start Kit",
                                                    "type": "SUBSCRIPTION",
                                                    "message": "Free Fresh Start Kit"
                                                })}
                                                onCancel={() => {
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <img
                                                    className="cursorPointer" style={{width: "80%"}}
                                                    src="./layout/banner/free-start-kit.png"/>
                                            </Popconfirm>
                                            <p className="fs--18px fw-bold my-4 color--black">Are you new to Real
                                                Estate? Let us guide you through the process of getting started
                                                with:</p>
                                            <ul className="list-style-none">
                                                <li className="clearfix mb-4">
                                                    <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--main"/>
                                                    <p className="float-start color--black fs--18px vertical--middle width-80">
                                                        1 month of standard preforeclosure leads
                                                    </p>
                                                </li>
                                                <li className="clearfix mb-4">
                                                    <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--main"/>
                                                    <p className="float-start color--black fs--18px vertical--middle width-80">
                                                        Access to educational resources
                                                    </p>
                                                </li>
                                                <li className="clearfix mb-4">
                                                    <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--main"/>
                                                    <p className="float-start color--black fs--18px vertical--middle width-80">
                                                        Personalized consulting and coaching
                                                    </p>
                                                </li>
                                            </ul>
                                            <Popconfirm
                                                title={() => {
                                                    return (
                                                        <div>Ready to kickstart your Real Estate journey with our Free
                                                            Fresh Start Kit? <span className='fw-bold'>Confirm your request now!</span>
                                                        </div>
                                                    )
                                                }}
                                                onConfirm={() => actionTicketCreate({
                                                    "title": "Free Fresh Start Kit",
                                                    "type": "SUBSCRIPTION",
                                                    "message": "Free Fresh Start Kit"
                                                })}
                                                onCancel={() => {
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <p className="fs--18px color--black mt-5 cursorPointer hover__fw-bold--underline">REQUEST
                                                    NOW <IoIosArrowRoundForward size={30}/></p>
                                            </Popconfirm>
                                        </div>
                                    </div>

                                    {/*  ===================== Policy & Contact ===================== */}
                                    <div
                                        onClick={() => setIsOpenPolicy(true)}
                                        className={`mb-3 col-sm-12 width-410px me-4 col-lg-6 plans-pricing__policy height--270px`}>
                                        <div className="height--120px text-end position-relative">
                                            <div style={{
                                                cursor: "pointer",
                                                backgroundColor: "#FAE69C",
                                                width: "46px",
                                                height: "46px",
                                                borderRadius: "50%",
                                                padding: "8px",
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                            }}>
                                                <GoArrowUpRight size={30} className="color--black"/>
                                            </div>
                                        </div>
                                        <p className="fw-bold fs--28px">Read our 90 Day</p>
                                        <p className="fw-bold fs--56px">GUARANTEE</p>
                                    </div>
                                    <div
                                        className={`mb-3 col-sm-12 width-410px col-lg-6 plans-pricing__contact  height--270px`}
                                        onClick={() => {
                                            props.history.push("/ticket")
                                        }}>
                                        <div className="height--120px text-end position-relative">
                                            <div style={{
                                                cursor: "pointer",
                                                backgroundColor: "#FAE69C",
                                                width: "46px",
                                                height: "46px",
                                                borderRadius: "50%",
                                                padding: "8px",
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                            }}>
                                                <IoIosInformationCircleOutline size={30} className="color--black"/>
                                            </div>
                                        </div>
                                        <p className="fw-bold fs--28px">Got any questions?</p>
                                        <p className="fw-bold fs--56px">CONTACT US</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Spin>
        </MainLayout>
    )
}

export default PlansPricing
