import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class SubscriptionRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {

    }

}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        // month: filters?.month || "",
        // package_ids: filters?.packages || [],
        // order_type: filters?.orderType || "",
        // type: filters?.type || "",
        is_credit: formatInt(filters?.is_credit),
        exchange_package_ids: filters?.exchange_package_ids,
        redirect_url: filters?.redirect_url || `${process.env.REACT_APP_BASE_URL}/payment/result?type=s`,
        // redirect_url_error: filters?.redirect_url_error || `${process.env.REACT_APP_BASE_URL}/payment/result?type=f`
        // redirect_url: filters?.redirect_url || `http://localhost:3000/payment/result?type=s`,
        // redirect_url_error: filters?.redirect_url_error || `http://localhost:3000/payment/result?type=f`
    })
}
