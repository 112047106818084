import MainLayout from "../../common/layout/MainLayout";

// ============== LAYOUT ==============

// ============== ACTION ==============
import {
    ActionTicketComment,
    ActionTicketDetail,
    ActionTicketInitStore,
    ActionTicketList,
    ActionTicketCreate,
} from "../../../data/hooks/ticket"

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectTicketList,
    selectTicketDetail,
    selectStatusAction
} from "../../../data/reselects/ticketSelector"
import React, {useEffect, useRef, useState} from "react";
import {PAGINATION} from "../../../constants/define";
import {useSelector} from "react-redux";
import {TICKET} from "../../../data/mapping/Response/TicketResponse";
import {Avatar, Button, Comment, Spin, Table, Form, Input, message, Space, Typography, Popconfirm, Upload} from "antd";
import {ActionUpload, ActionUploadInitStore} from "../../../data/hooks/upload";
import {selectFilesUpload, selectFetching as selectFetchingUpload} from "../../../data/reselects/uploadSelector";
import {CloseOutlined, FileOutlined, LoadingOutlined, PaperClipOutlined} from "@ant-design/icons";

const columns = [
    {
        title: 'Created Ticket',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: 'Last Updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item, record) => <span className="me-3"
                                        style={{color: TICKET.STATUS_COLOR[item]}}>{record.status_explain}</span>
    },
];

const Detail = props => {

    const [isFetching, setIsFetching] = useState(false),
        [resultItem, setResultItem] = useState({}),
        [comments, setComments] = useState(""),
        [attachments, setAttachments] = useState([]),
        actionTicketDetail = ActionTicketDetail(),
        actionTicketInitStore = ActionTicketInitStore(),
        actionTicketComment = ActionTicketComment(),
        itemIsFetching = useSelector(selectFetching()),
        itemTicketDetail = useSelector(selectTicketDetail());

    useEffect(() => {
        actionTicketInitStore({clearDetail: true});
    }, []);

    useEffect(() => {

        return () => {
            setComments("");
            setAttachments([]);
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching);
    }, [itemIsFetching]);

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            actionTicketDetail({id: match.params?.id});
        }
    }, [props.match]);

    useEffect(() => {
        setResultItem(itemTicketDetail);
        setComments("")
    }, [itemTicketDetail]);

    const handleChange = e => {
        setComments(e.target.value);
    };

    const handleSubmit = e => {
        if (comments) {
            actionTicketComment({message: comments, id: resultItem?.id, attachments})
            setComments("");
        } else {
            message.error("Message is not empty")
        }
    };

    return (
        <MainLayout title={resultItem?.title} disabledTab breadcrumb={["My Ticket", resultItem?.title]}>
            <Spin spinning={isFetching}>
                {
                    Object.keys(resultItem).length > 0 && (
                        <>
                            <div className="mt-3 px-3 ticket__detail">
                                <div className="mb-4 w-50">
                                    <Table rowKey="key" dataSource={[resultItem]} columns={columns} pagination={false}/>
                                </div>
                                <div className="ticket__detail--content">
                                    {
                                        (resultItem?.messages || []).length > 0 && (
                                            resultItem?.messages.map((item, k) => {
                                                return (
                                                    <Comment
                                                        key={k}
                                                        // actions={actions}
                                                        className={`ticket__detail--item ${item?.user?.is_admin ? "ticket__detail--item-admin" : ""}`}
                                                        author={
                                                            <span
                                                                className={`fs--18px ${item?.user?.is_admin ? "color--red" : ""}`}>
                                                            {item?.user?.first_name} {item?.user?.last_name} {item?.user?.is_admin ? "(Admin)" : ""}
                                                        </span>
                                                        }
                                                        avatar={<Avatar className="ticket__detail--avatar"
                                                                        src={(item?.user?.first_name && item?.user?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=000000&color=ffffff&name=${item?.user?.first_name} ${item?.user?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}
                                                                        alt={`${item?.user?.first_name} ${item?.user?.last_name}`}/>}
                                                        content={
                                                            <>
                                                                <p className="mt-2">
                                                                    {item?.message}
                                                                </p>
                                                                <p className="fs--14px color--description mt-2">
                                                                    {item?.created_at}
                                                                </p>
                                                                {(item?.attachments || []).length > 0 ? (
                                                                    <div className="my-2 w-50">
                                                                        <Typography.Paragraph>
                                                                            <pre>
                                                                            {
                                                                                (item?.attachments || []).map((i, k) => {
                                                                                    return (
                                                                                        <div>
                                                                                            <Space>
                                                                                                <FileOutlined
                                                                                                    className="icon-ant"/>
                                                                                                <a href={i?.value} key={k}
                                                                                                   target="_blank">
                                                                                                    {/*<PaperClipOutlined className="cursorPointer"/>*/}
                                                                                                    {i?.title}
                                                                                                </a>
                                                                                            </Space>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </pre>
                                                                        </Typography.Paragraph>
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        }
                                                    />
                                                )
                                            })
                                        )
                                    }
                                    <AlwaysScrollToBottom/>
                                </div>
                            </div>
                            <div className="px-2 mt-4">
                                <p>Leave Message <span
                                    className="color--description fs--15px">(Maximum 2500 characters)</span></p>
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={isFetching}
                                    value={comments}
                                    setAttachments={e => setAttachments(e)}
                                />
                            </div>
                        </>
                    )
                }
            </Spin>
        </MainLayout>
    )
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const Editor = ({onChange, onSubmit, submitting, value, setAttachments}) => {
    const [inputVal, setInputVal] = useState(""),
        [fileList, setFileList] = useState([]),
        [attach, setAttach] = useState([]),
        [files, setFiles] = useState({}),
        [imageUrl, setImageUrl] = useState(),
        [loadingUpload, setLoadingUpload] = useState(false),
        actionUpload = ActionUpload(),
        actionUploadInitStore = ActionUploadInitStore(),
        itemFileUpload = useSelector(selectFilesUpload()),
        itemIsUpload = useSelector(selectFetchingUpload());

    useEffect(() => {
        setInputVal(value)
    }, [value]);

    useEffect(() => {
        return () => {
            setAttach([])
            actionUploadInitStore();
        }
    }, []);

    useEffect(() => {
        setInputVal(value)
    }, [value]);

    useEffect(() => {
        setLoadingUpload(itemIsUpload)
    }, [itemIsUpload]);

    useEffect(() => {
        // let newItems = [];
        // if (attach.length > 0) {
        //     attach.map(i => newItems.push(i?.value))
        // }
        setAttachments(attach)
    }, [attach]);

    useEffect(() => {
        setFiles(itemFileUpload)
        if (Object?.keys(itemFileUpload || {}).length > 0) {
            let newAttach = [...attach];
            newAttach.push({
                title: itemFileUpload?.file_name,
                value: itemFileUpload?.file_url
            });
            setAttach(newAttach)
        }
    }, [itemFileUpload]);

    const _onChange = (event) => {
        const caret = event.target.selectionStart
        const element = event.target
        window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
        })

        onChange(event);
    }

    const handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoadingUpload(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoadingUpload(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    return (
        <>
            <Form.Item className="mb-2">
                <Input.TextArea rows={5} onChange={_onChange} value={inputVal} maxLength={2500}/>
            </Form.Item>
            <div>
                {attach.length > 0 ? (
                    <div className="mb-2">
                        {
                            attach.map((i, k) => {
                                return (
                                    <div>
                                        <Space>
                                            <a href={i.value} key={k} target="_blank">
                                                {/*<PaperClipOutlined className="cursorPointer"/>*/}
                                                {i.title}
                                            </a>
                                            <Popconfirm
                                                title="Are you sure to delete this item?"
                                                onConfirm={() => {
                                                    let response = [...attach]
                                                    response = response.filter((i2, k) => i2?.title !== i?.title)
                                                    setAttach(response)
                                                }}
                                                onCancel={e => {
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <CloseOutlined className="icon-ant fs--13px"/>
                                            </Popconfirm>
                                        </Space>
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : null}
                <Upload
                    name="file"
                    listType="picture"
                    // className="avatar-uploader h-100 profile--upload upload-full-wrap"
                    showUploadList={false}
                    action={(files) => actionUpload({files})}
                    customRequest={() => {
                    }}
                    // beforeUpload={beforeUpload}
                    onChange={handleChangeImage}
                >
                    {loadingUpload ? <>
                        <LoadingOutlined/>
                    </> : <PaperClipOutlined className="cursorPointer"/>}
                </Upload>
            </div>
            <Form.Item className="text-end">
                <Button htmlType="submit" loading={submitting} onClick={e => {
                    onSubmit(e);
                    setAttach([])
                }} type="primary">
                    Send
                </Button>
            </Form.Item>
        </>
    )
};

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef}/>;
};

export default Detail
