import * as Types from '../types/Divorce';

export const divorceMasterAction = (filters) => ({type: Types.DIVORCE_MASTER_ACTION, filters});
export const divorceMasterSuccess = (master) => ({
    type: Types.DIVORCE_MASTER_SUCCESS,
    master
});

export const divorceListAction = (filters) => ({type: Types.DIVORCE_LIST_ACTION, filters});
export const divorceListSuccess = (divorce) => ({
    type: Types.DIVORCE_LIST_SUCCESS,
    divorce
});

export const divorceDetailAction = (params) => ({type: Types.DIVORCE_DETAIL_ACTION, params});
export const divorceDetailSuccess = (divorceDetail) => ({
    type: Types.DIVORCE_DETAIL_SUCCESS,
    divorceDetail
});

export const divorceUpdateAction = (params) => ({type: Types.DIVORCE_UPDATE_ACTION, params});
export const divorceUpdateSuccess = (dataUpdate) => ({
    type: Types.DIVORCE_UPDATE_SUCCESS,
    dataUpdate
});

export const divorceDeleteAction = (params) => ({type: Types.DIVORCE_DELETE_ACTION, params});
export const divorceDeleteSuccess = (dataDelete) => ({
    type: Types.DIVORCE_DELETE_SUCCESS,
    dataDelete
});

export const divorceUnlockAction = (params) => ({type: Types.DIVORCE_UNLOCK_ACTION, params});
export const divorceUnlockSuccess = (resultUnlock) => ({
    type: Types.DIVORCE_UNLOCK_SUCCESS,
    resultUnlock
});

export const divorceFavoriteAction = (params) => ({type: Types.DIVORCE_FAVORITE_ACTION, params});
export const divorceFavoriteSuccess = (favorite) => ({
    type: Types.DIVORCE_FAVORITE_SUCCESS,
    favorite
});

export const divorceDeleteFavoriteAction = (params) => ({type: Types.DIVORCE_FAVORITE_DELETE_ACTION, params});
export const divorceDeleteFavoriteSuccess = (favoriteDelete) => ({
    type: Types.DIVORCE_FAVORITE_DELETE_SUCCESS,
    favoriteDelete
});

export const divorceExportFavoriteAction = (filters) => ({type: Types.DIVORCE_FAVORITE_EXPORT_ACTION, filters});
export const divorceExportFavoriteSuccess = (resultExport) => ({
    type: Types.DIVORCE_FAVORITE_EXPORT_SUCCESS,
    resultExport
});

export const divorceInitStore = (params) => ({type: Types.DIVORCE_INIT, params});
export const divorceClearStore = (params) => ({type: Types.DIVORCE_FAIL, params});
