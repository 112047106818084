import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class TicketRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.type = data?.type || "";
        this.message = data?.message || "";
        this.attachments = data?.attachments || [];
    }

    exportCreate() {
        return {
            title: this.title,
            type: this.type,
            message: this.message,
        }
    }

    exportComment() {
        return {
            id: this.id,
            message: this.message,
            attachments: this.attachments,
        }
    }
}
