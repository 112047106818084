import {cloneDeep, findIndex} from "lodash";
import {useEffect, useState} from "react";
import {ActionSubscriptionListPackage} from "../../../../data/hooks/subscription";
import {useSelector} from "react-redux";
import {selectFetching, selectSubscriptionListPackage} from "../../../../data/reselects/subscriptionSelector";
import Packages from "./Packages";

const ChoosePackages = (props) => {

    const [getSelectedItems, setSetSelectedItems] = useState([]),
        [keySelected, setKeySelected] = useState([]),
        // ============== ACTION ==============
        actionSubscriptionListPackage = ActionSubscriptionListPackage(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemListPackage = useSelector(selectSubscriptionListPackage());

    useEffect(() => {
        props.selectItems(getSelectedItems)
    }, [getSelectedItems]);


    const generateData = () => {
        const offMarkets = [];
        const offMarketSelects = [];
        (itemListPackage?.off_markets || []).map(offMarket => {
            offMarkets.push(offMarket);
            if (offMarket.select) {
                offMarketSelects.push(offMarket.key);
            }
        });
        const states = [];
        const stateSelects = [];
        (itemListPackage?.states || []).map(offMarket => {
            if (offMarketSelects.indexOf(offMarket.key) != -1) {
                states.push(offMarket);
                offMarket.children.map(state => {
                    if (state.select) {
                        stateSelects.push(state.key);
                    }
                })
            }
        });
        const counties = [];
        const countySelects = [];
        const countyObjectSelects = [];
        const countyChecks = [];
        (itemListPackage?.counties || []).map(offMarket => {
            if (offMarketSelects.indexOf(offMarket.key) != -1) {
                const _offMarket = cloneDeep(offMarket);
                const _offMarketChecked = cloneDeep(offMarket);
                _offMarket.children = [];
                _offMarketChecked.children = [];
                let checkEmpty = true;
                let checkCheckedEmpty = true;
                offMarket.children.map(state => {
                    if (stateSelects.indexOf(state.key) != -1) {
                        checkEmpty = false;
                        _offMarket.children.push(state);
                        const _state = cloneDeep(state);
                        _state.children = [];
                        let checkStateEmpty = true;
                        state.children.map(county => {
                            if (county.select) {
                                checkStateEmpty = false;
                                _state.children.push(county);
                                countySelects.push(county.key);
                                countyObjectSelects.push(county);
                            }
                        })
                        if (!checkStateEmpty) {
                            _offMarketChecked.children.push(_state);
                            checkCheckedEmpty = false;
                        }
                    }
                })
                if (!checkEmpty) {
                    counties.push(_offMarket);
                }
                if (!checkCheckedEmpty) {
                    countyChecks.push(_offMarketChecked);
                }
            }
        });
        props.onRequestParams(countyObjectSelects);
        setSetSelectedItems([offMarkets, states, counties, countyChecks]);
        setKeySelected([offMarketSelects, stateSelects, countySelects])
    }

    const onCheckOffMarket = (key) => {
        (itemListPackage?.off_markets || []).map(leadTypeItem => {
            if (leadTypeItem.key == key) {
                leadTypeItem.select = !leadTypeItem.select;
                if (!leadTypeItem.select) {
                    onClearState(key);
                }
            }
        });
    }

    const onCheckState = (key) => {
        (itemListPackage?.states || []).map(leadTypeItem => {
            let isSelectAll = false;
            if (leadTypeItem.key == key) {
                isSelectAll = true;
            }
            let findSelectedItem = findIndex(leadTypeItem.children, {select: true})
            leadTypeItem.children.map(stateItem => {
                if (findSelectedItem != -1 && isSelectAll) {
                    stateItem.select = false;
                    onClearCounty(leadTypeItem.key, stateItem.key);
                } else if (findSelectedItem == -1 && isSelectAll) {
                    stateItem.select = true;
                } else {
                    if (stateItem.key == key) {
                        stateItem.select = !stateItem.select;
                        if (!stateItem.select) {
                            onClearCounty(leadTypeItem.key, stateItem.key);
                        }
                    } else {
                        stateItem.select = stateItem.select;
                    }
                }
            })
        });
    }

    const onCheckCounty = (key) => {
        (itemListPackage?.counties || []).map(leadTypeItem => {
            let isSelectAllLeadType = false;
            if (leadTypeItem.key == key) {
                isSelectAllLeadType = true;
            }
            let findSelectedStateItem = false;
            leadTypeItem.children.map(stateItem => {
                let findSelectedCountyItem = findIndex(stateItem.children, {select: true});
                if (findSelectedCountyItem != -1) {
                    findSelectedStateItem = true;
                }
            });
            leadTypeItem.children.map(stateItem => {
                let isSelectAllState = false;

                if (findSelectedStateItem && isSelectAllLeadType) {
                    isSelectAllState = false;
                } else if (!findSelectedStateItem && isSelectAllLeadType) {
                    isSelectAllState = true;
                } else {
                    if (stateItem.key == key) {
                        isSelectAllState = true;
                    }
                }
                let findSelectedCountyItem = findIndex(stateItem.children, {select: true})
                stateItem.children.map(countyItem => {
                    if (countyItem.items.is_subscribed) {
                    }else{
                        if (findSelectedStateItem && isSelectAllLeadType) {
                            countyItem.select = false;
                        } else if (!findSelectedStateItem && isSelectAllLeadType) {
                            countyItem.select = true;
                        } else if (findSelectedCountyItem != -1 && isSelectAllState) {
                            countyItem.select = false;
                        } else if (findSelectedCountyItem == -1 && isSelectAllState) {
                            countyItem.select = true;
                        } else {
                            if (countyItem.key == key) {
                                countyItem.select = !countyItem.select;
                            } else {
                                countyItem.select = countyItem.select;
                            }
                        }
                    }
                })
                stateItem.children.map(countyItem => {
                    if (countyItem.items.is_subscribed) {
                    }else{
                        if (countyItem.title == "ALL" && countyItem.select) {
                            onClearCounty(leadTypeItem.key, stateItem.key);
                            onSetDisableCounty(leadTypeItem.key, stateItem.key, true);
                            countyItem.select = true;
                            countyItem.disabled = false;
                        } else if (countyItem.title == "ALL" && !countyItem.select) {
                            onSetDisableCounty(leadTypeItem.key, stateItem.key, false);
                        }
                    }
                    
                });
            })
        });
    }

    const onClearState = (offMarketKey) => {
        (itemListPackage?.states || []).map(leadTypeItem => {
            if (leadTypeItem.key == offMarketKey) {
                leadTypeItem.children.map(stateItem => {
                    stateItem.select = false;
                    onClearCounty(offMarketKey, stateItem.key);
                })
            }
        });
    }

    const onClearCounty = (offMarketKey, stateKey) => {
        (itemListPackage?.counties || []).map(leadTypeItem => {
            if (leadTypeItem.key == offMarketKey) {
                leadTypeItem.children.map(stateItem => {
                    if (stateItem.key == stateKey) {
                        stateItem.children.map(countyItem => {
                            countyItem.select = false;
                        })
                    }
                })
            }
        });
    }

    const onSetDisableCounty = (offMarketKey, stateKey, isDisable) => {
        (itemListPackage?.counties || []).map(leadTypeItem => {
            if (leadTypeItem.key == offMarketKey) {
                leadTypeItem.children.map(stateItem => {
                    if (stateItem.key == stateKey) {
                        stateItem.children.map(countyItem => {
                            if (!isDisable && countyItem.items.is_subscribed) {

                            } else {
                                countyItem.disabled = isDisable;
                            }
                        })
                    }
                })
            }
        });
    }

    const onCheck = (checkedItems, event, parent) => {
        switch (parent) {
            case 0:
                onCheckOffMarket(event?.node?.key);
                break;
            case 1:
                onCheckState(event?.node?.key);
                break;
            case 2:
                onCheckCounty(event?.node?.key);
                break;
        }
        generateData();
    }

    return <div className="container px-0">
        <div className="row">
            <Packages
                parent={0}
                title="Off market"
                items={itemListPackage?.off_markets || []}
                onCheck={onCheck}
                selectedItems={keySelected[0]}
            />
            <Packages
                title="States"
                parent={1}
                items={getSelectedItems[1]}
                onCheck={onCheck}
                selectedItems={keySelected[1]}
            />
            <Packages
                title="Counties"
                parent={2}
                items={getSelectedItems[2]}
                onCheck={onCheck}
                selectedItems={keySelected[2]}
            />
        </div>
    </div>
}

export default ChoosePackages
