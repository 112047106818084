export default {
    LOGIN: '/api/v1/login',
    REGISTER: '/api/v1/register',
    LOGOUT: '/',
    PROFILE: '/api/v1/profile',
    REFRESH: 'api/admin/user/refresh',
    UPDATE_PROFILE: '/api/v1/profile/update',
    PASSWORD_REQUEST: '/api/v1/password/request',
    PASSWORD_RESET: '/api/v1/password/reset',
    CREDIT_HISTORY: '/api/v1/credit',

    HOTEL_LIST: '/api/v1/admin/hotel',
    HOTEL_ALL: '/api/v1/admin/hotel',
    HOTEL_MASTER: '/api/v1/admin/hotel/master',
    HOTEL_CREATE: '/api/v1/admin/hotel',
    HOTEL_DELETE: '/api/v1/admin/hotel/:id',
    HOTEL_UPDATE: '/api/v1/admin/hotel',
    HOTEL_DETAIL: '/api/v1/admin/hotel/:id',

    DATA_MASTER_LEAD_TYPE: '/api/v1/master/lead-type',
    DATA_MASTER_PRE_FORE_CLOSURE: '/api/v1/master/pre-foreclosure',
    DATA_PRE_FORE_CLOSURE_LIST: '/api/v1/pre-foreclosure',
    DATA_PRE_FORE_CLOSURE_DETAIL: '/api/v1/pre-foreclosure/:id',
    DATA_PRE_FORE_CLOSURE_UNLOCK: '/api/v1/pre-foreclosure/:id/:field',
    DATA_PRE_FORE_CLOSURE_UPDATE: '/api/v1/pre-foreclosure/:id',
    DATA_PRE_FORE_CLOSURE_DELETE: '/api/v1/pre-foreclosure/:id',
    DATA_PRE_FORE_CLOSURE_ADD_FAVORITE: '/api/v1/pre-foreclosure/favorite/:id',
    DATA_PRE_FORE_CLOSURE_DELETE_FAVORITE: '/api/v1/pre-foreclosure/favorite/:id',

    BILL_DETAIL: '/api/v1/billing-info',

    INVOICE_LIST: '/api/v1/subscription/invoice',
    INVOICE_EXPORT: '/api/v1/subscription/invoice/:id/export',

    TAX_SALE_MASTER: '/api/v1/master/tax-sale',
    TAX_SALE_LIST: '/api/v1/tax-sale',
    TAX_SALE_DETAIL: '/api/v1/tax-sale/:id',
    TAX_SALE_UNLOCK: '/api/v1/tax-sale/:id/:field',
    TAX_SALE_UPDATE: '/api/v1/tax-sale/:id',
    TAX_SALE_DELETE: '/api/v1/tax-sale/:id',
    TAX_SALE_ADD_FAVORITE: '/api/v1/tax-sale/favorite/:id',
    TAX_SALE_DELETE_FAVORITE: '/api/v1/tax-sale/favorite/:id',

    PROBATE_HEIRSHIP_MASTER: '/api/v1/master/probates',
    PROBATE_HEIRSHIP_LIST: '/api/v1/probates',
    PROBATE_HEIRSHIP_DETAIL: '/api/v1/probates/:id',
    PROBATE_HEIRSHIP_UNLOCK: '/api/v1/probates/:id/:field',
    PROBATE_HEIRSHIP_UPDATE: '/api/v1/probates/:id',
    PROBATE_HEIRSHIP_DELETE: '/api/v1/probates/:id',
    PROBATE_HEIRSHIP_ADD_FAVORITE: '/api/v1/probates/favorite/:id',
    PROBATE_HEIRSHIP_DELETE_FAVORITE: '/api/v1/probates/favorite/:id',

    HEIRSHIP_MASTER: '/api/v1/master/heirship',
    HEIRSHIP_LIST: '/api/v1/heirship',
    HEIRSHIP_DETAIL: '/api/v1/heirship/:id',
    HEIRSHIP_UNLOCK: '/api/v1/heirship/:id/:field',
    HEIRSHIP_UPDATE: '/api/v1/heirship/:id',
    HEIRSHIP_DELETE: '/api/v1/heirship/:id',
    HEIRSHIP_ADD_FAVORITE: '/api/v1/heirship/favorite/:id',
    HEIRSHIP_DELETE_FAVORITE: '/api/v1/heirship/favorite/:id',

    EVICTION_MASTER: '/api/v1/master/eviction',
    EVICTION_LIST: '/api/v1/eviction',
    EVICTION_DETAIL: '/api/v1/eviction/:id',
    EVICTION_UNLOCK: '/api/v1/eviction/:id/:field',
    EVICTION_UPDATE: '/api/v1/eviction/:id',
    EVICTION_DELETE: '/api/v1/eviction/:id',
    EVICTION_ADD_FAVORITE: '/api/v1/eviction/favorite/:id',
    EVICTION_DELETE_FAVORITE: '/api/v1/eviction/favorite/:id',

    DIVORCE_MASTER: '/api/v1/master/divorce',
    DIVORCE_LIST: '/api/v1/divorce',
    DIVORCE_DETAIL: '/api/v1/divorce/:id',
    DIVORCE_UNLOCK: '/api/v1/divorce/:id/:field',
    DIVORCE_UPDATE: '/api/v1/divorce/:id',
    DIVORCE_DELETE: '/api/v1/divorce/:id',
    DIVORCE_ADD_FAVORITE: '/api/v1/divorce/favorite/:id',
    DIVORCE_DELETE_FAVORITE: '/api/v1/divorce/favorite/:id',

    LOAN_MODIFICATION_MASTER: '/api/v1/master/loan-modification',
    LOAN_MODIFICATION_LIST: '/api/v1/loan-modification',
    LOAN_MODIFICATION_DETAIL: '/api/v1/loan-modification/:id',
    LOAN_MODIFICATION_UNLOCK: '/api/v1/loan-modification/:id/:field',
    LOAN_MODIFICATION_UPDATE: '/api/v1/loan-modification/:id',
    LOAN_MODIFICATION_DELETE: '/api/v1/loan-modification/:id',
    LOAN_MODIFICATION_ADD_FAVORITE: '/api/v1/loan-modification/favorite/:id',
    LOAN_MODIFICATION_DELETE_FAVORITE: '/api/v1/loan-modification/favorite/:id',

    APPT_OF_SUB_TRUSTEE_MASTER: '/api/v1/master/appt-of-sub-trustee',
    APPT_OF_SUB_TRUSTEE_LIST: '/api/v1/appt-of-sub-trustee',
    APPT_OF_SUB_TRUSTEE_DETAIL: '/api/v1/appt-of-sub-trustee/:id',
    APPT_OF_SUB_TRUSTEE_UNLOCK: '/api/v1/appt-of-sub-trustee/:id/:field',
    APPT_OF_SUB_TRUSTEE_UPDATE: '/api/v1/appt-of-sub-trustee/:id',
    APPT_OF_SUB_TRUSTEE_DELETE: '/api/v1/appt-of-sub-trustee/:id',
    APPT_OF_SUB_TRUSTEE_ADD_FAVORITE: '/api/v1/appt-of-sub-trustee/favorite/:id',
    APPT_OF_SUB_TRUSTEE_DELETE_FAVORITE: '/api/v1/appt-of-sub-trustee/favorite/:id',

    LIS_PENDENS_MASTER: '/api/v1/master/lis-pendens',
    LIS_PENDENS_LIST: '/api/v1/lis-pendens',
    LIS_PENDENS_DETAIL: '/api/v1/lis-pendens/:id',
    LIS_PENDENS_UNLOCK: '/api/v1/lis-pendens/:id/:field',
    LIS_PENDENS_UPDATE: '/api/v1/lis-pendens/:id',
    LIS_PENDENS_DELETE: '/api/v1/lis-pendens/:id',
    LIS_PENDENS_ADD_FAVORITE: '/api/v1/lis-pendens/favorite/:id',
    LIS_PENDENS_DELETE_FAVORITE: '/api/v1/lis-pendens/favorite/:id',

    NOTICE_OF_DEFAULT_MASTER: '/api/v1/master/notice-of-default',
    NOTICE_OF_DEFAULT_LIST: '/api/v1/notice-of-default',
    NOTICE_OF_DEFAULT_DETAIL: '/api/v1/notice-of-default/:id',
    NOTICE_OF_DEFAULT_UNLOCK: '/api/v1/notice-of-default/:id/:field',
    NOTICE_OF_DEFAULT_UPDATE: '/api/v1/notice-of-default/:id',
    NOTICE_OF_DEFAULT_DELETE: '/api/v1/notice-of-default/:id',
    NOTICE_OF_DEFAULT_ADD_FAVORITE: '/api/v1/notice-of-default/favorite/:id',
    NOTICE_OF_DEFAULT_DELETE_FAVORITE: '/api/v1/notice-of-default/favorite/:id',

    CODE_VIOLATION_MASTER: '/api/v1/master/other-lead-type/code-violation',
    CODE_VIOLATION_LIST: '/api/v1/other-lead-type/code-violation',
    CODE_VIOLATION_DETAIL: '/api/v1/other-lead-type/code-violation/:id',
    CODE_VIOLATION_UNLOCK: '/api/v1/other-lead-type/code-violation/:id/:field',
    CODE_VIOLATION_UPDATE: '/api/v1/other-lead-type/code-violation/:id',
    CODE_VIOLATION_DELETE: '/api/v1/other-lead-type/code-violation/:id',
    CODE_VIOLATION_DOWNLOAD: '/api/v1/other-lead-type/code-violation/:id',
    CODE_VIOLATION_ADD_FAVORITE: '/api/v1/other-lead-type/code-violation/favorite/:id',
    CODE_VIOLATION_DELETE_FAVORITE: '/api/v1/other-lead-type/code-violation/favorite/:id',

    DELINQUENT_TAX_MASTER: '/api/v1/master/other-lead-type/delinquent-tax',
    DELINQUENT_TAX_LIST: '/api/v1/other-lead-type/delinquent-tax',
    DELINQUENT_TAX_DETAIL: '/api/v1/other-lead-type/delinquent-tax/:id',
    DELINQUENT_TAX_UNLOCK: '/api/v1/other-lead-type/delinquent-tax/:id/:field',
    DELINQUENT_TAX_UPDATE: '/api/v1/other-lead-type/delinquent-tax/:id',
    DELINQUENT_TAX_DELETE: '/api/v1/other-lead-type/delinquent-tax/:id',
    DELINQUENT_TAX_DOWNLOAD: '/api/v1/other-lead-type/delinquent-tax/:id',
    DELINQUENT_TAX_ADD_FAVORITE: '/api/v1/other-lead-type/delinquent-tax/favorite/:id',
    DELINQUENT_TAX_DELETE_FAVORITE: '/api/v1/other-lead-type/delinquent-tax/favorite/:id',

    TICKET_LIST: '/api/v1/ticket',
    TICKET_DETAIL: '/api/v1/ticket/:id',
    TICKET_CREATE: '/api/v1/ticket/submit',
    TICKET_COMMENT: '/api/v1/ticket/:id',

    SUBSCRIPTION_GENERAL: '/api/v1/subscription/general',
    SUBSCRIPTION_CURRENT: '/api/v1/subscription/current',
    SUBSCRIPTION_DETAIL_UPGRADE: '/api/v1/subscription/detail/:date',
    SUBSCRIPTION_LIST_PACKAGES: '/api/v1/subscription/package',
    SUBSCRIPTION_LIST_TRANSACTIONS: '/api/v1/subscription/transaction',
    SUBSCRIPTION_CANCELATION_FEEDBACK: '/api/v1/subscription/cancel',

    PACKAGE_PAYMENT_CREATE_ORDER: '/api/v1/subscription/order',
    PACKAGE_PAYMENT_CREATE_ORDER_V2: '/api/v1/subscription/order-v2',

    TEMPLATE_GENERAL: '/api/v1/general',
    TEMPLATE_MARKET_LEAD_FILTER: '/api/v1/master/filter/:id',
    TEMPLATE_MARKET_LEAD_CREATE: '/api/v1/master/filter/:id',
    TEMPLATE_MARKET_LEAD_DELETE: '/api/v1/master/filter/:id/:title',
    TEMPLATE_READ_NOTIFY: '/api/v1/read-notify/:id',

    EXCLUSIVE_CONTENT_LIST: '/api/v1/exclusive-content',

    PLANS_PRICING_LIST: '/api/v1/subscription',
    PLANS_PRICING_MASTER: '/api/v1/subscription/package-v2',
    PLANS_PRICING_DETAIL: '/api/v1/subscription/cart',
    PLANS_PRICING_CREATE: '/api/v1/subscription/submit',
    PLANS_PRICING_UPDATE: '/api/v1/subscription/cart',
    PLANS_PRICING_DELETE: '/api/v1/subscription/cart/:id',

    UPLOAD_FILE: '/api/v1/upload',
}
