import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import NoticeOfDefaultResponse from "../mapping/Response/NoticeOfDefaultResponse";
import {actionApptOfSubTrusteeExport} from "./apptOfSubTrusteeService";

export function master() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_MASTER,
        // params: removeObjectNull({ city_id }),
        parser: data => new NoticeOfDefaultResponse().exportMaster(data.data.result),
    }).get();
}

export function actionList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionExport(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_LIST,
        params: params,
        isDownload: true,
        parser: data => data,
    }).get();
}

export function actionDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_DETAIL,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new NoticeOfDefaultResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).put(data);
}

export function unlock(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_UNLOCK,
        endpointParams: {id: encodeURIComponent(data?.id), field: encodeURIComponent(data?.field)},
    }).post(data);
}

export function deleteData(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_DELETE,
        endpointParams: {id: encodeURIComponent(id)},
    }).delete();
}

export function addFavorite(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_ADD_FAVORITE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).post(data);
}

export function deleteFavorite(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.NOTICE_OF_DEFAULT_DELETE_FAVORITE,
        endpointParams: {id: encodeURIComponent(id)},
    }).delete();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new NoticeOfDefaultResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
